<!--
* @program: office_automation 
* @author: ly
* @component:DoAssessment 
* @description: 年度考核评定
* @create: 2022-02-25 17:29
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="refresh" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-radio-group @change="radioChange" style="margin-left: 10px" v-model:value="type">
                <a-radio value="1">查看所有记录</a-radio>
                <a-radio value="2">按用户查询</a-radio>
                <a-radio value="0">按年度查看</a-radio>
            </a-radio-group>
            <a-date-picker v-if="type === '0'" v-model:value="year" picker="year" placeholder="请选择年度"  @change="yearChange" />
            <a-select
                    style="width: 150px"
                    v-if="type === '2'"
                    show-search
                    v-model:value="userId"
                    placeholder="输入姓名查询"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @select="handleSelect"
                    @search="handleSearch">
                <a-select-option v-for="option in selectOptions" :key="option.id">
                    <a-tooltip :title="option.name">
                        <a-button style="padding: 0" type="text">
                            {{ option.name }}
                        </a-button>
                    </a-tooltip>
                </a-select-option>
            </a-select>
        </div>
        <div style="width: 100%;height: 100%;display: flex" >
            <div class="main-content25" style="min-width:400px">
                <LTable :data-source="dataSource" :columns="tableColumns">
                    <template #action="{record}">
                        <div class="action-column">
                            <a-button @click="showDetail(record)" class="action-button" type="link">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content75" style="min-width:400px">
                <AssessmentViewer :assessment-name="currentRecord.typeName" :view-model="false" :assessment-data="AssessmentData" user-type="judge" />
            </div>
        </div>
    </div>
</template>

<script>

    import {SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import assessmentYear from "../../../../assets/api/assessmentYear";
    import userInfo from "../../../../assets/api/userInfo";
    import LTable from "../../../../components/table/LTable";
    import AssessmentViewer from "../Monthly/AssessmentViewer";
    import ColumnType from "@/assets/tables/column_type";
    import Column from "@/assets/tables/column";
    import dayjs from "dayjs"
    export default {
        name: "DoAssessment",
        components: {
            LTable,
            SyncOutlined, SearchOutlined,
            AssessmentViewer
        },
        data(){
            return{
                year:null,
                dataSource:[],//提供table的DataSource
                tableColumns:[
                    new Column("年","year",ColumnType.String).setTableView(60),
                    new Column("姓名","userName",ColumnType.String).setTableView(80),
                    new Column("考核标准","typeName",ColumnType.String).setTableView(120),
                    new Column("操作","actions","actions").setTableView(80)
                ], //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                AssessmentData:null,
                viewModel:true,
                tips:"请选择查询年",
                type:"1",
                selectOptions:[],
                currentRecord:{}
            }
        },
        methods:{
            init(){
                this.type = "0";
                this.viewModel = true;
                this.AssessmentData = null;
                this.radioChange();
            },
            refresh(){
                this.init();
            },
            yearChange(){
                if(this.year){
                    let data = {
                        year:this.year.format("YYYY")
                    };
                    this.loading = true;
                    assessmentYear.queryByJudgeUser.urlPost(data,res=>{
                        this.loading = false;
                        if(res.data.flag){
                            this.dataSource = res.data.data;
                        }
                        else{
                            this.dataSource = []
                        }
                    })
                }
            },
            tableInit(){
                let data = {};
                this.loading = true;
                assessmentYear.queryByJudgeUser.urlPost(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            radioChange(){
                this.AssessmentData = null;
                this.selectOptions = [];
                this.userId = null;
                switch (this.type) {
                    case "0":{
                        this.yearChange();
                        break;
                    }
                    case "1":{
                        this.tableInit();
                        break;
                    }
                }
            },
            showDetail(record){
                this.currentRecord = record;
                this.AssessmentData = record.userDTOS;
                this.AssessmentData.sort((a,b)=>{
                    return a.sortOrder - b.sortOrder
                })
            },
            handleSearch(key){
                if(key){
                    key = key.replace(/'/g, '');
                    let data={
                        queryText:key,
                        disableState:"1",loginState:"0"
                    };
                    userInfo.queryAll.urlPost(data,res=>{
                        if(res.data.flag){
                            res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                            this.selectOptions = res.data.data;
                        }
                        else{
                            this.selectOptions = [];
                        }
                    })
                }
            },
            handleSelect(){
                let data = {
                    userId:this.userId
                };
                this.loading = true;
                assessmentYear.queryByJudgeUser.urlPost(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            }
        },
        created() {
            this.year = dayjs();
            this.init();
        }
    }
</script>

<style scoped>

</style>