<!--
* @program: TYHOA2 
* @author: ly
* @component:MenuItem 
* @description: 
* @create: 2023-09-15 14:05
-->
<template>
    <div :class="item.enable?'menu-item-enable':'menu-item'">
        {{item.cnName}}
    </div>
</template>

<script>
    export default {
        name: "MenuItem",
        props:["item"],
    }
</script>

<style scoped>
    .menu-item-enable{
        width: 220px;
        height: 120px;
        margin: 10px;
        background: #fafafa;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        border: 1px solid #E1E6EE;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 900;
        cursor: pointer;
    }
    .menu-item{
        width: 220px;
        height: 120px;
        margin: 10px;
        background: #d7d5d4;
        color: #909090;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        border: 1px solid #E1E6EE;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 900;
        cursor: pointer;
    }
</style>