<!--
* @program: TYHOA2 
* @author: ly
* @component:OutcomePayAuditSetting 
* @description: 外协支付审核流程设定   其他支付审核流程设定
* @create: 2024-09-14 15:00
-->
<template>
    <div class="column-flex">
        <ProjectPayAuditSetting :view-model="false"/>
        <OtherPayAuditSetting :view-model="false"/>
    </div>
</template>

<script>
    import ProjectPayAuditSetting from "./OutcomePayAuditSetting/ProjectPayAuditSetting";
    import OtherPayAuditSetting from "./OutcomePayAuditSetting/OtherPayAuditSetting";
    export default {
        name: "OutcomePayAuditSetting",
        components:{
            ProjectPayAuditSetting,OtherPayAuditSetting
        }
    }
</script>

<style scoped>

</style>