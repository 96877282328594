/**
 * @program: office_automation
 * @author: ly
 * @description: AssessmentRole
 * @create: 2022-02-24 15:22
 * 月度考核角色
 **/

import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

export default class AssessmentRole extends Table{
    constructor() {
        super();
        this.name = "AssessmentRole";
        this.CNName = "月度考核角色";
        this.columnArray = [
            new Column("角色","roleName",ColumnType.String,true).setTableView(100),
            new Column("角色代码","roleCode",ColumnType.String).setTableView(100),
            new Column("人员","userNames",ColumnType.String).setTableView(200),
            new Column("角色说明","remarks",ColumnType.String).setTableView(300),
        ];
        this.actions = new Column("操作","actions","actions",false).setTableView(100);
    }
}