import { createApp } from 'vue'
import App from './App.vue'
import {message } from 'ant-design-vue';
import andt from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import {createRouter,createWebHashHistory} from "vue-router"
import config from '../package.json'
import 'dayjs/locale/zh-cn';
import dayjs from "dayjs";
import "./main.css"
dayjs.locale('zh-cn');
dayjs.suppressDeprecationWarnings = true;
import {cryptoGetItem,cryptoSetItem} from "@/assets/webCryptoApply";
import axios from "axios";
axios.defaults.withCredentials = true;


const app = createApp(App);
app.config.productionTip = false;
app.config.globalProperties.$message = message;
app.config.globalProperties.$config = config;
app.config.globalProperties.$getItem = cryptoGetItem;
app.config.globalProperties.$setItem = cryptoSetItem;
app.config.globalProperties.$version = config.version;


import EquipmentComp from "@/page/Equipment/EquipmentComp";
import PersonalCenterComp from "@/page/PersonalCenter/PersonalCenterComp"
import FinanceComp from "@/page/Finance/FinanceComp";
import HumanResourcesComp from "@/page/HumanResources/HumanResourcesComp";
import WorkAdministrationComp from "@/page/WorkAdministration/WorkAdministrationComp";
import SystemComp from "@/page/System/SystemComp";
import OutComePayPDF from "@/page/WorkAdministration/ProjectOutcome/OutComePayPDF";
const routes = [
    {path: '/', redirect: { name: 'login' }},
    {path: '/main', redirect: { name: 'login' }},
    {path: '/login', component: ()=>import("@/page/Other/LoginPage"), name:"login",meta:{checkAuth:"notNeed"}},
    {path: '/OutComePayPDF', component: OutComePayPDF, name:"OutComePayPDF",meta:{checkAuth:"notNeed"}},
    {path: '/ForgetPassword', component: ()=>import("@/page/Other/ForgetPassword"), name:"ForgetPassword",meta:{checkAuth:"notNeed"}},
    {path: '/404', component: ()=>import("@/page/Other/Page404"), name:"404",meta:{checkAuth:"notNeed"}},
    {path: '/r', component: ()=>import("@/page/MainRoute"), name:"r",
        children:[
            {path: 'NoAuth', component: ()=>import("@/page/Other/PageNoAuth"), name:"NoAuth"},
            {path: 'HumanResources', component: ()=>import("@/page/HumanResources/HumanResources"),
                name:"HumanResources",children:HumanResourcesComp,
                meta:{main:true,cnName:"人"}
            },
            {path: 'Finance', component: ()=>import("@/page/Finance/Finance"),
                name:"Finance",children:FinanceComp,
                meta:{main:true,cnName:"财"}
            },
            {path: 'Equipment', component: ()=>import("@/page/Equipment/Equipment"),
                name:"Equipment", children:EquipmentComp,
                meta:{main:true,cnName:"物"}
            },
            {path: 'WorkAdministration', component: ()=>import("@/page/WorkAdministration/WorkAdministration"),
                name:"WorkAdministration",children:WorkAdministrationComp,
                meta:{main:true,cnName:"事"}
            },
            {path: 'PersonalCenter', component: ()=>import("@/page/PersonalCenter/PersonalCenter"),
                name:"PersonalCenter",children:PersonalCenterComp,
                meta:{cnName:"个人"}
            },
            {path: 'System', component: ()=>import("@/page/System/System"),
                name:"System",children:SystemComp,
                meta:{cnName:"系统"}
            },
        ]

    },


];
const router = createRouter({
    history: createWebHashHistory(),
    routes:routes // short for `routes: routes`
});

//刷新后从缓存读取菜单信息，如果修改菜单权限的话，缓存需要刷新才能更新
import RouterGuards from "@/router/routerGuards";
try{
    RouterGuards(router);
}
catch (e) {
    RouterGuards(router);
}

app.use(router);
app.use(andt);
app.mount("#app");
app.config.unwrapInjectedRef = true;
let axiosInstance = axios.create()

export {app,router,axiosInstance};