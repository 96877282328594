<!--
* @program: office_automation
* @author: ly
* @component:AttendanceRecords
* @description:
* @create: 2022-01-14 17:03
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-input placeholder="姓名" style="width: 150px" v-model:value="filter.userName"/>
            <a-date-picker  style="width: 120px" v-model:value="yearMonth" picker="month" placeholder="请选择月份"  @change="init" />
            <a-button @click="modalVisible = true" type="dashed">
                <template #icon>
                    <FolderOpenOutlined/>
                </template>
                上传月度考勤记录
            </a-button>
            <a-button @click="downLoadFile" type="dashed">
                下载文件模板
            </a-button>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <LTable :columns="tableColumns" :dataSource="source">

            </LTable>
        </div>
    </div>


    <a-modal v-model:open="modalVisible"
             destroyOnClose
             :footer="null"
             title="选择考勤文件和对应的月份">
        <div class="center-flex">
            <a-date-picker  style="width: 120px" v-model:value="yearMonth2" picker="month" placeholder="请选择月份" />
            <a-upload :accept="accept"
                      :action="uploadUrl"
                      :data="withData"
                      :show-upload-list="false"
                      @change="handleChange"
                      v-model:file-list="fileList"
            >
                <a-tooltip title="上传记录考勤记录的excel文件">
                    <a-button type="primary" danger :loading="loading">
                        <template #icon><FolderOpenOutlined/></template>
                        上传考勤记录
                    </a-button>
                </a-tooltip>
            </a-upload>
        </div>
    </a-modal>
</template>

<script>
    import {FolderOpenOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import attendance from "../../../../assets/api/attendance";
    import dayjs from "dayjs";
    import LTable from "../../../../components/table/LTable";
    import sys_attendance from "../../../TableStruct/sys_attendance";
    import {ArrayFilter} from "../../../../assets/utils/general";
    export default {
        name: "AttendanceRecords",
        components: {
            LTable,
            FolderOpenOutlined,SyncOutlined
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
            withData(){
                if(this.yearMonth2){
                    return {yearMonth:this.yearMonth2.format('YYYY-MM')}
                }
                else{
                    return {}
                }
            }
        },
        data(){
            return{
                accept:"application/vnd.ms-excel",
                uploadUrl:attendance.importExcel.url,
                fileList: [],
                yearMonth: dayjs(),
                yearMonth2: dayjs(),
                modalVisible: false,
                dataSource: null,
                tableColumns: new sys_attendance().getTableColumns(),
                loading: false,
                filter:{},
                visible:false,
            }
        },
        methods: {
            init(){
                let data = {
                    yearMonth:this.yearMonth.format("YYYY-MM")
                };
                attendance.queryAll.post(data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.dataSource = this.dataSource.map(item=>{
                            item.overHour = parseInt(item.actualHour) - parseInt(item.standHour);
                            return item
                        })

                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            handleChange({file}) {
                if (file.status === 'done') {
                    if (file.response.flag) {
                        this.$message.success(file.response.msg);
                    } else {
                        this.$message.error(file.response.msg);
                    }
                }
            },
            downLoadFile(){
                attendance.exportExcel.fileDownloadGet({},"考勤文件模板.xls");
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>
