/** 
* 自动生成文件，请勿修改 
* @name: 项目:项目管理模块
* @description: 项目-项目管理模块-增删改查(二期修改，增加字段类型、类别、预算)
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:24条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const itemInfo = { 
	/** 1 summary:导出-绩效汇总那个大表结果
	description:参数：year:项目年度;name:项目名称;auditState:审核状态：0：未审核；1：已通过；2：未通过;haveTask:有无分配任务，true:有分配任务；false：未分配任务;havePayRecord:有无兑付记录，true:有；false：没有
	method:post
	params:需要配合Column函数使用
	*/ 
	exportReport : new APIObject.postObject("/api/itemInfo/exportReport.do"), 
	/** 2 summary:查询-仅当前登录用户创建的（分页）
	description:参数：pageBean分页对象(page、limit参数)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPageByUser : new APIObject.postObject("/api/itemInfo/queryPageByUser.do"), 
	/** 3 summary:统计各项目下报账支出情况-(导出)
	description:参数：无
	method:get
	params:
	*/ 
	exportItemExpend : new APIObject.getObject("/api/itemInfo/exportItemExpend.do"), 
	/** 4 summary:查询-所有项目数据（带出项目的附带数据：收款、合同、兑付、报账、外协付款、任务等）
	description:参数：ItemInfoQueryVO对象，项目属性中一些查询字段单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAllItem : new APIObject.postObject("/api/itemInfo/queryAllItem.do"), 
	/** 5 summary:查询(根据承接公司id查询)-分页
	description:参数：(page、limit,分页参数，int型)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPageByCompany : new APIObject.postObject("/api/itemInfo/queryPageByCompany.do"), 
	/** 6 summary:审核完结申请
	description:参数：单条数据的主键id，完成状态
	method:post
	params:需要配合Column函数使用
	*/ 
	audit : new APIObject.postObject("/api/itemInfo/audit.do"), 
	/** 7 summary:更新兑付
	description:参数：无
	method:post
	params:
	*/ 
	updduifu : new APIObject.postObject("/api/itemInfo/updduifu.do"), 
	/** 8 summary:查询-项目绩效兑付汇总那个大表结果
	description:参数：year:项目年度;name:项目名称;auditState:审核状态：0：未审核；1：已通过；2：未通过;haveTask:有无分配任务，true:有分配任务；false：未分配任务;havePayRecord:有无兑付记录，true:有；false：没有
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAllExport : new APIObject.postObject("/api/itemInfo/queryAllExport.do"), 
	/** 9 summary:项目合并================新需求
	description:参数：主项目id(mainItemId),被合并项目id(subItemId)
	method:post
	params:需要配合Column函数使用
	*/ 
	itemMerge : new APIObject.postObject("/api/itemInfo/itemMerge.do"), 
	/** 10 summary:导出项目质量档案excel表-项目质检得分情况-(导出)
	description:参数：
	method:post
	params:需要配合Column函数使用
	*/ 
	exportItemQuality : new APIObject.postObject("/api/itemInfo/exportItemQuality.do"), 
	/** 11 summary:查询-仅当前登录用户创建的（不分页）
	description:参数：无
	method:post
	params:
	*/ 
	queryAllByUser : new APIObject.postObject("/api/itemInfo/queryAllByUser.do"), 
	/** 12 summary:保存/修改-单个项目
	description:参数：单条数据对象ItemInfoVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/itemInfo/saveOrUpd.do"), 
	/** 13 summary:查询-分页
	description:参数：(page、limit,分页参数，int型)、ItemInfoQueryVO对象，项目属性中一些查询字段单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/itemInfo/queryPage.do"), 
	/** 14 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/itemInfo/batchDelete.do"), 
	/** 15 summary:更新等效合同额
	description:参数：单条数据的主键id，dxhte
	method:post
	params:需要配合Column函数使用
	*/ 
	updDxhte : new APIObject.postObject("/api/itemInfo/updDxhte.do"), 
	/** 16 summary:查询-分页(小程序使用-带出项目的外协支付申请记录List)
	description:参数：(page、limit,分页参数，int型)
	method:post
	params:需要配合Column函数使用
	*/ 
	wxPage : new APIObject.postObject("/api/itemInfo/wxPage.do"), 
	/** 17 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/itemInfo/delete.do"), 
	/** 18 summary:查询(根据承接公司id查询)-所有数据
	description:参数：companyId，公司主键id（甲方表中选择）
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAllByCompany : new APIObject.postObject("/api/itemInfo/queryAllByCompany.do"), 
	/** 19 summary:查询-查询项目的外协合同情况
	description:参数：payDateStart-payDateEnd该事件段内有付款的才出来
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOutPay : new APIObject.postObject("/api/itemInfo/queryOutPay.do"), 
	/** 20 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/itemInfo/queryOne.do"), 
	/** 21 summary:保存/修改-批量
	description:参数：List<ItemInfoVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/itemInfo/batchSaveOrUpd.do"), 
	/** 22 summary:根据某外协id得到参与的项目及相关的质检结果
	description:参数：外协单位/人员id,* 1、根据外协id，查询合同表，得到该外协帮我们做的所有项目（签合同才算），查询项目出来  2、根据项目id得到项目质检申请  3、根据质检申请得到质检报告  4、根据项目得到项目参与人员打分记录
	method:post
	params:需要配合Column函数使用
	*/ 
	queryByOutId : new APIObject.postObject("/api/itemInfo/queryByOutId.do"), 
	/** 23 summary:导出个人质量档案excel表-某个人参与的项目质检得分情况-(导出)
	description:参数：userId,userType
	method:get
	params:需要配合Column函数使用
	*/ 
	exportPersonQuality : new APIObject.getObject("/api/itemInfo/exportPersonQuality.do"), 
	/** 24 summary:查询-所有数据
	description:参数：ItemInfoQueryVO对象，项目属性中一些查询字段单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/itemInfo/queryAll.do"), 
};
export default itemInfo;