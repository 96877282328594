/**
 * @program: office_automation
 * @author: ly
 * @description: item_finance_bank_balance
 * @create: 2022-01-24 15:04
 * 银行卡账户
 **/


import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
export default class item_finance_bank_balance extends Table{
    constructor() {
        super();
        this.name = "item_finance_bank_balance";
        this.CNName = "费用类别";
        this.columnArray = [
            new Column("类型","accountType",ColumnType.Enum,false).setTableView(80)
                .setDictEnum("item_finance_bank_balance.accountType"),
            new Column("银行名称","bankName",ColumnType.String,true).setTableView(90),
            new Column("办卡支行名称","subBank",ColumnType.String,true),
            new Column("银行卡号","bankNum",ColumnType.String,true).setTableView(200),
            new Column("初始余额(元)","balance",ColumnType.Number,false),
            new Column("初始余额日期","balanceDate",ColumnType.Date,false),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(70)];
    }
}
