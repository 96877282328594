<!--
* @program: office_automation
* @author: ly
* @component:BankManager
* @description: 银行账户管理
* @create: 2022-01-24 14:53
-->
<template>
    <div class="column-flex">
        <a-spin :spinning="loading" tip="正在查询数据库...">
            <div style="display: flex;flex-wrap: wrap;overflow: auto;">
                <a-row :gutter="12" style="width: 100%;margin-left:0">
                    <a-col :sm="8" :xl="6" :xs="8">
                        <div class="card-total">
                            <a-row class="card-total-title">
                                余额合计
                            </a-row>
                            <a-row class="card-total-money">
                                ￥{{allSum}}
                            </a-row>
                        </div>
                    </a-col>
                    <a-col :sm="8" :xl="6" :xs="8">
                        <div class="card-total">
                            <a-row class="card-total-title">
                                其中贵州中色蓝图
                            </a-row>
                            <a-row class="card-total-money">
                                ￥{{sum1}}
                            </a-row>
                        </div>
                    </a-col>
                    <a-col :sm="8" :xl="6" :xs="8">
                        <div class="card-total">
                            <a-row class="card-total-title">
                                分公司
                            </a-row>
                            <a-row class="card-total-money">
                                ￥{{sum2}}
                            </a-row>
                        </div>
                    </a-col>
                    <a-col :sm="8" :xl="6" :xs="8">
                        <div class="card-total">
                            <a-row class="card-total-title">
                                中色
                            </a-row>
                            <a-row class="card-total-money">
                                ￥{{sum3}}
                            </a-row>
                        </div>
                    </a-col>
                </a-row>
                <a-row :gutter="6" style="width: 100%">
                    <a-col :key="idx" :sm="8" :xl="6"
                           :xs="8" v-for="(item,idx) in dataSource">
                        <a-card hoverable class="card-info">
                            <template #title>
                              <span class="card-title">
                                {{item.bankName}}
                              </span>
                            </template>

                            <a-row class="card-ye">
                                <span style="font-size: 16px;font-weight: 400;color: #8A9FC4;">
                                    余额
                                </span>
                                <span style="margin-left: 10px;font-size: 18px;font-weight: 400;color: #337EFF;">
                                    {{item.currentBlance}}元
                                </span>
                            </a-row>

                            <LForm :form-columns="getFormColumns(item)" :form-data="item" :view-model="true"
                                   style="height: 250px"/>

                            <template #actions>
                                <a-button @click="showFlows(item)" class="show-info" type="link" v-if="!item.visible">
                                    查看详情
                                </a-button>
                                <a-button @click="back(item)" class="show-info" type="link" v-else>
                                    返回
                                </a-button>
                            </template>
                        </a-card>
                    </a-col>
                    <a-col :sm="16" :xl="18" :xs="16">
                        <a-tabs v-if="visible" v-model:activeKey="activeKey">
                            <a-tab-pane key="7" tab="流水汇总">
                                <CardFlows :record="currentRecord" :show-bill="showBill"/>
                            </a-tab-pane>
                            <a-tab-pane key="6" tab="账单一览" v-if="showBill">
                                <ShowBills/>
                            </a-tab-pane>
                            <a-tab-pane key="1" tab="项目收入流水">
                                <ProjectInflow :record="currentRecord"/>
                            </a-tab-pane>
                            <a-tab-pane key="2" tab="项目支出流水">
                                <ProjectOutflow :record="currentRecord"/>
                            </a-tab-pane>
                            <a-tab-pane key="3" tab="其他收入">
                                <OtherInflow :record="currentRecord"/>
                            </a-tab-pane>
                            <a-tab-pane key="4" tab="其他支出">
                                <OtherOutflow :record="currentRecord"/>
                            </a-tab-pane>
                            <a-tab-pane key="5" tab="其他账户转入">
                                <FromOtherBank :record="currentRecord"/>
                            </a-tab-pane>
                        </a-tabs>
                    </a-col>
                </a-row>
                <a-row style="width: 100%;font-size: 15px;color: red;justify-content: center;">
                    让账户的金额变动的流水日期需要在【账户初始余额日期】之后，录入在【账户初始余额日期】之前的流水会导致该银行卡
                    出现各部分流水金额统计起来跟系统余额不同的情况。
                </a-row>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import BankManager from "@/page/Finance/financialManager/BankManager/BankManager";
    import LForm from "@/components/form/LForm";

    export default {
        name: "BankViewer",
        components: {LForm},
        mixins: [BankManager]
    }
</script>

<style scoped>

</style>
