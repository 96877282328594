<!--
* @program: 前台 
* @author: ly
* @component:SLTable
* @description: 
* @create: 2023-01-13 09:31
-->
<template>
    <Transition name="fade">
        <div style="position: relative;width: 100%;height: 100%;">
            <div class="form-title" style="margin: 0" v-if="title">{{title}}</div>
            <a-table
                    size="small"
                    bordered
                    :columns="cols"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    :data-source="dataSource"
                    :rowClassName = "rowClass"
                    :rowKey="rowKey"
                    :pagination="pagination"
                    :loading="loading"
                    :custom-row="customRow"
                    @resizeColumn="handleResizeColumn"
                    :scroll="{x:offsetWidth,y:offsetHeight}"
                    :rowExpandable="(record)=>{return record && record.children && record.children.length>0}"
                    :showExpandColumn="false"
            >
                <template #headerCell="{ column }">
                    <div class = "action-column">{{column.title}}</div>
                </template>
                <template #bodyCell="{record, index, column}">
                    <template v-if="column.type === 'actions'">
                        <slot name="action" :index="index" :record="record" :column="column"/>
                    </template>
                    <template v-else>
                        <DataPresent @click="handleClick(index, record , column)" :index="index+1+passedNum" :column="column" :record="record" :view-model="record.viewModel !== false"/>
                    </template>
                </template>
                <template #footer>
                    <div v-if="download" @click="downLoadExcel" class="table-download-btn">
                        Excel下载
                    </div>
                </template>
            </a-table>
        </div>
    </Transition>
</template>

<script>
    import LTable from "./LTable";
    export default {
        name: "SLTable",
        mixins:[LTable],
        emits:["selectChange"],
        data() {
            return {
                selectedRowKeys:[],
                selectedRows:[]
            }
        },
        methods: {
            onSelectChange(selectedRowKeys, selectedRows){
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
                this.$emit("selectChange",selectedRowKeys,selectedRows)
            }
        }
    }
</script>

<style scoped>

</style>