<!--
* @program: office_automation
* @author: ly
* @component:PromotionPoints
* @description: 晋升评分表
* @create: 2022-03-09 16:49
-->
<template>
    <a-spin :spinning="spinning">
        <h3 v-if="assessmentData">总分：{{fullPoints}}    得分：{{sumPoints.toFixed(2)}}</h3>
        <a-tabs v-if="assessmentData"   v-model:activeKey="activeKey" >
            <a-tab-pane v-for="(item,index) in tabList"  :key="index.toString()" :tab="item.label" >
                <LTable :view-model="true" :page-size="15"  :dataSource="item.dataSource"
                        :columns="tableColumns" >
                    <template #action="{record}">
                        <div  class="action-column">
                            <a-tooltip v-if="userType === 'judge'" title="打分">
                                <a-button @click="judge(record)"  type="link">
                                    打分
                                </a-button>
                            </a-tooltip>
                            <a-tooltip v-if="userType === 'judgeView'" title="评分记录">
                                <a-button @click="judge(record)"  type="link">
                                    记录
                                </a-button>
                            </a-tooltip>
                            <a-tooltip v-if="record.setJudgeUser && userType === 'judgeView' && !viewModel" title="设定评分人">
                                <a-button @click="setJudgeUser(record)"  type="link">
                                    <template #icon><UsergroupAddOutlined style="color: #1890ff"/></template>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </LTable>
            </a-tab-pane>
            <template #renderTabBar="{ DefaultTabBar, ...props }">
                <component :is="DefaultTabBar" v-bind="props" :style="{ zIndex: 1, background: '#fff', }"/>
            </template>
        </a-tabs>
        <div v-else >
            未自动生成晋升评分表，请联系管理员
         </div>
    </a-spin>
    <a-modal v-model:open="visible"
             destroy-on-close
             :footer="null"
             title="评分">
        <PromotionRecordHistory :show-all="userType === 'judgeView'" :record="currentRecord" @init="dataHandle"/>
        <TagShow v-if="userType === 'judge' && !viewModel" :tag="fullScore===999?'请按要求规定输入分数':'满分为：'+fullScore" color="blue">
            <template #content>
                <span>得分：</span><a-input-number :max="fullScore" v-model:value="score"
                                                style="width: 188px; margin-bottom: 8px;"
                                                @pressEnter="saveData"/>
                <br />
                <span>评语：</span><a-input placeholder="请输入评语"
                                         v-model:value="remarks" style="width: 188px; margin-bottom: 8px;"/>
                <br />
                <a-button @click="saveData"  type="primary">
                    提交
                </a-button>
            </template>
        </TagShow>
    </a-modal>
    <a-modal v-model:open="visible2"
             destroyOnClose
             :width="600"
             :footer="null"
             title="同事评分人员选择">
        <a-radio-group  v-model:value="setType" >
            <a-radio value="1">批量设定所有同事评分条目评分人</a-radio>
            <a-radio value="0">单条设定</a-radio>
        </a-radio-group>
        <UserPicker v-model:value="selectedUsers" />
        <a-button type="primary" @click="saveJudgeUser">
            保存
        </a-button>
    </a-modal>
</template>

<script>
    import Welcome from "../../../Other/Welcome";
    import TagShow from "../../../_components/TagShow";
    import LTable from "../../../../components/table/LTable";
    import {CheckOutlined, CloseOutlined,UsergroupAddOutlined} from "@ant-design/icons-vue";
    import PromotionRecordHistory from "./PromotionRecordHistory";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import UserPicker from "../../../common/UserPicker";
    import promotionScore from "../../../../assets/api/promotionScore";
    import scoreRecord from "../../../../assets/api/scoreRecord";

    export default {
        name: "PromotionPoints",
        emits:["update:value"],
        props:["value","viewModel","userType"],//userType user查看分数的人  judge 对分项进行打分的人
        components:{
            Welcome,TagShow, UserPicker,
            LTable,CloseOutlined,CheckOutlined,PromotionRecordHistory,UsergroupAddOutlined
        },
        computed:{
            sumPoints(){
                let num = 0;
                for(let i in this.assessmentData){
                    num += this.assessmentData[i].obtainScore
                }
                return num;
            },
            fullPoints(){
                let num = 0;
                for(let i in this.assessmentData){
                    num += this.assessmentData[i].standardScore
                }
                return num;
            },
            userIds(){
                let ids =[];
                for(let i in this.selectedUsers){
                    ids.push(this.selectedUsers[i].id)
                }
                return ids
            }
        },
        watch:{
            value(){
                this.init()
            },
            assessmentData(){
                this.tabInit()
            }
        },
        data(){
            return{
                activeKey:"0",
                tabList:[],
                tableColumns:[],
                visible:false,
                fullScore:null,
                score:null,
                currentRecord:null,
                remarks:null,
                historyRecord:null,
                assessmentData:null,
                spinning:false,
                visible2:false,
                selectedUsers:null,
                setType:"1"
            }
        },
        methods:{
            init(){
                let data = {
                    applyId:this.value.id
                };
                this.spinning = true;
                if(this.userType ==="judge"){
                    promotionScore.queryByJudgeUser.urlPost(data,res=>{
                        this.spinning = false;
                        if(res.data.flag){
                            this.assessmentData = res.data.data;
                            for(let i in this.assessmentData){
                                if(this.assessmentData[i].bigIndex === "同事评分"){
                                    this.assessmentData[i].setJudgeUser = true;
                                }
                            }
                        }
                        else{
                            this.assessmentData = null
                        }
                    })
                }
                else{
                    promotionScore.queryAll.post(data,res=>{
                        this.spinning = false;
                        if(res.data.flag){
                            this.assessmentData = res.data.data;
                            for(let i in this.assessmentData){
                                if(this.assessmentData[i].bigIndex === "同事评分"){
                                    this.assessmentData[i].setJudgeUser = true;
                                }
                            }
                        }
                        else{
                            this.assessmentData = null
                        }
                    })
                }

            },
            tabInit(){
                this.tabList = [];
                for(let i in this.assessmentData){
                    let tabs = this.tabList.filter(item =>item.label === this.assessmentData[i].bigIndex);
                    if(tabs.length === 0){
                        this.tabList.push(
                            {
                                label:this.assessmentData[i].bigIndex,
                                dataSource:[this.assessmentData[i]]
                            }
                        )
                    }
                    else{
                        tabs[0].dataSource.push(this.assessmentData[i])
                    }
                }
            },
            judge(record){
                this.score = 0;
                this.remarks = null;
                this.fullScore = record.standardScore?record.standardScore:999;
                this.visible = true;
                this.currentRecord = record;
                this.historyRecord = null;
            },
            saveData(){
                let data = {
                    "assessmentId": this.currentRecord.id,
                    "obtainScore": this.score,
                    "remarks": this.remarks
                };
                if(this.historyRecord){
                    data.id = this.historyRecord.id;
                }
                scoreRecord.saveOrUpd.post(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.currentRecord.obtainScore = this.score;
                        this.currentRecord.judgeEnd = true;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            dataHandle(historyRecord){//评分历史数据
                this.historyRecord = historyRecord;
            },
            setJudgeUser(record){
                this.currentRecord = record;
                this.visible2 = true;
            },
            saveJudgeUser(){
                let ids = [];
                if(this.setType === "1"){//统一设定
                    let arr = this.assessmentData.filter(item =>item.setJudgeUser);
                    for(let i in arr){
                        ids.push(arr[i].id);
                    }
                    let data = {
                        ids:ids.toString(),
                        userIds:this.userIds.toString(),
                    };
                    promotionScore.appoint.urlPost(data,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.init();
                            this.visible2 = false;
                        }
                        else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }
                else if(this.setType === "0"){//单条设定
                    let data = {
                        ids:this.currentRecord.id,
                        userIds:this.userIds.toString(),
                    };
                    promotionScore.appoint.urlPost(data,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.init();
                            this.visible2 = false;
                        }
                        else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            }
        },
        created() {
            this.init();
            if(this.viewModel){
                this.tableColumns.pop();
            }
            if(this.userType === "user"){
                this.tableColumns = [
                    new Column("类别","smallIndex",ColumnType.String).setTableView(100),
                    new Column("评分规则","indexDescribe",ColumnType.String).setTableView(300),
                    new Column("满分","standardScore",ColumnType.Number).setTableView(60),
                    new Column("得分","obtainScore",ColumnType.Number).setTableView(60),
                    new Column("评分状态","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                ]
            }
            else if(this.userType === "judge"){
                this.tableColumns = [
                    new Column("类别","smallIndex",ColumnType.String).setTableView(100),
                    new Column("打分人","judgeUserNames",ColumnType.String).setTableView(100),
                    new Column("评分规则","indexDescribe",ColumnType.String).setTableView(300),
                    new Column("满分","standardScore",ColumnType.Number).setTableView(60),
                    new Column("操作","actions","actions").setTableView(60)
                ]
            }
            else if(this.userType === "judgeView"){
                this.tableColumns = [
                    new Column("类别","smallIndex",ColumnType.String).setTableView(100),
                    new Column("打分人","judgeUserNames",ColumnType.String).setTableView(100),
                    new Column("评分规则","indexDescribe",ColumnType.String).setTableView(300),
                    new Column("满分","standardScore",ColumnType.Number).setTableView(60),
                    new Column("得分","obtainScore",ColumnType.Number).setTableView(60),
                    new Column("评分状态","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                    new Column("操作","actions","actions").setTableView(60)
                ]
            }
        }
    }
</script>

<style scoped>

</style>
