<!--
* @program: TYHOA2 
* @author: ly
* @component:WeekReportState 
* @description: 周报的上报进度情况
* @create: 2024-08-30 11:01
-->
<template>
    <div style="width:200px">
        <a-row>
            <a-col :span="18">
                <div>项目负责人上报</div>
            </a-col>
            <a-col :span="6">
                <AuditState :states="states" :state="report.writerState"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col   :span="18">
                <div>部门负责人审核</div>
            </a-col>
            <a-col :span="6">
                <AuditState :state="report.deptApproveState"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col   :span="18">
                <div>部门负责人上报</div>
            </a-col>
            <a-col :span="6">
                <AuditState :states="states" :state="report.deptState"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col   :span="18">
                <div>总经办</div>
            </a-col>
            <a-col :span="6">
                <AuditState  :state="report.reviewState"/>
            </a-col>
        </a-row>
    </div>

</template>

<script>
    import AuditState from "./AuditState";
    export default {
        name: "WeekReportState",
        props:["report"],
        components:{
            AuditState
        },
        data() {
            return {
                states:[
                    {label:"待提交",value:"0",color:"purple"},
                    {label:"已提交",value:"1",color:"blue"},
                ],
            }
        },
    }
</script>

<style scoped>

</style>