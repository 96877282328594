<!--
* @program: tyh-oa 
* @author: ly
* @component:typeAvatar 用户照片
* @description: 
* @create: 2021-04-22 10:18
-->
<template>
    <div v-if="viewModel" style="text-align: center">
        <a-spin :spinning="spinning" v-if="previewImage"  >
<!--            <img  alt="avatar" class="avatar" :src="previewImage" />-->
            <a-image alt="图片" class="avatar" :width="110" :src="previewImage"/>
        </a-spin>
        <div v-else>
            暂无照片
        </div>
    </div>
    <div v-else style="text-align: center">
        <a-image v-if="previewImage" :alt="previewImage" :width="150"  :src="previewImage" />
        <a-upload
                :action="actionUrl"
                list-type="picture-card"
                v-model:file-list="fileList"
                :accept="accept"
                @change="handleChange"
                :showUploadList="false"
                withCredentials>
            <div >
                <plus-outlined />
                <div>上传</div>
            </div>
        </a-upload>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {PlusOutlined} from "@ant-design/icons-vue";
    import api_file from "../../assets/api/file";
    import {GetCurrentFileUrl} from "/src/assets/utils/general"
    export default {
        name: "typeAvatar",
        mixins:[typeBase],
        components:{PlusOutlined},
        data(){
            return{
                accept:"image/png,image/jpeg",
                fileList:[],
                previewVisible:false,
                previewImage:null,
                actionUrl:api_file.upload.url,
                spinning:false
            }
        },
        watch:{
            record(){
                this.previewImage = GetCurrentFileUrl(this.record[this.column.dataIndex]);
            }
        },
        methods:{
            handleChange({file}){
                if(file.status === "uploading"){ //status: "uploading"
                    this.spinning = true;
                }
                else if(file.status==="done"){//status: "done"
                    //this.fileList = [file];
                    this.spinning = false;
                    this.record[this.column.dataIndex] = file.response.data.fileUrl;
                    this.previewImage = GetCurrentFileUrl(file.response.data.fileUrl);
                    this.fileList = [];
                }
                else {
                    this.spinning = false;
                }
            }
        },
        created() {
            this.previewImage = GetCurrentFileUrl(this.record[this.column.dataIndex]);
            console.log(this.record[this.column.dataIndex])
            console.log(this.previewImage)
        }
    }
</script>

<style scoped>

</style>