<!--
* @program: office_automation
* @author: ly
* @component:OtherIncome
* @description: 其他收入
* @create: 2022-01-24 17:23
-->
<template>
    <div class="column-flex">
            <div class="search-bar">
                <a-button @click="refresh"  shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-tooltip title="添加新的其他收入">
                    <a-button @click="addData"  shape="circle" type="dashed">
                        <template #icon><Add/></template>
                    </a-button>
                </a-tooltip>
                <span style="margin: 10px;font-size: 16px">只能修改和删除当天创建的条目</span>
            </div>
            <div style="width: 100%;100%;display: flex">
                <div class="main-content70">
                    <LTable ref="ltable"
                            :download="true"
                            :loading="loading"
                            :data-source="dataSource"
                            :columns="tableColumns"
                            :page-size="pageParam.limit"
                            @pageChange="pageChange">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button class="action-button" type="link" @click="showDetail(record)">
                                    <Search class="pointer"/>
                                </a-button>
                                <a-button  class="action-button" type="link" @click="editDetail(record)">
                                    <EditOutlined class="pointer"/>
                                </a-button>
                                <a-popconfirm

                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(index,record)">
                                    <Delete  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </LTable>
                </div>
                <div class="main-content30">
                    <div v-if="visible" >
                        <LForm title="流水信息" :form-data="formData" :form-columns="formColumns" :view-model="viewModel" >
                            <template #action="{record}">
                                <a-button @click="saveData(record)"  type="primary">
                                    提交
                                </a-button>
                            </template>
                        </LForm>
                    </div>
                    <welcome v-else />
                </div>
            </div>
    </div>
</template>

<script>
    import Welcome from "@/page/Other/Welcome";
    import Icons from "@/page/common/Icons";
    import LTable from "@/components/table/LTable";
    import LForm from "@/components/form/LForm";
    import item_finance_income from "@/page/TableStruct/item_finance_income";
    import bankBalance from "@/assets/api/bankBalance";
    import income from "@/assets/api/income";
    export default {
        name: "OtherIncome",
        mixins:[Icons],
        components: {
            LTable,LForm,Welcome
        },
        data(){
            return{
                pageParam:{
                    page: 1,
                    limit: 15,
                },
                tableColumns:new item_finance_income().getTableColumns(),
                dataSource:[],
                loading:false,
                formColumns:new item_finance_income().getFormColumns(),
                formData:{},
                viewModel:true,

                visible:false,

            }
        },
        created() {
            this.init();
            bankBalance.queryAll.post({},res=>{
                if(res.data.flag){
                    let cards = [];
                    for(let i in res.data.data){
                        cards.push({
                            id:res.data.data[i].id,
                            name:res.data.data[i].bankName +"【"+ res.data.data[i].bankNum + "】"
                        })
                    }
                }
                else{
                    this.$message.error(res.data.msg);
                }
            });
        },
        methods:{
            init(){
                this.visible = false;
                let urlParam = this.pageParam;
                let formParam = {};
                this.loading = true;
                income.queryPage.bothPost({urlParam,formParam},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        for(let i in res.data.data){
                            if(res.data.data[i].bankName){
                                res.data.data[i].bankNum = res.data.data[i].bankName+"【"+res.data.data[i].bankNum+"】";
                            }
                        }
                        this.dataSource = res.data.data;
                        this.$refs.ltable.setPage(res.data.count);

                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            pageChange(arg){
                this.pageParam.page = arg.pageNum;
                this.pageParam.limit = arg.pageSize;
                let urlParam = this.pageParam;
                let formParam = {};
                this.loading = true;
                income.queryPage.bothPost({urlParam,formParam},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        for(let i in res.data.data){
                            if(res.data.data[i].bankName){
                                res.data.data[i].bankNum = res.data.data[i].bankName+"【"+res.data.data[i].bankNum+"】";
                            }
                        }
                        this.dataSource = res.data.data;
                        this.$refs.ltable.setTotalSize(res.data.count);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.pageParam.page = 1;
                this.pageParam.limit = 15;
                this.visible = false;
                this.init()
            },
            addData(){
                this.formData = {};
                this.viewModel = false;
                this.visible = true;
            },
            saveData(record){
                income.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            showDetail(record){
                this.formData = record;
                this.viewModel = true;
                this.visible = true;
            },
            editDetail(record){
                this.formData = record;
                this.viewModel = false;
                this.visible = true;
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                income.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
        },

    }
</script>

<style scoped>

</style>
