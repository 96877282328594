/**
 * @program: TYHOA2
 * @author: ly
 * @description: SystemComp
 * @create: 2024-09-02 16:56
 **/

let SystemComp = [
    {path: 'RoleSetting', component: ()=>import("@/page/System/RoleSetting/RoleSetting"), name:"RoleSetting",cnName:"角色管理"},
    {path: 'MenuSetting', component: ()=>import("@/page/System/MenuSetting/MenuSetting"), name:"MenuSetting",cnName:"菜单管理"},
    {path: 'DictSetting', component: ()=>import("@/page/System/DictSetting/DictSetting"), name:"DictSetting",cnName:"数据字典"},
];

export default SystemComp