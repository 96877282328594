<!--
* @program: TYHOA2 
* @author: ly
* @component:BillFlows
* @description: 
* @create: 2024-08-16 16:56
-->
<template>
    <div class="column-flex" style="overflow: auto">
        <div class="search-bar">
            <a-tooltip  title="账单概况">
                <a-button @click="showBill" >
                    <template #icon><file-search-outlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip  title="添加新流水（继承）">
                <a-button v-if="bill.submitState === '0' "  :disabled="!formData.categoryId"  @click="modalAdd(true)">
                    <template #icon><file-add-outlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip  title="添加新流水（空白）">
                <a-button v-if="bill.submitState === '0'"  type="dashed"  @click="modalAdd(false)">
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip  title="顺序">
                <a-button  type="dashed"  @click="sortItem">
                    <template #icon><SortAscendingOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip title="导出流水">
                <a-button @click="downloadFlows" type="dashed">
                    <template #icon><DownloadOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip title="流水详情">
                <a-button @click="visible2 = true"  type="dashed">
                    <template #icon><ExpandOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-input placeholder="项目名称" style="width: 150px" v-model:value="filter.itemName" />
            <div style="margin-left:10px;font-weight: bold;" v-if="bill.submitState === '0'">
                <div v-if="overTime">本账单已超过允许提交的时间 <span style="color: red">（{{bill.recordYearMonth}}-{{deadline}}之前）</span>请联系部门入账人提交或将账单归属时间改为下个月</div>
                <div v-else>请于<span style="color: red">{{bill.recordYearMonth}}-01至{{bill.recordYearMonth}}-{{deadline}}</span>提交本账单</div>
            </div>
        </div>
        <div class="form-title" style="margin:0">
            <div style="margin:0 5px;color:#7b7b7b;">发票面额 {{calcSum.ISum.toFixed(2)}} 元</div>
            <div style="margin:0 5px;color:#00a466;">报销（有发票部分） {{calcSum.WSum.toFixed(2)}} 元</div>
            <div style="margin:0 5px;color:#1b6cb5;">报销（无发票部分） {{calcSum.NSum.toFixed(2)}} 元</div>
            <div style="margin:0 5px;color:#00338b;font-weight: bold;font-size: 18px;">报销合计：{{calcSum.ASum.toFixed(2)}}元</div>
        </div>
        <div style="height:100%">
            <LTable :download="false" :loading="loading1" :columns="tableColumns1" :data-source="flows" >
                <template #action = "{index,record}">
                    <div class = "action-column">
                        <a-button class="action-button" type="link" @click="showDetail(record)">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button v-if="checkAction('edit')" class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="checkAction('delete')"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </LTable>
        </div>
        <div style="min-height:120px">
            <div class="form-title">审核进度</div>
            <BillAuditSteps ref="steps" style="margin: 0 50px" :bill="bill" @update="flowUpdate"/>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :maskClosable="VModel"
             :footer="null">
        <LForm :label-span="8" :view-model="VModel" :form-columns="formColumns" :form-data="formData" >
            <template #action = "{record}">
                <a-button type="primary"  @click="saveData(record)">提交</a-button>
            </template>
        </LForm>
    </a-modal>
    <a-drawer
            :destroyOnClose="true"
            title="流水详情"
            :visible="visible2"
            @close="visible2 = false"
            height="750px"
            placement="bottom">
        <div style="height:630px">
            <LTable :page-size="13" :columns="tableColumns2" :data-source="flows" />
        </div>
    </a-drawer>
</template>

<script>
    import {PlusOutlined, SearchOutlined,SortAscendingOutlined,
        DeleteOutlined,EditFilled,FileAddOutlined,FileSearchOutlined,
        DownloadOutlined,ExpandOutlined
    } from "@ant-design/icons-vue"
    import LForm from "../../../components/form/LForm";
    import LTable from "../../../components/table/LTable";
    import item_finance_record_flow from "./item_finance_record_flow";
    import recordFlow from "../../../assets/api/recordFlow";
    import dayjs from "dayjs"
    import {ArrayFilter} from "../../../assets/utils/general";
    import BillAuditSteps from "./BillAuditSteps";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    export default {
        name: "BillFlows",
        props:["bill","deadline"],
        emits:["edit","show"],
        components:{
            PlusOutlined, SearchOutlined,SortAscendingOutlined,DeleteOutlined,EditFilled,FileAddOutlined,FileSearchOutlined,
            DownloadOutlined,ExpandOutlined,
            LForm,LTable,BillAuditSteps
        },
        watch:{
            bill(){
                this.init()
            }
        },
        computed:{
            flows(){
                if(this.flowIds){
                    if(this.flowIds === "all"){
                        return ArrayFilter(this.dataSource,this.filter);
                    }
                    else{
                        let ids = this.flowIds.split(",");
                        let arr = [];
                        for(let id of ids){
                            for(let item of this.dataSource){
                                if(id === item.id){
                                    arr.push(item);
                                }
                            }
                        }
                        return ArrayFilter(arr,this.filter);
                    }
                }
                else{
                    return ArrayFilter(this.dataSource,this.filter);
                }
            },
            calcSum(){
                // ISum 发票合计
                // WSum 报销（有发票）
                // NSum 报销（无发票）
                // ASum 总合计
                let ISum = 0;
                let WSum = 0;
                let NSum = 0;
                let ASum = 0;
                for(let i in this.flows){
                    ISum += this.flows[i].invoiceAmount;
                    ASum += this.flows[i].applyAmount;
                    if(this.flows[i].invoiceState){ //是否有发票
                        if(this.flows[i].applyAmount>this.flows[i].invoiceAmount){//报销金额大于发票金额
                            WSum += this.flows[i].invoiceAmount;
                            NSum += this.flows[i].applyAmount - this.flows[i].invoiceAmount;
                        }
                        else{
                            WSum += this.flows[i].applyAmount ;
                        }
                    }
                    else{
                        NSum += this.flows[i].applyAmount ;
                    }
                }
                return {ISum,WSum,NSum,ASum}
            },
            overTime(){
                let billDeadLine = dayjs(this.bill.recordYearMonth + "-" + this.deadline);
                return  dayjs().isAfter(billDeadLine);
            }
        },
        data(){
            return{
                visible:false,
                visible2:false,
                table: new item_finance_record_flow(),
                filter:{},
                dataSource:[],
                loading1:false,
                VModel:false,
                tableColumns1:null, //提供table的字-段集 this.table.getTableColumns()
                currentIndex1:null,
                tableColumns2:[
                    new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(200),
                    new Column("支出时间","flowTime",ColumnType.Date,true).setTableView(80),
                    new Column("项目","itemName",ColumnType.String,true).setVisible(false).setTableView(300),
                    new Column("发票金额(元)","invoiceAmount",ColumnType.Number,true).setTableView(100),
                    new Column("报销金额(元)","applyAmount",ColumnType.Number,true).setTableView(100),
                    new Column("报账说明","remarks",ColumnType.String,true).setTableView(300),
                    new Column("报销凭证","invoiceUrl",ColumnType.PreViewFile,true).setTableView(80),
                ], //提供table的字-段集 this.table.getTableColumns()
                currentIndex2:null,
                formColumns:[], //提供Form的字段集 this.table.getFormColumns()
                formData:{},//提供Form的formData
                tableVisible:false,
                sortType:"默认", //0 默认 1 添加时间正序  2 添加时间倒叙   3 项目名称正序
                flowIds:"all",
            }
        },
        methods:{
            init(){
                let timeCol = this.formColumns.find(item=>item.dataIndex ==="flowTime");
                if(timeCol && this.bill){
                    let recordYearMonth = this.bill.recordYearMonth;
                    let end = dayjs(recordYearMonth);
                    let year = end.year();
                    let month = end.month();
                    let month_Previou = year + "-" + month
                    let month_Next = year + "-" + (month + 2);// 下个月
                    timeCol.setEnableTime([month_Previou,month_Next]);
                }
                if(this.bill && this.bill.id){
                    this.loading1 = true;
                    let data = {recordId:this.bill.id};
                    recordFlow.queryAll.urlPost(data,res=>{
                        this.loading1 = false;
                        if(res.data.flag){
                            this.dataSource = res.data.data;
                            /**
                             * 因为前后台对于 invoiceAmount 理解不一致，前台为发票金额 ，后台为支出金额
                             * 在存储无发票的时候，需要将invoiceAmount与applyAmount的值保持一致，所以导致在查询无发票的时候结果中，
                             * invoiceAmount字段会有属性值，但是前台展示的为发票金额，并不是支出金额，所以现在需要将查询结果中无发票
                             * 的条件下，将invoiceAmount值清空
                             */
                            for(let i in this.dataSource){
                                if(!this.dataSource[i].invoiceState){
                                    this.dataSource[i].invoiceAmount = 0;
                                }
                            }

                        }
                        else{
                            this.dataSource = []
                            //this.$message.error(res.data.msg);
                        }
                    });
                }
            },
            reload(){
                this.$refs.steps.init();
            },
            saveData(record){
                record.recordId = this.bill.id;
                if(this.checkRecordFlow(record)){
                    recordFlow.saveOrUpd.post(record,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.formData = record;
                            this.visible =false;
                            this.init();
                        }
                        else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            },
            modalAdd(bool){//添加新流水 true 继承前账单  false空白账单
                if(bool){
                    this.formData = Object.keys(this.formData).length !== 0?this.formData:this.table.getFormData();
                    if(this.formData.invoiceUrl){
                        this.formData.invoiceUrl="";
                    }
                    delete this.formData.id;
                    this.visible = true;
                    this.VModel = false;
                }
                else{
                    this.formData=this.table.getFormData();
                    this.visible = true;
                    this.VModel = false;
                }
            },
            showDetail(record){
                this.VModel=true;
                this.visible = true;
                this.formData = record;
            },
            editDetail(record){
                this.VModel=false;
                this.visible = true;
                this.formData = record;
            },
            deleteData(index,record){
                let data = {id:record.id};
                recordFlow.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            checkRecordFlow(flow){
                let step1=false,step2=false;
                //flow.invoiceState  没有发票的情况需要报销凭证，除 技术服务费A（bd2637b3-81cb-4526-82e9-736aba6db811）出差餐补（fbadaa53-2412-44ad-87d1-3ba46ec18157） 除外
                let nonList = ["bd2637b3-81cb-4526-82e9-736aba6db811","fbadaa53-2412-44ad-87d1-3ba46ec18157"];
                //无发票的情况
                if(!flow.invoiceState){
                    /**
                     * 因为前后台对于 invoiceAmount 理解不一致，前台为发票金额 ，后台为支出金额
                     * 在存储无发票的时候，需要将invoiceAmount与applyAmount的值保持一致，所以导致在填写无发票的账单中，
                     * invoiceAmount 需要在这里进行赋值（将报销金额赋给发票金额），而不是由弹出框Form中进行填写
                     */
                    flow.invoiceAmount = flow.applyAmount;
                    /** -------------*/
                    if(flow.invoiceUrl){
                        step1 = true
                    }
                    else {
                        if(nonList.indexOf(flow.categoryId )!==-1) {//不需要凭证的数组除外
                            step1 =  true;
                        }
                        else {
                            this.$message.error("p1没有报销凭证");
                            step1 = false
                        }
                    }
                }
                //有发票的情况 且 发票金额不等于报销金额的情况需要报销凭证
                if(flow.invoiceState && flow.invoiceAmount < flow.applyAmount){
                    if(flow.invoiceUrl){
                        step1 = true
                    }
                    else {
                        this.$message.error("p2没有报销凭证");
                        step1 = false
                    }
                }
                if(flow.invoiceState && flow.invoiceAmount >= flow.applyAmount){
                    delete flow.invoiceUrl;
                    step1 = true;
                }
                if(flow.itemExpenditure){
                    if(flow.itemId){
                        step2 = true;
                    }
                    else{
                        step2 = false;
                        this.$message.error("请选择所属项目")
                    }

                }
                else{
                    if(!flow.itemId){
                        step2 = true;
                    }
                    else{
                        step2 = false;
                        this.$message.error("并非为项目支付")
                    }
                }
                return step1 && step2;
            },
            sortItem(){
                switch (this.sortType) {// 默认  添加时间正序   添加时间倒叙    项目名称正序
                    case "默认" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            return a.createTime - b.createTime;
                        });
                        this.sortType = "添加时间正序";
                        break;
                    }
                    case "添加时间正序" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            return b.createTime - a.createTime;
                        });
                        this.sortType = "添加时间倒叙";
                        break;
                    }
                    case "添加时间倒叙" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            let name1 = a.itemName?a.itemName:"无";
                            let name2 = b.itemName?b.itemName:"无";
                            return name1.localeCompare(name2, 'zh-CN');
                        });
                        this.sortType = "项目名称正序";
                        break;
                    }
                    case "项目名称正序" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            let name1 = a.itemName?a.itemName:"无";
                            let name2 = b.itemName?b.itemName:"无";
                            return name2.localeCompare(name1, 'zh-CN');
                        });
                        this.sortType = "项目名称倒序";
                        break;
                    }
                    case "项目名称倒序" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            return a.createTime - b.createTime;
                        });
                        this.sortType = "添加时间正序";
                        break;
                    }
                }
            },
            flowUpdate(ids){
                this.flowIds = ids;
            },
            showBill(){
                this.$emit("show");
            },
            checkAction(action) {
                switch (action) {
                    case "edit": {
                        return this.bill.submitState === "0" || this.bill.entryState === "3" || this.bill.state === "3";
                    }
                    case "delete": {
                        return this.bill.submitState === "0";
                    }
                }
            },
            downloadFlows() {
                let data = {recordId: this.bill.id};
                let fileName = this.bill.title + ".xls";
                recordFlow.exportExcelByRecord.fileDownloadGet(data, fileName);
            },
        },
        created() {
            this.tableColumns1 = this.table.getTableColumns();
            this.formColumns = this.table.getFormColumns();
            this.init();
        }
    }
</script>

<style scoped>

</style>