<!--
* @program: office_automation
* @author: ly
* @component:PromotionRecordHistory
* @description:
* @create: 2022-03-09 17:37
-->
<template>
    <TagShow v-if="source.length>0" color="red" tag="评分记录" >
        <template #content>
            <LTable :view-model="true" :loading="loading" :page-size="15"
                    :dataSource="dataSource"  :columns="tableColumns" />
        </template>
    </TagShow>
    <h3 v-else>还未进行评分</h3>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import TagShow from "../../../_components/TagShow";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import scoreRecord from "../../../../assets/api/scoreRecord";

    export default {
        name: "PromotionRecordHistory",
        props:["record","showAll"],
        components:{
            LTable,TagShow
        },
        computed:{
            source(){
                if(this.showAll){
                    return this.dataSource
                }
                else{
                    let source = this.dataSource.filter(item=>{return item.createUser === this.userId});
                    if(source.length === 1){
                        this.$emit("init",source[0]);
                    }
                    return source
                }
            }
        },
        data(){
            return{
                dataSource:[],
                tableColumns:[
                    new Column("评分人","judgeUserName",ColumnType.String,false).setTableView(80),
                    new Column("评分","obtainScore",ColumnType.Number,false).setTableView(80),
                    new Column("评语","remarks",ColumnType.String,false).setTableView(150),
                ],
                loading:false,
                userId:null
            }
        },
        methods:{
            init(){
                let data = {
                    "assessmentId": this.record?.id
                    // "judgeUser": "string"
                };
                this.loading = true;
                scoreRecord.queryAll.post(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else {
                        this.dataSource = [];
                    }

                })
            }
        },
        async created() {
            this.init();
            this.userId = await this.$getItem("__myID");
        }
    }
</script>

<style scoped>

</style>
