<!--
* @program: TYH_office_automation 
* @author: ly
* @component:assetsCategoryTree 
* @description: 用于获取assetsCategory类别Id
* @create: 2021-06-25 16:39
-->
<template>
    <a-tree-select
            v-model:value="local_value"
            style="width: 250px"
            placeholder="选择设备类别"
            :field-names="fieldNames"
            :treeData="categoryGroup"
            :treeNodeFilterProp="'categoryName'"
            @change="selectChange"
            allow-clear
            v-model:treeExpandedKeys="expandKey"
    />
</template>

<script>
    export default {
        name: "assetsCategoryTree",
        props:{
            value:{required:true},
            categoryGroup:{
                required:true,
                default:[]
            }
        },
        watch:{
            value(){
                this.local_value = this.value;
            }
        },
        data(){
            return{
                local_value:null,
                fieldNames :{
                    children: 'children',
                    label: 'categoryName',
                    key:"id",
                    value:"id",
                },
                expandKey:[]
            }
        },
        methods:{
            selectChange(){
                this.$emit('update:value', this.local_value);
                this.$emit("change");
            }
        },
        created() {
            if(this.categoryGroup && this.categoryGroup.length > 0){
                this.expandKey = [this.categoryGroup[0].id];
            }
        }
    }
</script>

<style scoped>

</style>