<!--
* @program: tyh-oa
* @author: ly
* @component:billSetting 报账相关设定，基础设定
* @description:
* @create: 2021-04-14 11:15
-->
<template>
    <div class="column-flex" >
        <div class="row-flex">
            <div style="height: 100%;overflow: auto;width: 200px;background: #fafafa;">
                <MenuCardItem :menu="menu1" :enable="currentComp === 'AuditSetting'" @click="change('AuditSetting')"/>
                <MenuCardItem :menu="menu3" :enable="currentComp === 'OutcomePayAuditSetting'" @click="change('OutcomePayAuditSetting')"/>
                <MenuCardItem :menu="menu2" :enable="currentComp === 'CategorySetting'" @click="change('CategorySetting')"/>
            </div>
            <div style="height: 100%;overflow: auto;min-width: 600px;width: calc(100% - 200px);background: #fafafa;box-shadow: 0px 8px 16px 1px rgba(37,84,165,0.15);">
                <component :is="currentComp"/>
            </div>
        </div>
    </div>
</template>

<script>
    import AuditSetting from "./AuditSetting";
    import CategorySetting from "./CategorySetting";
    import MenuCardItem from "../../../Other/OAMenuDefaultPage/MenuCardItem";
    import OutcomePayAuditSetting from "./OutcomePayAuditSetting";
    export default {
        name: "billSetting",
        components:{
            AuditSetting,CategorySetting,MenuCardItem,
            OutcomePayAuditSetting
        },
        data() {
            return {
                currentComp:"AuditSetting",
                menu1:{
                    cnName:'个人账单审核流程',
                },
                menu2:{
                    cnName:'费用类别设定',
                    meta:{
                        content:"报账费用类别设定"
                    }
                },
                menu3:{
                    cnName:'支付审批流程',
                    meta:{
                        content:"外协支付、银行支付"
                    }
                }
            }
        },
        methods: {
            change(name){
                this.currentComp = name;
            }
        }
    }
</script>

<style scoped>

</style>
