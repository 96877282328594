/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工月度考核表-各维度打分记录
* @description: 员工月度考核表-各维度打分记录（三期新增）
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userScore = { 
	/** 1 summary:查询-单条(根据主键)
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/userScore/queryOne.do"), 
	/** 2 summary:保存/修改-批量
	description:参数：List<UserScoreVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/userScore/batchSaveOrUpd.do"), 
	/** 3 summary:查询-分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/userScore/queryPage.do"), 
	/** 4 summary:查询-不分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/userScore/queryAll.do"), 
	/** 5 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/userScore/delete.do"), 
	/** 6 summary:保存/修改-单条
	description:参数：单条数据对象UserScoreVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/userScore/saveOrUpd.do"), 
	/** 7 summary:月度考核分数同步-单人单月
	description:说明：选择人、目标月度、源月度，将源月度的评分自动同步到目标月度上,若不选择源月度，默认找除目标月度外的最后一月作为源。该接口只能同步作业员的考核type=1，且是由部门负责人打分的主观分维度，已经生成的系统打分等不会同步
	method:post
	params:需要配合Column函数使用
	*/ 
	scoreSync : new APIObject.postObject("/api/userScore/scoreSync.do"), 
};
export default userScore;