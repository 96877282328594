<!--
* @program: TYHOA2 
* @author: ly
* @component:WeekReport 
* @description: 周报，部门审核使用
* @create: 2024-08-12 14:04
-->
<template>
    <a-card hoverable class="card-info" >
        <template #title>
            <div class="card-title">
                {{formData.itemName}}
                <a-popover trigger="click" title="周报上报进度">
                    <template #content>
                        <WeekReportState :report="formData"/>
                    </template>
                    <a-button type="link" >进度</a-button>
                </a-popover>
            </div>
        </template>
        <template #extra>
            <div v-if="formData.deptApproveState === '0' && formData.writerState ==='1'" class="action-column">
                <a-popover title="部门审阅意见" trigger="click">
                    <template #content>
                        <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="approveRemark"/>
                        <a-button @click="audit"  type="primary">
                            通过并上报总经办
                        </a-button>
                        <a-button @click="refuse" type="primary" danger>
                            退回负责人
                        </a-button>
                    </template>
                    <a-button  type="primary">
                        <template #icon><UpCircleOutlined /></template>
                        审阅
                    </a-button>
                </a-popover>
            </div>
            <div v-if="formData.deptApproveState === '1' && formData.deptState ==='0'" class="action-column">
                <a-button @click="submit"  type="primary">
                    上报总经办
                </a-button>
                <a-button v-if="vModel" type="link"  @click="editData">
                    编辑
                </a-button>
                <a-button v-if="!vModel" type="link"  @click="saveData">
                    保存
                </a-button>
                <a-button v-if="!vModel" type="link"  @click="cancelEdit">
                    取消
                </a-button>
            </div>
            <div v-if="formData.deptState ==='1' && formData.deptApproveState === '1' && formData.reviewState === '0'" class="action-column">
                <a-button @click="cancel" type="link">
                    撤回
                </a-button>
            </div>
        </template>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>周报时间</div>
            </a-col>
            <a-col class="form-value" :span="18">
                {{formData.weekDate}}({{weekDate}})
            </a-col>
        </a-row>
        <a-row style="margin-bottom: 10px">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>汇报人</div>
            </a-col>
            <a-col class="form-value" :span="4">
                {{formData.createUserName}}
            </a-col>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>完成进度</div>
            </a-col>
            <a-col class="form-value" :span="6">
                <pre class="report-item" >{{formData.completeRatio}}%</pre>
            </a-col>
        </a-row>
        <a-row v-if="!vModel">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>周报时间</div>
            </a-col>
            <a-col class="form-value" :span="6">
                <a-week-picker style="width: 100%;" @change="selectHandle" v-model:value="selectedDate" :disabledDate="disabledDate" placeholder="请选择周查询"/>
            </a-col>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div >完成进度(%)</div>
            </a-col>
            <a-col class="form-value" :span="6">
                <a-input-number style="width: 100%;" :max="100" :min="0" v-model:value="formData.completeRatio"  />
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>本周工作情况</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.thisWeekWork"  placeholder="本周工作情况" :rows="4" />
                <pre v-else class="report-item" >{{formData.thisWeekWork}}</pre>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>下周工作计划</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.nextWeekWork"  placeholder="下周工作计划" :rows="4" />
                <pre v-else class="report-item" >{{formData.nextWeekWork}}</pre>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>目前存在问题</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.existProblem"  placeholder="目前存在问题" :rows="4" />
                <pre v-else class="report-item" >{{formData.existProblem}}</pre>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>目前应对措施</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.solveMethod"  placeholder="目前应对措施" :rows="4" />
                <pre v-else class="report-item" >{{formData.solveMethod}}</pre>
            </a-col>
        </a-row>
        <a-row v-if="formData.writerState === '1' && vModel">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>部门领导审核</div>
            </a-col>
            <a-col  :span="2">
                <AuditState :state="formData.deptApproveState"/>
            </a-col>
            <a-col class="form-value" v-if="formData.deptApproveState !=='0'"  :span="16">
                <pre class="report-item" >{{formData.deptApproveRemark}}</pre>
            </a-col>
        </a-row>
        <a-row v-if="formData.writerState === '1' && vModel">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>总经办审阅</div>
            </a-col>
            <a-col :span="2">
                <AuditState :state="formData.reviewState"/>
            </a-col>
            <a-col v-if="formData.reviewState !=='0'" class="form-value" :span="16">
                <pre class="report-item" >{{formData.reviewSugg}}</pre>
            </a-col>
        </a-row>
    </a-card>
</template>

<script>
    import dayjs from "dayjs";
    import {UpCircleOutlined} from "@ant-design/icons-vue"
    import weekWork from "../../../../assets/api/weekWork";
    import AuditState from "../../../PersonalCenter/WorkCenter/WorkTaskReport/AuditState";
    import WeekReportState from "../../../PersonalCenter/WorkCenter/WorkTaskReport/WeekReportState";
    export default {
        name: "WeekReport",
        props:["report"],
        components:{
            UpCircleOutlined,AuditState,WeekReportState
        },
        data() {
            return {
                selectedDate:null,
                vModel:true,
                formData:{},
                regex:/(\d{4})年第(\d+)周/,
                approveRemark:null
            }
        },
        computed:{
            weekDate(){
                let week = "";
                if(this.formData.weekDate) {
                    const match = this.formData.weekDate.match(this.regex);
                    if(match && match.length === 3){
                        const startOfWeek = dayjs().year(match[1]).week(match[2]).startOf('week');
                        const endOfWeek = dayjs().year(match[1]).week(match[2]).endOf('week');
                        week = startOfWeek.format('YYYY/MM/DD') + "—" + endOfWeek.format('YYYY/MM/DD')
                    }
                }
                return week;
            }
        },
        methods: {
            init() {
                this.approveRemark =  dayjs().format("YYYY-MM-DD")+"已阅";
                this.formData = {...this.report};
                if(this.formData.weekDate){
                    const match = this.formData.weekDate.match(this.regex);
                    if(match){
                        this.selectedDate = dayjs().year(match[1]).week(match[2])
                    }
                }
            },
            audit(){
                if(!this.approveRemark){
                    this.$message.info("请输入审核备注");
                    return;
                }
                let data = {
                    id:this.report.id,
                    approveState:'1',
                    approveRemark:this.approveRemark
                };
                weekWork.approve.urlPost(data,res=>{
                    if(res.data.flag){
                        this.submit();
                        this.formData.deptApproveState = "1";
                        this.formData.deptApproveRemark = this.approveRemark
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            refuse(){
                if(!this.approveRemark){
                    this.$message.info("请输入审核备注");
                    return;
                }
                let data = {
                    id:this.report.id,
                    approveState:'2',
                    approveRemark:this.approveRemark
                };
                weekWork.approve.urlPost(data,res=>{
                    if(res.data.flag){
                        this.formData.deptApproveState = "2";
                        this.formData.approveRemark = this.approveRemark
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            submit(){
                let data = {id:this.report.id};
                weekWork.leaderSubmit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.formData.deptState = "1";
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            cancel(){
                let data = {id:this.report.id};
                weekWork.cancelLeaderSubmit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.formData.deptState = "0";
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            editData(){
                this.vModel = false;
            },
            cancelEdit(){
                this.vModel = true;
                this.init()
            },
            saveData(){
                this.vModel = true;
                weekWork.saveOrUpd.post(this.formData,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>