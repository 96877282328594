<!--
* @program: tyh-oa 
* @author: ly
* @description: typeEnum 
* @create: 2021-03-23 10:44
-->
<template>
    <div v-if="viewModel" >
        <a-tag :color="color" @click="onClick" >
          {{getTags(presentValue)}}
        </a-tag>
    </div>
    <div v-else>
        <a-select v-if="editable"  @blur="blurTrigger" allowClear
                  :placeholder="placeholder"
                  v-model:value="record[column.dataIndex]" style="width: 100%"
                  :options="options"
                  @change="columnTrigger">
            <template #option="{label,color}">
                <a-tag :color="color">{{label}}</a-tag>
            </template>
        </a-select>
        <div v-else >
            <a-tag :color="color" @click="onClick">
                {{getTags(presentValue)}}
            </a-tag>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import ColorMap from "../../assets/utils/ColorMap";
    export default {
        name: "typeEnum",
        mixins:[typeBase],
        computed:{
            options(){
                let options = [];
                for(let item of this.column.enums){
                    let {label,value,color} = item;
                    if(label){
                        options.push({label,value,color})
                    }
                    else{
                        options.push({label:item,value:item,color:item})
                    }
                }
                return options;
            },
            color(){
                let option = this.options.find(item=>item.value === this.record[this.column.dataIndex]);
                if(option){
                    return option.color?option.color:"blue"
                }
                else{
                    return "blue"
                }
            }
        },
        data(){
            return{
                defaultColor:"purple",
                ColorMap:ColorMap
            }
        },
        methods:{
            getTags(value){
                let label = "";
                let option = this.options.find(item=>item.value === value);
                if(option){
                    label = option.label;
                }
                else{
                    label = value;
                }
                return label;
            },
        }
    }
</script>

<style scoped>

</style>