<!--
* @program: TYHOA2 
* @author: ly
* @component:BillEntry 
* @description: 入账管理
* @create: 2024-08-20 17:31
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-input  placeholder="用户名" style="width: 100px" v-model:value="filter.userName" />
            <a-input  placeholder="账单标题" style="width: 150px" v-model:value="filter.title" />
            <a-input  placeholder="归属年月" style="width: 150px" v-model:value="filter.recordYearMonth" />
            <a-input  placeholder="入账人" style="width: 150px" v-model:value="filter.transferUserName" />
            <a-radio-group style="margin-left:15px;border-left: #022d5c solid;padding-left:10px" @change="init" v-model:value="defaultQuery.entryState">
                <a-radio v-for="item in entryState" :key="item.value" :value="item.value">{{item.label}}</a-radio>
            </a-radio-group>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="main-content30" style="height:100%">
                <LTable :loading="loading" :data-source="source" :columns="tableColumns">
                    <template #action="{record}">
                        <div class = "action-column">
                            <a-tooltip title="账单信息">
                                <a-button @click="editDetail(record)"
                                          class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip  title="入账操作">
                                <a-button @click="showModal(record)" class="action-button" type="link">
                                    <SnippetsOutlined class="pointer" style="color: orange;"/>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content75" style="height:100%">
                <BillFlows v-if="currentRecord" :bill="currentRecord" ref="bill"
                          @show="showBill" :deadline="deadline"/>
                <Welcome v-else />
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             :footer="null"
             title="账单详情">
        <LForm :label-span="8" :form-columns="formColumns" :form-data="currentRecord" :view-model="true">

        </LForm>
    </a-modal>

    <a-modal :footer="null" title="账单入账" v-model:open="visible2">
        <a-radio-group v-model:value="entryType">
            <a-radio v-for="item in entryState.filter(item=>item.value !== '0')" :key="item.value" :value="item.value">{{item.label}}</a-radio>
        </a-radio-group>
        <a-form layout="vertical">
            <a-form-item label="请输入原因" v-if="entryType==='2' || entryType ==='3'">
                <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" placeholder="原因" v-model:value="reason"/>
            </a-form-item>
            <a-form-item label="提示" v-if="entryType==='4'">
                <a-textarea value="选择私人入账的时候，该账单会挂到当前用户下"/>
            </a-form-item>
        </a-form>
        <a-button @click="entryData" class="search-item" type="dashed">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import LTable from "../../../components/table/LTable";
    import LForm from "../../../components/form/LForm";
    import {
        SearchOutlined,
        SyncOutlined,SnippetsOutlined
    } from "@ant-design/icons-vue";
    import BillFlows from "../../PersonalCenter/MyBill/BillFlows";
    import Welcome from "../../Other/Welcome";
    import {ArrayFilter, GetDict} from "../../../assets/utils/general";
    import item_finance_record from "../../PersonalCenter/MyBill/item_finance_record";
    import api_item_finance_record from "../../../assets/api/record";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";

    export default {
        name: "BillEntry",
        components:{
            LTable,LForm,
            SyncOutlined,SnippetsOutlined,SearchOutlined,
            BillFlows,Welcome
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
        },
        data() {
            return {
                deadline:20,
                dataSource: [],
                tableColumns: [
                    new Column("报账人","userName",ColumnType.String).setTableView(80),
                    new Column("账单标题","title",ColumnType.String).setTableView(100),
                    new Column("归属时间","recordYearMonth",ColumnType.String).setTableView(100),
                    new Column("入账人","transferUserName",ColumnType.String).setTableView(80),
                    new Column("操作","actions","actions").setTableView(80)
                ],
                formColumns: new item_finance_record().getFormColumns(),
                loading: false,
                defaultQuery: {
                    approvalState:"1",
                    submitState:"1",
                    entryState:"0"//入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                },
                entryState:GetDict("entryState"),
                viewModel:true,
                currentRecord:null,
                filter:{},
                visible:false,
                visible2:false,
                myName:null,
                userId:null,
                entryType:"4",
                reason:null
            }
        },
        methods: {
            init() {
                this.loading = true;
                api_item_finance_record.queryAll.urlPost(this.defaultQuery, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data;
                    } else {
                        this.dataSource = [];
                    }
                });
            },
            editDetail(record) {
                this.viewModel = false;
                this.currentRecord = record;
            },
            showModal(record){
                this.viewModel = false;
                this.currentRecord = record;
                this.visible2 = true;
            },
            showBill(){
                this.visible = true;
                this.viewModel = true;
            },
            entryData(){
                let data = {};
                if (this.entryType === "4") {
                    data = {
                        id: this.currentRecord.id,
                        entryState: this.entryType,
                        transferUser: this.userId
                    };
                } else {
                    data = {
                        id: this.currentRecord.id,
                        entryState: this.entryType,
                        remarks: this.reason,
                    };
                }
                api_item_finance_record.recordEntry.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource = this.dataSource.filter(item=>item.id !==this.currentRecord.id)
                        this.visible2 = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }

        },
        created() {
            this.$getItem("__myID").then(str => {
                this.userId = str;
                this.init()
            });
            this.$getItem("__myName").then(str => {
                this.myName = str;
            })

        }
    }
</script>

<style scoped>

</style>