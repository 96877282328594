<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentWeekReport 
* @description: 部门周报管理
* @create: 2024-08-29 20:32
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init" :loading="loading" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-select placeholder="按部门筛选" v-model:value="departmentId" style="width: 200px" @change="init">
                <a-select-option v-for="item in departments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <div class="form-label" style="margin: 0 20px">本周：{{nowWeek}}</div>
            <a-radio-group style="margin-left:10px" v-model:value="approveState" @change="init">
                <a-radio value="待审核">待审核</a-radio>
                <a-radio value="待上报">待上报</a-radio>
                <a-radio value="待总经办审核">待总经办审核</a-radio>
                <a-radio value="全部">全部</a-radio>
            </a-radio-group>
            <a-week-picker v-model:value="weekDate" placeholder="请选择周查询" @change="init"/>
            <a-input v-model:value="filter.itemName" style="width:150px" placeholder="项目名称" />
        </div>
        <div style="width: 100%;height: 100%;">
            <div style="width: 100%;height:100%;display: flex;flex-wrap: wrap;overflow:auto " v-if="source.length>0">
                <WeekReportItemDepart style="width:600px"  v-for="report in source" :key="report.id" :report="report" />
            </div>
            <div v-else style="position: relative;width: 100%;height: 100%;">
                <a-result status="404" title="暂无数据" />
            </div>
        </div>
    </div>
</template>

<script>
    import {SyncOutlined} from "@ant-design/icons-vue";
    import WeekReportItemDepart from "./WeekReportItemDepart";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import weekWork from "../../../../assets/api/weekWork";
    import dayjs from "dayjs";

    export default {
        name: "DepartmentWeekReport",
        components:{
            SyncOutlined, WeekReportItemDepart
        },
        data() {
            return {
                approveState:"待审核",
                filter:{},
                weekDate:undefined,
                weekReports:[],
                departmentId:undefined,
                departments:[],
                nowWeek:null,
                loading:false,
            }
        },
        computed:{
            source(){
                return ArrayFilter(this.weekReports,this.filter);
            }
        },
        methods: {
            init() {
                let data = {
                    writerState:'1',
                    departId: this.departmentId,
                };
                if(this.weekDate){
                    data.weekDate = this.weekDate.format("YYYY") + "年第" + this.weekDate.week() + "周";
                }
                if(this.approveState ==="待审核"){
                    data.deptApproveState = "0";
                }
                if(this.approveState ==="待上报"){
                    data.deptState = "0";
                }
                if(this.approveState ==="待总经办审核"){
                    data.reviewState = "0";
                    data.deptState = "1";
                }
                this.loading = true;
                weekWork.queryAll.post(data,res=>{
                    if(res.data.flag){
                        this.weekReports = res.data.data;
                    }
                    else{
                        this.weekReports = [];
                    }
                    this.loading = false;
                })
            },
            reviewHandle(record){
                this.weekReports = this.weekReports.filter(item=>item.id !== record.id);
            }
        },
        async created() {
            this.weekDate = dayjs();
            this.nowWeek = this.weekDate.format("YYYY") + "年第" + this.weekDate.week() + "周";
            this.departments = JSON.parse(await this.$getItem("__myDepartments"));
            this.departmentId = this.departments[0].id;
            this.init();
        }
    }
</script>

<style scoped>

</style>