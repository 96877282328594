<!--
* @program: TYHOA2 
* @author: ly
* @component:WeekReportItem
* @description: 
* @create: 2024-08-12 14:04
-->
<template>
    <a-card hoverable class="card-info" >
        <template #title>
            <span class="card-title">
                {{formData.weekDate}}({{weekDate}})
                <a-popover trigger="click" title="周报上报进度">
                    <template #content>
                        <WeekReportState :report="formData"/>
                    </template>
                    <a-button  type="link" >审核进度</a-button>
                </a-popover>
            </span>
        </template>

        <template #extra>
            <div v-if="!viewModel" class="action-column">
                <a-button v-if="vModel && formData.writerState === '0'" type="link"  @click="editData">
                    编辑
                </a-button>
                <a-button v-if="!vModel" type="link"  @click="saveData">
                    保存
                </a-button>
                <a-button v-if="!vModel" type="link"  @click="cancelEdit">
                    取消
                </a-button>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定提交吗?"
                        v-if="vModel && formData.writerState === '0'"
                        @confirm="submit">
                    <a-button style="color: #154a80" type="link">
                        <template #icon>
                            <UpCircleOutlined  class="pointer" title="提交"/>
                        </template>
                        提交
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定撤回吗?"
                        v-if="vModel && formData.writerState === '1' && formData.deptApproveState !== '1'"
                        @confirm="cancel">
                    <a-button style="color: #af9e45" type="link">
                        <template #icon>
                            <RollbackOutlined  class="pointer" title="撤回"/>
                        </template>
                        撤回
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        v-if="vModel && formData.writerState === '0'"
                        @confirm="deleteData">
                    <a-button style="color: red" type="link">
                        <template #icon>
                            <DeleteOutlined   title="删除" class="pointer"/>
                        </template>
                        删除
                    </a-button>
                </a-popconfirm>
            </div>
        </template>
        <a-row v-if="!vModel">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>周报时间</div>
            </a-col>
            <a-col class="form-value" :span="6">
                <a-week-picker style="width: 100%;" @change="selectHandle" v-model:value="selectedDate" :disabledDate="disabledDate" placeholder="请选择周查询"/>
            </a-col>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div >完成进度(%)</div>
            </a-col>
            <a-col class="form-value" :span="6">
                <a-input-number style="width: 100%;" :max="100" :min="0" v-model:value="formData.completeRatio"  />
            </a-col>
        </a-row>
        <a-row v-if="vModel">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>完成进度</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-progress :percent="formData.completeRatio" status="active" />
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>本周工作情况</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.thisWeekWork"  placeholder="本周工作情况" :rows="4" />
                <pre v-else class="report-item" >{{formData.thisWeekWork}}</pre>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>下周工作计划</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.nextWeekWork"  placeholder="下周工作计划" :rows="4" />
                <pre v-else class="report-item" >{{formData.nextWeekWork}}</pre>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>目前存在问题</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.existProblem"  placeholder="目前存在问题" :rows="4" />
                <pre v-else class="report-item" >{{formData.existProblem}}</pre>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>目前应对措施</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.solveMethod"  placeholder="目前应对措施" :rows="4" />
                <pre v-else class="report-item" >{{formData.solveMethod}}</pre>
            </a-col>
        </a-row>
        <a-row v-if="formData.writerState === '1'">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>部门领导审核</div>
            </a-col>
            <a-col  :span="3">
                <AuditState :state="formData.deptApproveState"/>
            </a-col>
            <a-col class="form-value" v-if="formData.deptApproveState !=='0'"  :span="12">
                <pre  class="report-item" >{{formData.deptApproveRemark}}</pre>
            </a-col>
        </a-row>
        <a-row v-if="formData.writerState === '1'">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>总经办审阅</div>
            </a-col>
            <a-col :span="3">
                <AuditState :state="formData.reviewState"/>
            </a-col>
            <a-col v-if="formData.reviewState !=='0'" class="form-value" :span="12">
                <pre class="report-item" >{{formData.reviewSugg}}</pre>
            </a-col>
        </a-row>
    </a-card>
</template>

<script>
    /**
     * deptApproveRemark:
     "2024-08-30已阅"
     deptApproveState:“”
     */
    import dayjs from "dayjs";
    import {UpCircleOutlined,RollbackOutlined,DeleteOutlined} from "@ant-design/icons-vue"
    import weekWork from "../../../../assets/api/weekWork";
    import AuditState from "./AuditState";
    import WeekReportState from "./WeekReportState";
    export default {
        name: "WeekReportItem",
        props:["report","viewModel"],
        emits:["delete"],
        components:{
            UpCircleOutlined,RollbackOutlined,DeleteOutlined,AuditState,WeekReportState
        },
        data() {
            return {
                selectedDate:null,
                vModel:true,
                formData:{},
                regex:/(\d{4})年第(\d+)周/,
            }
        },
        computed:{
            weekDate(){
                let week = "";
                if(this.formData.weekDate) {
                    const match = this.formData.weekDate.match(this.regex);
                    if(match && match.length === 3){
                        const startOfWeek = dayjs().year(match[1]).week(match[2]).startOf('week');
                        const endOfWeek = dayjs().year(match[1]).week(match[2]).endOf('week');
                        week = startOfWeek.format('YYYY/MM/DD') + "—" + endOfWeek.format('YYYY/MM/DD')
                    }
                }
                return week;
            }
        },
        methods: {
            init() {
                this.formData = {...this.report};
                if(this.formData.weekDate){
                    const match = this.formData.weekDate.match(this.regex);
                    if(match){
                        this.selectedDate = dayjs().year(match[1]).week(match[2])
                    }
                }
            },
            disabledDate(current){ //
                let thisWeek = dayjs().week();
                let currentWeek = dayjs(current).week();
                return currentWeek !== thisWeek && currentWeek !== thisWeek -1
            },

            selectHandle(){
                if(this.selectedDate){
                    let year = this.selectedDate.format('YYYY');
                    let week = this.selectedDate.week();
                    this.formData.weekDate = year + '年第' + week + "周";
                }
            },
            editData(){
                this.vModel = false;
            },
            cancelEdit(){
                this.vModel = true;
                this.init()
            },
            saveData(){
                this.vModel = true;
                weekWork.saveOrUpd.post(this.formData,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            submit(){
                let data = {id:this.formData.id};
                weekWork.submit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.formData.writerState = "1";
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            cancel(){
                let data = {id:this.formData.id};
                weekWork.cancelSubmit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.formData.writerState = "0"
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData(){
                let data = {id:this.formData.id};
                weekWork.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$emit("delete",this.formData.id);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>