<!--
* @program: 前台 
* @author: ly
* @component:typeUserRoles 
* @description: 
* @create: 2023-03-31 14:12
-->
<template>
    <div style="display: flex;justify-content: center;flex-wrap: wrap;">
        <div v-for="role in options" :key="role.id">
            <a-tag>{{role.label}}</a-tag>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeUserRoles",
        mixins:[typeBase],
        watch:{
            record(){
                this.init();
                this.columnTrigger();
            }
        },
        data(){
            return{
                options:[],
            }
        },
        methods:{
            init(){
                this.options = [];
                if(this.presentValue && this.presentValue.length >0){
                    if(this.holder === "form"){
                        for(let item of this.presentValue){
                            this.options.push({label:item.roleName});
                        }
                    }
                    else{
                        if(this.presentValue.length > 1){
                            this.options.push({label:this.presentValue[0].roleName});
                            this.options.push({label:'...'});
                        }
                        else if(this.presentValue.length === 1){
                            this.options.push({label:this.presentValue[0].roleName});
                        }
                    }
                }
                else{
                    this.options.push({label:"无角色"});
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>