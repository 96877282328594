/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_contract_info
 * 项目--合同使用
 * @create: 2021-06-09 09:42
 **/
import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import api_sys_user_info from "@/assets/api/userInfo";
import partyInfo from "@/assets/api/partyInfo";

export default class item_contract_info extends Table{
    constructor() {
        super();
        this.name = "item_contract_info";
        this.CNName = "合同信息表";
        this.columnArray = [
            new Column("所属项目","itemName",ColumnType.String,false).setGroup(1),
            new Column("合同类别","type",ColumnType.Enum,false).setTableView(100).setGroup(1)
                .setDictEnum("contractType"),
            new Column("合同名","name",ColumnType.String,true).setTableView(200).setGroup(1).setRequired(),
            new Column("合同编号","number",ColumnType.String,true).setTableView(200).setGroup(1).setRequired(),
            new Column("签署方","associatedKey",ColumnType.IdReplace,true).setGroup(1).setRequired()
                .setIdReplaceObject("associatedName",{api:partyInfo.queryAll,tag:"queryText"}),

          /*  new Column("收款责任人","collectUser",ColumnType.String,true).setGroup(1).setTableView(120),*/
            new Column("合同金额","amount",ColumnType.Number,true).setTableView(150).setGroup(1).setRequired(),
            new Column("签订公司","sigCompany",ColumnType.String,true).setGroup(1).setRequired()
                .setDictEnum("companies"),
            new Column("签订时间","sigDate",ColumnType.Date,true).setGroup(1).setRequired(),
            new Column("签订人","sigUser",ColumnType.IdReplace,true).setTableView(100).setGroup(1).setRequired()
                .setIdReplaceObject("sigUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("合同审核人","auditUser",ColumnType.IdReplace,true).setGroup(2)
                .setIdReplaceObject("auditUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("合同回收人","receiveUser",ColumnType.IdReplace,true).setGroup(2)
                .setIdReplaceObject("receiveUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("合同递交人","submitUser",ColumnType.IdReplace,true).setGroup(2)
                .setIdReplaceObject("submitUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("签收时间","signDate",ColumnType.Date,true).setGroup(2),
            new Column("签收状态","signState",ColumnType.Enum,true).setGroup(2).setRequired()
                .setDictEnum("signState"), //办公人事部签收状态:0:未签收；1：已签收
            new Column("签收人","signUser",ColumnType.IdReplace,true).setGroup(2)
                .setIdReplaceObject("signUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("盖章情况","sealFlag",ColumnType.Enum,true).setTableView(100).setGroup(2).setRequired()
                .setDictEnum("sealFlag"), //文件盖章情况：0：未盖章；1：已盖章
            new Column("合同说明","describe",ColumnType.String,true).setGroup(3),
            new Column("扫描附件","fileUrl",ColumnType.PreViewFile,true).setTableView(100).setGroup(3),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}