<!--
* @program: TYHOA2 
* @author: ly
* @component:LTabs 
* @description:
{tag:"父账单",component:"personalBillViewer",param:res.data.data}
* @create: 2023-08-25 16:11
-->
<template>
    <a-tabs v-if="paneList.length>0" v-model:activeKey="activeKey">
        <a-tab-pane  v-for="(pane,index) in paneList" :key="index" >
            <template #tab>
                <div style="margin:0 10px">{{pane.tag}}</div>
            </template>
            <component :is="pane.component" v-model:param="pane.param" @update="handleUpdate"/>
        </a-tab-pane>
    </a-tabs>
    <Welcome v-else/>
</template>

<script>
    import Welcome from "../../page/Other/Welcome";
    export default {
        name: "LTabs",
        components:{
            Welcome
        },
        props:{
            paneList:{
                required:true,
                default:[]
            }
        },
        emits:["update"],
        data() {
            return {
                activeKey:0
            }
        },
        methods:{
            handleUpdate(data){
                this.$emit("update",data);
            }
        }
    }
</script>

<style scoped>

</style>