<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentMemberKPI
* @description: 
* @create: 2024-09-11 21:16
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button  type="dashed" @click="monthChange">
                <template #icon><SyncOutlined /></template>
            </a-button>
            <a-select v-model:value="departmentId" style="width: 250px"
                      placeholder="通过部门筛选"
                      @change="departmentSelect">
                <a-select-option v-for="item in myDepartments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <a-date-picker  style="width: 120px" v-model:value="yearMonth" picker="month" placeholder="请选择月份"  @change="monthChange" />
            <a-input  placeholder="姓名" style="width: 150px" v-model:value="filter.name" />
        </div>
        <div class="row-flex">
            <div class="main-content60">
                <LTable :columns="tableColumns" :loading="loading" :dataSource="source">
                    <template #action="{record}">
                        <div class="action-column">
                            <a-button class="action-button" type="link" @click="showDetail(record)">
                                <template #icon><SearchOutlined /></template>
                            </a-button>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content40">
                <LTable v-if="dataSource2.length >0" :title="'绩效合计：'+completeMoneySum"
                        :columns="tableColumns2" :loading="loading" :dataSource="dataSource2" />
                <Welcome v-else />
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from "dayjs"
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import Welcome from "../../../Other/Welcome";
    import LTable from "../../../../components/table/LTable";
    import {SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import api_sys_department_relation from "../../../../assets/api/userDepartRelation";
    import taskMonthUser from "../../../../assets/api/taskMonthUser";
    import assessmentUser from "../../../../assets/api/assessmentUser";
    export default {
        name: "DepartmentMemberKPI",
        components:{
            Welcome,LTable,
            SearchOutlined,SyncOutlined,
        },
        computed:{
            source(){
                return ArrayFilter(this.monthScores,this.filter);
            },
            completeMoneySum(){
                let sum = 0;
                for(let item of this.dataSource2){
                    sum += item.completeMoney
                }
                return sum
            }
        },
        data(){
            return{
                departmentId:undefined,
                myDepartments:[],
                filter:{},
                monthScores:[],//按月份查询时候 当前月份的所有分数
                tableColumns:[
                    new Column("时间","yearMonth",ColumnType.String).setTableView(80),
                    new Column("姓名","name",ColumnType.String,true).setTableView(80),
                    new Column("考核得分","score",ColumnType.Number,false).setTableView(80),
                    new Column("月度评级","level",ColumnType.String,false).setTableView(80),
                    new Column("绩效标准值","indexDescribe",ColumnType.Number,false).setTableView(100),
                    new Column("考核结果","actualFinish",ColumnType.Number,false).setTableView(100),
                    new Column("达标比（%）","ratio",ColumnType.Number,false).setTableView(100),
                    new Column("操作","actions","actions",false).setTableView(80)
                ],
                tableColumns2:[
                    new Column("用户","userName",ColumnType.String).setTableView(80),
                    new Column("时间","yearMonth",ColumnType.String).setTableView(80),
                    new Column("任务名称","taskTitle",ColumnType.String).setTableView(200),
                    new Column("下发绩效","completeMoney",ColumnType.String).setTableView(80),
                ],
                dataSource2:[],
                loading:false,
                yearMonth:undefined,
                departmentUserIds:[]
            }
        },
        methods:{
            departmentSelect(){
                if(this.departmentId){
                    this.departmentUserIds = [];
                    this.loading = true;
                    api_sys_department_relation.queryAll.urlPost({departmentId:this.departmentId},res=>{
                        this.loading = false;
                        if(res.data.flag && res.data.data.length>0){
                            for(let item of res.data.data){
                                this.departmentUserIds.push(item.userId);
                            }
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    this.monthScores = [];
                }
            },
            showDetail(record){
                let data ={
                    "userId": record.userId,
                    "yearMonth": record.yearMonth
                };
                taskMonthUser.queryAll.post(data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.dataSource2 = res.data.data
                    }
                    else{
                        this.dataSource2 = [];
                    }
                })
            },
            monthChange(){
                if(this.yearMonth){
                    let data = {
                        yearMonth:this.yearMonth.format("YYYY-MM")
                    };
                    this.loading = true;
                    assessmentUser.queryScore.urlPost(data,res=>{
                        this.loading = false;
                        if(res.data.flag){
                            for(let item of res.data.data){
                                if(item.list.length > 0){
                                    item.id = item.userId;
                                    item.score = parseFloat(item.list[0].sumScore);
                                    item.yearMonth = item.list[0].yearMonth;
                                    item.indexDescribe = item.list[0].assessmentUser?.indexDescribe;
                                    item.actualFinish = item.list[0].assessmentUser?.actualFinish;
                                    if(item.indexDescribe && item.actualFinish){
                                        item.ratio = parseFloat((item.actualFinish/item.indexDescribe*100).toFixed(2));
                                    }
                                    item.level = getLevel(item.score);
                                }
                            }
                            this.monthScores = res.data.data.filter(item=>this.departmentUserIds.indexOf(item.id) !==-1);
                        }
                        else{
                            this.monthScores = [];
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else {
                    this.$message.error("请选择月份进行查询")
                }
            },
        },
        async created() {
            this.myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.departmentId = this.myDepartments[0].id;
            this.yearMonth = dayjs().subtract(1, 'month');
            this.departmentUserIds = [];
            this.loading = true;
            api_sys_department_relation.queryAll.urlPost({departmentId:this.departmentId},res=>{
                this.loading = false;
                if(res.data.flag && res.data.data.length>0){
                    for(let item of res.data.data){
                        this.departmentUserIds.push(item.userId);
                    }
                    this.monthChange();
                }
                else{
                    this.$message.error(res.data.msg);
                }
            });
        }
    }
    function getLevel(score) {
        if(0 <= score && score<=10 ){return "一等"}
        else if (10 < score && score <= 20){return "二等"}
        else if (20 < score && score <= 30){return "三等"}
        else if (30 < score && score <= 40){return "四等"}
        else if (40 < score && score <= 50){return "五等"}
        else if (50 < score && score <= 60){return "六等"}
        else if (60 < score && score <= 70){return "七等"}
        else if (70 < score && score <= 80){return "八等"}
        else if (80 < score && score <= 90){return "九等"}
        else if (90 < score && score <= 100){return "十等"}
        else {return "数据错误"}
    }
</script>

<style scoped>

</style>