/** 
* 自动生成文件，请勿修改 
* @name: 职位:员工晋升考核打分
* @description: 员工晋升考核打分（三期新增）
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const promotionScore = { 
	/** 1 summary:查询-单条(根据主键)
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/promotionScore/queryOne.do"), 
	/** 2 summary:指定打分人：（针对晋升考核中同事评分的几项考核维度）
	description:参数：考核维度主键ids，多个，打分人userIds，多个
	method:post
	params:需要配合Column函数使用
	*/ 
	appoint : new APIObject.postObject("/api/promotionScore/appoint.do"), 
	/** 3 summary:查询-全部不分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/promotionScore/queryAll.do"), 
	/** 4 summary:修改：单条
	description:参数：单条数据对象PromotionScoreVO
	method:post
	params:需要配合Column函数使用
	*/ 
	update : new APIObject.postObject("/api/promotionScore/update.do"), 
	/** 5 summary:查询-（需要自己打分的）当前登录用户为评分人
	description:参数：无参数，后台根据session自己获取当前登录用户
	method:post
	params:需要配合Column函数使用
	*/ 
	queryByJudgeUser : new APIObject.postObject("/api/promotionScore/queryByJudgeUser.do"), 
	/** 6 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/promotionScore/batchDelete.do"), 
	/** 7 summary:修改：多条
	description:参数：List<PromotionScoreVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchUpd : new APIObject.postObject("/api/promotionScore/batchUpd.do"), 
	/** 8 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/promotionScore/delete.do"), 
};
export default promotionScore;