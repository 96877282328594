<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectInfo 
* @description: 
* @create: 2023-09-14 15:32
-->
<template>
    <div class="column-flex" v-if="changeType">
        <a-layout-content style="display: inline-flex;margin: 25vh 10vw">
            <a-card hoverable v-for="(item,index) in itemType" :key="index" class="mode">
                <a-button @click="selectType(item.value)" class="cover" type="primary">{{item.label}}</a-button>
            </a-card>
        </a-layout-content>
    </div>
    <div class="column-flex" v-else>
        <div style="border: 1px solid rgb(235, 237, 240);height: 52px" v-if="!viewModel && mFormData.id">
            <div style="display: flex;align-items: center;font-size:20px;padding: 10px">
                <div>《{{mFormData.name}}》</div>
                <div v-if="!mFormData.dxhte">&nbsp;&nbsp; 预计金额：{{cnNumber(mFormData.itemBudget)}}</div>
                <div v-if="mFormData.dxhte">&nbsp;&nbsp; 等效合同额：{{cnNumber(mFormData.dxhte)}}</div>
                <div v-else>&nbsp;&nbsp; 还未添加等效合同额</div>
                <a-popover  title="等效合同额">
                    <template #content>
                        <a-input-number
                                :max="100000000"
                                :min="0"
                                style="width:200px" v-model:value="dxhte"/>
                        <a-button @click="saveDxhte" type="primary">
                            提交
                        </a-button>
                    </template>
                    <a-tag style="cursor: pointer;margin-left: 20px;height: 22px" color='blue'>修改</a-tag>
                </a-popover>
            </div>
        </div>
        <div style="height: 100%;overflow: auto ">
            <MForm title="基础信息" @save="saveData"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns" :view-model="viewModel"/>
            <MForm title="合同相关" @save="saveData"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns2" :view-model="viewModel"/>
            <MForm v-if="mFormData.id" title="归档状态" @save="saveData"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns1" :view-model="viewModel"/>
            <MForm v-if="mFormData.id" title="项目创建和审核"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns3" :view-model="true"/>
            <MForm v-if="mFormData.id" title="项目完结信息"
                   :labelSpan="3" :row-size="2" :form-data="mFormData" :form-columns="formColumns4" :view-model="true"/>
        </div>
    </div>
</template>

<script>
    import item_info from "../../TableStruct/item_info";
    import api_item_info from "../../../assets/api/itemInfo";
    import MForm from "../../../components/form/MForm";
    import {GetDict} from "../../../assets/utils/general";

    export default {
        name: "projectInfo",
        props:["record"],
        emits:["update","update:record"],
        components: {
            MForm
        },
        computed:{
            projectId(){
                if(this.record && this.record.itemId){
                    return this.record.itemId
                }
                else{
                    return this.record.id
                }
            }
        },
        watch: {
            record() {
                this.init()
            }
        },
        data() {
            return {
                viewModel:false,
                mFormData: {},
                table: new item_info(),
                formColumns: [],
                formColumns1: [],
                formColumns2: [],
                formColumns3: [],
                formColumns4: [],
                formColumns5: [],
                changeType: false,
                spinning: false,
                contractInfoDTO: null,
                dxhte: 0,
                dxhteEdit: false,
                itemType:GetDict("itemType")
            }
        },
        methods: {
            saveData(record,viewModel) {
                api_item_info.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.mFormData = record;
                        this.$emit("update",record);
                        this.$emit("update:record",record);
                        this.$message.success(res.data.msg);
                        viewModel(true);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            saveDxhte() {
                let data = {
                    id: this.projectId,
                    dxhte: this.dxhte
                };
                api_item_info.updDxhte.urlPost(data, res => {
                    if (res.data.flag) {
                        this.mFormData.dxhte = this.dxhte;
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            selectType(type) {
                this.changeType = false;
                this.mFormData.itemType = type;
                if (this.formColumns && this.formColumns.length > 0) {
                    this.formColumns.find(item => item.dataIndex === "partyInfoId").visible = type === '1' || type === '3';
                }
            },
            init() {
                if (this.projectId) {
                    this.changeType = false;
                    let data = {
                        id: this.projectId
                    };
                    this.spinning = true;
                    api_item_info.queryOne.urlPost(data, res => {
                        this.spinning = false;
                        if (res.data.flag) {
                            this.mFormData = ApplyContract(res.data.data);
                            if (this.mFormData.contractInfoDTO) {
                                this.contractInfoDTO = this.mFormData.contractInfoDTO;
                                this.$emit("contractInfo", this.contractInfoDTO)
                            }
                            console.log(this.mFormData)
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                } else {
                    this.changeType = true;
                    this.mFormData = this.table.getFormData();
                }
            },
            cnNumber(num){
                if(num){
                    return TransformToChinese(num) + `元(${num})`
                }
                else {
                    return 0
                }
            }
        },
        created() {
            this.formColumns = this.table.getFormColumns().filter(item => item.group === 0);
            this.formColumns1 = this.table.getFormColumns().filter(item => item.group === 1);
            this.formColumns2 = this.table.getFormColumns().filter(item => item.group === 2);
            this.formColumns3 = this.table.getFormColumns().filter(item => item.group === 3);
            this.formColumns4 = this.table.getFormColumns().filter(item => item.group === 4);
            this.formColumns5 = this.table.getFormColumns().filter(item => item.group === 5);
            this.init()
        }
    }

    let chnNumChar = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    let chnUnitSection = ["", "万", "亿", "万亿", "亿亿"];
    let chnUnitChar = ["", "拾", "佰", "仟"];

    function numToChn(num) {
        let index = num.toString().indexOf(".");
        if (index !== -1) {
            let str = num.toString().slice(index);
            let a = "点";
            for (let i = 1; i < str.length; i++) {
                a += chnNumChar[parseInt(str[i])];
            }
            return a;
        } else {
            return '';
        }
    }

    //定义在每个小节的内部进行转化的方法，其他部分则与小节内部转化方法相同
    function sectionToChinese(section) {
        let str = '', chnstr = '', zero = false, count = 0;   //zero为是否进行补零， 第一次进行取余由于为个位数，默认不补零
        while (section > 0) {
            let v = section % 10;  //对数字取余10，得到的数即为个位数
            if (v === 0) {                    //如果数字为零，则对字符串进行补零
                if (zero) {
                    zero = false;        //如果遇到连续多次取余都是0，那么只需补一个零即可
                    chnstr = chnNumChar[v] + chnstr;
                }
            } else {
                zero = true;           //第一次取余之后，如果再次取余为零，则需要补零
                str = chnNumChar[v];
                str += chnUnitChar[count];
                chnstr = str + chnstr;
            }
            count++;
            section = Math.floor(section / 10);
        }
        return chnstr;
    }

    //定义整个数字全部转换的方法，需要依次对数字进行10000为单位的取余，然后分成小节，按小节计算，当每个小节的数不足1000时，则需要进行补零
    /**
     * @return {string}
     */
    function TransformToChinese(num) {
        let a = numToChn(num);
        num = Math.floor(num);
        let unitPos = 0;
        let strIns = '', chnStr = '';
        let needZero = false;

        if (num === 0) {
            return chnNumChar[0];
        }
        while (num > 0) {
            let section = num % 10000;
            if (needZero) {
                chnStr = chnNumChar[0] + chnStr;
            }
            strIns = sectionToChinese(section);
            strIns += (section !== 0) ? chnUnitSection[unitPos] : chnUnitSection[0];
            chnStr = strIns + chnStr;
            needZero = (section < 1000) && (section > 0);
            num = Math.floor(num / 10000);
            unitPos++;
        }

        return chnStr + a;
    }

    /**
     * 将合同的实际情况统计并赋予项目JSON
     * item 项目附带合同信息
     * @constructor
     */
    function ApplyContract(item) {
        item.sumAmount = 0;
        item.contractTypes = []; //合同类型
        item.partyContract = !!item.contractInfoDTOs;
        let contractInfoDTOs = item.contractInfoDTOs;
        if (contractInfoDTOs != null && contractInfoDTOs.length > 0) {
            for (let item_c of contractInfoDTOs) {
                item.contractTypes.push(item_c.type);
                if (item_c.type === '1' || item_c.type === '4' || item_c.type === '7') {
                    item.partyInfoId = item_c.associatedKey;
                    item.partyInfoName = item_c.associatedName;
                    item.sumAmount += item_c.amount; //合同总金额
                    item.sigCompany = item_c.sigCompany;
                    let company = GetDict("companies").find(item=>item.value === item_c.sigCompany);
                    if(company){
                        item.sigCompanyName = company.label;
                    }
                    else{
                        console.log("找不到对应的签署公司，id:"+item.id+",sigCompany:"+item_c.sigCompany)
                    }
                }
            }
        }
        item.sumCollectNo = item.sumAmount; //项目未收款总额 = 合同总额
        if (item.sumCollectNo > item.sumCollect) { //sumCollect 已收款总额
            item.sumCollectNo = item.sumCollectNo - item.sumCollect //未收款 = 合同额 - 已收款总额
        } else {
            item.sumCollectNo = 0; //有收款无合同的情况 未收款为0 不为负数
        }
        item.collecState = item.sumCollect ? "1" : "0";
        item.departmentName = item.itemTaskDTO?.departmentName;
        return item
    }
</script>

<style scoped>

</style>