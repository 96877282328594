<!--
* @program: TYHOA2 
* @author: ly
* @component:MenuCardItem 
* @description: 
* @create: 2024-09-13 16:33
-->
<template>
    <div style="padding:5px;position: relative;">
        <div :class="enable?'menu-card-enable':'menu-card'">
            <div :class="enable?'menu-card-title enable':'menu-card-title'">{{menu.cnName}}</div>
            <div :class="enable?'menu-card-sub-title enable':'menu-card-sub-title'">{{menu.meta?.content}}</div>
            <div :class="enable?'menu-card-dot enable':'menu-card-dot'">.</div>
            <img class="menu-card-img non-select" src="../../../assets/image/menu/组.png" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "MenuCardItem",
        props:["enable","menu"]
    }
</script>

<style scoped>
    .enable{
        color: #10539c !important;
    }
    .menu-card-dot{
        position: absolute;
        top:-20px;
        right:10px;
        font-weight: bold;
        font-size: 40px;
        color: #d1d1d1;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .menu-card-title{
        position: absolute;
        top:25px;
        left:23px;
        font-family:"黑体";
        font-weight: bold;
        font-size: 20px;
        color: #d1d1d1;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .menu-card-sub-title{
        position: absolute;
        top:55px;
        left:23px;
        width: 100px;
        font-size: 10px;
        color: #ededed;
        text-align: left;
    }
    .menu-card{
        position: relative;
        cursor: pointer;
        width: 180px;
        height: 100px;
        background: #fbfbfb;
        box-shadow: 0px 8px 16px 1px rgba(37,84,165,0.15);
        border-radius: 12px 12px 12px 12px;
    }
    .menu-card-enable{
        position: relative;
        cursor: pointer;
        width: 180px;
        height: 100px;
        background: linear-gradient( 150deg, #E1EFFF 0%, #91C1FF 100%);
        box-shadow: 0px 8px 16px 1px rgba(51,126,255,0.3);
        border-radius: 12px 12px 12px 12px;
    }
    .menu-card-img{
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
</style>