<!--
* @program: TYHOA2 
* @author: ly
* @component:typeCategory 
* @description: 
* @create: 2024-07-25 09:46
-->
<template>
    <div v-if="viewModel">
        <div>
            <a-tag color="red">{{parentName}}</a-tag>
            <a-tag color="blue">{{categoryName}}</a-tag>
        </div>
    </div>
    <div v-else>
        <a-spin :spinning="spinning"  v-if="editable">
            <a-tree-select
                    show-search
                    v-model:value="record[column.dataIndex]"
                    style="width: 100%"
                    placeholder="输入查询/选择子分类"
                    :field-names="fieldNames"
                    :treeData="treeData"
                    :treeNodeFilterProp="'categoryName'"
                    @change="columnTrigger"
                    v-model:treeExpandedKeys="expandKey"

            />
        </a-spin>
        <div v-else >
            <a-tag color="red">{{parentName}}</a-tag>
            <a-tag color="blue">{{categoryName}}</a-tag>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeCategory",
        mixins:[typeBase],
        computed:{
            parentName(){
                if(this.record["category"] && this.record["category"].parent){
                    return this.record["category"].parent.categoryName;
                }
                else{
                    return ""
                }
            },
            categoryName(){
                if(this.record["category"]){
                    return this.record["category"].categoryName;
                }
                else if(this.record.categoryName){
                    return this.record.categoryName;
                }
                else{
                    return "";
                }
            }
        },
        data(){
            return{
                fieldNames :{
                    label: 'categoryName',
                    key:"id",
                    value:"id",
                },
                treeData:[],
                spinning:false,
                expandKey:[]
            }
        },
        methods:{
            async categoryInit(){
                this.treeData = JSON.parse(await this.$getItem("__myCategory"));
            }
        },
        created() {
            if(!this.viewModel){
                this.categoryInit()
            }
        }
    }
</script>

<style scoped>

</style>