<!--
* @program: tyh-oa 
* @author: ly
* @component:yearSelector 
* @description: 
* @create: 2021-06-11 14:36
-->
<template>
    <div  style="display: flex">
        <div class="statistic-label"> 年份：</div>
        <a-select v-model:value="date"
                  style="width: 150px"
        >
            <a-select-option v-for="option in years" :key="option.value">
                {{ option.label }}
            </a-select-option>
        </a-select>
        <a-button  style="font-size: 16px;margin-left: 15px" type="link" @click="tipsShow" >
            <template #icon><InfoCircleOutlined /></template>
        </a-button>
    </div>
</template>

<script>
    import {InfoCircleOutlined} from "@ant-design/icons-vue"
    export default {
        name: "yearSelector",
        components:{
            InfoCircleOutlined
        },
        data(){
            return{
                date:null,
                years:[]
            }
        },
        methods:{
            getValue(){
                return this.date;
            },
            tipsShow(){
                if(this.years.length>1){
                    this.$message.info("可选年份为"+this.years[0].label+"-"+this.years[1].label)
                }
                else{
                    this.$message.info("可选年份为"+this.years[0].label)
                }
            },
            initYears(){
                let date = new Date();
                let currentYear = date.getFullYear();
                let startYear = 2021;
                while (startYear !==currentYear){
                    this.years.push({value:startYear.toString(),label:startYear});
                    startYear++;
                }
                this.years.push({value:currentYear.toString(),label:currentYear});
            },
        },
        mounted() {
            this.initYears();
        }
    }
</script>

<style scoped>

</style>