<!--
* @program: office_automation
* @author: ly
* @component:OtherOutcomeAuditHistory
* @description:
* @create: 2022-02-22 10:42
-->
<template>
    <div  class="search-bar">
        <a-button @click="refresh"   type="dashed">
            <template #icon><SyncOutlined/></template>
        </a-button>
        <a-radio-group v-model:value="type" @change="selectChange">
            <a-radio value="4">支出记录</a-radio>
            <a-radio value="0">内部账户间转账记录</a-radio>
        </a-radio-group>
        <span>银行：</span>
        <a-select v-model:value="bank" style="width: 200px"  :options="banks" />
        <span>合计：{{sum}}</span>
        <a-popover trigger="hover" placement="bottom">
            <template #content>
                <a-list size="small" style="margin: 0" bordered :data-source="monthSum">
                    <template #renderItem="{item}">
                        <a-list-item>{{item.month}}:{{item.value}}元</a-list-item>
                    </template>
                </a-list>
            </template>
            <a-button  type="link" >
                按月份统计
            </a-button>
        </a-popover>
    </div>
    <LTable :loading="loading"
             :columns="tableColumns"
             :data-source="dataSource"
             :page-size="pageParam.limit" >
        <template #action="{record}">
            <div class="center-flex">
                <a-tooltip title="查看审核进度">
                    <a-button class="action-button" type="link" @click="stepDetail(record)">
                        <Search class="pointer"/>
                    </a-button>
                </a-tooltip>
            </div>
        </template>
    </LTable>
    <a-modal v-model:open="stepVisible"
             :width="800"
             destroyOnClose
             :footer="null"
             title="审核进度">
        <OtherOutcomeAuditStatus :record="formData" />
    </a-modal>
</template>

<script>

    import otherPay from "@/assets/api/otherPay";
    import OtherOutcomeAuditNow from "@/page/Finance/financialManager/OtherOutcomeAudit/OtherOutcomeAuditNow";
    import dayjs from "dayjs";

    export default {
        name: "OtherOutcomeAuditHistory",
        mixins:[OtherOutcomeAuditNow],
        computed:{
            monthSum(){
                let monthSum=[];
                for(let i in this.dataSource){
                    let payMonth = dayjs(this.dataSource[i].payDate).format("YYYY-MM");
                    let arr = monthSum.filter(item=>item.month === payMonth);
                    if(arr.length >0){
                        arr[0].value += this.dataSource[i].payAmount;
                    }
                    else{
                        monthSum.push({month:payMonth,value:this.dataSource[i].payAmount})
                    }
                }
                return monthSum
            }
        },
        methods: {
            init() {
                this.tableSource = [];
                this.tableSource2 = [];
                this.loading = true;
                let data = {
                    state: "2"
                };
                this.banks = [
                    {label: "全部", value: "全部"},
                ];
                this.selectChange();
                otherPay.queryMyApproval.urlPost(data, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        let source = res.data.data.sort((a, b) => {
                            return b.payDate - a.payDate;
                        });
                        for (let i in source) {
                            let arr = this.banks.filter(item => item.label === source[i].payBankName);
                            if (arr.length === 0) {
                                this.banks.push({label: source[i].payBankName, value: source[i].payBankName})
                            }
                            if (source[i].type === "4") {
                                this.tableSource.push(source[i]);
                            } else {
                                this.tableSource2.push(source[i]);
                            }

                        }
                    } else {
                        this.tableSource = [];
                        this.tableSource2 = [];
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
