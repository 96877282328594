/**
 * @program: oa2.0
 * @author: ly
 * @description: HumanResourcesComp
 * @create: 2023-08-25 09:34
 **/

import PromotionInfo from "@/page/HumanResources/StaffPromotion/StaffPromotionSetting/PromotionInfo";
import PromotionPoints from "@/page/HumanResources/StaffPromotion/StaffPromotionSetting/PromotionPoints";
import StaffPromotionAudit from "@/page/HumanResources/StaffPromotion/StaffPromotionSetting/StaffPromotionAudit";
import StaffPromotionSetting from "@/page/HumanResources/StaffPromotion/StaffPromotionSetting/StaffPromotionSetting";

import UseSealSetting from "@/page/HumanResources/SealManager/UseSealSetting";

import AssessmentRoleSetting from "@/page/HumanResources/StaffAssessment/MonthlyAssessment/AssessmentRoleSetting";
import YearlyAssessment from "@/page/HumanResources/StaffAssessment/YearlyAssessment/YearlyAssessment";
import DepartmentAimSetting from "@/page/HumanResources/StaffAssessment/YearlyAssessment/DepartmentAimSetting";
import YearlyAssessmentSetting from "@/page/HumanResources/StaffAssessment/YearlyAssessment/YearlyAssessmentSetting";
import AttendanceRecords from "@/page/HumanResources/StaffAssessment/AttendanceRecords/AttendanceRecords";
import AttendanceRuleSetting from "@/page/HumanResources/StaffAssessment/AttendanceRecords/AttendanceRuleSetting";



let HumanResourcesComp = [
    {path: '', component: ()=>import("@/page/Other/OAMenuDefaultPage/MenuList"), name:"HumanResourcesComp",children:()=>{return HumanResourcesComp}},
    {path: 'UserInfoManager', component: ()=>import("@/page/HumanResources/UserInfoManager/UserInfoManager"), name:"UserInfoManager",cnName:"用户管理"},
    {path: 'MyInfo', component: ()=>import("@/page/PersonalCenter/MyInfo/MyInfo"), name:"MyInfo",cnName:"个人信息"},
    {path: 'PasswordModify', component: ()=>import("@/page/PersonalCenter/MyInfo/PasswordModify"), name:"PasswordModify",cnName:"修改密码"},
    {path: 'DepartmentManager', component: ()=>import("@/page/HumanResources/DepartmentManager/DepartmentManager"), name:"DepartmentManager",cnName:"部门设定"},
    {path: 'phoneBook', component: ()=>import("@/page/PersonalCenter/MyInfo/phoneBook"), name:"phoneBook",cnName:"通讯录"},


    {path: 'PromotionInfo', component: PromotionInfo, name:"PromotionInfo",cnName:"晋升信息"},
    {path: 'PromotionPoints', component: PromotionPoints, name:"PromotionPoints",cnName:"晋升评分表"},
    {path: 'StaffPromotionAudit', component: StaffPromotionAudit, name:"StaffPromotionAudit",cnName:"员工晋升-审核"},
    {path: 'StaffPromotionSetting', component: StaffPromotionSetting, name:"StaffPromotionSetting",cnName:"员工晋升-考核科目配置"},
    {path: 'UseSealSetting', component: UseSealSetting, name:"UseSealSetting",cnName:"印章用途设定"},

    {path: 'MonthlyAssessment', component:()=>import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/MonthlyAssessment"), name:"MonthlyAssessment",cnName:"员工考核-月度考核"},
    {path: 'UserKPI', component:()=>import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/UserKPI"), name:"UserKPI",cnName:"员工月度KPI"},
    {path: 'PositionAimSetting', component: ()=>import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/PositionAimSetting"), name:"PositionAimSetting",cnName:"员工考核-职位月度绩效目标配置"},
    {path: 'MonthlyAssessmentSetting', component: ()=>import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/MonthlyAssessmentSetting"), name:"MonthlyAssessmentSetting",cnName:"员工考核-月度考核科目配置"},
    {path: 'AssessmentRoleSetting', component: AssessmentRoleSetting, name:"AssessmentRoleSetting",cnName:"员工考核-月度考核评分角色设置"},
    {path: 'YearlyAssessment', component: YearlyAssessment, name:"YearlyAssessment",cnName:"员工考核-年度考核"},
    {path: 'DepartmentAimSetting', component: DepartmentAimSetting, name:"DepartmentAimSetting",cnName:"员工考核-生产部门年度绩效目标配置"},
    {path: 'YearlyAssessmentSetting', component: YearlyAssessmentSetting, name:"YearlyAssessmentSetting",cnName:"员工考核-年度考核科目配置"},
    {path: 'AttendanceRecords', component: AttendanceRecords, name:"AttendanceRecords",cnName:"员工考核-考勤记录"},
    {path: 'AttendanceRuleSetting', component: AttendanceRuleSetting, name:"AttendanceRuleSetting",cnName:"员工考核-考勤扣分规则"},
    {path: 'HygieneRecords', component: ()=>import("@/page/HumanResources/StaffAssessment/HygieneRecords/HygieneRecords"), name:"HygieneRecords",cnName:"员工考核-卫生记录"},
    {path: 'UserExtraManager', component: ()=>import("@/page/HumanResources/UserExtraManager/UserExtraManager"), name:"UserExtraManager",cnName:"简历模块"},
    {path: 'CooperationUnits', component: ()=>import("@/page/HumanResources/CooperationUnits/CooperationUnits"), name:"CooperationUnits",cnName:"合作单位及人员"},

]

export default HumanResourcesComp

