/**
 * @program: oa2.0
 * @author: ly
 * @description: EquipmentComp
 * @create: 2023-08-25 09:33
 **/

let EquipmentComp = [
    {path: '', component: ()=>import("@/page/Other/OAMenuDefaultPage/MenuList"), name:"EquipmentComp",children:()=>{return EquipmentComp}},
    {path: 'AssetsManager', component: ()=>import("@/page/Equipment/AssetsManager/AssetsManager"), name:"AssetsManager",cnName:"设备管理"},
    {path: 'FixedAssetsManager', component: ()=>import("@/page/Equipment/FixedAssetsManager/FixedAssetsManager"), name:"FixedAssetsManager",cnName:"固定资产管理"},
    {path: 'AssetsSetting', component: ()=>import("@/page/Equipment/AssetsSetting/AssetsSetting"), name:"AssetsSetting",cnName:"资产相关设定"},
    {path: 'AssetsApplyAudit', component: ()=>import("@/page/Equipment/AssetsAuditCenter/AssetsApplyAudit"), name:"AssetsApplyAudit",cnName:"设备申请审核"},
    {path: 'AssetsReturnAudit', component: ()=>import("@/page/Equipment/AssetsAuditCenter/AssetsReturnAudit"), name:"AssetsReturnAudit",cnName:"设备归还审核"},
];


export default EquipmentComp