<!--
* @program: office_automation 
* @author: ly
* @component:StepSetting 
* @description: 
* @create: 2021-12-21 09:52
-->
<template>
    <div style="display: flex">
        <div :key="index" v-for="(step,index) in steps">
            <a-tooltip title="左边插入" v-if="!viewModel">
                <a-button @click="leftAdd(step)"  type="dashed">
                    <template #icon><PlusOutlined/></template>
                </a-button>
            </a-tooltip>
            <a-tag color="blue" style="font-size: 14px" class="non-select">
                <template #icon>
                    {{index+1}}.
                </template>
                {{step}}
                <a-popconfirm
                        v-if="!viewModel"
                        @confirm="remove(step)"
                        cancel-text="否"
                        ok-text="是"
                        title="确定删除吗?">
                    <a-button type="link">
                        <template #icon><DeleteOutlined style="color: red"/></template>
                    </a-button>
                </a-popconfirm>
            </a-tag>
            <a-tooltip v-if="!viewModel" title="右边插入">
                <a-button @click="rightAdd(step)" type="dashed">
                    <template #icon><PlusOutlined/></template>
                </a-button>
            </a-tooltip>
            <span style="margin:10px;font-size: 18px" class="non-select" v-if="index !==steps.length-1">></span>
        </div>
    </div>
</template>

<script>
    import {DeleteOutlined, PlusOutlined, UserOutlined} from "@ant-design/icons-vue"

    export default {
        name: "StepSetting",
        props: ["steps", "viewModel"],
        emits: ["leftAdd", "remove", "rightAdd"],
        components: {
            PlusOutlined, DeleteOutlined, UserOutlined
        },
        methods: {
            leftAdd(step) {
                this.$emit("leftAdd", step);
            },
            remove(step) {
                this.$emit("remove", step);
            },
            rightAdd(step) {
                this.$emit("rightAdd", step);
            }
        }
    }
</script>

<style scoped>

</style>