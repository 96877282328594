<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectTaskInfo
* @description: 
* @create: 2024-07-23 16:24
-->
<template>
    <div v-if="taskList.length >0" style="display: flex;flex-wrap: wrap">
        <TaskInfoItem @add="addSubTask"
                      @report="showWeekReport"
                      @delete="deleteTask"
                      @save="saveData"
                      :view-model="!mainTask"
                      style="width: 500px"
                      v-for="task in taskList" :task="task" :key="task.id"/>
    </div>
    <a-modal v-model:open="visible"
             destroy-on-close
             width="1200px"
             :footer="null"
             :title="currentTask.title">
        <TaskReport :task="currentTask" />
    </a-modal>
    <a-modal v-model:open="visible2"
             destroy-on-close
             width="600px"
             :footer="null"
             :title="currentTask.title">
        <TaskInfoItem :task="{}"  :view-model="false" @save="saveData2"/>
    </a-modal>
</template>

<script>
    import itemTask from "../../../assets/api/itemTask";
    import TaskReport from "./TaskReport";
    import TaskInfoItem from "./TaskInfoItem";
    export default {
        name: "ProjectTaskInfo",
        props:["record","viewModel"],
        components:{
            TaskReport,TaskInfoItem
        },
        computed:{
            projectId(){
                if(this.record && this.record.itemId){
                    return this.record.itemId
                }
                else{
                    return this.record.id
                }
            },
            mainTask(){
                return this.record.pid === '0'
            }
        },
        data(){
            return{
                taskList:[],
                loading:false,
                vModel:false,
                currentTask:{},
                visible:false,
                visible2:false,
                addSub:false,
                ratio:0
            }
        },
        methods:{
            init(){
                this.loading = true;
                let data = {itemId:this.projectId};
                itemTask.queryAll.urlPost(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.taskList = res.data.data;
                    }
                    else{
                        this.taskList = [];
                    }
                });
            },
            showWeekReport(task){
                this.visible = true;
                this.currentTask = task;
            },
            addSubTask(task){
                this.currentTask = task;
                this.visible2 = true;
            },
            saveData(record){
                record.itemId = this.projectId;
                itemTask.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData2(record){
                record.itemId = this.projectId;
                record.pid = this.currentTask.id;
                itemTask.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteTask(task){
                this.taskList = this.taskList.filter(item=>item.id !== task.id);
            }
        },
        created() {
            this.init()
        },
    }
</script>

<style scoped>

</style>