<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentUserPicker 
* @description: 
* @create: 2023-01-05 13:51
-->
<template>
    <a-select
            v-model:value="selectId"
            style="width: 150px"
            placeholder="选择人员"
            :options="treeData"
            :fieldNames="fieldNames"
            @change="selectConform"
    >
    </a-select>
</template>

<script>
    import userDepartRelation from "../../assets/api/userDepartRelation";
    export default {
        name: "DepartmentUserPicker",
        props:["value","departmentId"],
        emits:["update:value","change"],
        data(){
            return{
                departments:[],
                treeData:[],
                selectId:null,
                selectedData:[],
                fieldNames:{ label: "userName", value: "userId"}
            }
        },
        watch:{
            value(){
                this.selectId = this.value;
            }
        },
        methods:{
            init(){
                this.selectId = this.value;
                let data = {
                    departmentId:this.departmentId,
                    disableState:"1",
                    loginState:"0",
                };
                userDepartRelation.queryAll.urlPost(data,res=>{
                    if(res.data.flag){
                        //this.treeData = res.data.data.filter(item =>item.userName !=="超级管理员");
                        this.treeData = res.data.data;
                    }
                })
            },
            selectConform(){
                this.selectedData = this.treeData.find(item=>{return this.selectId === item.userId});
                this.$emit("update:value",this.selectId);
                this.$emit("change",this.selectedData);
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>