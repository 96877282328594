<!--
* @program: tyh-oa 
* @author: ly
* @description: typeString 
* @create: 2021-03-23 10:01
-->
<template>
    <a-textarea
            @blur="blurTrigger"
            @input="columnTrigger"
            :placeholder="placeholder"
            auto-size
            v-if="editable"
            v-model:value="record[column.dataIndex]"
    />
    <NoWrapString v-else @click="onClick" :text="presentValue"/>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeString",
        mixins:[typeBase],

    }
</script>

<style scoped>

</style>