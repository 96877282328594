<!--
* @program: TYHOA2 
* @author: ly
* @component:AssetsManager 
* @description: 
* @create: 2024-08-15 14:57
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-button @click="addData" type="dashed">
                <template #icon>
                    <PlusOutlined/>
                </template>
            </a-button>
            <div  style="margin-left:15px;">类别：</div>
            <assetsCategoryTree :category-group="categoryGroup" @change="init"  v-model:value="queryOptions.categoryId"/>
            <QueryFilter :filter="queryFilter" @change="init" v-model:value="queryOptions"/>
            <a-radio-group v-model:value="queryOptions.useState" @change="init">
                <a-radio value="0">在库</a-radio>
                <a-radio value="1">在人</a-radio>
            </a-radio-group>
            <a-radio-group v-model:value="queryOptions.mainFlag" @change="init">
                <a-radio value="1">主设备</a-radio>
                <a-radio value="0">附属设备</a-radio>
                <a-radio value="2">包含附属设备的主设备</a-radio>
            </a-radio-group>
            <a-input  placeholder="设备名称" style="width: 150px" class="title-divider" v-model:value="filter.name" />
            <a-input  placeholder="设备编码" style="width: 150px" v-model:value="filter.code" />
            <a-input  placeholder="使用人" style="width: 100px" v-model:value="filter.currentUseUserName" />
        </div>
        <div style="width: 100%;height:100%;">
            <LTable :columns="tableColumns" :dataSource="source" :download="true" :loading="loading">
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button v-if="!record.mainId" @click="showDetail(record)" class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button v-if="!record.mainId" @click="editDetail(record)" class="action-button" type="link">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-tooltip title="绑定附属设备" v-if="!record.mainId">
                            <a-button @click="binding(record)" class="action-button" type="link">
                                <LinkOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                        <a-popconfirm
                                v-if="record.mainId"
                                @confirm="unbinding(record)"
                                cancel-text="否"
                                class="action-button"
                                ok-text="是"
                                title="确定解除绑定吗?">
                            <a-tooltip title="解除绑定">
                                <a-button class="action-button" type="link">
                                    <LinkOutlined class="pointer" style="color: red"/>
                                </a-button>
                            </a-tooltip>
                        </a-popconfirm>
                        <a-tooltip v-if="!record.mainId" title="报废/报修设备">
                            <a-button class="action-button" @click="alter(record)" type="link">
                                <MinusCircleOutlined class="pointer" style="color: orange"/>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip v-if="!record.mainId" title="送出设备">
                            <a-button class="action-button" @click="send(record)" type="link">
                                <ExportOutlined class="pointer" style="color: #ff543e"/>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip v-if="!record.mainId" title="借还历史">
                            <a-button @click="deviceHistory(record)" class="action-button" type="link">
                                <ClockCircleOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                        <a-popconfirm
                                @confirm="rollbackOutlined(record)"
                                cancel-text="否"
                                class="action-button"
                                ok-text="是"
                                title="是否强制归还设备?"
                                v-if="!record.mainId && record.currentUseUserName != null">
                            <a-tooltip title="强制归还设备">
                                <a-button class="action-button" type="link">
                                    <RollbackOutlined class="pointer" style="color: #893cb3"/>
                                </a-button>
                            </a-tooltip>
                        </a-popconfirm>
                        <a-popconfirm
                                v-if="!record.mainId"
                                @confirm="deleteData(index,record)"
                                cancel-text="否"
                                class="action-button"
                                ok-text="是"
                                title="确定删除吗?">
                            <DeleteOutlined class="pointer" style="color: red"/>
                        </a-popconfirm>
                    </div>
                </template>
                <template #expandedRow="{children}">
                    <div class="action-column">
                        <span>本设备为主设备，包含附属设备为:</span>
                        <div style="margin-left: 10px" v-for="(child,index) in children" :key="child.id">
                            【{{index+1}}.{{child.name}}({{child.code}})】
                            <a-popconfirm
                                    @confirm="unbinding(child)"
                                    cancel-text="否"
                                    class="action-button"
                                    ok-text="是"
                                    title="确定解除绑定吗?">
                                <a-tooltip title="解除绑定">
                                    <a-button class="action-button" type="link">
                                        <LinkOutlined class="pointer" style="color: red"/>
                                    </a-button>
                                </a-tooltip>
                            </a-popconfirm>
                        </div>
                    </div>
                </template>
            </LTable>
        </div>
        <a-drawer
                placement="bottom"
                height="50%"
                :closable="false"
                :visible="visible"
                :get-container="false"
                :style="{ position: 'absolute' }"
                @close="visible =false"
                destroy-on-close>
            <div class="search-bar">
                <div style="margin:0 5px">设备名称：</div>
                <a-input placeholder="设备名称" style="width: 150px" v-model:value="filter2.name" />
                <div style="margin:0 5px">设备编码：</div>
                <a-input  placeholder="设备编码" style="width: 150px" v-model:value="filter2.code" />
                <assetsCategoryTree :category-group="categoryGroup" @change="init2"  v-model:value="queryOptions2.categoryId"/>
                <QueryFilter :filter="queryFilter" @change="init2" v-model:value="queryOptions2"/>
                <a-popconfirm
                        @confirm="saveBinding"
                        cancel-text="否"
                        ok-text="是"
                        title="确定绑定吗?">
                    <a-button type="primary">
                        绑定
                    </a-button>
                </a-popconfirm>
            </div>
            <SLTable :columns="tableColumns" :dataSource="source2" :loading="loading2" @selectChange="handleSelectChange" v-if="visible"/>
        </a-drawer>
    </div>
    <a-modal :footer="null"
             :maskClosable="viewModel"
             :title="title"
             destroyOnClose
             v-model:open="modalVisible">
        <LForm :label-span="8" :form-data="currentRecord" :formColumns="formColumns" :view-model="viewModel">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal :footer="null"
             :width="800"
             destroy-on-close
             title="借还历史"
             v-model:open="historyVisible">
        <LTable :download="false" title="申请历史" :columns="cols" :dataSource="applyDS" :loading="historyLoading" >
            <template #action="{record}">
                <div class="action-column">
                    <a-button  @click="applyHistory(record)" class="action-button" type="link">
                        审核情况
                    </a-button>
                </div>
            </template>
        </LTable>
        <LTable :download="false" title="归还历史" :columns="cols" :dataSource="backDS" :loading="historyLoading" >
            <template #action="{record}">
                <div class="action-column">
                    <a-button  @click="applyHistory(record)" class="action-button" type="link">
                        审核情况
                    </a-button>
                </div>
            </template>
        </LTable>
        <div v-if="steps.length>0" class="form-title">审核情况</div>
        <AuditSteps v-if="steps.length>0" :steps="steps" />
    </a-modal>

    <a-modal :footer="null"
             title="更改设备用途"
             destroyOnClose
             v-model:open="sendVisible">
        <a-radio-group v-model:value="assetPermanentFlag">
            <a-radio v-for="item in queryFilter.permanentFlag.options" :value="item.value" :key="item.value">{{item.label}}</a-radio>
        </a-radio-group>
        <a-form-item label="设备说明" >
            <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="param"/>
        </a-form-item>
        <a-button @click="flyAssets"  type="primary">
            提交
        </a-button>
    </a-modal>
    <a-modal :footer="null"
             title="更改设备健康状态"
             destroyOnClose
             v-model:open="alterVisible">
        <a-radio-group v-model:value="assetState">
            <a-radio v-for="item in queryFilter.state.options" :value="item.value" :key="item.value">{{item.label}}</a-radio>
        </a-radio-group>
        <a-form-item label="设备说明" >
            <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="param"/>
        </a-form-item>
        <a-button @click="alterState"  type="primary">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import LTable from "../../../components/table/LTable";
    import AssetsCategoryTree from "./AssetsCategoryTree";
    import {
        ClockCircleOutlined, DeleteOutlined, EditFilled, LinkOutlined,
        MinusCircleOutlined,
        PlusOutlined, RollbackOutlined,
        SearchOutlined,
        SyncOutlined,ExportOutlined
    } from "@ant-design/icons-vue";
    import LForm from "../../../components/form/LForm";
    import SLTable from "../../../components/table/SLTable";
    import device from "./device";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import api_device from "../../../assets/api/device";
    import deviceBack from "../../../assets/api/deviceBack";
    import deviceApply from "../../../assets/api/deviceApply";
    import {ArrayFilter, GetDict} from "../../../assets/utils/general";
    import QueryFilter from "../../WorkAdministration/ProjectManager/QueryFilter";
    import api_device_category from "../../../assets/api/deviceCategory";
    import assetApplyApproval from "../../../assets/api/assetApplyApproval";
    import AuditSteps from "../AssetsAuditCenter/AuditSteps";

    export default {
        name: "AssetsManager",
        components: {
            LTable,
            AssetsCategoryTree, SearchOutlined, SyncOutlined, PlusOutlined,
            MinusCircleOutlined, DeleteOutlined, RollbackOutlined, EditFilled,
            LinkOutlined, ClockCircleOutlined, QueryFilter, LForm, SLTable,
            ExportOutlined,AuditSteps
        },
        computed:{
            source(){
                if(this.queryOptions.mainFlag === "0"){
                    return ArrayFilter(this.dataSource,this.filter).filter(item=>item.mainId);
                }
                else if(this.queryOptions.mainFlag === "2"){
                    return ArrayFilter(this.dataSource,this.filter).filter(item=>item.children && item.children.length>0);
                }
                else{
                    return ArrayFilter(this.dataSource,this.filter);
                }

            },
            source2(){
                return ArrayFilter(this.dataSource2,this.filter2);
            }
        },
        data() {
            return {
                table: new device(),
                categoryGroup:undefined,
                searchData: null,
                queryFilter: {
                    state: {
                        name:"健康状态",
                        options:GetDict("device.state")
                    },
                    permanentFlag: {
                        name:"设备用途",
                        options:GetDict("permanentFlag"),
                    },
                },
                assetState:undefined,
                assetPermanentFlag:undefined,
                param:null,
                filter:{},
                dataSource: null,//提供table的DataSource
                tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                loading: false,//table的loading状态
                formColumns: null, //提供Form的字段集 this.table.getFormColumns()
                currentRecord: null,//提供Form的formData

                pageSize: 15,
                queryOptions: {
                    state: "1", // 启用状态：0：停用，1：启用
                    useState: "0", // useState 设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的）
                    mainFlag: "1", //是否主设备 0：显示全部；1：只显示主设备
                    categoryId:undefined,
                    permanentFlag: "0",
                    fixedAsset:"0",//是否为固定资产 0：不是  1：是
                },

                viewModel: true,
                title: "详情",
                modalVisible: false,
                filter2:{},
                visible: false,
                dataSource2: null,//提供table的DataSource
                loading2: false,//table的loading状态
                queryOptions2: {
                    state: "1", // 启用状态：0：停用，1：启用
                    useState: "0", // useState 设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的）
                    categoryId: undefined,// categoryId 所属设备分类id:(可以是大类id,也可以是小类id)
                    mainFlag: "1", //是否主设备
                    permanentFlag: "0",
                    fixedAsset:"0",//是否为固定资产 0：不是  1：是
                },
                selectedRows: null,

                cols: [
                    new Column("申请人", "userName", ColumnType.String).setTableView(80),
                    new Column("审核状态", "auditState", ColumnType.Enum).setTableView(80)
                        .setDictEnum("auditState"),
                    new Column("时间", "createTime", ColumnType.Date).setTableView(100),
                    new Column("操作","actions","actions").setTableView(80)
                ],
                applyDS: [],
                backDS: [],
                historyVisible: false,
                historyLoading: false,
                sendVisible:false,
                alterVisible:false,
                steps:[]
            }
        },
        methods: {
            init() {
                this.loading = true;
                api_device.queryAll.post(this.queryOptions, res => {
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                    } else {
                        this.dataSource = [];
                    }
                    this.loading = false;
                });
            },
            init2() {
                this.loading2 = true;
                api_device.queryAll.post(this.queryOptions2, res => {
                    if (res.data.flag) {
                        this.dataSource2 = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                    } else {
                        this.dataSource2 = [];
                    }
                    this.loading2 = false;
                });
            },
            refresh() {
                this.init();
            },
            addData() {
                this.title = "添加新设备";
                this.currentRecord = this.table.getFormData();
                this.modalVisible = true;
                this.viewModel = false;
            },
            showDetail(record) {
                this.title = "设备详情";
                this.currentRecord = record;
                this.modalVisible = true;
                this.viewModel = true;
            },
            editDetail(record) {
                this.title = "设备详情";
                this.currentRecord = record;
                this.modalVisible = true;
                this.viewModel = false;
            },
            rollbackOutlined(record) {
                let data = {deviceId: record.id};
                deviceBack.forceBack.urlPost(data, res => {
                    if (res.data.flag) {
                        record.currentUseUserName = null;
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            alter(record){
                this.alterVisible = true;
                this.currentRecord = record;
                this.param = record.param;
                this.assetState = record.state;
            },
            alterState() {
                this.currentRecord.state = this.assetState;
                this.currentRecord.param = this.param?this.param:this.currentRecord.param;
                api_device.saveOrUpd.post(this.currentRecord, res => {
                    if (res.data.flag) {
                        this.alterVisible = false;
                        this.$message.success(res.data.msg);
                    }
                    else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            send(record){
                this.sendVisible = true;
                this.currentRecord = record;
                this.param = record.param;
                this.assetPermanentFlag = record.permanentFlag;
            },
            flyAssets() {
                this.currentRecord.permanentFlag = this.assetPermanentFlag;
                this.currentRecord.param = this.param?this.param:this.currentRecord.param;
                api_device.saveOrUpd.post(this.currentRecord, res => {
                    if (res.data.flag) {
                        this.sendVisible = false;
                        this.$message.success(res.data.msg);
                    }
                    else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record) {
                /**
                 * 这里全部以设备来处理，不处理固定资产
                 * @type {string}
                 */
                record.fixedAsset = "0";
                record.fixedCategory = null;
                api_device.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.modalVisible = false;
                        this.init()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData(index, record) {
                let data = {id: record.id};
                api_device.delete.urlPost(data, res => {
                    if (res.data.flag) {
                        this.dataSource.splice(index, 1);
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            binding(record) {
                this.currentRecord = record;
                this.visible = true;
                this.selectedRows = [];
            },
            saveBinding() {
                if (this.selectedRows.length > 0) {
                    for (let i in this.selectedRows) {
                        this.selectedRows[i].mainId = this.currentRecord.id;
                    }
                    api_device.batchSaveOrUpd.post({deviceVOList: this.selectedRows}, res => {
                        if (res.data.flag) {
                            this.visible = false;
                            this.$message.success(res.data.msg)
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                } else {
                    this.$message.error("请选择附属设备")
                }
            },
            handleSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRows = selectedRows;
            },
            unbinding(record) {
                let data = {...record};
                data.mainId = "";
                api_device.saveOrUpd.post(data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            async deviceHistory(record) {
                this.historyVisible = true;
                this.historyLoading = true;
                let data = {
                    deviceId: record.id
                };
                this.steps = [];
                await deviceApply.queryAll.post(data, res => {
                    if (res.data.flag) {
                        this.applyDS = res.data.data;
                    } else {
                        this.applyDS = [];
                    }
                });
                await deviceBack.queryAll.post(data, res1 => {
                    if (res1.data.flag) {
                        this.backDS = res1.data.data;
                    } else {
                        this.backDS = [];
                    }
                });
                this.historyLoading = false
            },
            applyHistory(record){
                this.steps = []
                let data2 = {
                    applyId:record.id
                };
                assetApplyApproval.queryAll.post(data2,res=>{
                    if(res.data.flag){
                        this.steps = res.data.data;
                    }
                });
            }
        },
        created() {
            this.tableColumns = this.table.getTableColumns();
            this.formColumns = this.table.getFormColumns();
            this.init();
            api_device_category.queryOneLevel.urlPost({},res=>{
                if(res.data.flag){
                    this.categoryGroup = res.data.data;
                    for(let i in this.categoryGroup){//禁止大类被选中
                        this.categoryGroup[i]["selectable"] = false;
                    }
                }
            })
        }
    }
</script>

<style scoped>

</style>