<!--
* @program: office_automation 
* @author: ly
* @component:MyAssessment 
* @description: 我的月度考核记录
* @create: 2022-02-25 17:26
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="refresh" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-date-picker v-model:value="yearMonth" picker="month" placeholder="请选择月份"  @change="monthChange" />
        </div>
        <div style="width: 100%;height: 100%;display: flex" >
            <div class="main-content25" style="min-width:400px">
                <LTable :data-source="dataSource" :columns="tableColumns">
                    <template #action="{record}">
                        <div class="action-column">
                            <a-button v-if="checkJudge(record)" @click="showDetail(record)" class="action-button" type="link">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    v-if="checkAgree(record) && checkJudge(record)"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="同意本次打分结果（请在评分完毕后进行操作）?"
                                    @confirm="agree(record)">
                                <a-tooltip title="同意本次考核结果">
                                    <a-button class="action-button" type="link">
                                        <CheckOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content75" style="min-width:400px">
                <AssessmentViewer :assessment-name="currentRecord.typeName" :view-model="false" :assessment-data="AssessmentData" user-type="user" />
            </div>
        </div>
    </div>
</template>

<script>


    import { SearchOutlined, SyncOutlined,CheckOutlined} from "@ant-design/icons-vue";
    import ColumnType from "@/assets/tables/column_type";
    import Column from "@/assets/tables/column";
    import assessmentUser from "../../../../assets/api/assessmentUser";
    import AssessmentViewer from "./AssessmentViewer";
    import LTable from "../../../../components/table/LTable";
    import dayjs from "dayjs"

    export default {
        name: "MyAssessment",
        components: {
            SyncOutlined,
            SearchOutlined,CheckOutlined,
            AssessmentViewer,LTable,
        },
        data(){
            return{
                yearMonth:null,
                dataSource:[],//提供table的DataSource
                tableColumns:[
                    new Column("年月","yearMonth",ColumnType.String).setTableView(80),
                    new Column("考核标准","typeName",ColumnType.String).setTableView(100),
                    new Column("评分","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                    new Column("操作","actions","actions").setTableView(80)
                ], //提供table的字-段集 this.table.getTableColumns()
                AssessmentData:null,
                viewModel:true,
                tips:"请选择查询年月",
                loading:false,
                currentRecord:{}
            }
        },
        methods:{
            init(){
                this.viewModel = true;
                this.AssessmentData = null;
                this.monthChange()
            },
            refresh(){
                this.init();
            },
            monthChange(){
                this.AssessmentData = null;
                let data = {userId : this.userId};
                if(this.yearMonth){
                    data.yearMonth = this.yearMonth.format("YYYY-MM")
                }
                assessmentUser.queryAll.post(data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return new Date(b.yearMonth) - new Date(a.yearMonth)
                        });
                        for(let i in this.dataSource){
                            this.dataSource[i].judgeEnd = true;
                            for(let j in this.dataSource[i].userDTOS){
                                if(this.dataSource[i].userDTOS[j].judgeEnd === false){
                                    this.dataSource[i].judgeEnd = false
                                }
                            }
                        }
                    }
                    else{
                        this.dataSource = []
                    }
                })

            },
            showDetail(record){
                this.currentRecord = record;
                this.AssessmentData = record.userDTOS;
                this.AssessmentData.sort((a,b)=>{
                    return a.sortOrder - b.sortOrder
                });
            },
            agree(record){//同意本月考核信息
                this.showDetail(record);
                for(let i in this.AssessmentData){
                    if(this.AssessmentData[i].agreeFlag === "2"){
                        this.$message.error("有不同意评分的条目，不能批量完成同意操作");
                        return
                    }
                }
                let data = {
                    yearMonth:record.yearMonth,
                    agreeFlag:"1"
                }
                assessmentUser.agreeAll.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            checkAgree(record){
                for(let i in record.userDTOS){
                    if(record.userDTOS[i].agreeFlag !=="1"){//还未完全同意的情况
                        return true;
                    }
                }
                return  false;
            },
            checkJudge(record){
                for(let i in record.userDTOS){
                    if(!record.userDTOS[i].judgeEnd){//还未打分完成
                        return false;
                    }
                }
                return  true;
            }
        },
        created() {
            this.$getItem("__myID").then(str => {
                this.userId = str;
                this.yearMonth = dayjs();
                this.init();
            })
        }
    }
</script>

<style scoped>

</style>