<!--
* @program: tyh-oa 
* @author: ly
* @component:typeIndex 
* @description: 
* @create: 2021-04-15 17:05
-->
<template>
    <div @click="onClick">
        <span  v-if="hasChildren">
            <plus-square-outlined  v-if="up"  @click="expanded"/>
            <minus-square-outlined  v-else  @click="expanded"/>
        </span>
        {{index}}
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {PlusSquareOutlined,MinusSquareOutlined} from "@ant-design/icons-vue"

    export default {
        name: "typeIndex",
        components:{
            PlusSquareOutlined,MinusSquareOutlined
        },
        emits:["expanded"],
        props:{
            index:{required:true},
            column:{required: true},
            record:{required:true},
            viewModel:{required:true},
            },
        mixins:[typeBase],
        data(){
            return{
                up:true
            }
        },
        computed:{
            hasChildren(){
                return this.record && this.record.children && this.record.children.length>0
            }
        },
        methods:{
            expanded(){
                this.up = !this.up;
                this.$emit("expanded",this.record.id);//也许有其他的rowkey？
            }
        }
    }
</script>

<style scoped>
    .high-light{
        background: red;
    }
</style>