<!--
* @program: TYHOA2 
* @author: ly
* @component:MonthlyArrangePlan 
* @description: 主任务批量添加
* @create: 2024-08-06 20:16
-->
<template>
    <div>
        <a-row  style="margin-bottom: 10px;">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">项目月绩效总额</div>
            </a-col>
            <a-col :span="4">
                <div class="form-value">
                   {{money}}
                </div>
            </a-col>
            <a-col  :span="4">
                <div class="form-label" style="margin-right: 10px">主任务占比</div>
            </a-col>
            <a-col v-if="subTaskRatio" :span="2">
                <div class="form-value">
                    {{100 - subTaskRatio}}%
                </div>
            </a-col>
            <a-col v-else :span="2">
                <div class="form-value">
                    100%
                </div>
            </a-col>
            <a-col v-if="subTaskRatio" :span="4">
                <div class="form-label" style="margin-right: 10px">子任务占比</div>
            </a-col>
            <a-col v-if="subTaskRatio" :span="2">
                <div class="form-value">
                    {{subTaskRatio}}%
                </div>
            </a-col>

        </a-row>
        <a-row>
            <a-col  :span="6">
                <div class="form-label" style="margin-right: 10px">主任务绩效</div>
            </a-col>
            <a-col v-if="subTaskRatio" :span="10">
                <div class="form-value">
                    {{money*(100 - subTaskRatio)/100}} = {{money}} * {{100-subTaskRatio}}%
                </div>
            </a-col>
            <a-col v-else :span="10">
                <div class="form-value">
                    {{money}}
                </div>
            </a-col>
            <a-col v-if="monthIncrease" :span="4">
                <div class="form-label" style="margin-right: 10px">本月进度增量</div>
            </a-col>
            <a-col v-if="monthIncrease" :span="4">
                <div class="form-value">
                    {{monthIncrease}}%
                </div>
            </a-col>
        </a-row>
        <a-divider />
        <a-row >
            <a-col :span="2">
                <div class="form-label" style="margin-right: 10px">年月</div>
            </a-col>
            <a-col :span="4">
                <div class="form-value">
                    <a-date-picker  @change="monthSelect"  v-model:value="yearMonth" picker="month" />
                </div>
            </a-col>
            <a-col v-if="yearMonth" :span="4">
                <div class="form-label" style="margin-right: 10px">工作量合计</div>
            </a-col>
            <a-col v-if="yearMonth" :span="2">
                <div class="form-value">
                    {{completeRatio}}%
                    <a-popover v-if="completeRatio!==100" trigger="hover" >
                        <template #content>
                             <span style="color:red">比例不为100%时候自动计算会出错</span>
                        </template>
                        <InfoCircleOutlined style="color:red"/>
                    </a-popover>
                </div>
            </a-col>
            <a-col v-if="yearMonth" :span="4">
                <div class="form-label" style="margin-right: 10px">本月分配绩效</div>
            </a-col>
            <a-col v-if="yearMonth" :span="8">
                <div class="form-value">
<!--                    <a-input-number  style="width:100%" v-model:value="completeMoney"/>-->
                    {{completeMoney}} = 主任务绩效 * {{monthIncrease}}%
                </div>
            </a-col>
<!--            <a-col v-if="yearMonth" :span="4">-->
<!--                <a-button @click="autoCalc">按比例填入</a-button>-->
<!--            </a-col>-->
        </a-row>
        <a-divider />
        <a-row v-if="!monthIncrease" style="margin-bottom: 10px;">
            <div v-if="yearMonth" class="form-value">
                暂无项目进度增量信息，请确认【任务月报】是否已经正确填报
            </div>
            <div v-else class="form-value">
                请选择月份查看该月项目进度及工作比例分配情况
            </div>
        </a-row>
        <a-row v-if="yearMonth && plan.length === 0">
            <div class="form-value">
                暂无工作量分配记录，请确认【本月工作量】是否已分配完成
            </div>
        </a-row>
        <a-row style="padding: 10px;" v-for="user in plan" :key="user.userId">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">{{user.userName}}</div>
            </a-col>
            <a-col :span="4">
                <div class="form-label" style="margin-right: 10px">工作量占比</div>
            </a-col>
            <a-col :span="2">
                <div class="form-value">
                    {{user.completeRatio}}%
                </div>
            </a-col>
            <a-col :span="4">
                <div class="form-label" style="margin-right: 10px">本月分配绩效</div>
            </a-col>
            <a-col :span="8">
                <div class="form-value">
<!--                    <a-input-number  style="width:100%" v-model:value="user.completeMoney"/>-->
                    {{user.completeMoney}}
                </div>
            </a-col>
        </a-row>
        <a-divider />
        <a-button v-if="yearMonth" :disabled="plan.length === 0" type="primary" @click="saveData">提交</a-button>
    </div>
</template>

<script>
    import {InfoCircleOutlined} from "@ant-design/icons-vue"
    export default {
        name: "MonthlyArrangePlan",
        props:["users",
            "monthIncrease", //本月进度增值
            "money",//月绩效总额
            "subTaskRatio" //子任务绩效占比
        ],
        emits:["finish","change"],
        components:{
            InfoCircleOutlined
        },
        computed:{
            completeRatio(){
                let sum = 0;
                for(let item of this.plan){
                    sum += item.completeRatio
                }
                return sum
            }
        },
        data() {
            return {
                columns:[],
                plan:[],
                completeMoney:0,
                yearMonth:null
            }
        },
        watch:{
            users(){
                this.init()
            },
            monthIncrease(){
                this.completeMoney = parseFloat((this.money * (100 - this.subTaskRatio)/100 * this.monthIncrease /100).toFixed(2));
                this.autoCalc()
            }
        },
        methods: {
            init() {
                this.plan = [];
                if(this.users && this.users.length>0){
                    for(let user of this.users){
                        let p = {...user};
                        this.plan.push(p)
                    }
                }
                this.autoCalc()
            },
            autoCalc(){
                for(let user of this.plan){
                    user.completeMoney = parseFloat((user.completeRatio * this.completeMoney /100).toFixed(2)) ;
                }
            },
            saveData(){
                this.$emit("finish",this.plan);
            },
            monthSelect(){
                if(this.yearMonth){
                    this.$emit("change",this.yearMonth.format("YYYY-MM"));
                }
                else{
                    this.plan = [];
                    this.$message.error("请选择年月")
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>