/**
 * @program: oa2.0
 * @author: ly
 * @description: Crypto
 * @create: 2023-07-27 10:31
 * 本地加密使用，用于储存用户数据
 **/
const AES = 'AES-GCM';
const algorithm = {name: AES, length: 256};

function stringifyUint8Array(array) {
    return JSON.stringify(Array.from(array))
}

function parseUint8Array(string) {
    return new Uint8Array(JSON.parse(string));
}

/**
 * 获取Initialization Vector
 * 若本地已有iv则使用本地的
 * @returns {Uint8Array}
 */
function getEncryptParams() {
    const storedIv = localStorage.getItem('__iv');
    let iv;
    if (storedIv) {
        // 将字符串数据转换回Uint8Array
        iv = parseUint8Array(storedIv);
    } else {
        iv = window.crypto.getRandomValues(new Uint8Array(32));
        localStorage.setItem("__iv", stringifyUint8Array(iv));
    }
    return iv;
}

function exportEncryptKey() {
    return  new Promise((resolve, reject) => {
        const storedKeyData = localStorage.getItem("__CKey");
        if(!storedKeyData){
            window.crypto.subtle.generateKey(algorithm, true, ['encrypt', 'decrypt'])
                .then(key => {
                    // 导出密钥为可存储的格式
                    // 生成随机的Initialization Vector (IV)
                    window.crypto.subtle.exportKey('raw', key)
                        .then(exportedKeyData => {
                            // 将导出的密钥数据存储在localStorage中
                            let array = new Uint8Array(exportedKeyData);
                            localStorage.setItem("__CKey", stringifyUint8Array(array));
                            resolve(stringifyUint8Array(array))
                        })
                        .catch(err => {
                            reject();
                            console.error('生成密钥时出错:', err)
                        });
                })
        }
        else{
            resolve(storedKeyData)
        }

    })

}

function cryptoSetItem(item, string) {
    const storedKeyData = localStorage.getItem("__CKey");
    return new Promise((resolve, reject) => {
        if (storedKeyData) {
            window.crypto.subtle.importKey('raw', parseUint8Array(storedKeyData), algorithm, true, ['encrypt', 'decrypt'])
                .then(key => {
                    // 生成随机的Initialization Vector (IV)
                    const iv = getEncryptParams();
                    // 定义加密参数（包括IV）
                    const encryptParams = {name: AES, iv: iv};
                    // 加密
                    let data = new TextEncoder().encode(string);
                    window.crypto.subtle.encrypt(encryptParams, key, data)
                        .then(encryptedData => {
                            let array = new Uint8Array(encryptedData);
                            localStorage.setItem(item, stringifyUint8Array(array));
                            resolve();
                        })
                        .catch(err => {
                            reject();
                            console.error('加密出错:', err)
                        });
                })
                .catch(err => {
                    reject();
                    console.error('生成密钥时出错:', err)
                });
        } else {
            window.crypto.subtle.generateKey(algorithm, true, ['encrypt', 'decrypt'])
                .then(key => {
                    // 导出密钥为可存储的格式
                    // 生成随机的Initialization Vector (IV)
                    const iv = getEncryptParams();
                    // 定义加密参数（包括IV）
                    const encryptParams = {name: AES, iv: iv};
                    // 加密
                    let data = new TextEncoder().encode(string);
                    window.crypto.subtle.encrypt(encryptParams, key, data)
                        .then(encryptedData => {
                            let array = new Uint8Array(encryptedData);
                            localStorage.setItem(item, stringifyUint8Array(array));
                            resolve();
                        })
                        .catch(err => {
                            reject();
                            console.error('加密出错:', err)
                        });
                    window.crypto.subtle.exportKey('raw', key)
                        .then(exportedKeyData => {
                            // 将导出的密钥数据存储在localStorage中
                            let array = new Uint8Array(exportedKeyData);
                            localStorage.setItem("__CKey", stringifyUint8Array(array));
                        })
                        .catch(err => {
                            console.error('密钥出错:', err)
                        });
                })
        }
    });
}

function cryptoGetItem(item) {
    let encryptedString = localStorage.getItem(item);
    if(!encryptedString){
        return ""
    }
    try {
        let encryptedData = parseUint8Array(encryptedString);
        // 得到随机的Initialization Vector (IV)
        const iv = getEncryptParams();
        // 定义加密参数（包括IV）
        const decryptParams = {name: AES, iv: iv};
        const storedKeyData = exportEncryptKey();
        return new Promise((resolve, reject) => {
            storedKeyData.then(storedKey=>{
                window.crypto.subtle.importKey('raw', parseUint8Array(storedKey), algorithm, true, ['encrypt', 'decrypt'])
                    .then(key => {
                        // 加密
                        window.crypto.subtle.decrypt(decryptParams, key, encryptedData)
                            .then(decryptedData => {
                                resolve(new TextDecoder().decode(new Uint8Array(decryptedData)));
                            })
                            .catch(err => {
                                reject();
                                console.error('解密出错:', err)
                            });
                    })
                    .catch(err => {
                        reject();
                        console.error('生成密钥时出错:', err)
                    });
            })
        })
    }
    catch (e) {
        console.log(e);
        return ""
    }

}



export {cryptoSetItem, cryptoGetItem}
