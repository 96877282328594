<!--
* @program: office_automation 
* @author: ly
* @component:StepsViewer
* @description: 
* @create: 2022-06-01 09:20
-->
<template>
    <div v-if="lSteps.length>0">
        <a-steps type="navigation">
            <a-step status="finish" style="margin: 10px" v-for="(name,index) in lSteps" :key="index" :title="name" >
                <template #icon><user-outlined /></template>
            </a-step>
        </a-steps>
    </div>
    <div v-else>
        还未设定审批流程
    </div>
</template>

<script>
    import {UserOutlined} from "@ant-design/icons-vue";

    export default {
        name: "StepsViewer",
        props:["steps"],
        components:{UserOutlined},
        computed:{
            lSteps(){
                if(this.steps && this.steps.length >0){
                    return this.steps;
                }
                else{
                    return []
                }
            }
        }
    }
</script>

<style scoped>

</style>