/**
 * @description：财务科目已经关联的费用类别
 * @author:rzl
 * @date:2022/7/25 14:30
 **/
import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

export default class sujectRelated_item extends Table {
    constructor() {
        super();
        this.name = "sujectRelated_item";
        this.CNName = "财务科目已经关联的费用类别";
        this.columnArray = [
            new Column("主键", "id", ColumnType.String, false).setVisible(false),
            new Column("费用类别大类", "type", ColumnType.Enum, true).setTableView(80)
                .setEnum(
                    {value:"1",label: "项目收款",color:"Pink"},
                    {value:"2",label: "其他收入",color:"MediumPurple"},
                    {value:"3",label: "其他支出",color:"CornflowerBlue"},
                    {value:"4",label: "外协支付",color:"PowderBlue"},
                    {value:"5",label: "员工报账",color:"Plum"},
                    {value:"6",label: "财务科目",color:"DarkViolet"},
                    {value:"7",label: "销项发票",color:"CadetBlue"},
                    {value:"8",label: "进项发票",color:"#FFC1C1"},
                    {value:"9",label: "固定资产",color:"#CD9B9B"},
                    ),
            new Column("费用类别名称", "categoryName", ColumnType.String, true).setTableView(160),


        ];
    }
}
