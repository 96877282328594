<!--
* @program: office_automation 
* @author: ly
* @component:AuditSteps
* @description: 
* @create: 2022-06-09 14:22
-->
<template>
    <div :style="style">
        <a-steps v-if="steps.length>0" style="width: 100%" size="small" >
            <a-step v-for="({approvalUserName,updateTime,remarks,status},key) in lSteps"
                    :status = "status"
                    :sub-title="getTime(updateTime)"
                    :key="key">
                <template #title>
                    <div style="display: flex;justify-content: space-around;height: 24px;align-items: center;">
                        <div>{{approvalUserName}}</div>
                        <a-popover v-if="remarks" trigger="hover" placement="top">
                            <template #content>
                                {{remarks}}
                            </template>
                            <a-button  type="link" >审核意见</a-button>
                        </a-popover>
                    </div>
                </template>
            </a-step>
        </a-steps>
    </div>
</template>

<script>
    import dayjs from "dayjs";

    export default {
        name: "AuditSteps",
        props:{
            steps:{
                required:true,
                default:[],
            }
        },
        watch:{
            steps(){
                this.init();
            }
        },
        data(){
            return{
                modalVisible:false,
                remarks:null,
                dateFormat: 'YYYY/MM/DD HH:mm:ss',
                style:"width:",
                lSteps:[],
            }
        },
        methods:{
            getTime(timeStamp){
                if(timeStamp){
                    return dayjs(timeStamp).locale('zh-cn').format(this.dateFormat);
                }
                else{
                    return "";
                }
            },
            init(){
                this.lSteps = [
                    {approvalUserName:"提交审核",state:"0"}
                ];
                if(this.steps.length>0){
                    this.lSteps[0].updateTime = this.steps[0].createTime;
                }
                this.lSteps = this.lSteps.concat(this.steps);
                for(let step of this.lSteps){
                    switch (step.state) {
                        case "0":{
                            step.status = "wait" ; //wait process finish error
                            break;
                        }
                        case "1":{
                            step.status = "process" ; //wait process finish error
                            this.lSteps[0].status = "finish";
                            break;
                        }
                        case "2":{
                            step.status = "finish" ; //wait process finish error
                            this.lSteps[0].status = "finish";
                            break;
                        }
                        case "3":{
                            step.status = "error" ; //wait process finish error
                            this.lSteps[0].status = "finish";
                            break;
                        }
                    }
                }
                if(this.lSteps.length>0){
                    this.style += this.lSteps.length *250 + "px"
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>