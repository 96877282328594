<!--
* @program: TYHOA2 
* @author: ly
* @component:TableDiary 
* @description: 
* @create: 2024-08-14 16:14
-->
<template>
    <div class="search-bar">
        <div  style="margin:0 10px">项目名称：</div>
        <a-input  placeholder="项目名称" style="width: 200px" v-model:value="filter.itemName" />
        <div  style="margin:0 10px">工序名称：</div>
        <a-input   placeholder="工序名称" style="width: 200px" v-model:value="filter.processTitle" />
        <div  style="margin:0 10px">工作内容：</div>
        <a-input   placeholder="工作内容" style="width: 200px" v-model:value="filter.workContent" />
    </div>
    <div style="height: 100%">
        <LTable :columns="columns" :data-source="source" >
            <template #action="{record}">
                <div class = "action-column">
                    <a-button @click="editDetail(record)" type="link">
                        <EditFilled class="pointer"/>
                    </a-button>
                </div>
            </template>
        </LTable>
    </div>
</template>

<script>
    import {EditFilled} from "@ant-design/icons-vue"
    import LTable from "../../../../components/table/LTable";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import {ArrayFilter} from "../../../../assets/utils/general";
    export default {
        name: "TableDiary",
        components:{
            LTable,EditFilled
        },
        props:["diary"],
        emits:["edit"],
        watch:{
            diary(){
                this.init()
            }
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        data() {
            return {
                columns:[
                    new Column("时间","workDate",ColumnType.Date,false).setTableView(100),
                    new Column("项目名称","itemName",ColumnType.String,true).setTableView(100),
                    new Column("工序名称","processTitle",ColumnType.Number,true).setTableView(100),
                    new Column("工作内容","workContent",ColumnType.String,true).setTableView(400),
                    new Column("操作","actions","actions",false).setTableView(80)
                ],
                dataSource:[],
                filter:{
                    itemName:undefined,
                    processTitle:undefined,
                }
            }
        },
        methods: {
            init() {
                this.dataSource = [];
                if(this.diary && this.diary.length>0){
                    this.dataSource = [...this.diary];
                    this.dataSource = this.dataSource.map(item=>{
                        item.itemName = item.itemName?item.itemName:"无项目";
                        item.processTitle = item.processTitle?item.processTitle:"其他工作";
                        return item
                    });
                    this.dataSource.sort((a,b)=>{
                        return  b.workDate - a.workDate
                    });
                }
            },
            editDetail(record){
                this.$emit("edit",record);
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>