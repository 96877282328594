<!--
* @program: TYHOA2 
* @author: ly
* @component:TaskInfoItem 
* @description: 用于部门项目任务管理
* @create: 2024-08-28 17:44
-->
<template>
    <a-card class="card-info" hoverable>
        <template #title>
            <span v-if="task.pid ==='0'" class="card-title">主任务</span>
            <span v-else class="card-title">子任务【绩效占比{{task.ratio}}%】</span>
        </template>
        <template #extra>
            <a-button v-if="task.id" @click="showWeekReport" type="link">进度报告</a-button>
            <a-button v-if="task.id && task.pid ==='0' && !viewModel" @click="addData" type="link">新增子任务</a-button>
            <a-button v-if="task.id && task.pid !=='0' && !viewModel" @click="editData" type="link">编辑</a-button>
            <a-popconfirm
                    ok-text="是"
                    cancel-text="否"
                    class="action-button"
                    title="确定撤回吗?"
                    v-if="task.id && task.pid !=='0' && !viewModel"
                    @confirm="deleteData">
                <a-button danger type="link">删除</a-button>
            </a-popconfirm>
        </template>
        <LForm :form-columns="formColumns1" :form-data="formData" :labelSpan="8" :view-model="vModel">
            <template #action="{record}">
                <a-row style="margin-bottom: 5px">
                    <a-col :span="8"><div class="form-label" style="margin-right: 10px">选择部门</div></a-col>
                    <a-col :span="16">
                        <a-select placeholder="选择部门" v-model:value="departmentId" style="width: 100%">
                            <a-select-option v-for="item in departments"  :key="item.id">
                                {{item.fullName}}
                            </a-select-option>
                        </a-select>
                    </a-col>
                </a-row>
                <a-row v-if="task.pid !=='0'" style="margin-bottom: 10px">
                    <a-col :span="8"><div class="form-label" style="margin-right: 10px">绩效占比(%)</div></a-col>
                    <a-col :span="16">
                        <a-input-number style="width: 100%" v-model:value="ratio"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="12">
                    <a-button @click="saveData(record)" type="primary">
                        提交
                    </a-button>
                    </a-col>
                    <a-col v-if="task.id" :span="12">
                        <a-button @click="vModel = true" danger type="primary">
                            取消
                        </a-button>
                    </a-col>
                </a-row>
            </template>
        </LForm>
    </a-card>
</template>

<script>
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import LForm from "../../../components/form/LForm";
    import itemTask from "../../../assets/api/itemTask";
    export default {
        name: "TaskInfoItem",
        props:["task","viewModel"],
        emits:["report","save","delete","add"],
        components:{
            LForm
        },
        data() {
            return {
                formColumns1:[
                    new Column("任务标题","taskTitle",ColumnType.WrapString,true),
                    new Column("任务内容","content",ColumnType.WrapString,true),
                    new Column("分配部门","departmentName",ColumnType.String,false),
                    new Column("任务负责人","taskLeaderName",ColumnType.String,false),
                    new Column("任务开始时间","startDate",ColumnType.Date,true),
                    new Column("完成期限","endDate",ColumnType.Date,true),
                    new Column("备注","remarks",ColumnType.WrapString,true),
                    new Column("完成状态","state",ColumnType.String)
                        .setDictEnum("state"),
                    new Column("完成情况说明","describe",ColumnType.String,false),
                ],
                formData:{},
                departments:[],
                departmentId:undefined,
                ratio:0,
                vModel:true
            }
        },
        methods: {
            init() {
                this.formData = {...this.task};
                if(this.task.id){
                    this.departmentId = this.task.departmentId;
                    this.ratio = this.task.ratio;
                }
                else{
                    this.vModel = false
                }
            },
            showWeekReport(){
                this.$emit("report",this.task)
            },
            addData(){
                this.$emit("add",this.task)
            },
            saveData(record){
                this.vModel = true;
                record.ratio = this.ratio;
                record.departmentId = this.departmentId;
                this.$emit("save",record);
            },
            deleteData(){
                let data = {
                    id:this.task.id
                };
                itemTask.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$emit("delete",this.task);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editData(){
                this.vModel = false
            }
        },
        async created() {
            this.init();
            this.departments = JSON.parse(await this.$getItem("__departments"));
        }
    }
</script>

<style scoped>

</style>