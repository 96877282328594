<!--
* @program: TYHOA2 
* @author: ly
* @component:typeColorText 
* @description: 
* @create: 2024-09-11 11:12
-->
<template>
    <a-tooltip trigger="click" :title="presentValue?presentValue.text:presentValue">
        <div class="no-warp-string" :style="'color:'+(presentValue?presentValue.color:'')" >{{presentValue?presentValue.text:presentValue}}</div>
    </a-tooltip>
</template>

<script>
    import typeBase from "./typeBase";
    export default {
        name: "typeColorText",
        mixins:[typeBase]
    }
</script>

<style scoped>

</style>