/**
 * @program: office_automation
 * @author: ly
 * @description: item_invoice_record_new
 * 公司开票出去给甲方的发票（甲方付款给公司的发票）
 * 由项目直接填入发票
 * @create: 2021-12-15 19:06
 **/

import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import partyInfo from "@/assets/api/partyInfo";

export default class item_invoice_record_new extends Table {
    constructor() {
        super();
        this.name = "item_invoice_record_new";
        this.CNName = "发票信息";
        this.columnArray = [
            new Column("开票金额", "invoiceAmount", ColumnType.Number, true).setTableView(100),
            new Column("已收款额", "collectAmount", ColumnType.Number, false).setTableView(100).setGroup(1),
            new Column("收款状态", "collectState", ColumnType.Enum, false)
                .setDictEnum("collectState"),
            new Column("开票日期", "invoiceTime", ColumnType.Date, true).setTableView(100),
            new Column("发票代码", "invoiceCode", ColumnType.String, true),
            new Column("发票号码", "invoiceNumber", ColumnType.String, true).setTableView(100),
            new Column("发票类型", "invoiceType", ColumnType.Enum, true).setDefaultValue("1")
                .setDictEnum("invoiceType")
                .setTriggerOtherColumn("1", (formData, cols) => {
                    cols.find(col => {
                        return col.dataIndex === "checkCode"
                    })?.setVisible(true);
                }, (formData, cols) => {
                    cols.find(col => {
                        return col.dataIndex === "checkCode"
                    })?.setVisible(false);
                }),
            new Column("检验码", "checkCode", ColumnType.String, true),
            new Column("付款方", "partyId", ColumnType.IdReplace, true).setGroup(1).setTableView(200)
                .setIdReplaceObject("partyInfo.name",{api: partyInfo.queryAll, tag: "queryText"}),
            new Column("收款方", "companyId", ColumnType.Enum, true)
                .setDictEnum("companies").setGroup(1),
            new Column("冲红", "flushRed", ColumnType.Boolean, false).setGroup(2)
                .setBoolTags(["未冲红", "已冲红"]).setDefaultValue(false),

        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(100)];
    }
}