<!--
* @program: office_automation
* @author: ly
* @component:TagShow
* @description:
* @create: 2021-12-15 10:11
-->
<template>
    <div>
        <a-divider orientation="left" style="margin:0 0 5px 0">
            <a-tag :color="mColor">{{tag}}</a-tag>
            <a-button type="link" @click="mVisible = !mVisible">
                <template #icon>
<!--                    <div :is="comp"></div>-->
                    <component :is="comp" />
                </template>
            </a-button>
        </a-divider>
        <div v-if="mVisible">
            <div style="background: #fafafa;border: solid 1px #dadada;margin:  0 5px">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script>
    import {DownOutlined,UpOutlined} from "@ant-design/icons-vue";
    export default {
        name: "TagShow",
        props:["color","tag","visible"],
        //emits:["update:visible"],
        inject:["sigCompanyName"],
        watch:{
            visible(){
                this.mVisible = this.visible;
            }
        },
        components:{DownOutlined,UpOutlined},
        computed:{
            comp(){
                return this.mVisible?"UpOutlined":"DownOutlined"
            }
        },
        data(){
            return{
                mVisible:true,
                mColor:"cyan"
            }
        },
        created() {
            if(this.visible === false){
                this.mVisible = false;
            }
            if(this.color){
                this.mColor = this.color;
            }
        }
    }
</script>

<style scoped>

</style>
