<!--
* @program: TYHOA2 
* @author: ly
* @component:FinishWork 
* @description: 
* @create: 2024-08-15 11:21
-->
<template>
    <a-tree
            defaultExpandAll
            :tree-data="source"
            :field-names="fieldNames">
        <template #title="{data}">
            <div style="display:flex;">
                <div v-if="!data.processTitle" style="font-size: 14px;font-weight:bold">{{data.taskTitle}}</div>
                <a-tag @click="finishWork(data)" v-if="data.processTitle" :color="data.state?'blue':'red'">{{data.processTitle}}{{data.state?'':"（未完成）"}}</a-tag>
            </div>
        </template>
    </a-tree>
</template>

<script>
    import {uuid} from "../../../../assets/utils/general";

    export default {
        name: "FinishWork",
        props:["treeData"],
        emits:["finish"],
        computed:{
            source(){
                let taskList = [];
                let count = 1;
                for(let item of this.treeData){
                    let {taskTitle,taskId} = item;
                    let arr = taskList.filter(item=>item.taskId === taskId);
                    if(arr.length >0){
                        arr[0].children.push(item);
                    }
                    else{
                        taskList.push({id:uuid(10,10),selectable:false,taskId:taskId,taskTitle:count+"."+taskTitle,children:[item]})
                        count++
                    }
                }
                return taskList
            }
        },
        data() {
            return {
                fieldNames:{
                    children:'children',
                    label:'taskTitle',
                    value: 'id'
                },
            }
        },
        methods:{
            finishWork(processEmployee){
                if(!processEmployee.state){
                    this.$emit("finish",processEmployee);
                }
            }
        }
    }
</script>

<style scoped>

</style>