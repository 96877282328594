<!--
* @program: TYHOA2 
* @author: ly
* @component:ModalPoolApply 
* @description: 
* @create: 2024-09-12 16:13
-->
<template>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             title="绩效详情">
        <a-row>
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">年度</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-input-number :min="2020" v-model:value="currentRecord.year"/>
                </div>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">月度绩效</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-input-number style="width:100%" v-model:value="currentRecord.monthAmount"/>
                </div>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">年度绩效</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-input-number style="width:100%" v-model:value="currentRecord.yearAmount"/>
                </div>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">申请原因</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-textarea v-model:value="currentRecord.applyReason" :auto-size="{ minRows: 2, maxRows: 6 }"/>
                </div>
            </a-col>
        </a-row>
        <a-button @click="saveData"  type="dashed">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import depAchievePool from "../../../assets/api/depAchievePool";
    import dayjs from "dayjs"
    export default {
        name: "ModalPoolApply",
        emits:["update"],
        data() {
            return {
                visible:false,
                currentRecord:{}
            }
        },
        methods: {
            saveData(){
                depAchievePool.saveOrUpd.post(this.currentRecord,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.$emit("update");
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            open(data){
                this.currentRecord = {
                    year:dayjs().year(),
                    approvState:"0",
                    monthAmount:0,
                    yearAmount:0
                };
                Object.assign(this.currentRecord,data)
                this.visible = true;
            }
        },
        created() {
            this.currentRecord = {};
        }
    }
</script>

<style scoped>

</style>