/** 
* 自动生成文件，请勿修改 
* @name: 项目:生产工序管理模块============新增了一检附件上传
* @description: 项目-生产工序管理模块-增删改查(二期修改，增加字段)
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const itemTaskProcess = { 
	/** 1 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/itemTaskProcess/queryOne.do"), 
	/** 2 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/itemTaskProcess/batchDelete.do"), 
	/** 3 summary:查询-所有数据
	description:参数：departmentId，itemId,queryText
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/itemTaskProcess/queryAll.do"), 
	/** 4 summary:工序审核(可以批量审核)===========新增接口
	description:参数：ids,approveState,approveRemarks
	method:post
	params:需要配合Column函数使用
	*/ 
	approve : new APIObject.postObject("/api/itemTaskProcess/approve.do"), 
	/** 5 summary:保存/修改-批量
	description:参数：List<ItemTaskProcessVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/itemTaskProcess/batchSaveOrUpd.do"), 
	/** 6 summary:上传一检附件===========新增接口
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	qcFileUpload : new APIObject.postObject("/api/itemTaskProcess/qcFileUpload.do"), 
	/** 7 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/itemTaskProcess/delete.do"), 
	/** 8 summary:保存/修改-单个生产工序
	description:参数：单条数据对象ItemTaskProcessVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/itemTaskProcess/saveOrUpd.do"), 
	/** 9 summary:查询-分页
	description:参数：(pageBean分页参数)、departmentId，itemId,queryText
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/itemTaskProcess/queryPage.do"), 
};
export default itemTaskProcess;