<!--
* @program: TYHOA2 
* @author: ly
* @component:UserAssessmentItem 
* @description: 
* @create: 2024-08-30 17:43
-->
<template>
    <div class="search-bar">
        <div style="margin:0 5px">考核月份：</div>
        <a-input  placeholder="XXXX-XX" style="width: 150px" v-model:value="filter.yearMonth" />
    </div>
    <a-spin :spinning="loading">
        <div style="overflow: hidden;position: relative;height:500px ">
            <LTable :data-source="source" :columns="tableColumns">
                <template #action="{record}">
                    <div class="action-column">
                        <a-button @click="showDetail(record)" class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button @click="downLoadFile(record)" class="action-button" type="link">
                            <DownloadOutlined class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="record.judgeEnd && record.agreeFlag !=='1'"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="发出短信提醒该员工评分已完成?"
                                @confirm="setMail(record)">
                            <a-tooltip title="短信提醒">
                                <a-button class="action-button" type="link">
                                    <MailOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                        </a-popconfirm>
                    </div>
                </template>
            </LTable>
        </div>
    </a-spin>
    <a-modal
            title="考核详情"
            width="1200px"
            :footer="null"
            v-model:open="visible">
        <div style="width: 100%;height: 500px;overflow: auto ">
            <AssessmentViewer :assessment-name="currentRecord.typeName" :view-model="true" :assessment-data="AssessmentData" user-type="user" />
        </div>
    </a-modal>
</template>

<script>
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import LTable from "../../../../components/table/LTable";
    import {SearchOutlined,DownloadOutlined,MailOutlined} from "@ant-design/icons-vue";
    import assessmentUser from "../../../../assets/api/assessmentUser";
    import kpi from "../../../../assets/api/kpi";
    import smsRecord from "../../../../assets/api/smsRecord";
    import AssessmentViewer from "../../../PersonalCenter/PersonalAssessment/Monthly/AssessmentViewer";
    import {ArrayFilter} from "../../../../assets/utils/general";
    export default {
        name: "UserAssessmentItem",
        props:["param"],//
        watch:{
            param(){
                this.init()
            }
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        components:{
            LTable,AssessmentViewer,
            SearchOutlined,
            DownloadOutlined,MailOutlined
        },
        data(){
            return{
                filter:{
                    yearMonth:null
                },
                yearMonth:null,
                dataSource:[],//提供table的DataSource
                tableColumns:[
                    new Column("年月","yearMonth",ColumnType.String).setTableView(100),
                    new Column("考核类型","typeName",ColumnType.String).setTableView(100),
                    new Column("得分","score",ColumnType.String).setTableView(100),
                    new Column("评分","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                    new Column("同意","agreeFlag",ColumnType.Enum).setTableView(60)
                        .setDictEnum("agreeFlag"),
                    new Column("操作","actions","actions").setTableView(80)
                ], //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                AssessmentData:null,
                vModel:true,
                currentRecord:{},
                visible:false,
                cYearMonth:null
            }
        },
        methods:{
            init(){
                this.vModel = true;
                let data = {
                    userId : this.param.id,
                };
                assessmentUser.queryAll.post(data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return new Date(b.yearMonth) - new Date(a.yearMonth)
                        });
                        for(let i in this.dataSource){
                            this.dataSource[i].judgeEnd = true;
                            this.dataSource[i].agreeFlag = "1";
                            let score = 0;
                            for(let j in this.dataSource[i].userDTOS){
                                score += this.dataSource[i].userDTOS[j].obtainScore;
                                if(this.dataSource[i].userDTOS[j].judgeEnd === false){
                                    this.dataSource[i].judgeEnd = false
                                }
                                if(this.dataSource[i].userDTOS[j].agreeFlag === "2"){
                                    this.dataSource[i].agreeFlag = "2"
                                }
                                if(this.dataSource[i].userDTOS[j].agreeFlag === "0"){
                                    this.dataSource[i].agreeFlag = "0"
                                }
                            }
                            this.dataSource[i].score = score.toFixed(2)
                        }
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            showDetail(record){
                this.currentRecord = record;
                this.AssessmentData = record.userDTOS;
                this.AssessmentData.sort((a,b)=>{
                    return a.sortOrder - b.sortOrder
                });
                this.vModel = true;
                this.userType = "user";
                this.visible = true
            },
            editDetail(record){
                this.currentRecord = record;
                this.AssessmentData = record.userDTOS;
                this.AssessmentData.sort((a,b)=>{
                    return a.sortOrder - b.sortOrder
                });
                this.visible = true;
                this.vModel = false;
                this.userType = "judge";
            },
            downLoadFile(record){
                let {yearMonth,userId,userName} = record;
                let data = {
                    yearMonth:yearMonth,
                    userId : userId,
                };
                kpi.exportByUserMonth.fileDownloadGet(data,"月度考核报告【"+yearMonth+"-"+userName+"】.xls");
            },
            setMail(record){
                let {userId,yearMonth} = record;
                let data = {
                    userIds:userId,
                    month:yearMonth
                };
                smsRecord.notifyStaff.urlPost(data);
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>