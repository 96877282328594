<!--
* @program: office_automation
* @author: ly
* @component:YearlyAssessmentSetting
* @description: 年度考核配置设定
* @create: 2022-02-24 15:12
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh"  shape="circle" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <span style="margin: 20px">考核表类型：</span>
            <a-select v-model:value="defaultQuery.type" style="width: 250px" @change="selectChange">
                <a-select-option v-for="option in options" :key="option.id">{{option.name}}</a-select-option>
            </a-select>
            <span style="margin: 20px">分数合计：{{sumScore}}</span>
            <a-button  type="dashed" shape="circle" @click="addData" >
                <template #icon><PlusOutlined /></template>
            </a-button>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="full-fill">
                <LTable :columns="argument.tableColumns" :dataSource="argument.dataSource" :view-model="true" :page-size="pageSize">
                    <template #action="{index,record}">
                        <div class="action-column">
                            <a-button class="action-button" type="link" @click="showDetail(record)">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-button v-if="record.judgeRoleName !=='系统评分'" class="action-button" type="link" @click="editDetail(record)">
                                <EditFilled class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    v-if="record.judgeRoleName !=='系统评分'"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(index,record)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <div>
            <span>打分角色：</span>
            <a-select v-model:value="roleId" style="width: 250px" @change="selectChange">
                <a-select-option v-for="option in roleOptions" :key="option.id">{{option.roleName}}</a-select-option>
            </a-select>
        </div>
        <LForm  :formColumns="formArg.formColumns" :formData="formArg.formData" :view-model="viewModel" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import MonthlyAssessmentSetting from "../MonthlyAssessment/MonthlyAssessmentSetting";
    export default {
        name: "YearlyAssessmentSetting",
        mixins:[MonthlyAssessmentSetting],
        data(){
            return{
                options:[
                    {id:"11",name:"作业员年度考核"},
                    {id:"12",name:"工勤岗年度考核"},
                    {id:"13",name:"技术管理岗年度考核"},
                    {id:"14",name:"工勤管理岗年度考核"},
                ],
                defaultQuery: {
                    type:"11",
                },
            }
        }
    }
</script>

<style scoped>

</style>
