<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectPayAuditSetting 
* @description: 
* @create: 2024-09-14 15:23
-->
<template>
    <div class="form-title">
        项目外协支付申请审批流程
        <a-button style="margin-left: 10px" type="dashed" @click="init">
            <template #icon><SyncOutlined /></template>
        </a-button>
        <a-popconfirm
                v-if="!viewModel"
                ok-text="是"
                cancel-text="否"
                title="确定保存吗？"
                @confirm="handleSave">
            <a-button style="margin-left: 10px"  type="primary">
                保存
            </a-button>
        </a-popconfirm>
        <a-button style="margin-left: 10px" v-if="!viewModel && approvalUserName.length === 0" @click="handleAdd">
            <template #icon><PlusOutlined /></template>
        </a-button>
    </div>
    <StepSetting :view-model="viewModel" style="padding: 10px 20px"  :steps="approvalUserName" @leftAdd="leftAdd" @remove="remove" @rightAdd="rightAdd"/>
    <div v-if="approvalUserName.length === 0">
        还未设定审核步骤
    </div>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="选择审核人"
             :maskClosable="false"
             :footer="null">
        <a-select
                style="width: 300px" show-search v-model:value="value"
                placeholder="输入关键字查询"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @select="handleSelect"
                @search="handleSearch"
        >
            <a-select-option v-for="option in options" :key="option.id">
                <a-tooltip :title="option.name">
                    <a-button style="padding: 0" type="text">
                        {{ option.name }}
                    </a-button>
                </a-tooltip>
            </a-select-option>
        </a-select>
    </a-modal>
</template>

<script>
    import StepSetting from "../StepSetting";
    import {PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import payAudit from "../../../../../assets/api/payAudit";
    import userInfo from "../../../../../assets/api/userInfo";

    export default {
        name: "ProjectPayAuditSetting",
        props:["viewModel"],
        components:{
            StepSetting,
            SyncOutlined,PlusOutlined
        },
        data(){
            return{
                spinning:false,
                value:null,
                addType:"",
                currentIndex:null,
                options:[],
                step:{
                    name : "项目支付审核流程",
                    type : "1",
                    id : null,
                },
                approvalUsers:[],
                approvalUserName:[],
                visible:false
            }
        },
        methods:{
            init(){
                this.spinning = true;
                payAudit.queryAll.post(this.step,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        if(res.data.data.length === 1){
                            this.approvalUsers = res.data.data[0].approvalUsers.split(",");
                            this.approvalUserName = res.data.data[0].approvalUserName?res.data.data[0].approvalUserName:[];
                            this.step.id = res.data.data[0].id;
                        }
                    }
                })
            },
            handleSearch(key){
                if(!key) return;
                let data={queryText:key,disableState:"1",loginState:"0"};
                userInfo.queryAll.urlPost(data,res=>{
                    if(res.data.flag){
                        this.options = res.data.data;
                    }
                })
            },
            handleAdd(){
                this.currentIndex = 0;
                this.addType = "left";
                this.visible = true;
                this.value = null;
            },
            handleSelect(){
                this.visible = false;
                let name = this.options.filter(option=>{return option.id === this.value})[0]?.name;
                switch (this.addType) {
                    case "left":{
                        this.approvalUserName.splice(this.currentIndex,0,name);
                        this.approvalUsers.splice(this.currentIndex,0,this.value);
                        break;
                    }
                    case "right":{
                        this.approvalUserName.splice(this.currentIndex+1,0,name);
                        this.approvalUsers.splice(this.currentIndex+1,0,this.value);
                        break;
                    }
                }
                this.handleSave();
            },
            handleSave(){
                let data = {
                    approvalUsers : this.approvalUsers.toString(),
                    stepCount : this.approvalUsers.length
                };
                Object.assign(data,this.step);
                payAudit.saveOrUpd.post(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            leftAdd(step){
                this.currentIndex = this.approvalUserName.indexOf(step);
                this.addType = "left";
                this.visible = true;
                this.value = null;
            },
            remove(step){
                let index = this.approvalUserName.indexOf(step);
                this.approvalUserName.splice(index,1);
                this.approvalUsers.splice(index,1);
                this.handleSave();
            },
            rightAdd(step){
                this.currentIndex = this.approvalUserName.indexOf(step);
                this.addType = "right";
                this.visible = true;
                this.value = null;
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>