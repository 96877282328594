<!--
* @program: TYHOA2 
* @author: ly
* @component:MonthlyLeaderArrange 
* @description: 
* @create: 2024-08-12 09:28
-->
<template>
    <div class="column-flex">
        <div style="width: 100%;display: flex;height:100%">
            <div class="main-content50">
                <MonthReport @workRatio="workRatio" :view-model="viewModel" :task="task" />
            </div>
            <div class="main-content50">
                <div class="search-bar">
                    <a-button @click="init"   type="dashed">
                        <template #icon><SyncOutlined/></template>
                    </a-button>
                    <a-tooltip v-if="defaultUsers.length >0 " title="按成员分配">
                        <a-button v-if="!viewModel" @click="addPlan"  type="dashed">
                            <template #icon><PlusOutlined/></template>
                        </a-button>
                    </a-tooltip>
                    <a-input v-model:value="filter.yearMonth" style="width:150px" placeholder="年月" />
                    <a-input v-model:value="filter.userName" style="width:150px" placeholder="姓名" />
                </div>
                <div style="height:100%">
                    <LTable title="工作量比例分配" :columns="columns" :data-source="source">
                        <template #action="{record}">
                            <div v-if="!viewModel" class="action-column">
                                <a-button  class="action-button" type="link" @click="editDetail(record)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(record)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </LTable>
                </div>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroy-on-close
             title="月度绩效分配"
             :width="400"
             :footer="null">
        <a-row>
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">选择人员</div>
            </a-col>
            <a-col :span="12">
                <div class="form-value"><DepartmentUserPicker :department-id="departmentId" v-model:value="user"/></div>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">年月</div>
            </a-col>
            <a-col :span="12">
                <div class="form-value">
                    <a-date-picker v-model:value="yearMonth" picker="month" />
                </div>
            </a-col>
        </a-row>
        <LForm :labelSpan="8" :form-data="formData" :form-columns="columns" :view-model="vModel">
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal v-model:open="visible2"
             destroy-on-close
             title="月度工作量分配"
             :width="800"
             :footer="null">
        <MonthlyLeaderArrangePlan :yearMonth="filter.yearMonth" :departmentId="departmentId" @change="monthChange"  :users="users" @finish="savePlan"/>
    </a-modal>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import LForm from "../../../../components/form/LForm";
    import DepartmentUserPicker from "../../../common/DepartmentUserPicker";
    import {DeleteOutlined, EditFilled, PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import taskMonthUser from "../../../../assets/api/taskMonthUser";
    import dayjs from "dayjs";
    import processEmployee from "../../../../assets/api/processEmployee";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import MonthlyLeaderArrangePlan from "./MonthlyLeaderArrangePlan";
    import MonthReport from "../WorkTaskReport/MonthReport";

    export default {
        name: "MonthlyLeaderArrange",
        props:["viewModel","task","departmentId"],
        components:{
            LTable,LForm,DepartmentUserPicker,MonthlyLeaderArrangePlan,
            EditFilled,DeleteOutlined,PlusOutlined,SyncOutlined,
            MonthReport
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
        },
        data() {
            return {
                pool:[],
                columns:[
                    new Column("年月","yearMonth",ColumnType.Month,false).setTableView(80),
                    new Column("用户名","userName",ColumnType.String,false).setTableView(100),
                    new Column("作业比例（%）","completeRatio",ColumnType.Number,true).setTableView(150),
                    new Column("操作","actions","actions").setTableView(100)
                ],
                dataSource:[],
                columns2:[
                    new Column("工序","processTitle",ColumnType.String,false).setTableView(200),
                    new Column("开始时间","startDate",ColumnType.Date,false).setTableView(100),
                    new Column("成员","userName",ColumnType.String,false).setTableView(200),
                ],
                dataSource2:[],
                filter:{},
                visible:false,
                visible2:false,
                formData:{},
                user:null,
                users:[],
                defaultUsers:[],
                yearMonth:null,
                vModel:true,
            }
        },
        methods: {
            init() {
                let data = {
                    taskId:this.task.id
                };
                taskMonthUser.queryAllOnlyRatio.post(data,res=>{//得到每个人已经分配的绩效
                    if(res.data.flag){
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return  b.createTime - a.createTime
                        });
                    }
                    else{
                        this.dataSource = [];
                    }
                });
            },
            addData(){
                this.visible = true;
                this.formData = {};
                this.vModel = false;
            },
            addPlan(){
                this.visible2 = true;
                this.users = [];

            },
            editDetail(record){
                this.vModel = false;
                this.visible = true;
                this.formData = record;
                this.user = record.userId;
                this.yearMonth = dayjs(record.yearMonth);
            },
            deleteData(record){
                let data = {id:record.id};
                taskMonthUser.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource = this.dataSource.filter(item=>item.id !== record.id);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                record.yearMonth = this.yearMonth.format("YYYY-MM");
                record.taskId = this.task.id;
                record.userId = this.user;
                record.completeMoney = 0;
                taskMonthUser.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            showDetailAll(){
                let data = {taskId:this.task.id};
                processEmployee.queryAll.post(data,res=>{
                    this.dataSource2 = [];
                    this.defaultUsers = [];
                    if(res.data.flag){
                        for(let item of res.data.data){
                            let {userId,userName,processTitle,startDate} = item;
                            let record = this.dataSource2.find(it=>it.processTitle === processTitle)
                            if(record){
                                record.userName += " | " + userName
                            }
                            else{
                                this.dataSource2.push({userName,startDate,processTitle})
                            }
                            let record2 =  this.defaultUsers.find(it=>it.userName === userName)
                            if(record2){
                                record2.processTitle += " | " + processTitle
                            }
                            else{
                                this.defaultUsers.push({
                                    taskId:this.task.id,
                                    completeMoney:0,
                                    completeRatio:0,
                                    userId:userId,
                                    userName:userName,
                                    processTitle:processTitle
                                })
                            }
                        }
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            savePlan(plan){
                let params = {
                    taskMonthUserVOS: plan
                };
                taskMonthUser.batchSaveOrUpd.post(params,res=>{
                    if (res.data.flag) {
                        this.visible2 = false;
                        this.init()
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            monthChange(yearMonth){
                let users = this.dataSource.filter(item => item.yearMonth === yearMonth);
                if(users.length >0){
                    this.users = [...users];
                }
                else{
                    this.users = [...this.defaultUsers];
                }
            },
            allowEdit(record){
                let year = dayjs().year() === dayjs(record.yearMonth).year();
                let month = dayjs().month() === dayjs(record.yearMonth).month() || dayjs().month()-1 === dayjs(record.yearMonth).month()
                return year && month
            },
            workRatio(month){
                this.filter.yearMonth = month;
            }
        },
        created() {
            this.init();
            this.showDetailAll();
        }
    }
</script>

<style scoped>

</style>