<!--
* @program: TYHOA2 
* @author: ly
* @component:AuditState 
* @description: 
* @create: 2024-08-30 09:53
-->
<template>
    <a-tag :color="value.color">{{value.label}}</a-tag>
</template>

<script>
    import {GetDict} from "../../../../assets/utils/general";

    export default {
        name: "AuditState",
        props:["state","states"],
        watch:{
            state(){
                this.init()
            }
        },
        data() {
            return {
                ApproveStates:GetDict("item_progress_apply.approveState"),
                value:{}
            }
        },
        methods: {
            init() {
                if(this.states){
                    let state = this.states.find(item =>item.value === this.state);
                    this.value = state?state:{};
                }
                else{
                    let state = this.ApproveStates.find(item =>item.value === this.state);
                    this.value = state?state:{};
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>