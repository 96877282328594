<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentPool
* @description: 部门绩效池
* @create: 2024-08-13 15:02
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-tooltip title="添加部门间流转">
                <a-button ref="ref1" type="dashed" @click="addData">
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </a-tooltip>
            <div style="margin: 10px;color: black">部门:</div>
            <a-select v-model:value="departmentId"
                      style="width: 200px"
                      @change="init">
                <a-select-option v-for="department in myDepartments" :key="department.id">
                    {{ department.name }}
                </a-select-option>
            </a-select>
            <a-input v-model:value="filter.itemName" style="width:150px" placeholder="项目名称" />
            <a-input v-model:value="filter.year" style="width:150px" placeholder="年度" />
            <a-tooltip title="金额统计">
                <a-button ref="ref2" @click="visible2 = true" type="dashed" >
                    <template #icon><AreaChartOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-button type="primary" @click="tourVisible=true">功能说明</a-button>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div  class="main-content50">
                <div  style="height:calc(100% - 300px);">
                    <LTable ref="ref3" :title="title" :columns="columns" :data-source="source"/>
                </div>
                <div  style="height:300px;">
                    <LTable ref="ref4" :title="exTitle" :columns="columns3" :data-source="dataSource3">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-popconfirm
                                        v-if="record.parent"
                                        ok-text="是"
                                        cancel-text="否"
                                        title="确定删除吗？"
                                        @confirm="deleteData(record)">
                                    <a-button type="link">
                                        <template #icon><DeleteOutlined style="color: red" /></template>
                                    </a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                    </LTable>
                </div>
            </div>
            <div class="main-content50">
                <LTable :title="title2"  :loading="loading" :columns="columns2" :data-source="source2"/>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             width="600px"
             destroyOnClose
             title="部门间流转">
        <a-row style="margin-bottom: 10px;">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">支付给：</div>
            </a-col>
            <a-col :span="6">
                <div class="form-value">
                    <DepartmentPicker  v-model:value="currentExchange.recDepartId" />
                </div>
            </a-col>
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">绩效：</div>
            </a-col>
            <a-col :span="6">
                <div class="form-value">
                    <a-input-number :min="0" style="width: 100%;"  v-model:value="currentExchange.amount" />
                </div>
            </a-col>
        </a-row>
        <a-row style="margin-bottom: 10px;">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">支付描述：</div>
            </a-col>
            <a-col :span="18">
                <div class="form-value">
                    <a-textarea v-model:value="currentExchange.describe"  placeholder="请描述清楚支付缘由" :rows="4" />
                </div>
            </a-col>
        </a-row>
        <template #footer>
            <a-button key="back" @click="visible =false">取消</a-button>
            <a-button key="submit" type="primary"  @click="saveData">提交</a-button>
        </template>
    </a-modal>

    <a-modal v-model:open="visible2"
             width="800px"
             destroyOnClose
             :footer="null"
             :title="null">
        <div style="font-size:20px;font-weight: bold;">统计说明：将3个表内展示的数据进行数据统计（可以联动过滤筛选条件）</div>
        <div class="form-title">收入</div>
        <a-row style="display:flex;align-items: center;justify-content: center;">
            <a-col :span="6">
                <a-statistic  :value="allSum.all.toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">绩效合计</div>
                    </template>
                </a-statistic>
            </a-col>
            <a-col :span="2">
                <div style="font-size:40px">+</div>
            </a-col>
            <a-col :span="6">
                <a-statistic :value="exchangeSum.in.toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">部门间流转（收入）</div>
                    </template>
                </a-statistic>
            </a-col>
            <a-col :span="2">
                <div style="font-size:40px">=</div>
            </a-col>
            <a-col :span="6">
                <a-statistic :value="(allSum.all+exchangeSum.in).toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">合计</div>
                    </template>
                </a-statistic>
            </a-col>
        </a-row>
        <div class="form-title">支出</div>
        <a-row style="display:flex;align-items: center;justify-content: center;">
            <a-col :span="6">
                <a-statistic  :value="completeMoney.toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">已下发绩效</div>
                    </template>
                </a-statistic>
            </a-col>
            <a-col :span="2">
                <div style="font-size:40px">+</div>
            </a-col>
            <a-col :span="6">
                <a-statistic  :value="exchangeSum.out.toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">部门间流转（支出）</div>
                    </template>
                </a-statistic>
            </a-col>
            <a-col :span="2">
                <div style="font-size:40px">=</div>
            </a-col>
            <a-col :span="6">
                <a-statistic  :value="(completeMoney+exchangeSum.out).toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">合计</div>
                    </template>
                </a-statistic>
            </a-col>
        </a-row>
        <div class="form-title">剩余</div>
        <a-row style="display:flex;align-items: center;justify-content: center;">
            <a-col :span="6">
                <a-statistic :value="(allSum.all - completeMoney ).toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">
                            <span :style="(allSum.all - completeMoney)<0?'color:red':'color:blue'">剩余绩效</span>
                        </div>
                    </template>
                </a-statistic>
            </a-col>
            <a-col :span="2">
                <div style="font-size:40px">+</div>
            </a-col>
            <a-col :span="6">
                <a-statistic :value="(exchangeSum.in - exchangeSum.out).toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">
                            <span :style="(exchangeSum.in - exchangeSum.out)<0?'color:red':'color:blue'">部门间流转(合计)</span>
                        </div>
                    </template>
                </a-statistic>
            </a-col>
            <a-col :span="2">
                <div style="font-size:40px">=</div>
            </a-col>
            <a-col :span="6">
                <a-statistic :value="(allSum.all+exchangeSum.in - completeMoney - exchangeSum.out).toFixed(2)"  >
                    <template #title>
                        <div style="font-size:20px;font-weight: bold;">
                            <span :style="(allSum.all+exchangeSum.in - completeMoney - exchangeSum.out)<0?'color:red':'color:blue'">剩余总计</span>
                        </div>
                    </template>
                </a-statistic>
            </a-col>
        </a-row>
    </a-modal>
    <a-tour v-model:current="tCurrent" :open="tourVisible" :steps="tSteps" @close="()=>{tourVisible=false;tCurrent=0}" />
</template>

<script>
    import depAchievePool from "../../../assets/api/depAchievePool";
    import LTable from "../../../components/table/LTable";
    import {SyncOutlined,AreaChartOutlined,DeleteOutlined,PlusOutlined} from "@ant-design/icons-vue";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import {ArrayFilter} from "../../../assets/utils/general";
    import taskMonthUser from "../../../assets/api/taskMonthUser";
    import depIncomePay from "../../../assets/api/depIncomePay";
    import DepartmentPicker from "../../common/DepartmentPicker";
    import dayjs from "dayjs"
    import {h} from "vue";
    export default {
        name: "DepartmentPool",
        components:{
            LTable,SyncOutlined,AreaChartOutlined,DeleteOutlined,PlusOutlined,
            DepartmentPicker
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
            source2(){
                let filter = {
                    taskTitle:this.filter.itemName,
                    yearMonth:this.filter.year
                };
                return ArrayFilter(this.dataSource2,filter);
            },
            completeMoney(){
                let sum = 0;
                for(let item of this.source2){
                    sum += item.completeMoney;
                }
                return sum;
            },
            allSum(){//下发绩效合计
                let sum = {
                    monthAmount:0,
                    yearAmount:0,
                };
                for(let item of this.source){
                    sum.monthAmount += item.monthAmount;
                    sum.yearAmount += item.yearAmount;
                }
                sum.all = sum.monthAmount + sum.yearAmount;
                return sum;
            },
            exchangeSum(){//部门间流转统计
                let sum = {
                    in:0,out:0
                };
                for(let item of this.dataSource3){
                    if(item.parent){
                        sum.in += item.amount
                    }
                    else{
                        sum.out += item.amount
                    }
                }
                return sum
            },
            title(){
                return "绩效下发情况 | 合计：￥" + this.allSum.all.toFixed(2)+`【月度：￥${this.allSum.monthAmount.toFixed(2)} 年度：￥${this.allSum.yearAmount.toFixed(2)}】`
            },
            title2(){
                return `已分配绩效合计：￥${(this.completeMoney).toFixed(2)}`
            },
            exTitle(){
                return '部门间流转 | 支出：'+this.exchangeSum.out.toFixed(2) + ' 收入：'+ this.exchangeSum.in.toFixed(2)
            }
        },
        data() {
            return {
                Departments:[],
                myDepartments:[],
                departmentId:null,
                filter:{},
                columns:[
                    new Column("项目名称", "itemName", ColumnType.String).setTableView(250),
                    new Column("下发年度", "year", ColumnType.String).setTableView(100),
                    new Column("月度绩效", "monthAmount", ColumnType.Number).setTableView(100),
                    new Column("年度绩效", "yearAmount", ColumnType.Number).setTableView(100),
                ],
                dataSource:[],
                columns2:[
                    new Column("项目名称", "taskTitle", ColumnType.String).setTableView(150),
                    new Column("年月","yearMonth",ColumnType.Month,false).setTableView(80),
                    new Column("用户名","userName",ColumnType.String,false).setTableView(100),
                    new Column("作业绩效","completeMoney",ColumnType.Number,true).setTableView(100),
                ],
                loading:false,
                dataSource2:[],
                currentRecord:{},
                columns3:[
                    new Column("时间","happenDate",ColumnType.Date).setTableView(100),
                    new Column("收入/支出","parent",ColumnType.Boolean).setTableView(100).setBoolTags(["支出","收入"]),
                    new Column("绩效","amount",ColumnType.Number,true).setTableView(100),
                    new Column("描述","describe",ColumnType.String,true).setTableView(300),
                    new Column("操作","actions","actions").setTableView(80)
                ],
                dataSource3:[],
                currentExchange:{},
                visible:false,
                visible2:false,
                tourVisible:false,
                tCurrent:0,
                tSteps:[
                    {
                        title: '部门间流转',
                        description: '分配子任务给其他部门时候，通过部门间流转支付子任务绩效给其他部门',
                        target: () => this.$refs.ref1.$el,
                    },
                    {
                        title: '金额统计',
                        description: '可以统计当前绩效池的具体情况',
                        target: () => this.$refs.ref2.$el,
                    },
                    {
                        title: '总经办下发的绩效列表',
                        description: '可以查看到每个项目具体下发的绩效情况',
                        target: () => this.$refs.ref3.$el,
                    },
                    {
                        title: '部门间流转情况',
                        description: h('div',[
                            h("div","1、支付出去的流转记录无法删除，若出现错误，可以由对方部门删除后重新支付"),
                            h("div","2、流转记录删除后难以恢复，若操作错误请由对方部门重新进行支付。"),
                            h("div","3、流转记录不与项目进行绑定，所以请写清楚说明信息"),
                        ]),
                        target: () => this.$refs.ref4.$el
                    },
                ]
            }
        },
        methods: {
            init() {
                this.currentRecord = {}
                depAchievePool.queryAll.urlPost({departId:this.departmentId},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                });
                taskMonthUser.queryAll.post({deptId:this.departmentId},res=>{//得到每个人已经分配的绩效
                    if(res.data.flag){
                        this.dataSource2 = res.data.data.sort((a,b)=>{
                            return  b.createTime - a.createTime
                        });
                    }
                    else{
                        this.dataSource2 = [];
                    }
                });
            },
            init2(){//部门间流转
                depIncomePay.queryAll.post({departId:this.departmentId},res=>{//得到每个人已经分配的绩效
                    if(res.data.flag){
                        this.dataSource3 = res.data.data.sort((a,b)=>{
                            return  b.createTime - a.createTime
                        });
                    }
                    else{
                        this.dataSource3 = [];
                    }
                });
            },
            addData(){
                this.visible = true;
                this.currentExchange = {};
            },
            editData(record){
                this.visible = true;
                this.currentExchange = {
                    id:record.id,
                    departId:record.departId,
                    recDepartId:record.child.departId,
                    amount:record.amount,
                    describe:record.describe,
                };
            },
            saveData(){
                this.currentExchange.departId = this.departmentId;
                this.currentExchange.happenDate = dayjs().format("YYYY-MM-DD");
                depIncomePay.save.post(this.currentExchange,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.init2();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(record){
                let data = {id:record.id};
                depIncomePay.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource3 = this.dataSource3.filter(item=>item.id !== record.id);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })

            }
        },
        async created() {
            this.myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.departmentId = this.myDepartments[0].id;
            this.init();
            this.init2()
        }
    }
</script>

<style scoped>

</style>