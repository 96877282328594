import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
// import partyInfo from "@/assets/api/partyInfo";
// import {companyColors, companyIds, companyNames} from "@/assets/system/companies";

/**
 * @program: office_automation
 * @author: ly
 * @description: dimension_conf
 * @create: 2022-01-17 11:08
 **/
import attendanceRule from "@/assets/api/attendanceRule";
export default class dimension_conf extends Table{
    constructor() {
        super();
        this.name = "dimension_conf";
        this.CNName = "考核配置表";
        this.columnArray = [
           // new Column("考核表类型","type",ColumnType.String,false).setTableView(120),
            new Column("大类","bigIndex",ColumnType.String,true).setTableView(100),
            new Column("细类","smallIndex",ColumnType.String,true).setTableView(100),
            new Column("满分","standardScore",ColumnType.Number,true).setTableView(60),
            new Column("指标","indexDescribe",ColumnType.String,true).setTableView(150),
            new Column("打分角色","judgeRole",ColumnType.IdReplace,false).setTableView(100)
                .setIdReplaceObject("judgeRoleName",{}),//相关页面进行选择编辑
            new Column("排序","sortOrder",ColumnType.Number,true),
            new Column("指标编码","indexCode",ColumnType.String,true).setTableView(120)
                .setTriggerOtherColumn("work_attendance",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="judgeScoreRule"})[0].setEditable(true);
                    },(formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="judgeScoreRule"})[0].setEditable(false);
                    }),
            new Column("评分规则","judgeScoreRule",ColumnType.String,true).setTableView(100)
                .setIdReplaceObject("scoreRuleTitle",{api:attendanceRule.queryAll,tag:"title"}),
            new Column("规则描述","describe",ColumnType.String,true).setTableView(400),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}
