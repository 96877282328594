<!--
* @program: office_automation
* @author: ly
* @component:ModalAddNewUser
* @description: 新增人员操作模块
* @create: 2021-12-10 13:58
-->

<template>
    <a-modal v-model:open="visible"
             width="900px"
             destroyOnClose
             :footer="null"
             :maskClosable="false"
             title="新增人员">
        <a-steps :current="current">
            <a-step v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps>
        <div class="steps-content">
            <!--添加用户 -->
            <div style="padding: 20px" v-if="current===0">
                <LForm :view-model="false" :formColumns="formColumns" :formData="formData" >
                    <template #action="{record}">
                        <a-button   type="primary"  @click="saveUser(record)">
                            提交
                        </a-button>
                    </template>
                </LForm>
            </div>
            <!--选择部门 -->
            <div style="padding: 20px" v-if="current===1">
                <a-select style="width: 300px" v-model:value="departmentId">
                    <a-select-option v-for="option in departments" :key="option.id">
                        {{ option.name }}
                    </a-select-option>
                </a-select>
                <a-button   type="primary"  @click="saveDepartment">提交</a-button>
            </div>
            <!--添加用户角色 -->
            <div style="padding: 20px" v-if="current===2">
                <div>所有人都需要添加【正式成员】这个角色</div>
                <a-select v-model:value="role" style="width: 250px">
                    <a-select-option v-for="item in roles"  :key="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
                <a-button  type="dashed"  @click="saveRole">
                    保存
                </a-button>
            </div>
            <!--设定报账流程 -->
            <div style="padding: 20px" v-if="current===3">
                <BillStepsAdd @finish="addSuccess"/>
            </div>
            <!--完成 -->
            <div style="padding: 20px" v-if="current===4">
                <a-button   type="primary"  @click="visible = false">
                    添加完毕
                </a-button>
            </div>
        </div>
    </a-modal>
</template>

<script>
    import LForm from "../../../components/form/LForm";
    import sys_user_info from "../../TableStruct/sys_user_info";
    import api_sys_user_info from "../../../assets/api/userInfo";
    import api_department from "../../../assets/api/department";
    import api_sys_department_relation from "../../../assets/api/userDepartRelation";
    import BillStepsAdd from "../../Finance/BillManager/BIllSetting/BillStepsAdd"
    import api_sys_user_role_relation from "../../../assets/api/userRoleRelation";
    import api_sys_role from "../../../assets/api/role";
    export default {
        name: "ModalAddNewUser",
        components:{
           LForm,BillStepsAdd
        },
        data(){
            return{
                table: new sys_user_info(),
                visible:false,
                current:0,
                steps: [
                    {title: '添加用户',finish:false},
                    {title: '选择部门',finish:false},
                    {title: '添加用户角色',finish:false},
                    {title: '设定报账流程',finish:false},
                    {title: '完成',finish:false},
                ],
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
                userInfo:null,
                departments:[],
                departmentId: null,

                role:null,
                roles:[]
            }
        },
        methods:{
            open(){
                this.visible = true;
                this.current = 0;
                this.departmentId = null;
                this.formData = this.table.getFormData();
                this.formColumns = this.table.getFormColumns();
            },
            saveUser(record){
                api_sys_user_info.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.current ++;
                        this.userInfo = res.data.data;
                        this.getDepartment();
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            getDepartment(){
                api_department.queryAll.post({},res=>{
                    if(res.data.flag){
                        this.departments = res.data.data;
                    }
                })
            },
            saveDepartment(){
                if(!this.departmentId){
                    return
                }
                let data ={
                    "departmentId": this.departmentId,
                    "userId": this.userInfo.id
                };
                api_sys_department_relation.saveOrUpd.post(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.current ++;
                        this.getRoles();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            getRoles(){
                api_sys_role.queryAll.post({},res=>{
                    if(res.data.flag){
                        this.roles = res.data.data.filter(role=>role.name !=="超级管理员");
                    }
                })
            },
            addSuccess(){
                this.current++;
            },
            saveRole(){
                let data = {
                    "roleId":this.role,
                    "userId":this.userInfo.id
                };
                api_sys_user_role_relation.saveOrUpd.post(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.current++;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .steps-content {
        margin-top: 16px;
        border: 1px dashed #e9e9e9;
        border-radius: 6px;
        background-color: #fafafa;
        min-height: 200px;
        text-align: center;
    }

    .steps-action {
        margin-top: 24px;
    }
</style>
