<!--
* @program: office_automation
* @author: ly
* @component:invoiceManagerPayoff
* @description: 发票管理  支出发票
* @create: 2021-12-16 14:27
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <SearchBar v-model:value="searchData" :search-options="searchOptions" />
            <a-button  type="dashed" shape="circle" @click="doSearch">
                <template #icon><Search /></template>
            </a-button>
            <a-button  type="dashed" shape="circle" @click="addData">
                <template #icon><Add /></template>
            </a-button>
            <a-button  type="default" shape="circle" @click="refresh">
                <template #icon><SyncOutlined /></template>
            </a-button>
        </div>
        <div class="row-flex">
            <div class="main-content70">
                <LTable  ref="ltable"
                         :loading="loading"
                         :data-source="dataSource"
                         :columns="tableColumns"
                         :page-size="pageParam.limit"
                         @pageChange="pageChange">
                    <template #action="{index,record}">
                        <div class="action-column">
                            <a-button type="link" @click="showDetail(index,record)">
                                <template #icon><Search /></template>
                            </a-button>
                            <a-button type="link" @click="editDetail(index,record)">
                                <template #icon><EditFilled /></template>
                            </a-button>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(index,record)">
                                <Delete  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content30">
                <invoiceFormPayoff v-if="visible" :formData="formData" :view-model="viewModel" @refresh="refresh"/>
                <welcome v-else />
            </div>
        </div>
    </div>
</template>

<script>
    import SearchBar from "@/components/SearchBar";
    import TwoParts from "@/page/_components/TwoParts";
    import LTable from "@/components/table/LTable";
    import invoiceFormPayoff from "@/page/Finance/financialManager/invoiceManager/invoiceFormPayoff";
    import item_invoice_record_payoff from "@/page/TableStruct/item_invoice_record_payoff";
    import invoiceInto from "@/assets/api/invoiceInto";
    import Welcome from "@/page/Other/Welcome";
    import Icons from "@/page/common/Icons";

    export default {
        name: "invoiceManagerPayoff",
        mixins:[Icons],
        components:{
            SearchBar,TwoParts,LTable,invoiceFormPayoff,Welcome
        },
        data(){
            return{
                table:new item_invoice_record_payoff(),

                pageParam:{
                    page: 1,
                    limit: 15,
                },
                dataSource:null,
                tableColumns:new item_invoice_record_payoff().getTableColumns(),
                loading:false,
                formData:{},
                viewModel:true,

                currentIndex:null,


                searchData:null,
                searchOptions:[],
                visible:false,
            }
        },
        created() {
            this.searchOptions = this.table.searchOptions;
            this.formData = {};
            this.init()
        },
        methods:{
            init(){
                let urlParam = this.pageParam;
                let formParam = {};
                this.loading = true;
                invoiceInto.queryPage.bothPost({urlParam,formParam},res=>{
                    this.loading = false;
                    if(res.data.flag){
                      let listData = res.data.data;
                      if(listData != null && listData.length > 0){
                        listData.forEach((item)=>{
                          item.itemName = item.itemInfo.name;
                        })
                      }
                      this.dataSource = listData;
                        this.$refs.ltable.setPage(res.data.count);
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            refresh(){
                this.visible = false;
                this.init();
            },
            doSearch(){
                let urlParam = {
                    page: 1,
                    limit: 15,
                };
                let formParam = {};
                this.loading = true;
                Object.assign(formParam,this.searchData);
                this.loading = true;
                invoiceInto.queryPage.bothPost({urlParam,formParam},res=>{
                    this.loading = false;
                    if(res.data.flag){
                      let listData = res.data.data;
                      if(listData != null && listData.length > 0){
                        listData.forEach((item)=>{
                          item.itemName = item.itemInfo.name;
                        })
                      }
                        this.dataSource = listData;
                        this.$refs.ltable.setPage(res.data.count);
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            pageChange(arg){
                this.pageParam.page = arg.pageNum;
                this.pageParam.limit = arg.pageSize;
                let urlParam = this.pageParam;
                let formParam = {};
                Object.assign(formParam,this.searchData);
                this.loading = true;
                invoiceInto.queryPage.bothPost({urlParam,formParam},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        let listData = res.data.data;
                        if(listData != null && listData.length > 0){
                            listData.forEach((item)=>{
                                item.itemName = item.itemInfo.name;
                            })
                        }
                        this.dataSource = listData;
                        this.$refs.ltable.setPage(res.data.count);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            showDetail(index,record){
                this.visible = true;
                this.viewModel = true;
                this.formData = record ;
            },
            editDetail(index,record){
              this.formData = {};
              this.visible = true;
                this.viewModel = false;
                this.formData = record ;
                this.formData.itemName = record.itemName;
            },
          deleteData(index,record){
            let data = {id:record.id};
            invoiceInto.delete.urlPost(data,res=>{
              if(res.data.flag){
                this.dataSource.splice(index,1);
                  this.visible = false;
                this.$message.success(res.data.msg);
              }
              else{
                this.$message.error(res.data.msg);
              }
            });
          },
            addData(){
                this.visible = true;
                this.viewModel = false;
                this.currentIndex = null;
                this.formData = {};
            }
        },

    }
</script>

<style scoped>

</style>
