
/**
 * @program: TYH_office_automation
 * @author: ly
 * @description: device_my
 * @create: 2021-06-22 16:31
 **/

import Table from "./table";
import Column from "./column";
import ColumnType from "./column_type";
export default class device_my  extends Table{
    constructor() {
        super();
        this.name = "device_my";
        this.CNName = "我的设备";
        this.columnArray = [
            new Column("设备名称","deviceName",ColumnType.String,true).setTableView(150),
            new Column("设备编码","deviceCode",ColumnType.String,true).setTableView(150),
            new Column("申请人","userName",ColumnType.String,true),
            new Column("申领类型","applyType",ColumnType.Enum).setDefaultValue("1")
                .setDictEnum("applyType"),
            new Column("申请理由","purposeDescri",ColumnType.String,true).setTableView(200),
            new Column("设备编码类别","deviceCodeName",ColumnType.String,true)
                .setDictEnum("deviceCodeName"),
            new Column("归还状态","useState",ColumnType.String,true).setTableView(100)
                .setDictEnum("useState"),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(100)];
    }
}