<!--
* @program: tyh-oa 
* @author: ly
* @component:typeMonth 选择月份
* @description: 
* @create: 2021-04-15 15:47
-->
<template>
    <div v-if="viewModel">
        <NoWrapString @click="onClick" :text="presentValue"/>
    </div>
    <div v-else>
        <a-date-picker
                @blur="blurTrigger"
                :placeholder="placeholder"
                style="width: 100%" v-model:value="date" picker="month" @change="columnTrigger" />
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import dayjs from 'dayjs'
    import {isFunction} from "../../assets/utils/general";
    export default {
        name: "typeMonth",
        mixins:[typeBase],
        watch:{
            record(){
                this.date = this.record[this.column.dataIndex]?dayjs(this.record[this.column.dataIndex]):null;
            }
        },
        data(){
            return{
                dateFormat: 'YYYY-MM',
                date:null,
                dPlaceholder:"请选择月份",
            }
        },
        methods:{
            columnTrigger(){
                this.record[this.column.dataIndex] = this.date?.format(this.dateFormat);
                this.$emit("update");
                let triggers = this.column.triggerOtherColumn;
                if(triggers && triggers.length>0) {
                    //遍历设置的触发器
                    for(let i in triggers){
                        if(triggers[i].value instanceof Array){//trigger.value 为 数组的情况
                            let colValue = this.record[this.column.dataIndex];
                            if(triggers[i].value.indexOf(colValue) !== -1 && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else if(isFunction(triggers[i].value)){////trigger.value 为 条件函数的情况
                            if(triggers[i].value(this.record) && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else{//trigger.value 为 普通值的情况
                            if(triggers[i].value === this.record[this.column.dataIndex] && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                    }
                }
            },
        },
        computed:{
            presentValue(){
                let date = this.record[this.column.dataIndex];
                if(date){
                    return dayjs(date).format(this.dateFormat);
                }
                return "无";
            },
        },
        created() {
            this.date = this.record[this.column.dataIndex]?dayjs(this.record[this.column.dataIndex]):null;
        }

    }
</script>

<style scoped>

</style>