<!--
* @program: TYHOA2 
* @author: ly
* @component:WorkTaskReport 
* @description: 进度报告，包括周报及月报
* @create: 2024-08-12 13:58
-->
<template>
    <div class="column-flex">
        <div class="row-flex">
            <div class="main-content50" >
                <WeekReport :view-model="viewModel" :task="task" />
            </div>
            <div class="main-content50">
                <LTable title="工序及成员分配" :columns="columns2" :data-source="dataSource2"/>
            </div>
        </div>
    </div>
</template>

<script>
    import WeekReport from "./WeekReport";
    import LTable from "../../../../components/table/LTable";
    import processEmployee from "../../../../assets/api/processEmployee";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"

    export default {
        name: "WorkTaskReport",
        props:["viewModel","task"],
        components:{
            WeekReport,LTable
        },
        data(){
            return{
                columns2:[
                    new Column("工序","processTitle",ColumnType.String,false).setTableView(200),
                    new Column("开始时间","startDate",ColumnType.Date,false).setTableView(100),
                    new Column("成员","userName",ColumnType.String,false).setTableView(200),
                ],
                dataSource2:[],
            }
        },
        methods:{
            showDetailAll(){
                let data = {taskId:this.task.id};
                processEmployee.queryAll.post(data,res=>{
                    this.dataSource2 = [];
                    this.defaultUsers = [];
                    if(res.data.flag){
                        for(let item of res.data.data){
                            let {userId,userName,processTitle,startDate} = item;
                            let record = this.dataSource2.find(it=>it.processTitle === processTitle)
                            if(record){
                                record.userName += " | " + userName
                            }
                            else{
                                this.dataSource2.push({userName,startDate,processTitle})
                            }
                            let record2 =  this.defaultUsers.find(it=>it.userName === userName)
                            if(record2){
                                record2.processTitle += " | " + processTitle
                            }
                            else{
                                this.defaultUsers.push({
                                    taskId:this.task.id,
                                    completeMoney:0,
                                    completeRatio:0,
                                    userId:userId,
                                    userName:userName,
                                    processTitle:processTitle
                                })
                            }
                        }
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
        },
        created() {
            this.showDetailAll()
        }
    }
</script>

<style scoped>

</style>