<!--
* @program: TYHOA2 
* @author: ly
* @component:typeWrapString
* @description: 
* @create: 2023-09-15 17:47
-->
<template>
    <a-textarea
            @blur="blurTrigger"
            @input="columnTrigger"
            :placeholder="placeholder"
            auto-size
            v-if="editable"
            v-model:value="record[column.dataIndex]"
    />
    <div v-else @click="onClick" >
        <pre style="white-space: pre-wrap;text-align: left;">{{presentValue}}</pre>
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeWrapString",
        mixins:[typeBase],
    }
</script>

<style scoped>

</style>