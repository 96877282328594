/** 
* 自动生成文件，请勿修改 
* @name: 系统:部门管理模块
* @description: 部门管理模块-增加部门余额
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const department = { 
	/** 1 summary:查询-分页
	description:参数：queryText(部门简称或全称（name/fullname）)；pid父部门id（若传，则查询该id下的所有子部门，不传，查询所有根部门）
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/department/queryPage.do"), 
	/** 2 summary:新增部门-单条
	description:参数：单条数据对象department，新增根部门时，pid可不传
	method:post
	params:需要配合Column函数使用
	*/ 
	save : new APIObject.postObject("/api/department/save.do"), 
	/** 3 summary:删除-批量
	description:参数：数据的主键id,逗号分隔：1,2,3,4,5.......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/department/batchDelete.do"), 
	/** 4 summary:查询-不分页（用户所属的全部部门）
	description:参数：userId(用户id)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryByUser : new APIObject.postObject("/api/department/queryByUser.do"), 
	/** 5 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/department/delete.do"), 
	/** 6 summary:查询-不分页（全部）
	description:参数：queryText(部门简称或全称（name/fullname）)；pid父部门id（若传，则查询该id下的所有子部门，不传，查询所有根部门）
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/department/queryAll.do"), 
	/** 7 summary:查询-获取所有部门树结构
	description:参数：无
	method:post
	params:
	*/ 
	queryTree : new APIObject.postObject("/api/department/queryTree.do"), 
	/** 8 summary:修改-单条
	description:参数：单条数据对象department
	method:post
	params:需要配合Column函数使用
	*/ 
	update : new APIObject.postObject("/api/department/update.do"), 
	/** 9 summary:查询-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryInfoId : new APIObject.postObject("/api/department/queryInfoId.do"), 
};
export default department;