<!--
* @program: office_automation
* @author: ly
* @component:OtherOutcome
* @description: 资金账户支出
* @create: 2022-02-17 09:58
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh"  shape="circle" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-button @click="addData"  shape="circle" type="dashed">
                <template #icon><add/></template>
            </a-button>
            <span style="margin-left: 10px">审核状态：</span>
            <a-radio-group @change="radioChange"  v-model:value="defaultQuery.approvalState">
                <a-radio :value="'0'">未完成审核</a-radio>
                <a-radio :value="'1'">已完成审核</a-radio>
            </a-radio-group>
            <span>金额统计：</span>
            <MonthPicker style="width: 120px" v-model:value="dateS" />
            <MonthPicker :start-month="dateS" style="width: 120px" v-model:value="dateE" />
            <a-button @click="getSumMoney"  shape="circle" type="dashed">
                <template #icon><BarChartOutlined/></template>
            </a-button>
            <a-popover v-if="sumMoney" trigger="hover" placement="bottom">
                <template #content>
                    <a-list size="small" style="margin: 0" bordered :data-source="sumMoney">
                        <template #renderItem="{item}">
                            <a-list-item>{{item.month}}【{{item.value}}元】</a-list-item>
                        </template>
                    </a-list>
                </template>
                <a-button  type="link" >
                    总计：{{sum}}元
                </a-button>
            </a-popover>
        </div>
        <div class="row-flex">
            <div class="main-content60">
                <LTable ref="ltable"
                        :download="true"
                        :loading="loading"
                        :data-source="dataSource"
                        :columns="tableColumns"
                        :page-size="pageParam.limit"
                        @pageChange="pageChange">
                    <template #action="{index,record}">
                        <div class = "action-column">
                            <a-button @click="showDetail(index,record)"
                                      class="action-button" type="link">
                                <Search class="pointer"/>
                            </a-button>
                            <a-button @click="editDetail(record)"
                                      v-if="checkAction('edit',record)"
                                      class="action-button" type="link">
                                <EditFilled class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    @confirm="deleteData(index,record)"
                                    cancel-text="否"
                                    class="action-button"
                                    ok-text="是"

                                    title="删除？">
                                <Delete class="pointer" style="color: red"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content40">
                <div v-if="edit_flag">
                    <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;" :title="rHeader"/>
                    <a-radio-group :disabled="viewModel" v-model:value="currentType" @change="selectType(true)">
                        <a-radio :value="'1'">银行账户间转账</a-radio>
                        <a-radio :value="'2'">由银行卡取出到现金账户</a-radio>
                        <a-radio :value="'3'">由现金账户存入银行卡</a-radio>
                        <a-radio :value="'4'">支出</a-radio>
                    </a-radio-group>
                    <LForm :form-columns="formColumns" v-model:form-data="formData" :view-model="viewModel">
                        <template #action="{record}">
                            <a-button @click="saveData(record)" type="primary">
                                提交
                            </a-button>
                        </template>
                    </LForm>
                    <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;" title="审核进度"/>
                    <OtherOutcomeAuditStatus :record="formData"/>
                </div>
                <welcome v-else/>
            </div>
        </div>
    </div>
</template>

<script>
    ////支出类型（1：卡间相互间转账；2：取现；3：存入，4：对外支出）
    //"银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"
    import LTable from "@/components/table/LTable";
    import TwoParts from "@/page/_components/TwoParts";
    import Icons from "@/page/common/Icons";
    import LForm from "@/components/form/LForm";
    import item_other_pay from "@/page/TableStruct/item_other_pay";
    import otherPay from "@/assets/api/otherPay";
    import bankBalance from "@/assets/api/bankBalance";
    import OtherOutcomeAuditStatus from "@/page/Finance/financialManager/OtherOutcomeAudit/OtherOutcomeAuditStatus";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import dayjs from "dayjs";
    import api_item_info from "@/assets/api/itemInfo";
    import Welcome from "@/page/Other/Welcome";
    export default {
        name: "OtherOutcome",
        mixins: [Icons],
        components: {
            TwoParts, LTable, LForm,OtherOutcomeAuditStatus, Welcome
        },
        computed:{
            dataSource(){
                if(this.dateS){
                    if(this.dateE){
                        return this.tableSource.filter(item=>{
                            let current = dayjs(item.payDate);
                            let sDate = dayjs(this.dateS);
                            let eDate = dayjs(this.dateE);
                            return current.isAfter(sDate) && current.isBefore(eDate)
                        });
                    }
                    else{
                        return this.tableSource.filter(item=>{
                            return dayjs(item.payDate).format("YYYY-MM") === this.dateS;
                        });
                    }
                }
                else {
                    return this.tableSource;
                }
            },
            sum(){
                let sum =0;
                for(let i in this.sumMoney){
                    sum += this.sumMoney[i].value;
                }
                return sum;
            }
        },
        data() {
            return {
                pageParam:{
                    page: 1,
                    limit: 15,
                },
                tableColumns:new item_other_pay().getTableColumns(),
                tableSource: [],
                loading:false,
                formColumns:new item_other_pay().getFormColumns(),
                formData:{},
                viewModel:true,

                edit_flag:false,

                dateS:null,
                dateE:null,
                sumMoney:null,
                defaultQuery: {
                    approvalState: "0"
                },
                authority: [],
                queryObject: {}, //用于存储查询数据



                currentType:"1",
                cardAccounts:[],
                moneyAccount:[],
                rHeader:null,
            }
        },
        created() {
            this.init();
            bankBalance.queryAll.post({},res=>{
                if(res.data.flag){
                    this.cardAccounts = [];
                    this.moneyAccount = [];
                    for(let i in res.data.data){
                        let bankName = res.data.data[i].bankName;
                        if(bankName === "现金账户"){
                            this.moneyAccount.push({
                                id:res.data.data[i].id,
                                name:bankName
                            })
                        }
                        else{

                            this.cardAccounts.push({
                                id:res.data.data[i].id,
                                name:bankName +"【"+ res.data.data[i].bankNum + "】"
                            })
                        }
                    }
                }
                else{
                    this.$message.error(res.data.msg);
                }
            });
        },
        methods: {
            init() {
                this.dateS = null;
                this.dateE = null;
                this.sumMoney = null;
                this.formData = null;
                let formParam = {...this.defaultQuery};
                let urlParam = this.pageParam;
                this.loading = true;
                otherPay.queryPage.bothPost({urlParam,formParam}, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        for(let i in res.data.data){
                            if(res.data.data[i].payBankName){
                                res.data.data[i].payBankNum = res.data.data[i].payBankName+"【"+res.data.data[i].payBankNum+"】";
                            }
                            if(res.data.data[i].receiveBankName){
                                res.data.data[i].receiveBankNum = res.data.data[i].receiveBankName+"【"+res.data.data[i].receiveBankNum+"】";
                            }
                        }
                        this.tableSource = res.data.data;
                        this.tableSource = this.tableSource.sort((a,b)=>{
                            return b.payDate - a.payDate;
                        })
                        this.$refs.ltable.setPage(res.data.count);

                    } else {
                        this.tableSource = [];
                    }
                });
            },
            pageChange(arg){
                this.pageParam.page = arg.pageNum;
                this.pageParam.limit = arg.pageSize;
                let urlParam = this.pageParam;
                let formParam = {};
                this.loading = true;
                otherPay.queryPage.bothPost({urlParam,formParam},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        for(let i in res.data.data){
                            if(res.data.data[i].payBankName){
                                res.data.data[i].payBankNum = res.data.data[i].payBankName+"【"+res.data.data[i].payBankNum+"】";
                            }
                            if(res.data.data[i].receiveBankName){
                                res.data.data[i].receiveBankNum = res.data.data[i].receiveBankName+"【"+res.data.data[i].receiveBankNum+"】";
                            }
                        }
                        this.tableSource = res.data.data;
                        this.tableSource = this.tableSource.sort((a,b)=>{
                            return b.payDate - a.payDate;
                        })
                        this.$refs.ltable.setTotalSize(res.data.count);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            doSearch() {
                let data = {...this.defaultQuery};
                Object.assign(data, this.searchData);
                this.loading = true;
                otherPay.queryAll.post( data, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.tableSource = res.data.data;
                    } else {
                        this.tableSource = [];
                    }
                });
            },
            showDetail(index, record) {
                this.viewModel = true;
                this.formData = record;
                this.rHeader = "支出详情";
                this.currentType = record.type;
                this.selectType();
                this.edit_flag = true;
            },
            editDetail(record){
                this.currentType = record.type;
                this.viewModel = false;
                this.formData = record;
                this.rHeader = "支出详情";
                this.selectType();
                this.edit_flag = true;
            },
            checkAction(action, record) {
                switch (action) {
                    case "edit": {
                        return record.approvalState === "1";
                    }
                    case "delete": {
                        return record.approvalState === "0";
                    }
                }
            },
            deleteData(index, record) {
                let data = {id: record.id};
                otherPay.delete.urlPost( data, res => {
                    if (res.data.flag) {
                        this.tableSource.splice(record.index, 1);
                        this.edit_flag = false;
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            refresh() {
                this.init()
                this.edit_flag = false;
            },
            addData() {
                this.rHeader = "添加新的支出信息";
                this.currentType = "1";
                this.formData = {};
                this.viewModel = false;
                this.selectType();
                setTimeout(()=>{
                    this.formData.type = this.currentType;
                })
                this.edit_flag = true;
            },
            radioChange() {
                this.init();
            },
            saveData(record) {
                if(record.id){
                    otherPay.forceUpd.post( record, res => {
                        if (res.data.flag) {
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    otherPay.saveOrUpd.post( record, res => {
                        if (res.data.flag) {
                            this.$message.success(res.data.msg);
                            this.init();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                }

            },
            selectType(bool){
                switch (this.currentType) {//（1：卡间相互间转账；2：取现；3：存入，4：对外支出）
                    case "1":{
                        this.rHeader = "银行账户间转账";
                        this.formColumns = [
                            /*new Column("转账类型","categoryId",ColumnType.Giro,true),*/
                            new Column("转账类型","categoryId",ColumnType.String,true),
                            new Column("支付金额","payAmount",ColumnType.Number,true),
                            new Column("支付日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankNum",this.cardAccounts),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("receiveBankNum",this.cardAccounts),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "2":{
                        this.rHeader = "由银行卡取出到现金账户";
                        this.formColumns = [
                           /* new Column("转账类型","categoryId",ColumnType.Giro,true),*/
                            new Column("转账类型","categoryId",ColumnType.String,true),
                            new Column("支付金额","payAmount",ColumnType.Number,true),
                            new Column("支付日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankNum",this.cardAccounts),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("receiveBankNum",this.moneyAccount),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "3":{
                        this.rHeader = "由现金账户存入银行卡";
                        this.formColumns = [
                           /* new Column("转账类型","categoryId",ColumnType.Giro,true),*/
                            new Column("转账类型","categoryId",ColumnType.String,true),
                            new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                            new Column("支付日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankNum",this.moneyAccount),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("receiveBankNum",this.cardAccounts),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "4":{
                        this.rHeader = "支出";
                        this.formColumns = [
                           /* new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(100),*/
                            new Column("费用类别","categoryId",ColumnType.String,true).setTableView(100),
                            new Column("所属项目","itemId",ColumnType.IdReplace2,true)
                                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                                        "startTime": "", "tenderWay": ""
                                    }}),
                            new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                            new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
                            new Column("支付账户","payBankId",ColumnType.Selector,true).setTableView(100)
                                .setSelectorObject("payBankNum",this.cardAccounts.concat(this.moneyAccount)),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }

                }
                if(bool){
                    this.formData = {};
                    this.formData.type = this.currentType;
                }
            },
            getSumMoney(){
                this.sumMoney = [];
                for(let i in this.dataSource){
                    let month = dayjs(this.dataSource[i].payDate).format("YYYY-MM");
                    let arr = this.sumMoney.filter(item=>item.month === month);
                    if(arr.length >0){
                        arr[0].value += this.dataSource[i].payAmount;
                    }
                    else{
                        this.sumMoney.push({month:month,value:this.dataSource[i].payAmount})
                    }
                }

            },
        },
    }
</script>

<style scoped>

</style>
