/** 
* 自动生成文件，请勿修改 
* @name: 项目:收款情况记录模块
* @description: 项目-收款情况记录-增删改查（二期修改，增加发票id、付款方、收款方）
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const collectionInfo = { 
	/** 1 summary:新增-单条
	description:参数：对象collectionVO
	method:post
	params:需要配合Column函数使用
	*/ 
	save : new APIObject.postObject("/api/collectionInfo/save.do"), 
	/** 2 summary:删除-批量
	description:参数：数据的主键id,逗号分隔多条：1,2,3,4.。
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/collectionInfo/batchDelete.do"), 
	/** 3 summary:查询-分页
	description:参数：
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPageByObj : new APIObject.postObject("/api/collectionInfo/queryPageByObj.do"), 
	/** 4 summary:查询-单条
	description:参数：单条数据的主键id,
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOneById : new APIObject.postObject("/api/collectionInfo/queryOneById.do"), 
	/** 5 summary:修改-单条
	description:参数：对象collectionVO
	method:post
	params:需要配合Column函数使用
	*/ 
	update : new APIObject.postObject("/api/collectionInfo/update.do"), 
	/** 6 summary:查询-不分页(可根据项目名称模糊查询)
	description:参数：itemName项目名称;itemId项目id，invoiceId发票id，发票列表后面可以放按钮“收款情况”，弹出某张发票的收款记录信息
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/collectionInfo/queryAll.do"), 
	/** 7 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/collectionInfo/delete.do"), 
	/** 8 summary:保存/修改-批量
	description:参数：List<CollectionVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/collectionInfo/batchSaveOrUpd.do"), 
	/** 9 summary:查询-分页(可根据项目名称模糊查询)
	description:参数：pageBean(分页对象，limit，page即可)；itemName项目名称；itemId项目id，invoiceId发票id，发票列表后面可以放按钮“收款情况”，弹出某张发票的收款记录信息
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/collectionInfo/queryPage.do"), 
	/** 10 summary:查询-不分页(可根据项目名称模糊查询)
	description:参数：itemName项目名称;itemId项目id，invoiceId发票id，发票列表后面可以放按钮“收款情况”，弹出某张发票的收款记录信息
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAllByObj : new APIObject.postObject("/api/collectionInfo/queryAllByObj.do"), 
};
export default collectionInfo;