<!--
* @program: TYHOA2 
* @author: ly
* @component:CalendarDiary 
* @description: 
* @create: 2024-08-14 16:14
workContent
taskTitle
processTitle
remarks
-->
<template>
    <a-calendar mode="month" v-model:value="value">
        <template #dateFullCellRender="{ current }">
            <div style="height:150px;cursor: default">
                <CalendarCellHead @click="add(current)" style="height:32px;cursor: pointer" :day="current"/>
                <div style="height:168px;overflow: auto">
                    <div v-for="item in getList(current)" :key="item.id">
                        <div @click="edit(item)" class="diary-title no-warp-string">{{item.taskTitle?item.taskTitle:"其他工作"}}</div>
                    </div>
                </div>
            </div>
        </template>
    </a-calendar>
</template>

<script>
    import CalendarCellHead from "./CalendarCellHead";
    import dayjs from "dayjs"
    export default {
        name: "CalendarDiary",
        props:["diary"],
        emits:["edit"],
        components:{
            CalendarCellHead
        },
        data() {
            return {
                value:undefined,
            }
        },
        methods: {
            getList(current){
                if(this.diary && this.diary.length >0){
                    return this.diary.filter(item =>{
                        return dayjs(item.workDate).format('YYYY-MM-DD') === current.format('YYYY-MM-DD')
                    })
                }
                else{
                    return []
                }
            },
            getClass(current){
                //let name = this.value?.format('YYYY-MM-DD') === current.format('YYYY-MM-DD')?'item-selected':'';
                let name = "";
                if(dayjs().format('YYYY-MM-DD') === current.format('YYYY-MM-DD')){
                    name += " diary-today"
                }
                return name
            },
            edit(item){
                this.$emit("edit",item);
            },
            add(current){
                let item = {};
                item.workDate = current;
                this.$emit("edit",item);
            },
        }
    }
</script>

<style scoped>

</style>