/** 
* 自动生成文件，请勿修改 
* @name: 系统:短信发送管理
* @description: 短信发送管理(三期新增)
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const smsRecord = { 
	/** 1 summary:发送短信验证码测试
	description:接口描述：发送短信验证码测试，可以输入多个手机号，逗号分隔
	method:post
	params:需要配合Column函数使用
	*/ 
	sendSms : new APIObject.postObject("/api/smsRecord/sendSms.do"), 
	/** 2 summary:查询验证码发送记录详情(直接查询阿里云接口)
	description:接口描述：查询验证码发送记录详情，mobile：手机号，date:日期（格式20210824），一次只能查询一个手机号及一天的数据
	method:post
	params:需要配合Column函数使用
	*/ 
	querySmsDetail : new APIObject.postObject("/api/smsRecord/querySmsDetail.do"), 
	/** 3 summary:发送短信给夏总（部门负责人的月度考核完成后）
	description:接口描述：当人事给部门负责人打分完成后（发送短信提醒夏总，登录OA系统查看）
	method:post
	params:需要配合Column函数使用
	*/ 
	notify : new APIObject.postObject("/api/smsRecord/notify.do"), 
	/** 4 summary:物理删除短信发送记录-（根据多个id）
	description:接口描述：根据id(可多个，逗号分隔)主键物理删除短信发送记录
	method:post
	params:需要配合Column函数使用
	*/ 
	delPhysics : new APIObject.postObject("/api/smsRecord/delPhysics.do"), 
	/** 5 summary:发送短信给被考核人（各部门负责人打分完成后通知被打分人）
	description:接口描述：发送月度考核打分完成给被考核人(模板：您的${month}月月度考核表评分已完成，请及时登录OA系统进行确认(同意/不同意)操作!)
	method:post
	params:需要配合Column函数使用
	*/ 
	notifyStaff : new APIObject.postObject("/api/smsRecord/notifyStaff.do"), 
	/** 6 summary:分页查询
	description:参数：
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/smsRecord/queryPage.do"), 
};
export default smsRecord;