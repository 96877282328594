<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentPicker 
* @description: 
* @create: 2024-08-13 17:55
-->
<template>
    <a-select
            v-model:value="selectId"
            style="width: 150px"
            placeholder="选择部门"
            :options="departments"
            :fieldNames="fieldNames"
            @change="selectChange"
            allow-clear
    >
    </a-select>
</template>

<script>
    export default {
        name: "DepartmentPicker",
        props:["value"],
        emits:["update:value","change"],
        data() {
            return {
                selectId:undefined,
                departments:[],
                fieldNames:{label: "name", value: "id"}
            }
        },
        methods: {
            selectChange() {
                let selectedData = this.departments.find(item=>{return this.selectId === item.id});
                this.$emit("update:value",this.selectId);
                this.$emit("change",selectedData);
            }
        },
        async created() {
            this.departments = JSON.parse(await this.$getItem("__departments"));
            this.selectId = this.value;
        }
    }
</script>

<style scoped>

</style>