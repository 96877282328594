/** 
* 自动生成文件，请勿修改 
* @name: 职位：职位级别晋升申请
* @description: 职位级别晋升申请(三期新增)
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const promotionApply = { 
	/** 1 summary:单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/promotionApply/queryOne.do"), 
	/** 2 summary:所有数据查询
	description:参数：所有参数可有可无
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/promotionApply/queryAll.do"), 
	/** 3 summary:查询-（有维度需要自己打分的）当前登录用户为评分人
	description:参数：无参数，后台根据session自己获取当前登录用户
	method:post
	params:需要配合Column函数使用
	*/ 
	queryByJudgeUser : new APIObject.postObject("/api/promotionApply/queryByJudgeUser.do"), 
	/** 4 summary:单条删除
	description:参数：单条数据的主键id(晋升申请删除时，审核流水将删除)
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/promotionApply/delete.do"), 
	/** 5 summary:分页查询
	description:参数：分页对象(传page、limit即可)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/promotionApply/queryPage.do"), 
	/** 6 summary:保存/修改
	description:参数：PromotionApplyVO(晋升申请对象)
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/promotionApply/saveOrUpd.do"), 
	/** 7 summary:提交
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	submit : new APIObject.postObject("/api/promotionApply/submit.do"), 
	/** 8 summary:撤销提交
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	cancelSubmit : new APIObject.postObject("/api/promotionApply/cancelSubmit.do"), 
	/** 9 summary:根据申请id导出对应晋升审批表
	description:参数：id
	method:get
	params:需要配合Column函数使用
	*/ 
	exportById : new APIObject.getObject("/api/promotionApply/exportById.do"), 
};
export default promotionApply;