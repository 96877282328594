/**
 * @description：每年度期初余额记录
 * @author:rzl
 * @date:2022/7/25 14:30
 **/
import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

export default class sujectType_item extends Table {
    constructor() {
        super();
        this.name = "sujectType_item";
        this.CNName = "现金流量表会计科目设置";
        this.columnArray = [
            new Column("主键", "id", ColumnType.String, false).setVisible(false),
            new Column("父科目id，第一级pid为0", "parentId", ColumnType.String, false).setVisible(false),
            new Column("科目名称", "subjectName", ColumnType.String, true),
            new Column("排序", "sort", ColumnType.Number, true),
            new Column("描述", "remarks", ColumnType.TextArea, true),

        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(100)];
    }
}
