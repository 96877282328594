<!--
* @program: 前台 
* @author: ly
* @component:typePassword 
* @description: 
* @create: 2023-02-09 15:00
-->
<template>
    <a-input-password
            @blur="blurTrigger"
            @input="columnTrigger"
            auto-size
            v-if="editable"
            v-model:value="record[column.dataIndex]"
    />
    <div v-else  @click="onClick">
        *****
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typePassword",
        mixins:[typeBase]
    }
</script>

<style scoped>

</style>