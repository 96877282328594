/**
 * @program: office_automation
 * @author: ly
 * @description: item_invoice_record
 * 公司开票出去给甲方的发票（甲方付款给公司的发票）
 * @create: 2021-12-15 19:06
 **/
import partyInfo from "@/assets/api/partyInfo";
import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import api_item_info from "@/assets/api/itemInfo";
import {SearchSegment} from "@/assets/tables/tableUtils";

export default class item_invoice_record extends Table {
    constructor() {
        super();
        this.name = "item_invoice_record";
        this.CNName = "发票信息";
        this.columnArray = [
           //new Column("docName", "invoiceTitle", ColumnType.String, true).setTableView(150),
            new Column("开票金额", "invoiceAmount", ColumnType.Number, true).setTableView(80),
            new Column("已收款额", "collectAmount", ColumnType.Number, false).setTableView(80).setGroup(1),
            new Column("收款状态", "collectState", ColumnType.Enum, false).setGroup(1).setDictEnum("collectState"),
            new Column("开票日期", "invoiceTime", ColumnType.Date, true).setTableView(80),
            new Column("发票代码", "invoiceCode", ColumnType.String, true),
            new Column("发票号码", "invoiceNumber", ColumnType.String, true),
            new Column("发票类型", "invoiceType", ColumnType.Enum, true).setDefaultValue("1")
                .setDictEnum("invoiceType")
                .setTriggerOtherColumn("1", (formData, cols) => {
                    cols.filter(col => {
                        return col.dataIndex === "checkCode"
                    })[0]?.setVisible(true);
                }, (formData, cols) => {
                    cols.filter(col => {
                        return col.dataIndex === "checkCode"
                    })[0]?.setVisible(false);
                }),
            new Column("检验码", "checkCode", ColumnType.String, true),
            new Column("付款方", "partyId", ColumnType.ObjectReplace, true).setGroup(1).setTableView(150)
                .setObjectReplaceObject("partyInfo","name", {api: partyInfo.queryAll, tag: "queryText"}),
            new Column("收款方", "companyId", ColumnType.Enum, true)
                .setDictEnum("companies").setGroup(1).setTableView(200),
            new Column("项目", "itemId", ColumnType.IdReplace2, true).setGroup(1).setTableView(250)
                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                        "startTime": "", "tenderWay": ""
                    }}),
            new Column("冲红", "flushRed", ColumnType.Boolean, true).setGroup(2)
                .setBoolTags(["未冲红", "已冲红"]).setDefaultValue(false),

        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(90).setFixed("right")];
        this.searchOptions = [
            new SearchSegment("发票标题", "invoiceTitle"),
            new SearchSegment("收款状态", "collectState")
                .setEnum(
                    {value:"1",label: "现金",color:"green"},
                    /*["0", "1", "2"], ["未收款", "已收款", "部分收款"]*/),
        ]
    }
}
