<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentRole 
* @description: 部门角色设定
* @create: 2024-08-30 15:27
-->
<template>
    <div>
        正在开发
    </div>
</template>

<script>
    export default {
        name: "DepartmentRole",
        data() {
            return {}
        },
        methods: {
            init() {
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>