import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

/**
 * @program: tyh-oa
 * @author: ly
 * @description: user_info
 private String disableState;               //禁用状态：0：禁用；1：启用；
 private String emails;                     //邮箱
 private String headUrl;                    //头像路径
 private String loginAccount;               //登录账号 默认手机号
 private String loginState;                  //登录状态：
 private String name;                        //用户姓名
 private String passWord	;                  //密码
 private String phone;                      //手机号
 private String sex;                        //性别：0：女；1：男
 * @create: 2021-04-21 15:03
 **/
export default class user_info extends Table{
    constructor(name,CNName) {
        super(name,CNName);
        this.name = "user_info";
        this.CNName = "用户信息表";
        this.columnArray = [
            new Column("照片","headUrl",ColumnType.Avatar,true),
            new Column("姓名","name",ColumnType.String,true).setTableView(80),
            new Column("密码","passWord",ColumnType.String,true).setDefaultValue("123456").setVisible(false),
            new Column("手机号","phone",ColumnType.String,true).setTableView(80)
                .setTriggerOtherColumn(null,
                    null,
                    (formData)=>{formData["loginAccount"] = formData["phone"]}),
            new Column("电子邮箱","emails",ColumnType.String,true),
            new Column("登录账号","loginAccount",ColumnType.String,false),
            new Column("入职状态","loginState",ColumnType.Enum,false)
                .setDictEnum("loginState").setTableView(80),
            // new Column("离职说明","remarks",ColumnType.String,false),

        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(120)];
    }
}
