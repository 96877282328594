/**
 * @description：时间工具类
 * @author:rzl
 * @date:2022/6/16 17:01
 **/
/**
 * 日期格式化
 * @param {*} date  时间
 * @param {*} fmt   格式
 */
const dateFormat = (date, fmt) => {
    date = new Date(date)
    var a = ['日', '一', '二', '三', '四', '五', '六']
    var o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds(), // 毫秒
        'w': date.getDay(), // 周
        'W': a[date.getDay()], // 大写周
        'T': 'T'
    }
    if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
    }
    return fmt
}
/**
 * 时间戳式化
 * @param date      时间
 * @param fmt       格式
 * @returns {*}
 */
const dateFmt = (date, fmt)=> {
    date = new Date(date)
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "M+": (date.getMonth() + 1).toString(), // 月
        "D+": date.getDate().toString(), // 日
        "h+": date.getHours().toString(), // 时
        "m+": date.getMinutes().toString(), // 分
        "s+": date.getSeconds().toString() // 秒
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            if (k === 'Y+') {
                fmt = fmt.replace(ret[1], opt[k].substr(ret[1].length * (-1)))
            } else {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
    }
    return fmt;
}
/**
 * 获取当前月份
 * @returns {string}
 */
const getNowMonth = ()=>{
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    var nowMonth = year + "-" + month;
    return nowMonth;
}

/**
 * 转为年月日
 * @param date
 * @returns {string}
 */
const timestamp =(date)=> {
    if (date != '' && date != undefined && date != null) {
        date = new Date(date)
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + m + '-' + d;
    } else {
        return ''
    }
};
/***
 * 获取下个月份
 * @param data      当前时间
 * @returns {string}
 */
const getNextMonth=(data)=> {
    let arr = data.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let day = arr[2] // 获取当前日期的日
    let year2 = year
    let month2 = parseInt(month) + 1
    if (month2 === 13) {
        year2 = parseInt(year2) + 1
        month2 = 1
    }
    let day2 = day
    let days2 = new Date(year2, month2, 0)
    days2 = days2.getDate()
    if (day2 > days2) {
        day2 = days2
    }
    if (month2 < 10) {
        month2 = '0' + month2
    }
    let m = year2 + '-' + month2
    return m
}
/***
 * 获取上个个月份
 * @param data      当前时间
 * @returns {string}
 */
const getPreviousMonth=(data)=>{
    let arr = data.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let year2 = year
    let month2 = parseInt(month) - 1
    if (month2 === 0) {
        year2 = parseInt(year2) - 1
        month2 = 12
    }
    if (month2 < 10) {
        month2 = '0' + month2
    }
    let m = year2 + '-' + month2
    return m
}
export { dateFormat,dateFmt,getNowMonth,timestamp,getNextMonth,getPreviousMonth }