<!--
* @program: TYHOA2 
* @author: ly
* @component:MonthlyArrangePlan 
* @description: 子任务批量添加
* @create: 2024-08-06 20:16
-->
<template>
    <div>
        <div class="form-label"  style="margin: 10px;color:red">由于本任务为子任务，绩效由本部门绩效池出，所以绩效额自定</div>
        <a-row  style="margin-bottom: 10px;">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">子任务绩效总额</div>
            </a-col>
            <a-col :span="4">
                <div class="form-value">
                    <a-input-number @change="autoCalc" style="width: 100%" v-model:value="money"/>
                </div>
            </a-col>
            <a-col v-if="monthIncrease" :span="6">
                <div class="form-label" style="margin-right: 10px">本月项目进度增量</div>
            </a-col>
            <a-col v-if="monthIncrease" :span="4">
                <div class="form-value">
                   {{monthIncrease}}%
                </div>
            </a-col>
        </a-row>
        <a-divider />
        <a-row style="margin-bottom: 10px;">
            <a-col :span="2">
                <div class="form-label" style="margin-right: 10px">年月</div>
            </a-col>
            <a-col :span="4">
                <div class="form-value">
                    <a-date-picker  @change="monthSelect"  v-model:value="yearMonth" picker="month" />
                </div>
            </a-col>
            <a-col v-if="yearMonth" :span="4">
                <div class="form-label" style="margin-right: 10px">占比合计</div>
            </a-col>
            <a-col v-if="yearMonth" :span="2">
                <div class="form-value">
                    {{completeRatio}}%
                    <a-popover v-if="completeRatio!==100" trigger="hover" >
                        <template #content>
                             <span style="color:red">比例不为100%时候自动计算会出错</span>
                        </template>
                        <InfoCircleOutlined style="color:red"/>
                    </a-popover>
                </div>
            </a-col>
            <a-col v-if="yearMonth" :span="6">
                <div class="form-label" style="margin-right: 10px">本月分配绩效</div>
            </a-col>
            <a-col v-if="yearMonth" :span="6">
                <div class="form-value">
<!--                    <a-input-number  style="width:100%" v-model:value="completeMoney"/>-->
                    {{completeMoney}} = {{money}} * {{monthIncrease}}%
                </div>
            </a-col>
<!--            <a-col v-if="yearMonth" :span="4">-->
<!--                <a-button @click="autoCalc">按比例填入</a-button>-->
<!--            </a-col>-->
        </a-row>
        <a-divider />
        <a-row v-if="!monthIncrease" style="margin-bottom: 10px;">
            <div v-if="yearMonth" class="form-value">
                暂无项目进度增量信息，请确认【任务月报】是否已经正确填报
            </div>
            <div v-else class="form-value">
                请选择月份查看该月项目进度及工作比例分配情况
            </div>
        </a-row>
        <a-row v-if="yearMonth && plan.length === 0">
            <div class="form-value">
                暂无工作量分配记录，请确认【本月工作量】是否已分配完成
            </div>
        </a-row>
        <a-row style="padding: 10px;" v-for="user in plan" :key="user.userId">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">{{user.userName}}</div>
            </a-col>
            <a-col :span="4">
                <div class="form-label" style="margin-right: 10px">占比</div>
            </a-col>
            <a-col :span="2">
                <div class="form-value">
                    {{user.completeRatio}}%
                </div>
            </a-col>
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">本月分配绩效</div>
            </a-col>
            <a-col :span="6">
                <div class="form-value">
<!--                    <a-input-number  style="width:100%" v-model:value="user.completeMoney"/>-->
                    {{user.completeMoney}}
                </div>
            </a-col>
        </a-row>
        <a-divider />
        <a-button v-if="yearMonth" :disabled="plan.length === 0" type="primary" @click="saveData">提交</a-button>
    </div>
</template>

<script>
    import {InfoCircleOutlined} from "@ant-design/icons-vue"
    export default {
        name: "MonthlyArrangePlan",
        props:["users",
            "monthIncrease", //本月进度增值
        ],
        emits:["finish","change"],
        components:{
            InfoCircleOutlined
        },
        computed:{
            completeRatio(){
                let sum = 0;
                for(let item of this.plan){
                    sum += item.completeRatio
                }
                return sum
            }
        },
        data() {
            return {
                columns:[],
                plan:[],
                completeMoney:0,
                yearMonth:null,
                money:0,
            }
        },
        watch:{
            users(){
                this.init()
            },
            monthIncrease(){
                this.autoCalc()
            }
        },
        methods: {
            init() {
                this.plan = [];
                if(this.users && this.users.length>0){
                    for(let user of this.users){
                        let p = {...user};
                        this.plan.push(p)
                    }
                }
                this.autoCalc()
            },
            autoCalc(){
                this.completeMoney = this.money * this.monthIncrease  * 100 / 10000;
                for(let user of this.plan){
                    user.completeMoney = user.completeRatio * this.completeMoney /100 * 100/100;
                }
            },
            saveData(){
                this.$emit("finish",this.plan);
            },
            monthSelect(){
                if(this.yearMonth){
                    this.$emit("change",this.yearMonth.format("YYYY-MM"));
                }
                else{
                    this.plan = [];
                    this.$message.error("请选择年月")
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>