<!--
* @program: tyh-oa 
* @author: ly
* @description: typeDate 
* @create: 2021-03-23 10:44
-->
<template>
    <div v-if="viewModel">
        <NoWrapString @click="onClick" :text="pValue"/>
    </div>
    <div v-else>
        <div v-if="editable">
            <a-date-picker :disabledDate="disabledDate"
                           @blur="blurTrigger"
                           :placeholder="placeholder"
                           style="width: 100%"
                           v-model:value="date" :format="dateFormat" @change="columnTrigger"/>
        </div>
        <div v-else>
            <NoWrapString @click="onClick" :text="pValue"/>
        </div>
    </div>
</template>

<script>

    import typeBase from "./typeBase";
    import dayjs from "dayjs"
    import {isFunction} from "../../assets/utils/general";
    export default {
        name: "typeDate",
        mixins:[typeBase],
        watch:{
            record(){
                this.init();
                this.columnTrigger();
            }
        },
        data(){
            return{
                dateFormat: 'YYYY/MM/DD',
                date:null,
                dPlaceholder:"请选择日期",
            }
        },
        computed:{
            pValue(){
                if(this.presentValue){
                    return dayjs(this.presentValue).locale('zh-cn').format(this.dateFormat);
                }
                else{
                    return "";
                }
            },
        },
        methods:{
            init(){
                this.date = this.presentValue?dayjs(this.presentValue):null;
            },
            getDate(){
                return this.date?this.date.valueOf():null;
            },
            disabledDate(current){
                if (this.column.enableTime) {
                    if(this.column.enableTime.length === 1){
                        let month = current.format("YYYY-MM");
                        return month !== this.column.enableTime[0];
                    }
                    else if(this.column.enableTime.length === 2){
                        return current.isBefore(this.column.enableTime[0]) || current.isAfter(this.column.enableTime[1])
                    }
                    else{
                        return false
                    }
                }
                else{
                    return  false;
                }
            },
            columnTrigger(){
                this.record[this.column.dataIndex] = this.getDate();
                this.$emit("update");
                let triggers = this.column.triggerOtherColumn;
                if(triggers && triggers.length>0) {
                    //遍历设置的触发器
                    for(let i in triggers){
                        if(triggers[i].value instanceof Array){//trigger.value 为 数组的情况
                            let colValue = this.record[this.column.dataIndex];
                            if(triggers[i].value.indexOf(colValue) !== -1 && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else if(isFunction(triggers[i].value)){////trigger.value 为 条件函数的情况
                            if(triggers[i].value(this.record) && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else{//trigger.value 为 普通值的情况
                            if(triggers[i].value === this.record[this.column.dataIndex] && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                    }
                }
            },
        },
        created() {
           this.init();
        }
    }
</script>

<style scoped>

</style>