<!--
* @program: TYHOA2 
* @author: ly
* @component:MonthReport 
* @description: 
* @create: 2024-08-12 14:04
-->
<template>
    <div class="form-title">
        <span>项目月报</span>
        <a-button @click="init" style="margin-left: 20px" type="dashed">
            <template #icon>
                <SyncOutlined/>
            </template>
        </a-button>
        <a-button @click="addData" type="dashed" v-if="!viewModel">
            <template #icon>
                <PlusOutlined/>
            </template>
        </a-button>
        <a-input placeholder="XXXX-XX" style="width:150px" v-model:value="filter.month"/>
        <div style="font-size:12px;width:400px">
            <div style="text-align: right;color: #a7a7a7">【完成总进度】指本项目的总体进度 【本月进度增量】为自动计算的值</div>
            <div style="text-align: right;color: #a7a7a7">进度增量 = 本月完成进度 - 上月完成进度</div>

        </div>
    </div>

    <div style="height:calc(100% - 62px);overflow:auto" v-if="monthSource.length >0">
        <MonthReportItem :key="report.id" :report="report" :view-model="viewModel" @delete="deleteData"
                         @workRatio="workRatio" @update="handleUpdate"
                         v-for="report in monthSource"/>
    </div>
    <a-result status="404" title="还未上传月报" v-else/>
    <a-modal title="月报"
             v-model:open="visible"
             width="800px">
        <a-row>
            <a-col :span="6" class="form-label" style="padding-right: 15px;">
                <div>月报时间</div>
            </a-col>
            <a-col :span="6" class="form-value">
                <a-date-picker :disabledDate="disabledDate" picker="month" style="width: 100%;"
                               v-model:value="selectedDate"/>
            </a-col>
            <a-col :span="6" class="form-label" style="padding-right: 15px;">
                <div>完成进度(%)</div>
            </a-col>
            <a-col :span="4" class="form-value">
                <a-input-number :max="100" :min="minRatio" style="width: 100%;" v-model:value="formData.ratio"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="6" class="form-label" style="text-align: right;padding-right: 15px;">
                <div>本月工作情况</div>
            </a-col>
            <a-col :span="16" class="form-value">
                <a-textarea :rows="4" placeholder="本月工作情况" v-model:value="formData.descri"/>
            </a-col>
        </a-row>
        <template #footer>
            <a-button @click="saveData" html-type="submit" type="primary">保存</a-button>
        </template>
    </a-modal>
</template>

<script>
    import {PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import MonthReportItem from "./MonthReportItem";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import dayjs from "dayjs";
    import taskMonthRatio from "../../../../assets/api/taskMonthRatio";

    export default {
        name: "MonthReport",
        props: ["viewModel", "task"],
        components: {
            PlusOutlined, SyncOutlined, MonthReportItem

        },
        computed: {
            monthSource() {
                return ArrayFilter(this.monthReport, this.filter)
            }
        },
        data() {
            return {
                loading: false,
                monthReport: [],
                formData: {},
                filter: {},
                visible: false,
                selectedDate: null,
                dateFormat: 'YYYY-WW',
                vModel: true,
                minRatio: 0
            }
        },
        methods: {
            init() {
                if (this.task && this.task.id) {
                    this.monthReport = [];
                    this.loading = true;
                    let data = {
                        taskId: this.task.id
                    };
                    taskMonthRatio.queryAll.urlPost(data, res => {
                        if (res.data.flag) {
                            this.monthReport = res.data.data.sort((a, b) => {
                                return dayjs(b.month) - dayjs(a.month)
                            });
                            for (let i = 0; i < this.monthReport.length; i++) {
                                this.minRatio = this.monthReport[i].ratio > this.minRatio ? this.monthReport[i].ratio : this.minRatio;
                                if(i < this.monthReport.length - 1){
                                    this.monthReport[i].increase = this.monthReport[i].ratio - this.monthReport[i+1].ratio
                                }
                                else{
                                    this.monthReport[i].increase = this.monthReport[i].ratio
                                }
                            }
                        }
                        this.loading = false;
                    })
                }
            },
            addData() {
                this.vModel = false;
                this.formData = {
                    radio: this.minRatio
                };
                this.visible = true;
            },
            saveData() {
                this.formData.taskId = this.task.id;
                this.formData.month = this.selectedDate.format('YYYY-MM');
                taskMonthRatio.saveOrUpd.post(this.formData, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.init();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(id) {
                this.monthReport = this.monthReport.filter(item => item.id !== id)
            },
            disabledDate(current) { //
                let thisMonth = dayjs().month();
                let currentMonth = dayjs(current).month();
                return currentMonth !== thisMonth && currentMonth !== thisMonth - 1 && currentMonth !== thisMonth - 2
            },
            workRatio(month){
                this.$emit("workRatio",month)
            },
            handleUpdate(){
                this.init();
            }
        },
        created() {
            this.init();
            this.selectedDate = dayjs();
        },
    }
</script>

<style scoped>

</style>