<!--
* @program: TYHOA2 
* @author: ly
* @component:NoWrapString
* @description: 
* @create: 2023-08-31 16:45
-->
<template>
    <a-tooltip trigger="click" :title="text">
        <div class="no-warp-string" >{{text}}</div>
    </a-tooltip>
</template>

<script>
    export default {
        name: "NoWrapString",
        props:["text"],
    }
</script>

<style scoped>

</style>