/** 
* 自动生成文件，请勿修改 
* @name: 项目:工序-员工管理模块
* @description: 工序-员工管理模块-------修改了（带出工序下每个月完成产值）
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const processEmployee = { 
	/** 1 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/processEmployee/batchDelete.do"), 
	/** 2 summary:查询-不分页
	description:参数：ProcessEmployeeQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/processEmployee/queryAll.do"), 
	/** 3 summary:保存/修改-批量
	description:参数：List<ProcessEmployeeVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/processEmployee/batchSaveOrUpd.do"), 
	/** 4 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/processEmployee/queryOne.do"), 
	/** 5 summary:查询-分页
	description:参数：(page、limit,分页参数，)、ProcessEmployeeQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/processEmployee/queryPage.do"), 
	/** 6 summary:返回系统时间(用于填写工序完成时自动赋值完成日期)
	description:参数：无
	method:post
	params:
	*/ 
	returnDate : new APIObject.postObject("/api/processEmployee/returnDate.do"), 
	/** 7 summary:保存/修改-单个
	description:参数：单条数据对象ProcessEmployeeVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/processEmployee/saveOrUpd.do"), 
	/** 8 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/processEmployee/delete.do"), 
	/** 9 summary:统计某部门、某月份yyyy-MM 员工当月的头上的任务工序
	description:说明：统计某部门、某月份yyyy-MM 员工当月的头上的任务工序
	method:post
	params:需要配合Column函数使用
	*/ 
	processExcel : new APIObject.postObject("/api/processEmployee/processExcel.do"), 
};
export default processEmployee;