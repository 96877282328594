<!--我的设备一览/历史设备也在列-->
<template>
    <div  class="column-flex">
        <div class="search-bar">
            <a-button  type="dashed" shape="circle" @click="init" >
                <template #icon><SyncOutlined /></template>
            </a-button>
            <a-radio-group v-model:value="filter.useState" >
                <a-radio value="0">未归还</a-radio>
                <a-radio value="1">已归还</a-radio>
            </a-radio-group>
        </div>
        <div style="width: 100%;height: 100%">
            <LTable :columns="tableColumns" :dataSource="source">
                <template #action="{record}">
                    <div class="action-column">
                        <a-popconfirm
                                v-if="record.useState === '0'"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="归还设备？"
                                @confirm="returnAssets(record)">
                            <a-button  class="action-button" type="link">
                                归还
                            </a-button>
                        </a-popconfirm>
                        <a-button @click = "applyHistory(record)" class="action-button" type="link">
                            审核情况
                        </a-button>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             :width="800"
             title="审核状态">
        <div class="form-title">审核进度</div>
        <AuditSteps v-if="visible" :steps="steps" />
    </a-modal>
</template>

<script>
    import LTable from "../../../components/table/LTable";
    import device_my from "../../../assets/tables/device_my";
    import api_device_my from "../../../assets/api/myDevice";
    import api_device_back from "../../../assets/api/deviceBack";
    import assetBackApproval from "../../../assets/api/assetBackApproval";
    import {SyncOutlined} from "@ant-design/icons-vue"
    import {ArrayFilter} from "../../../assets/utils/general";
    import AuditSteps from "../../Equipment/AssetsAuditCenter/AuditSteps";


    export default {
        name: "personalAssets",
        components:{
            LTable,SyncOutlined,AuditSteps
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        data(){
            return{
                table:new device_my(),
                dataSource:[],//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                queryOptions:{
                    userId:null
                },
                filter:{
                    useState:"0"
                },
                visible:false,
                steps:[]
            }
        },
        methods:{
            init(){
                this.loading = true;
                api_device_my.queryAll.urlPost(this.queryOptions,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                    this.loading = false;
                });
            },
            returnAssets(record){
                let data = {
                    applyId:record.id
                };
                api_device_back.saveOrUpd.post(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            applyHistory(record){
                this.steps = [];
                let data= {
                    applyId:record.id
                };
                api_device_back.queryAll.post(data,res=>{
                    if(res.data.flag){
                        if(res.data.data.length>0){
                            let data2={
                                backId:res.data.data[0].id
                            };
                            assetBackApproval.queryAll.post(data2,res=>{
                                if(res.data.flag){
                                    this.steps = res.data.data;
                                }
                                else{
                                    this.steps = []
                                }
                                this.visible=true
                            })
                        }
                        else{
                            this.$message.error("还未发起归还")
                        }
                    }
                    else{
                        this.$message.error("还未发起归还")
                    }
                })
            }
        },
        created(){
            this.$getItem("__myID").then(str=>{
                this.queryOptions.userId = str;
                this.init()
            });
            this.tableColumns = this.table.getTableColumns()
        }

    }
</script>

<style scoped>

</style>