<template>
    <a-config-provider  :locale="locale">
        <router-view />
    </a-config-provider>
</template>

<script>
    import zhCN from "ant-design-vue/es/locale/zh_CN";
    export default {
        name: 'App',
        data() {
            return {
                locale: zhCN
            }
        }
    }
</script>

<style>
    html,
    body,
    #app {
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>
