<!--
* @program: tyh-oa 
* @author: ly
* @component:typeIdReplace 
* @description: 
* @create: 2021-04-16 10:27
-->
<template>
    <div v-if="viewModel">
        <NoWrapString @click="onClick" :text="presentValue"/>
    </div>
    <div v-else>
        <a-select
                v-if="editable"
                show-search
                v-model:value="record[column.dataIndex]"
                placeholder="输入关键字查询"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @select="handleSelect"
                @search="handleSearch"
                @change="columnTrigger"
                :options="selectOptions"/>
        <NoWrapString v-else @click="onClick" :text="presentValue"/>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {computed} from "vue";
    export default {
        name: "typeIdReplace",
        mixins:[typeBase],
        watch:{
            record(){
                this.defaultOptions=[
                    {value:this.record[this.column.dataIndex], label:this.record[this.column.idReplaceObject.replaceSegment]}
                ]
            }
        },
        computed:{
            presentValue(){
                let arr = this.column.idReplaceObject.replaceSegment.split(".");
                if(arr.length ===1){
                    return this.record[arr[0]]
                }
                else if(arr.length ===2){
                    if(this.record[arr[0]]){
                        return this.record[arr[0]][arr[1]];
                    }
                    else{
                        return null;
                    }
                }
                else{
                    return this.record[this.column.dataIndex];
                }
            },
            //能够选择的选项
            selectOptions(){
                return this.defaultOptions.concat(this.options)
            },
        },
        data(){
            return{
                //查询到的选项
                options:[],
                queryObject:computed(()=>this.column.idReplaceObject.queryObject),
                defaultOptions:[]
            }
        },
        methods:{
            handleSearch(key){
                if(key){
                    this.options = [];
                    key = key.replace(/'/g, '');
                    let data={};
                    data[this.queryObject.tag] = key;
                    this.queryObject.api.urlPost(data,res=>{
                        if(res.data.flag){
                            for(let item of res.data.data){
                                let {id,name,title} = item;
                                this.options.push(
                                    {value:id, label:name?name:title}
                                )
                            }
                        }
                        else{
                            this.options =[];
                        }
                    })
                }
            },
            handleSelect(value){
                let option = this.selectOptions.find(item=>item.value === value);
                if(option){
                    this.record[this.column.idReplaceObject.replaceSegment] = option.label;
                }
            }
        },
        created() {
            this.defaultOptions=[
                {value:this.record[this.column.dataIndex], label:this.presentValue}
            ]
        }
    }
</script>

<style scoped>

</style>