<!--
* @program: TYHOA2 
* @author: ly
* @component:WorkCalendar 
* @description: 员工工作安排情况
* @create: 2024-08-30 15:41
-->
<template>
    <a-spin :spinning="spinning">
    <a-calendar :locale="locale">
        <template #dateCellRender="{ current: value }">
            <div v-for="item in getWorkList(value)" :key="item.id">
                <a-badge @click="showWork(value)" status="success" :text="item.taskTitle" />
            </div>
        </template>
        <template #monthCellRender="{ current: value }">
            <div v-for="item in getMonthWorkList(value)" :key="item.id">
                <a-badge @click="showMonthWork(value)" status="success" :text="item.taskTitle" />
            </div>
        </template>
    </a-calendar>
    </a-spin>
    <a-modal v-model:open="visible"
             width="1000px"
             :footer="null"
             title="工作详情">
        <LTable :columns="tableColumns" :data-source="dataSource"/>
    </a-modal>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import processEmployee from "../../../../assets/api/processEmployee";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import dayjs from "dayjs"
    export default {
        name: "WorkCalendar",
        props:["param"],
        components:{
            LTable
        },
        watch:{
            param(){
                this.init()
            }
        },
        data(){
            return{
                workList:[],
                visible:false,
                dataSource:null,
                tableColumns:[
                    new Column("项目任务","taskTitle",ColumnType.String).setTableView(150),
                    new Column("参与工序","processTitle",ColumnType.String).setTableView(200),
                ],
                spinning:false,
            }
        },
        methods:{
            init(){
                if(this.param && this.param.id){
                    let data = {
                        userId:this.param.id,
                    };
                    this.spinning = true;
                    processEmployee.queryAll.post(data,res=>{
                        this.spinning = false;
                        if(res.data.flag){
                            this.workList = res.data.data;
                        }
                        else{
                            this.workList = []
                        }
                    })
                }
            },
            getFullWorkList(value){//用于展示详情，把所有满足条件的都列出
                let works = [];
                for(let work of this.workList){
                    let sDate = dayjs(work.startDate);
                    let eDate = dayjs(work.endDate);
                    if(value.isAfter(sDate) && value.isBefore(eDate.add(1, 'day'))){
                        let {taskTitle,processTitle} = work;
                        let record = works.find(it=>it.taskTitle === taskTitle)
                        if(record){
                            record.processTitle += " | " + processTitle
                        }
                        else{
                            works.push(work)
                        }
                    }
                }
                return works;
            },
            getFullMonthWorkList(value){//用于展示详情，把所有满足条件的都列出
                let works = [];
                for(let work of this.workList){
                    let sDate = dayjs(work.startDate);
                    let eDate = work.completeDate?dayjs(work.completeDate):dayjs(work.endDate);
                    let sMonth = sDate.year()*12 + sDate.month();
                    let eMonth = eDate.year()*12 + eDate.month();
                    let vMonth = value.year()*12 + value.month();
                    if(vMonth >= sMonth && vMonth <= eMonth){
                        let {taskTitle,processTitle} = work;
                        let record = works.find(it=>it.taskTitle === taskTitle)
                        if(record){
                            record.processTitle += " | " + processTitle
                        }
                        else{
                            works.push(work)
                        }
                    }
                }
                return works;
            },
            getWorkList(value){//用于在日历上展示，相同项目任务只显示一个
                let works = [];
                for(let work of this.workList){
                    let sDate = dayjs(work.startDate);
                    let eDate = dayjs(work.endDate);
                    if(value.isAfter(sDate) && value.isBefore(eDate.add(1, 'day'))){
                        works.push(work)
                    }
                }
                let tasks =[];
                for(let i in works){
                    let arr = tasks.filter(item=>item.taskTitle === works[i].taskTitle);
                    if(arr.length === 0){
                        tasks.push(works[i])
                    }
                }
                return tasks;
            },
            getMonthWorkList(value){////用于在日历上展示，相同项目任务只显示一个
                let works = [];
                for(let work of this.workList){
                    let sDate = dayjs(work.startDate);
                    let eDate = work.completeDate?dayjs(work.completeDate):dayjs(work.endDate);
                    let sMonth = sDate.year()*12 + sDate.month();
                    let eMonth = eDate.year()*12 + eDate.month();
                    let vMonth = value.year()*12 + value.month();
                    if(vMonth >= sMonth && vMonth <= eMonth){
                        works.push(work)
                    }
                }
                let tasks =[];
                for(let i in works){
                    let arr = tasks.filter(item=>item.taskTitle === works[i].taskTitle);
                    if(arr.length === 0){
                        tasks.push(works[i])
                    }
                }
                return tasks;
            },
            showWork(value){
                let works = this.getFullWorkList(value);
                if(works.length>0){
                    this.visible = true;
                    this.dataSource = works;
                }
            },
            showMonthWork(value){
                let works = this.getFullMonthWorkList(value);
                if(works.length>0){
                    this.visible = true;
                    this.dataSource = works;
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>