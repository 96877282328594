/** 
* 自动生成文件，请勿修改 
* @name: 支出:其他支出申请
* @description: 其他支出申请(四期新增（董灿提出）)
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const otherPay = { 
	/** 1 summary:其他支出-统计每月度支出总计（月度下类别总计）
	description:参数：OtherPayStatVO中yearMonth可以是年/年月
	method:post
	params:需要配合Column函数使用
	*/ 
	otherPayStat : new APIObject.postObject("/api/otherPay/otherPayStat.do"), 
	/** 2 summary:分页查询
	description:参数：分页对象(传page、limit即可)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/otherPay/queryPage.do"), 
	/** 3 summary:查询需要当前登录用户审批且已到达的申请-不分页
	description:参数：无
	method:post
	params:需要配合Column函数使用
	*/ 
	queryMyApproval : new APIObject.postObject("/api/otherPay/queryMyApproval.do"), 
	/** 4 summary:保存/修改
	description:参数：OtherPayVO(其他支出申请对象)
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/otherPay/saveOrUpd.do"), 
	/** 5 summary:所有数据查询
	description:参数：所有参数可有可无
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/otherPay/queryAll.do"), 
	/** 6 summary:查询需要当前登录用户审批且已到达我这里的申请-分页
	description:参数：无
	method:post
	params:需要配合Column函数使用
	*/ 
	pageMyApproval : new APIObject.postObject("/api/otherPay/pageMyApproval.do"), 
	/** 7 summary:单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/otherPay/queryOne.do"), 
	/** 8 summary:强制修改
	description:参数：OtherPayVO(其他支出申请对象)
	method:post
	params:需要配合Column函数使用
	*/ 
	forceUpd : new APIObject.postObject("/api/otherPay/forceUpd.do"), 
	/** 9 summary:单条删除
	description:参数：单条数据的主键id(其他支出申请删除时，审核流水将删除)
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/otherPay/delete.do"), 
};
export default otherPay;