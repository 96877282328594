<!--
* @program: 前台 
* @author: ly
* @component:typeGeometry 
* @description: 
* @create: 2023-03-13 15:04
-->
<template>
    <a-button type="link" shape="circle" @click="onClick">
        <template #icon>
            <FileImageTwoTone />
        </template>
    </a-button>
</template>

<script>
    import typeBase from "./typeBase";
    import {FileImageTwoTone,} from "@ant-design/icons-vue";

    export default {
        name: "typeGeometry",
        mixins:[typeBase],
        components:{FileImageTwoTone}
    }
</script>

<style scoped>

</style>