<!--
* @program: tyh-oa 
* @author: ly
* @description: typeBase 
* @create: 2021-03-23 10:51
-->
<template>
    <div>

    </div>
</template>

<script>
    import {uuid,isFunction} from "../../assets/utils/general";
    import NoWrapString from "./NoWrapString";
    export default {
        name: "typeBase",
        components:{NoWrapString},
        inject:["holder"],
        props:{
            record:{required:true},
            column:{required: true},
            size:{required:false},
            viewModel:{required:true},
            index:{required:true}
        },
        watch:{
            record(){
                this.columnTrigger();
            }
        },
        data(){
            return{
                dPlaceholder:"请输入"+this.column.title,
                dId:uuid(10,16)
            }
        },
        emits:["update","trigger","blur","click"],
        methods:{
            columnTrigger(){
                this.$emit("update");
                let triggers = this.column.triggerOtherColumn;
                if(triggers && triggers.length>0){
                    //遍历设置的触发器
                    for(let i in triggers){
                        if(triggers[i].value instanceof Array){//trigger.value 为 数组的情况
                            let colValue = this.record[this.column.dataIndex];
                            if(triggers[i].value.indexOf(colValue) !== -1 && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else if(isFunction(triggers[i].value)){////trigger.value 为 条件函数的情况
                            if(triggers[i].value(this.record) && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else{//trigger.value 为 普通值的情况
                            if(triggers[i].value === this.record[this.column.dataIndex] && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                    }
                }
            },
            blurTrigger(){
                this.$emit("blur");
            },
            onClick(){
                if(this.column.clickHandler){
                    this.column.clickHandler(this.record,this.column)
                }
                this.$emit("click");
            }
        },
        computed:{
            editable(){ //字段是否允许编辑
                return this.column.editable && !this.viewModel;
            },
            dataIndex(){
                return this.column.dataIndex.split(".")
            },
            presentValue(){
                if(this.dataIndex.length ===1){
                    return this.record[this.dataIndex[0]]
                }
                else if(this.dataIndex.length ===2){
                    if(this.record[this.dataIndex[0]]){
                        return this.record[this.dataIndex[0]][this.dataIndex[1]];
                    }
                    else{
                        return null;
                    }
                }
                else{
                    return this.record[this.column.dataIndex];
                }
            },
            placeholder(){
                return this.column.placeholder?this.column.placeholder:this.dPlaceholder
            }
        },
        mounted() {
            this.columnTrigger();
        },
    }
</script>

<style scoped>

</style>