<!--
* @program: office_automation
* @author: ly
* @component:invoiceManagerIncome
* @description: 发票管理   收入发票
* @create: 2021-12-15 18:54
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <SearchBar v-model:value="searchData" ref="searchBr" :search-options="searchOptions" />
            <a-button  type="dashed" shape="circle" @click="doSearch">
                <template #icon><Search /></template>
            </a-button>
            <a-button  type="dashed" shape="circle" @click="addData">
                <template #icon><Add /></template>
            </a-button>
            <a-button  type="default" shape="circle" @click="refresh">
                <template #icon><SyncOutlined /></template>
            </a-button>
        </div>
        <div class="row-flex">
            <div class="main-content70">
                <LTable v-if="tableFlag"
                        ref="ltable"
                        :page-size="pageParam.limit"
                        :data-source="argument.dataSource"
                        :columns="argument.tableColumns"
                        @pageChange="pageChange">
                    <template #action="{index,record}">
                        <div class="action-column">
                            <a-button type="link" @click="showDetail(index,record)">
                                <template #icon><Search /></template>
                            </a-button>
                            <a-button type="link" @click="editDetail(index,record)">
                                <template #icon><EditFilled /></template>
                            </a-button>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(index,record)">
                                <Delete  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content30">
                <invoice-form v-if="visible" :formData="formData" :view-model="viewModel" @refresh="refresh"/>
                <welcome v-else />
            </div>
        </div>
    </div>
</template>

<script>
    import Welcome from "@/page/Other/Welcome";
    import Icons from "@/page/common/Icons";
    import SearchBar from "@/page/Finance/financialManager/invoiceManager/searchBar/SearchBar_com";
    import item_invoice_record from "@/page/TableStruct/item_invoice_record";
    import LTable from "@/components/table/LTable";
    import invoiceForm from "@/page/Finance/financialManager/invoiceManager/invoiceForm";
    import invoiceRecord from "@/assets/api/invoiceRecord";
    export default {
        name: "invoiceManagerIncome",
        mixins:[Icons],
        components:{
            SearchBar,Welcome,
            LTable,invoiceForm
        },
        data(){
            return{
                pageParam:{
                    page: 1,
                    limit: 15,
                },
                table:new item_invoice_record(),
                searchData:null,
                searchOptions:[],
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                    currentIndex:null //当前选中的条目
                },
                formData:{},
                viewModel:true,
                visible:false,
                tableFlag:false,

            }
        },
        methods:{
            init(){
                this.argument.loading = true;
                this.tableFlag = false;
                let urlParam = this.pageParam;
                let formParam = {};
                invoiceRecord.queryPage.bothPost({urlParam,formParam},res=>{
                    this.argument.loading = false;
                    this.tableFlag = true;
                    if(res.data.flag){
                      let listData = res.data.data;
                      if(listData != null && listData.length > 0){
                        listData.forEach((item)=>{
                          item.itemName = item.itemInfo.name;
                        })
                      }
                        this.argument.dataSource = listData;

                      this.$nextTick(()=>{
                          this.$refs.ltable.setTotalSize(res.data.count);
                      })
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            pageChange(arg){
                this.pageParam.page = arg.pageNum;
                this.pageParam.limit = arg.pageSize;
                this.loading = true;
                let urlParam = this.pageParam;
                let formParam = {};
                invoiceRecord.queryPage.bothPost({urlParam,formParam},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        let listData = res.data.data;
                        if(listData != null && listData.length > 0){
                            listData.forEach((item)=>{
                                item.itemName = item.itemInfo.name;
                            })
                        }
                        this.argument.dataSource = listData;

                        this.$nextTick(()=>{
                            this.$refs.ltable.setTotalSize(res.data.count);
                        })
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.pageParam.page = 1;
                this.pageParam.limit = 15;
                this.searchData = null;
                this.$refs.searchBr.setvalue();
                this.visible = false;
                this.init();
            },
            doSearch(){
                let formParam = {};
                Object.assign(formParam,this.searchData);
                this.argument.loading = true;
                let urlParam = this.pageParam;
                invoiceRecord.queryPage.bothPost({urlParam,formParam},res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                      let listData = res.data.data;
                      if(listData != null && listData.length > 0){
                        listData.forEach((item)=>{
                          item.itemName = item.itemInfo.name;
                        })
                      }
                      this.argument.dataSource = listData;
                        this.$nextTick(()=>{
                            this.$refs.ltable.setTotalSize(res.data.count);
                        })
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            showDetail(index,record){
                this.visible = true;
                this.viewModel = true;

                this.formData = record ;
            },
            editDetail(index,record){
                this.visible = true;
                this.viewModel = false;

                this.formData = record ;
            },
          deleteData(index,record){
            let data = {id:record.id};
            invoiceRecord.delete.urlPost(data,res=>{
              if(res.data.flag){
                this.argument.dataSource.splice(index,1);
                  this.visible = false;
                this.$message.success(res.data.msg);
              }
              else{
                this.$message.error(res.data.msg);
              }
            });
          },
            addData(){
                this.visible = false;
                setTimeout(()=>{
                    this.visible = true;
                });
                this.viewModel = false;
                this.argument.currentIndex = null;
                this.formData = this.table.getFormData();
            }
        },
        created() {
            this.searchOptions = this.table.searchOptions;
            this.argument.tableColumns = this.table.getTableColumns();
            this.formData = this.table.getFormData();
            this.init()
        }
    }
</script>

<style scoped>

</style>
