/** 
* 自动生成文件，请勿修改 
* @name: 财务:公司除项目收款外的其他收入记录模块
* @description: 公司除项目收款外的其他收入记录模块-增删改查
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const income = { 
	/** 1 summary:其他收入-统计每月度收入总计（月度下类别总计）
	description:参数：yearMonth
	method:post
	params:需要配合Column函数使用
	*/ 
	incomeStat : new APIObject.postObject("/api/income/incomeStat.do"), 
	/** 2 summary:新增/修改-单条
	description:参数：IncomeVO incomeVO保存参数封装
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpdate : new APIObject.postObject("/api/income/saveOrUpdate.do"), 
	/** 3 summary:删除-批量
	description:参数：数据的主键id,逗号分隔：1,2,3,4,5.......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/income/batchDelete.do"), 
	/** 4 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/income/delete.do"), 
	/** 5 summary:根据id查询
	description:参数：id(主键id)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/income/queryOne.do"), 
	/** 6 summary:查询-分页
	description:参数：IncomeQueryVO queryVO查询记录封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/income/queryPage.do"), 
	/** 7 summary:查询-不分页（全部）
	description:参数：IncomeQueryVO queryVO查询记录封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/income/queryAll.do"), 
};
export default income;