<!--
* @program: TYHOA2 
* @author: ly
* @component:OutComePayPDF 
* @description: 支付打印模板
* @create: 2024-09-10 16:19
-->
<template>
    <div id="bill" >
        <div style="text-align: center;font-size: 20px;font-weight: bold">支出凭单</div>
        <a-row>
            <a-col :span="12">付款单位名称：[付款单位名称]</a-col>
            <a-col :span="6">付款日期：[付款日期]</a-col>
            <a-col :span="6">附：单据X张</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="3">部门</a-col>
            <a-col class="bill-border" :span="9">[XXXXXXXXXXXX]</a-col>
            <a-col class="bill-border" :span="3">付款方式</a-col>
            <a-col class="bill-border" style="border-right:black 1px solid;" :span="9">电汇</a-col>
<!--            <a-col class="bill-border" style="border-right:black 1px solid;" :span="2">平台</a-col>-->
<!--            <a-col class="bill-border" style="border-right:black 1px solid;" :span="4">XXXX平台</a-col>-->
        </a-row>
        <a-row>
            <a-col style="
            border-left: black 1px solid;
            border-top: black 1px solid;" :span="3">支出项目</a-col>
            <a-col class="bill-border" :span="9">{{title1}}</a-col>
            <a-col class="bill-border" :span="1">收</a-col>
            <a-col class="bill-border" :span="3">全称</a-col>
            <a-col class="bill-border" style="border-right:black 1px solid;" :span="8">【XXXXXXXXXXXX】</a-col>
        </a-row>
        <a-row>
            <a-col style="border-left:black 1px solid;" :span="3"> </a-col>
            <a-col style="border-left:black 1px solid;" :span="9">{{title2}}</a-col>
            <a-col style="border-left:black 1px solid;" :span="1">款</a-col>
            <a-col class="bill-border" :span="3">账号</a-col>
            <a-col class="bill-border" style="border-right:black 1px solid;" :span="8">【XXXXXXXXXXXXXXXX】</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="6">是否已提供结算</a-col>
            <a-col class="bill-border" :span="6">是 </a-col>
            <a-col style="border-left:black 1px solid;" :span="1">人</a-col>
            <a-col class="bill-border" :span="3">开户银行</a-col>
            <a-col class="bill-border" style="border-right:black 1px solid;" :span="8">【XXXXXXXXXXXX】</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="8">申请金额(小写)：￥</a-col>
            <a-col class="bill-border" style="border-right:black 1px solid;" :span="16">申请金额(大写)：</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="8">平台手续费(小写)：￥</a-col>
            <a-col class="bill-border" style="border-right:black 1px solid;" :span="16">平台手续费(大写)：</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="8">核准金额(小写)：￥</a-col>
            <a-col class="bill-border" style="border-right:black 1px solid;" :span="16">核准金额(大写)：</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="8">合同总金额：</a-col>
            <a-col class="bill-border" :span="8">已结算金额：</a-col>
            <a-col class="bill-border" style="border-right:black 1px solid;" :span="8">已付款金额：</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" style="border-right:black 1px solid;border-bottom:black 1px solid;" :span="24">备注：</a-col>
        </a-row>
        <a-row style="margin-top: 20px">
            <a-col :span="6">单位负责人：</a-col>
            <a-col :span="6">主管领导：</a-col>
            <a-col :span="6">项目负责人：</a-col>
            <a-col :span="6">经办人：</a-col>
        </a-row>
        <a-row style="margin-top: 20px">
            <a-col :span="6">财务主管：</a-col>
            <a-col :span="6">财务审核：</a-col>
            <a-col :span="6">生产办：</a-col>
            <a-col :span="6">出纳：</a-col>
        </a-row>
    </div>
    <div style="border-top: #c8c8c8 1px dashed;padding:10px">
        <a-button type="primary" @click="getPDF">
            下载
        </a-button>
    </div>
</template>

<script>
    /**
     * 一二三四五六七八九十
     */
    import html2pdf from "html2pdf.js";

    export default {
        name: "OutComePayPDF",
        computed:{
            title1(){
                return this.itemName.substr(0,18);
            },
            title2(){
                return this.itemName.substr(18);
            }
        },
        data() {
            return {
                itemName:"一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十",
            }
        },
        methods: {
            getPDF(){
                var element = document.getElementById('bill');
                var opt = {
                    margin:       0.2,
                    filename:     'myfile.pdf',
                    image:        { type: 'jpeg', quality: 0.98 },
                    html2canvas:  { scale: 2 },
                    jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
                };
                // New Promise-based usage:
                html2pdf().set(opt).from(element).save();
            }
        }
    }
</script>

<style scoped>
    .bill-border{
        border-left: black 1px solid;
        border-top: black 1px solid;
    }
    .ant-col{
        padding: 5px 10px;
        text-align: left;
    }
    @page {
        size: A4; /* 设置纸张为 A4 大小 */
        margin: 1in;
    }
</style>