/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_finance_record  报账单表类型1：用于填写报账单
 * @create: 2021-04-12 09:58
 **/
import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type"
export default class item_finance_record extends Table{
    constructor() {
        super();
        this.name = "item_finance_record";
        this.CNName = "报销单";
        this.columnArray = [
            new Column("标题","title",ColumnType.String,true).setTableView(150),
            new Column("描述","content",ColumnType.String,true),
            new Column("归属月份","recordYearMonth",ColumnType.Month,false).setTableView(100),
            new Column("提交","submitState",ColumnType.Enum,false)
                .setDictEnum("submitState").setDefaultValue("0"),
            new Column("审核","approvalState",ColumnType.Enum,false)
                .setDictEnum("approvalState").setDefaultValue("0"),
            new Column("入账状态","entryState",ColumnType.Enum,false)
                .setDictEnum("entryState"),
            new Column("情况说明","remarks",ColumnType.String,false)
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(100)];
    }
}