<!--
* @program: TYHOA2 
* @author: ly
* @component:WorkSelector 
* @description: 
* @create: 2024-08-14 19:50
-->
<template>
    <a-tree-select
            @change="changeHandle"
            v-model:value="selectId"
            style="width: 100%"
            placeholder="未选择则为其他工作"
            allow-clear
            :height="400"
            tree-default-expand-all
            :tree-data="taskList"
            :fieldNames="fieldNames">
        <template #title="{taskTitle,processTitle}">
            <div v-if="!processTitle" style="border: #c8c8c8 1px dashed">{{taskTitle}}</div>
            <a-tag v-if="processTitle" color="blue">{{processTitle}}</a-tag>
        </template>
    </a-tree-select>
</template>

<script>
    export default {
        name: "WorkSelector",
        props:["taskList","value"],
        emits:["update:value"],
        data() {
            return {
                selectId:undefined,
                fieldNames:{
                    children:'children',
                    label:'taskTitle',
                    value: 'id'
                }
            }
        },
        watch:{
            value(){
                this.selectId = this.value
            }
        },
        methods: {
            init() {
                this.selectId = this.value
            },
            changeHandle(){
                this.$emit("update:value",this.selectId)
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>