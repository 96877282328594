<!--
* @program: office_automation 
* @author: ly
* @component:typePositionLevel 
* @description: 
* @create: 2021-12-23 17:57
-->
<template>
    <div>
        <div v-if="editable" >
            <a-tag  :color="color">{{record[column.dataIndex]}}</a-tag>
            <a-tooltip  title="选择职位级别">
                <a-button type="link" @click="selectShow">
                    <template #icon><SelectOutlined /></template>
                </a-button>
            </a-tooltip>

        </div>
        <div v-else >
            <a-tag @click="selectShow" style="cursor: pointer;" :color="color">{{presentValue}}</a-tag>
        </div>
        <a-modal v-model:open="visible"
                 destroyOnClose
                 :footer="null"
                 :title="null">
            <div v-if="TZVisible">
                <a-divider>技术岗</a-divider>
                <a-button  v-for="item in TZOptions" :key="item" :type="getType(item)" @click="select(item)">
                    {{item}}
                </a-button>
            </div>
            <div v-if="TQVisible">
                <a-divider>工勤岗</a-divider>
                <a-button v-for="item in TQOptions" :key="item" :type="getType(item)" @click="select(item)">
                    {{item}}
                </a-button>
            </div>
            <div v-if="TGVisible">
                <a-divider>管理岗</a-divider>
                <a-button v-for="item in TGOptions" :key="item" :type="getType(item)" @click="select(item)">
                    {{item}}
                </a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {SelectOutlined} from "@ant-design/icons-vue"

    export default {
        name: "typePositionLevel",
        mixins:[typeBase],
        components:{
            SelectOutlined
        },
        data(){
            return{
                visible:false,
                TZ:18, //技术岗1-18
                TQ:12, //工勤岗1-12
                TG:5, //管理岗1-5
                TZOptions:[], //blue
                TQOptions:[],  //cyan
                TGOptions:[],   //purple
                TZVisible:false,
                TQVisible:false,
                TGVisible:false
            }
        },
        computed:{
            color(){
                if(this.presentValue){
                    let type = this.presentValue.substr(0,2);
                    type = type.toUpperCase();
                    switch (type) {
                        case "TZ":{
                            return  "blue"
                        }
                        case "TQ":{
                            return  "cyan"
                        }
                        case "TG":{
                            return  "purple"
                        }
                        default :{
                            return "red"
                        }
                    }
                }
                else{
                    return "red"
                }
            },
            title(){
                if(this.presentValue){
                    let type = this.presentValue.substr(0,2);
                    type = type.toUpperCase();
                    switch (type) {
                        case "TZ":{
                            return  "技术岗"
                        }
                        case "TQ":{
                            return  "工勤岗"
                        }
                        case "TG":{
                            return  "管理岗"
                        }
                        default :{
                            return "无"
                        }
                    }
                }
                else{
                    return "无"
                }
            }
        },
        methods:{
            selectShow(){
                this.visible = true;
                if(this.editable){
                    this.TZVisible = true;
                    this.TQVisible = true;
                    this.TGVisible = true;
                }
                else{
                    if(this.presentValue){
                        let type = this.presentValue.substr(0,2);
                        type = type.toUpperCase();
                        switch (type) {
                            case "TZ":{
                                this.TZVisible = true;
                                this.TQVisible = false;
                                this.TGVisible = false;
                                break;
                            }
                            case "TQ":{
                                this.TZVisible = false;
                                this.TQVisible = true;
                                this.TGVisible = false;
                                break;
                            }
                            case "TG":{
                                this.TZVisible = false;
                                this.TQVisible = false;
                                this.TGVisible = true;
                                break;
                            }
                            default:{
                                this.TZVisible = false;
                                this.TQVisible = false;
                                this.TGVisible = false;
                            }
                        }
                    }
                }
            },
            select(item){
                if(this.editable){
                    this.record[this.column.dataIndex] = item;
                    this.visible = false;
                }
                else{
                    this.visible = false;
                }
            },
            getType(item){
                if(this.record[this.column.dataIndex]?.toUpperCase() === item){
                    return "danger"
                }
                else{
                    return "link"
                }
            },
        },
        created() {
            for(let i = 1;i <= this.TZ;i++){
                this.TZOptions.push("TZ-"+i)
            }
            for(let i = 1;i <= this.TQ;i++){
                this.TQOptions.push("TQ-"+i)
            }
            for(let i = 1;i <= this.TG;i++){
                this.TGOptions.push("TG-"+i)
            }
        }
    }
</script>

<style scoped>

</style>