<!--
* @program: TYHOA2 
* @author: ly
* @component:WorkTask 
* @description: 任务负责人
* @create: 2024-08-09 15:45
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="refresh" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <div style="margin: 10px;color: black">部门:</div>
            <a-select v-model:value="defaultQuery.departmentId"
                      style="width: 200px"
                      @change="init"
            >
                <a-select-option v-for="department in myDepartments" :key="department.id">
                    {{ department.name }}
                </a-select-option>
            </a-select>
            <a-segmented @change="doSearch" v-model:value="state" :options="states" />
            <div style="margin:0 5px">名称：</div>
            <a-input @change="doSearch" placeholder="项目名称" style="width: 150px" v-model:value="filter.itemName" />
        </div>
        <div style="width: 100%;height: 100%;">
            <LTable :columns="taskColumns" :data-source="dataSource" :loading="loading">
            <template #expandedRow="{children}">
                <a-row>
                    <a-col :span="8">
                        <div class="form-label" style="margin-right: 10px">已分配子任务，分配部门：</div>
                    </a-col>
                    <a-col :span="16">
                        <div class="form-value" >
                            <span style="margin-left:10px " v-for="child in children" :key="child.id">{{child.departmentName}}</span>
                        </div>
                    </a-col>
                </a-row>
            </template>
            <template #action="{record}">
                <div class="action-column">
                    <a-button @click="showDetail(record)" class="action-button" type="link">
                        <SearchOutlined class="pointer"/>
                    </a-button>
                    <a-tooltip v-if="!record.state" title="提交任务完成">
                        <a-button  class="action-button" type="link" @click="finishTask(record)">
                            <CheckOutlined  class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip v-if="!record.state" title="任务延期申请">
                        <a-button  class="action-button" type="link" @click="delayApply(record)">
                            <FallOutlined  style="color: red" class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </div>
            </template>
        </LTable>
        </div>
        <a-drawer :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  @close="visible = false"
                  destroy-on-close
                  height="90%"
                  placement="bottom"
                  v-model:open="visible">
            <a-tabs style="height: 100%" tabPosition="left" v-model:activeKey="activeKey">
                <a-tab-pane style="height: 100%;overflow: auto" key="1" tab="项目信息">
                    <ProjectInfoView  :record="formData"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="2" tab="任务信息">
                    <ProjectTaskInfo  :record="formData" :view-model="true"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="3" tab="任务工序">
                    <LeaderTaskProcess :departmentId="defaultQuery.departmentId" :task="formData" :view-model="false"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="5" tab="周进度上报">
                    <WorkTaskReport :task="formData" :view-model="false"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="4" tab="月进度上报">
                    <MonthlyLeaderArrange :task="formData" :departmentId="defaultQuery.departmentId" :view-model="false"/>
                </a-tab-pane>
            </a-tabs>
        </a-drawer>
    </div>
    <a-modal v-model:open="visible2"
             destroy-on-close
             title="提交任务完成情况"
             :footer="null">
        <a-row style="margin: 10px">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">完成时间</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-date-picker style="width: 200px" v-model:value="taskDate" />
                </div>
            </a-col>
        </a-row>
        <a-row style="margin: 10px">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">完成描述</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-input style="width: 200px" v-model:value="formData.describe" />
                </div>
            </a-col>
        </a-row>
        <a-button @click="saveData"  type="primary">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:open="visible3"
             destroy-on-close
             width="800px"
             title="任务延期申请"
             :footer="null">
        <delayApply :record="formData" type="1" />
    </a-modal>
</template>

<script>
    import {
        CheckOutlined,
        FallOutlined,
        SearchOutlined,
        SyncOutlined,
    } from "@ant-design/icons-vue";
    import LTable from "../../../../components/table/LTable";
    import ProjectInfoView from "../../../WorkAdministration/ProjectManager/ProjectInfoView";
    import ProjectTaskInfo from "../../../WorkAdministration/ProjectTask/ProjectTaskInfo";
    import DelayApply from "../../../WorkAdministration/DelayManager/DelayApply";
    import itemTask from "../../../../assets/api/itemTask";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import MonthlyLeaderArrange from "./MonthlyLeaderArrange";
    import WorkTaskReport from "../WorkTaskReport/WorkTaskReport";
    import dayjs from "dayjs";
    import LeaderTaskProcess from "./LeaderTaskProcess";

    export default {
        name: "WorkTask",
        components: {
            SearchOutlined, SyncOutlined,  LTable,ProjectInfoView,ProjectTaskInfo,
            CheckOutlined,FallOutlined,DelayApply,
            LeaderTaskProcess,MonthlyLeaderArrange,WorkTaskReport
        },
        data() {
            return {
                projectList: [],//
                taskList: [],//
                taskColumns: [
                    new Column("任务类型", "taskType", ColumnType.ColorText).setTableView(80),
                    new Column("项目名称", "itemName", ColumnType.String).setTableView(250),
                    //new Column("任务标题", "taskTitle", ColumnType.String).setTableView(250),
                    //new Column("分配部门", "departmentName", ColumnType.String).setTableView(120),
                    new Column("任务负责人", "taskLeaderName", ColumnType.String).setTableView(80),
                    new Column("任务开始时间", "startDate", ColumnType.Date).setTableView(80),
                    new Column("完成期限", "endDate", ColumnType.Date).setTableView(80),
                    new Column("剩余时间", "deadLine", ColumnType.ColorText).setTableView(80),
                    new Column("总进度", "taskMonthRatios", ColumnType.Array).setTableView(80)
                    .setArrayStringfy((item)=>{
                        return `${item.month} - ${item.ratio}%`
                    }, (a,b)=>{return a.ratio - b.ratio}),
                    new Column("完成状态","state",ColumnType.String)
                        .setDictEnum("state"),
                    new Column("备注", "remarks", ColumnType.String).setTableView(80),
                    new Column("操作", "actions", "actions", false).setTableView(100)
                ],
                searchData: null,
                formData: {},
                loading: false,
                defaultQuery: {
                    leaderUser:null,
                },
                filter:{},
                condition:"1",
                visible: false,
                visible2: false,
                visible3: false,
                visible4: false,
                dataSource: [],
                columns: [],
                activeKey:"1",
                vModel:true,
                state:"0",
                states:[
                    {value:"0",label:"未完成"},
                    {value:"1",label:"已完成"},
                    {value:"2",label:"全部"},
                ],
                taskDate:null,
                user:null,
                myDepartments:[]
            }
        },
        methods: {
            init() {
                this.taskList = [];
                this.loading = true;
                let today = dayjs();
                itemTask.queryAll.urlPost(this.defaultQuery,res=>{
                    this.loading = false;
                    if (res.data.flag) {
                        this.taskList = res.data.data.sort((a, b) => {
                            return b.createTime- a.createTime;
                        });
                        this.taskList = this.taskList.map(item=>{
                            if(item.endDate && !item.state){
                                let endDate = dayjs(item.endDate)
                                let num = endDate.diff(today, 'day');
                                if(num>0){
                                    item.deadLine = {text:`剩余 ${num} 天`,color:"#00338b"}
                                }
                                else{
                                    item.deadLine = {text:`已超时 ${0-num} 天`,color:"#c64634"}
                                }
                            }
                            item.taskType = item.pid === "0"? {text:"主任务",color:"#00338b"}:{text:"子任务",color:"#c64634"}
                            return item
                        });
                        this.doSearch()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })

            },
            doSearch() {
                let list = []
                switch (this.state) {
                    case "0":{
                        list = this.taskList.filter(item => !item.state);
                        break;
                    }
                    case "1":{
                        list = this.taskList.filter(item => item.state);
                        break;
                    }
                    case "2":{
                        list = this.taskList;
                        break
                    }
                }
                this.dataSource = ArrayFilter(list,this.filter);
            },
            refresh() {
                this.init();
            },
            editDetail(record) {
                this.formData = record;
                this.visible = true;
                this.vModel = false;
            },
            showDetail(record) {
                this.formData = record;
                this.visible = true;
                this.vModel = true;
            },
            finishTask(record){
                this.formData = record;
                this.visible2 = true;
            },
            delayApply(record){
                this.formData = record;
                this.visible3 = true;
            },
            saveData(){
                /** 以当前时间为完成时间
                 let time = localStorage.getItem("time");
                 record.completeDate = parseInt(time);
                 */
                this.formData.completeDate = this.taskDate.valueOf();
                itemTask.saveOrUpd.post(this.formData,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        async created() {
            this.defaultQuery.leaderUser = await this.$getItem("__myID");
            this.myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.defaultQuery.departmentId = this.myDepartments[0].id;
            this.init();
        }
    }
</script>

<style scoped>

</style>