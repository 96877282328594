<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectRegister 
* @description: 项目创建
* @create: 2024-08-28 15:49
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init"   type="dashed">
                <template #icon><SyncOutlined title="重置"/></template>
            </a-button>
            <a-button @click="addData" style="margin: 0 10px"   type="dashed">
                新建项目
            </a-button>
            <a-radio-group v-model:value="auditState">
                <a-radio v-for="item in auditOptions" :key="item.value" :value="item.value">{{item.label}}</a-radio>
            </a-radio-group>
        </div>
        <div style="width: 100%;height: 100%;">
            <LTable :fixed="true" :data-source="dataSource" :download="true" :loading="loading" :columns="tableColumns">
                <template #action="{record}">
                    <div  class="action-column">
                        <a-button class="action-button" v-if="record.auditState !== '1'" @click="editDetail(record)" type="link">
                            <EditOutlined class="pointer" title="查看"/>
                        </a-button>
                        <a-popconfirm
                                v-if="record.auditState === '2'"
                                @confirm="reUpdate(record)"
                                cancel-text="否"
                                ok-text="是"
                                title="重新提交审核?">
                            <a-button class="action-button" type="link">
                                <UploadOutlined class="pointer" title="提交"/>
                            </a-button>
                        </a-popconfirm>
                        <a-popconfirm
                                v-if="record.auditState !== '1'"
                                @confirm="cancel(record)"
                                cancel-text="否"
                                ok-text="是"
                                title="确定删除吗?">
                            <a-button class="action-button" type="link">
                                <DeleteOutlined class="pointer" style="color: red" title="删除"/>
                            </a-button>
                        </a-popconfirm>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
    <a-modal v-model:open="visible"
             width="1200px"
             destroyOnClose
             :footer="null"
             title="项目详情">
        <project-info :record="currentRecord" @update="init"/>
    </a-modal>
</template>

<script>
    import itemInfo from "../../../assets/api/itemInfo";
    import {DeleteOutlined, SearchOutlined, SyncOutlined,EditOutlined,UploadOutlined} from "@ant-design/icons-vue";
    import LTable from "../../../components/table/LTable";
    import ProjectInfo from "../ProjectManager/ProjectInfo";
    import {GetDict} from "../../../assets/utils/general";
    import item_info from "../../TableStruct/item_info";
    export default {
        name: "ProjectRegister",
        components:{
            DeleteOutlined,
            LTable, SearchOutlined, SyncOutlined,EditOutlined,UploadOutlined,
            ProjectInfo,
        },
        data() {
            return {
                auditState:"0",
                auditOptions:GetDict("auditState"),
                loading:false,
                tableColumns:new item_info().getTableColumns(),
                projectList:[],
                visible:false,
                currentRecord:{}
            }
        },
        computed:{
            dataSource(){
                return this.projectList.filter(item=>item.auditState === this.auditState);
            }
        },
        methods: {
            init() {
                this.loading = true;
                itemInfo.queryAllByUser.post({},res=>{
                    if(res.data.flag){
                        this.projectList = res.data.data;
                    }
                    else{
                        this.projectList = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            cancel(record){
                let data = {id:record.id};
                itemInfo.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.projectList = this.projectList.filter(item => item.id !== record.id)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            editDetail(record){
                this.currentRecord = record;
                this.visible = true;
            },
            addData(){
                this.currentRecord = {};
                this.visible = true;
            },
            reUpdate(record){
                record.auditState = "0";
                itemInfo.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.init()
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>