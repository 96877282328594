<!--
* @program: office_automation
* @author: ly
* @component:invoiceForm
* @description:
* @create: 2021-12-16 09:48
-->
<template>
    <div v-if="visible" style="display: flex;flex-wrap: wrap;">
        <LForm title="基础信息" style="width:500px" :form-columns="formColumns" v-model:form-data="mFormData" :view-model="viewModel" />
        <LForm title="收付款信息" style="width:500px" :form-columns="formColumns1" v-model:form-data="mFormData" :view-model="viewModel" />
        <LForm title="冲红" style="width:500px" :form-columns="formColumns2" v-model:form-data="mFormData" :view-model="viewModel" />
    </div>
    <a-divider />
    <a-button v-if="!viewModel"  type="primary" @click="saveData">提交</a-button>
</template>

<script>
  import LForm from "@/components/form/LForm";
    import item_invoice_record_payoff from "@/page/TableStruct/item_invoice_record_payoff";
    import TagShow from "@/page/_components/TagShow";
    import invoiceInto from "@/assets/api/invoiceInto";
    export default {
        name: "invoiceForm",
        props:["formData","viewModel"],
        emits:["refresh"],
        components:{
            LForm,TagShow
        },
        watch:{
            formData(){
                this.mFormData = {...this.formData};
            },
            viewModel(){
                this.visible = false;
                setTimeout(()=>{
                    this.visible = true;
                })
            }
        },
        data(){
            return{
                mFormData:{},
                table:new item_invoice_record_payoff(),
                formColumns:[],
                formColumns1:[],
                formColumns2:[],
                visible:true
            }
        },
        created() {
            this.mFormData = {...this.formData};
            this.formColumns = this.table.getFormColumns().filter(item=>item.group === 0);
            this.formColumns1 = this.table.getFormColumns().filter(item=>item.group === 1);
            this.formColumns2 = this.table.getFormColumns().filter(item=>item.group === 2);
        },
        methods:{
            saveData(){
                invoiceInto.saveOrUpdate.post(this.mFormData,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.$emit("refresh");
                    }
                    else{
                        this.$message.success(res.data.msg)
                    }
                })
            }
        },
    }
</script>

<style scoped>

</style>
