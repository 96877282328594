/**
 * @program: office_automation
 * @author: ly
 * @description: tableUtils
 * @create: 2021-12-15 19:32
 **/

export class SearchSegment{
    constructor(label,value){
        this.value = value;
        this.label = label;
    }
    setEnum(enums,tags){
        this.enums = enums;
        this.enumTags = tags?tags:null;
        return this;
    }
}

