/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_info 项目一览用
 * 甲方项目使用
 * @create: 2021-04-07 18:47
 *
 **/

import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type"
import userInfo from "@/assets/api/userInfo";
import partyInfo from "@/assets/api/partyInfo";
export default class item_info extends Table{
    constructor() {
        super();
        this.name = "item_info";
        this.CNName = "项目详情";
        this.columnArray = [
            new Column("项目名称","name",ColumnType.String,true).setTableView(250),
            new Column("项目类型","itemType",ColumnType.Enum,true).setDefaultValue("1").setTableView(100)
                .setDictEnum("itemType"),
            new Column("任务大类","itemCategory",ColumnType.Enum,true)
                .setDictEnum("itemCategory"),//0：其他；1：系统研发；2：工程测量；3：规划编制；4：数据处理
            new Column("年度","year",ColumnType.Number,true).setTableView(80),
            new Column("甲方名称","partyInfoId",ColumnType.IdReplace,true).setTableView(150)
                .setIdReplaceObject("partyInfoName",{api:partyInfo.queryAll,tag:"queryText"}),
            new Column("归档编号","archiveCode",ColumnType.String,true).setGroup(1),
            new Column("归档提交时间","archiveSubmitTime",ColumnType.Date,true).setGroup(1),
            new Column("归档提交人","archiveSubmitUser",ColumnType.IdReplace,true).setGroup(1)
                .setIdReplaceObject("archiveSubmitUserName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("归档接收时间","archiveReceiveTime",ColumnType.Date,true).setGroup(1),
            new Column("归档接收人","archiveReceiveUser",ColumnType.IdReplace,true).setGroup(1)
                .setIdReplaceObject("archiveReceiveUserName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("预计金额","itemBudget",ColumnType.Number,true).setDefaultValue(0).setTableView(100),//项目金额
            new Column("合同状态","contractState",ColumnType.String,true).setGroup(2)
                .setTableView(100).setDictEnum("contractState").setDefaultValue("0"),
            new Column("签订公司","sigCompanyName",ColumnType.String).setGroup(2).setTableView(120),
            new Column("总合同额","sumAmount",ColumnType.Number,false).setGroup(2).setTableView(100),
            new Column("等效合同额","dxhte",ColumnType.Number,false).setGroup(2).setTableView(120),
            new Column("已收款额","sumCollect",ColumnType.Number,false).setGroup(2).setTableView(120),
            new Column("未收款额","sumCollectNo",ColumnType.Number,false).setGroup(2).setTableView(120),
            new Column("开标日期","tenderDate",ColumnType.Date,true).setGroup(2),
            new Column("招标方式","tenderWay",ColumnType.Enum,true).setGroup(2)
                .setDictEnum("tenderWay"),
            new Column("投标保证金","margin",ColumnType.Number,true).setGroup(2),
            new Column("保证金退还日期","marginRefundTime",ColumnType.Date,true).setGroup(2),
            new Column("质保金","retentionMoney",ColumnType.Number,true).setGroup(2),
            new Column("质保金退还日期","zbjRefundTime",ColumnType.Date,true).setGroup(2),
            new Column("收款状态","collectionState",ColumnType.Enum,true).setGroup(2).setTableView(100)
                .setDictEnum("collectionState").setDefaultValue("0"),
            new Column("收款责任人","collectUser",ColumnType.String,true).setTableView(120),
            new Column("项目内容","content",ColumnType.String,true),
            new Column("创建时间","createTime",ColumnType.Date,false).setTableView(100),
            new Column("启动时间","startTime",ColumnType.Date,true).setTableView(100),
            new Column("完成期限","endDate",ColumnType.Date,true).setTableView(100),
            new Column("项目状态","itemState",ColumnType.Enum,true).setTableView(90)
                .setDictEnum("itemState").setDefaultValue("0"),
            new Column("分配部门","departmentName",ColumnType.String,false).setTableView(150),
            new Column("是否监管项目","monitorFlag",ColumnType.Boolean,false)
                .setBoolTags(["否","是"]),
            new Column("质检状态","qualityState",ColumnType.Enum,false)
                .setDictEnum("qualityState").setDefaultValue("0"),
            new Column("创建人","createUserName",ColumnType.String).setGroup(3),
            new Column("创建部门","departName",ColumnType.String).setGroup(3),
            new Column("审核时间","auditTime",ColumnType.Date).setGroup(3),
            new Column("审核状态","auditState",ColumnType.Enum).setDefaultValue("0").setGroup(3)
                .setDictEnum("auditState").setDefaultValue("0"),
            new Column("审核人","auditUserName",ColumnType.String).setGroup(3),
            new Column("审核意见","auditNote",ColumnType.String).setGroup(3),
            new Column("项目完结审核","completeFlag",ColumnType.Enum).setGroup(4)
                .setDictEnum("completeFlag"),
            new Column("归档状态","archiveState",ColumnType.Enum,true).setDefaultValue("0").setGroup(1).setTableView(90)
                .setDictEnum("archiveState"),
            new Column("项目完全说明","describe",ColumnType.String).setGroup(4),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(100)];
    }
}