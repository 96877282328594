<!--
* @program: office_automation 
* @author: ly
* @component:BillAuditSteps
* @description: 
* @create: 2022-06-09 14:22
-->
<template>
    <div  :style="style">
        <a-steps v-if="steps.length>0" size="small" >
            <a-step v-for="({approvalUserName,updateTime,remarks,status},key) in steps"
                    :status = "status"
                    :sub-title="getTime(updateTime)"
                    :key="key">
                <template #title>
                    <div style="display: flex;justify-content: space-around;height: 24px;align-items: center;">
                        <div>{{approvalUserName}}</div>
                        <a-popover v-if="remarks" trigger="hover" placement="top">
                            <template #content>
                                {{remarks}}
                            </template>
                            <a-button  type="link" >审核意见</a-button>
                        </a-popover>
                    </div>
                </template>
            </a-step>
        </a-steps>
    </div>
</template>

<script>
    import dayjs from "dayjs";
    import recordApproval from "../../../assets/api/recordApproval";

    export default {
        name: "BillAuditSteps",
        props:{
            bill:{
                required:true,
                default:{}
            }
        },
        emits:["update"],
        data(){
            return{
                modalVisible:false,
                remarks:null,
                dateFormat: 'YYYY/MM/DD HH:mm:ss',
                steps:[],
                style:"width:",
                userId:null,
                loading:false,
            }
        },
        watch:{
            bill(){
                this.init()
            }
        },
        methods:{
            getTime(timeStamp){
                if(timeStamp){
                    return dayjs(timeStamp).locale('zh-cn').format(this.dateFormat);
                }
                else{
                    return "";
                }
            },
            init(){
                this.steps = [
                    {approvalUserName:"提交审核",state:"0",updateTime:null}
                ];
                if(this.bill.id){
                    let data = {
                        recordId:this.bill.id
                    };
                    this.loading = true;
                    recordApproval.queryAll.urlPost(data,res=>{
                        this.loading = false;
                        if(res.data.flag){
                            this.steps = this.steps.concat(res.data.data);
                            if(res.data.data.length>0){
                                this.steps[0].updateTime = res.data.data[0].createTime;
                            }

                            /**获取当前角色审核的账单条目 */
                            let flow = res.data.data.find(item=>{
                                return item.approvalUser === this.userId;
                            });
                            if(flow){
                                this.$emit("update",flow.flowIds);
                            }
                            for(let step of this.steps){
                                switch (step.state) {
                                    case "0":{
                                        step.status = "wait" ; //wait process finish error
                                        break;
                                    }
                                    case "1":{
                                        step.status = "process" ; //wait process finish error
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                    case "2":{
                                        step.status = "finish" ; //wait process finish error
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                    case "3":{
                                        step.status = "error" ; //wait process finish error
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                }
                            }
                            this.style += this.steps.length * 250 + "px"
                        }
                    })
                }
            },
        },
        created() {
            this.$getItem("__myID").then(str=>{
                this.userId = str;
                this.init();
            })
        }
    }
</script>

<style scoped>

</style>