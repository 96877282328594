<!--
* @program: TYHOA2 
* @author: ly
* @component:typeTime 
* @description: 
* @create: 2023-09-15 18:15
-->
<template>
    <div v-if="viewModel">
        <div @click="onClick" >{{presentValue}}</div>
    </div>
    <div v-else>
        <div v-if="editable">
            <a-date-picker :disabledDate="disabledDate"
                           @blur="blurTrigger"
                           :placeholder="placeholder"
                           style="width: 100%"
                           v-model:value="date" :format="dateFormat" @change="columnTrigger"/>
        </div>
        <div v-else>
            <div @click="onClick" >{{presentValue}}</div>
        </div>
    </div>
</template>

<script>
    import typeDate from "./typeDate";
    export default {
        name: "typeTime",
        mixins:[typeDate],
        data() {
            return {
                dateFormat: 'YYYY/MM/DD HH:mm',
            }
        }
    }
</script>

<style scoped>

</style>