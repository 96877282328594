<!--
* @program: tyh-oa
* @author: ly
* @component:monthSelector
* @description:
* @create: 2021-06-11 14:36
-->
<template>
    <div  style="display: flex">
        <div class="statistic-label"> 月份：</div>
<!--        <a-month-picker @change="check" placeholder="请选择月份" style="width: 120px" v-model:value="date" :format="dateFormat" />-->
        <a-date-picker v-model:value="date" picker="month" placeholder="请选择月份"  @change="check" />
    </div>
</template>

<script>
    import dayjs from "dayjs";
    import {EmitData,dateFormat} from "@/assets/utils/general";
    export default {
        name: "monthSelector",
        emits:{
            event:(data)=>{
                if(data instanceof EmitData){
                    return true;
                }
                else{
                    console.warn("EmitData event data type error!");
                    return false;
                }
            }
        },
        data(){
            return{
                date:null,
                dateFormat: 'YYYY-MM',
                values:["2020-01","2030-12"] //允许的时间范围
            }
        },
        methods:{
            getValue(){
                return dayjs(this.date).format(this.dateFormat);
            },
            check(){
                if(this.date && dayjs(this.date).isBefore(this.values[0])){
                    this.date = dayjs(this.values[0]);
                }
                if(this.date && dayjs(this.date).isAfter(this.values[1])){
                    this.date = dayjs(this.values[1]);
                }
                this.$emit("event",this.$GetEmitData("month_select",this.getValue()));
            },
            setValue(value){
                this.date = value
            }
        },
        created() {
            let date = new Date();
            let month = dateFormat(this.dateFormat,date);
            this.date = dayjs(month);
        }
    }
</script>

<style scoped>

</style>
