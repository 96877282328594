<!--设备申请-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <div  style="margin-left:15px;">类别：</div>
            <assetsCategoryTree :category-group="categoryGroup" @change="init"  v-model:value="queryOptions.categoryId"/>
            <a-input  placeholder="设备名称" style="width: 150px;margin-left:15px;border-left: #022d5c solid;padding-left:10px" v-model:value="filter.name" />
            <a-input  placeholder="设备编码" style="width: 150px" v-model:value="filter.code" />
            <a-button  type="dashed"  @click="showHistory">
                <template #icon><SearchOutlined /></template>我的申请记录
            </a-button>
        </div>
        <div style="width: 100%;height: 100%;position: relative;overflow: hidden" >
            <LTable :columns="tableColumns" :dataSource="source" :download="true" :loading="loading">
                <template #action="{record}">
                    <div class="action-column">
                        <a-button  @click="borrowAssets(record)" class="action-button" type="link">
                            申请
                        </a-button>
                    </div>
                </template>
                <template #expandedRow="{children}">
                    <div class="action-column">
                        <span>本设备为主设备，包含附属设备为:</span>
                        <div style="margin-left: 10px" v-for="(child,index) in children" :key="child.id">
                            【{{index+1}}.{{child.name}}({{child.code}})】
                        </div>
                    </div>
                </template>
            </LTable>
        </div>
        <a-drawer v-model:open="modalVisible"
                  height="80%"
                  :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  @close="modalVisible =false"
                  placement="bottom"
                  title="申请历史"
                  :footer="null">
            <LTable  :columns="tableColumns2" :dataSource="dataSource2" >
                <template #action="{record}">
                    <div class="action-column">
                        <a-button  @click="applyHistory(record)" class="action-button" type="link">
                            审核情况
                        </a-button>
                    </div>
                </template>
            </LTable>
        </a-drawer>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             title="申请理由">
        <a-textarea v-model:value="reason" />
        <a-button size="small" type="primary" style="margin: 20px" @click="saveBorrow">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:open="visible2"
             destroyOnClose
             :footer="null"
             :width="800"
             title="审核状态">
        <div class="form-title">{{applyUser}}</div>
        <div class="form-title">审核进度</div>
        <AuditSteps v-if="visible2" :steps="steps" />
    </a-modal>
</template>

<script>
    import AuditSteps from "../../Equipment/AssetsAuditCenter/AuditSteps";

    import api_device_apply from "../../../assets/api/deviceApply";
    import LTable from "../../../components/table/LTable";
    import assetApplyApproval from "../../../assets/api/assetApplyApproval";

    import device_apply from "../../../assets/tables/device_apply";
    import AssetsManager from "../../Equipment/AssetsManager/AssetsManager";

    export default {
        name: "personalAssetsAccess",
        components:{
            LTable,AuditSteps
        },
        mixins:[AssetsManager],
        data(){
            return{
                dataSource2:[],
                tableColumns2:new device_apply().getTableColumns(),
                modalVisible:false,
                userId:null,
                visible2:false,
                steps:[],
                reason:undefined,
                applyUser:undefined
            }
        },
        methods:{
            borrowAssets(record){
                this.loading = true;
                let data = {
                    deviceId:record.id
                };
                this.currentRecord = record;
                api_device_apply.queryAll.post(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        let arr = res.data.data.filter(item=>{
                            return item.auditState === "0";
                        });
                        if(arr.length>0){
                            let data2 = {
                                applyId:arr[0].id
                            };
                            assetApplyApproval.queryAll.post(data2,res=>{
                                if(res.data.flag){
                                    this.steps = res.data.data;
                                }
                                else{
                                    this.steps = []
                                }
                            })
                            this.applyUser = "用户【"+arr[0].userName+"】已申请该设备，但还未审核完成";
                            this.visible2=true
                        }
                        else{
                            this.visible = true;
                        }
                    }
                    else{
                        this.visible = true;
                    }
                })
            },
            showHistory(){
                let data = {
                    userId:this.userId
                };
                api_device_apply.queryAll.post(data,res=>{
                    if(res.data.flag){
                        this.dataSource2 = res.data.data;
                        this.modalVisible = true
                    }
                })
            },
            saveBorrow(){
                let data = {
                    "deviceId": this.currentRecord.id,
                    "purposeDescri": this.reason,
                };
                api_device_apply.saveOrUpd.post(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            applyHistory(record){
                this.steps = [];
                let data2 = {
                    applyId:record.id
                };
                this.applyUser= "";
                assetApplyApproval.queryAll.post(data2,res=>{
                    if(res.data.flag){
                        this.steps = res.data.data;
                    }
                    this.visible2=true
                })
            }
        },
        created(){
            this.$getItem("__myID").then(str=>{
                this.userId = str
            })
        }
    }
</script>

<style scoped>

</style>
