<!--
* @program: TYHOA2 
* @author: ly
* @component:CategorySetting 
* @description: 
* @create: 2024-08-21 17:24
-->
<template>
    <div style="height: 100%;width: 100%;display: flex;">
        <div class="main-content50">
            <div class="search-bar">
                <a-tooltip  title="添加大类">
                    <a-button  type="dashed"  @click="addGroup">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
                <a-tooltip  title="刷新">
                    <a-button  type="dashed"  @click="refresh">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
                </a-tooltip>
            </div>
            <div style="height:100%;">
                <LTable :loading="loading" bordered :dataSource="dataSource" :columns="cols">
                    <template #action="{record}">
                        <div class="action-column">
                            <a-tooltip  title="添加子类">
                                <a-button v-if="checkColumn(record)" class="action-button" type="link" @click="addDetail(record)">
                                    <PlusOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-button  class="action-button" type="link" @click="editDetail(record)">
                                <EditFilled class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(record)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                    <template #expandedRow="{children}">
                        <div style="margin:0 20px;">
                            <a-input  placeholder="名称" style="width: 200px" v-model:value="categoryName" />
                        </div>
                        <div style="display:flex;flex-wrap: wrap">
                            <div style="margin:0 20px;" v-for="(child,index) in children.filter(item=>categoryName?item.categoryName.includes(categoryName):true)" :key="child.id">
                                {{index+1}}.{{child.categoryName}}{{child.remarks?("["+child.remarks+"]"):""}}
                                <a-button  class="action-button" type="link" @click="editDetail(child)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(child)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </div>
                    </template>
                </LTable>
            </div>
        </div>
        <div class="main-content50">
            <Welcome />
        </div>
    </div>



    <a-modal v-model:open="visible"
             title="详情">
        <template #footer>
            <a-button key="back" @click="visible =false">取消</a-button>
            <a-button key="submit" type="primary"  @click="update">提交</a-button>
        </template>
        <a-form style="margin: 10px 100px" layout="horizontal"
                :model="currentRecord">
            <a-form-item label="类别">
                <a-input style="width: 200px" v-model:value="currentRecord.categoryName" />
            </a-form-item>
            <a-form-item label="备注">
                <a-textarea style="width: 200px" v-model:value="currentRecord.remarks" />
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import {SyncOutlined,PlusOutlined,EditFilled,DeleteOutlined} from "@ant-design/icons-vue"
    import api_category from "../../../../assets/api/category";
    import LTable from "../../../../components/table/LTable";
    import Welcome from "../../../Other/Welcome";
    export default {
        name: "CategorySetting",
        components:{
            SyncOutlined,PlusOutlined,EditFilled,DeleteOutlined,LTable,Welcome
        },
        data(){
            return{
                dataSource:[],
                cols:[],
                secondSource:[],
                visible:false,
                viewModel:false,
                currentRecord:{},
                loading:false,
                categoryName:null
            }
        },
        created() {
            this.cols = [
                new Column("类别","categoryName",ColumnType.String,true).setTableView(150),
                new Column("备注","remarks",ColumnType.String,true).setTableView(150),
                new Column("操作","actions","actions").setTableView(80)];
            this.init();
        },
        methods:{
            init(){
                this.loading = true;
                api_category.queryOneLevel.post({},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                })
            },
            addGroup(){//添加大类
                this.currentRecord = {};
                this.visible = true
            },
            addDetail(record){//添加子分类
                this.currentRecord = {parentId:record.id};
                this.visible = true
            },
            deleteData(record){
                let data ={id:record.id};
                api_category.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editDetail(record){
                this.visible = true;
                this.currentRecord = record;
            },
            checkColumn(record){
                return Object.prototype.hasOwnProperty.call(record, "children");
            },
            update(){
                api_category.saveOrUpd.post(this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.init()
            }
        },
    }
</script>

<style scoped>

</style>