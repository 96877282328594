/** 
* 自动生成文件，请勿修改 
* @name: 项目:任务/工序延期申请
* @description: 项目-任务/工序延期申请-增删改查（三期新增）
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:14条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const progressApply = { 
	/** 1 summary:查询-不分页(部门负责人用)
	description:注解：部门负责人可以审核部门下任务的工序的延期申请，及员工的延期申请
	method:post
	params:需要配合Column函数使用
	*/ 
	allDepartLeader : new APIObject.postObject("/api/progressApply/allDepartLeader.do"), 
	/** 2 summary:删除-批量
	description:参数：数据的主键id,逗号分隔多条：1,2,3,4.。
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/progressApply/batchDelete.do"), 
	/** 3 summary:查询-分页
	description:参数：(page、limit,分页参数，)、progressApplyQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/progressApply/queryPage.do"), 
	/** 4 summary:审核-单条
	description:参数：id主键，
	method:post
	params:需要配合Column函数使用
	*/ 
	approve : new APIObject.postObject("/api/progressApply/approve.do"), 
	/** 5 summary:查询-不分页(质检负责人用)
	description:注解：质检负责人自己承担的质检任务下的质检工序的延期申请，及员工的延期申请
	method:post
	params:需要配合Column函数使用
	*/ 
	allGroupLeader : new APIObject.postObject("/api/progressApply/allGroupLeader.do"), 
	/** 6 summary:查询-分页(项目负责人用（任何人都可能是项目负责人）)
	description:注解：项目负责人可以审核自己负责的任务下员工的延期申请，项目负责人只能审核其下的员工-生产工序延期申请
	method:post
	params:需要配合Column函数使用
	*/ 
	pageItemLeader : new APIObject.postObject("/api/progressApply/pageItemLeader.do"), 
	/** 7 summary:新增-单条
	description:参数：对象progressApplyVO
	method:post
	params:需要配合Column函数使用
	*/ 
	save : new APIObject.postObject("/api/progressApply/save.do"), 
	/** 8 summary:查询-不分页(项目负责人用（任何人都可能是项目负责人）)
	description:注解：项目负责人可以审核自己负责的任务下员工的延期申请，项目负责人只能审核其下的员工-生产工序延期申请
	method:post
	params:需要配合Column函数使用
	*/ 
	allItemLeader : new APIObject.postObject("/api/progressApply/allItemLeader.do"), 
	/** 9 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/progressApply/delete.do"), 
	/** 10 summary:查询-分页(质检负责人用)
	description:注解：质检负责人自己负责承担的质检任务下的质检工序的延期申请，及员工的延期申请
	method:post
	params:需要配合Column函数使用
	*/ 
	pageGroupLeader : new APIObject.postObject("/api/progressApply/pageGroupLeader.do"), 
	/** 11 summary:查询-不分页
	description:参数：progressApplyQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/progressApply/queryAll.do"), 
	/** 12 summary:查询-分页(部门负责人用)
	description:注解：部门负责人可以审核部门下任务的工序的延期申请，及员工的延期申请
	method:post
	params:需要配合Column函数使用
	*/ 
	pageDepartLeader : new APIObject.postObject("/api/progressApply/pageDepartLeader.do"), 
	/** 13 summary:查询-单条
	description:参数：单条数据的主键id,
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/progressApply/queryOne.do"), 
	/** 14 summary:修改-单条
	description:参数：对象progressApplyVO
	method:post
	params:需要配合Column函数使用
	*/ 
	update : new APIObject.postObject("/api/progressApply/update.do"), 
};
export default progressApply;