<!--
* @program: 前台 
* @author: ly
* @component:typeDepartsRole 
* @description: 部门所拥有的角色
* @create: 2023-03-22 15:28
-->
<template>
    <div v-if="viewModel" class="align-tag">
        <a-tag v-for="item in sEnums" @click="onClick" :key="item">
            <div >{{getLabel(item)}}</div>
        </a-tag>
    </div>
    <div v-else>
        <a-select v-if="editable"  @blur="blurTrigger"
                  mode="multiple"
                  :fieldNames="fieldNames"
                  v-model:value="value" style="width: 100%"
                  :options="options"
                  @change="changeHandler">
        </a-select>
        <div v-else class="align-tag">
            <a-tag v-for="item in sEnums" @click="onClick" :key="item">
                <div >{{getLabel(item)}}</div>
            </a-tag>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {isFunction} from "../../assets/utils/general";
    export default {
        name: "typeDepartsRole",
        mixins:[typeBase],
        inject:["DepartsRoles"],
        watch:{
            record(){
                this.columnTrigger();
                this.value = this.sEnums;
            }
        },
        computed:{
            sEnums(){
                let value = this.record[this.column.dataIndex];
                if(value){
                    return value.split(",");
                }
                else{
                    return [];
                }
            }
        },
        data() {
            return {
                allRoles:[],//系统内所有角色
                value:undefined,
                fieldNames:{ label: "roleName", value: "id"},
                options:[]
            }
        },
        methods: {
            changeHandler(value){
                this.record[this.column.dataIndex] = value.toString();
                this.$emit("update");
                let triggers = this.column.triggerOtherColumn;
                if(triggers && triggers.length>0){
                    //遍历设置的触发器
                    for(let i in triggers){
                        if(triggers[i].value instanceof Array){//trigger.value 为 数组的情况
                            let colValue = this.record[this.column.dataIndex];
                            if(triggers[i].value.indexOf(colValue) !== -1 && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else if(isFunction(triggers[i].value)){////trigger.value 为 条件函数的情况
                            if(triggers[i].value(this.record) && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else{//trigger.value 为 普通值的情况
                            if(triggers[i].value === this.record[this.column.dataIndex] && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                    }
                }
            },
            getLabel(item){
                let role = this.allRoles.find(r=>r.id === item);
                if(role){
                    return role.roleName
                }
                else{
                    return ""
                }
            }
        },
        created() {
            this.value = this.sEnums;
            this.allRoles = JSON.parse(localStorage.getItem("roles"));
            if(this.DepartsRoles) {
                let arr = this.DepartsRoles.split(",");
                for(let it of arr){
                    let role = this.allRoles.find(item =>item.id === it);
                    if(role){
                        this.options.push(role);
                    }
                }
            }
            else{
                this.options = this.allRoles;
            }
        }
    }
</script>

<style scoped>

</style>