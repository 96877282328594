/** 
* 自动生成文件，请勿修改 
* @name: 项目:工作日志记录模块
* @description: 工作日志记录模块-增删改查
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const workSituation = { 
	/** 1 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/workSituation/batchDelete.do"), 
	/** 2 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/workSituation/queryOne.do"), 
	/** 3 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/workSituation/delete.do"), 
	/** 4 summary:查询-不分页
	description:参数：WorkSituationQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/workSituation/queryAll.do"), 
	/** 5 summary:保存/修改-批量
	description:参数：List<WorkSituationVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/workSituation/batchSaveOrUpd.do"), 
	/** 6 summary:查询-分页
	description:参数：(page、limit,分页参数，)、WorkSituationQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/workSituation/queryPage.do"), 
	/** 7 summary:保存/修改-单个
	description:参数：单条数据对象WorkSituationVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/workSituation/saveOrUpd.do"), 
};
export default workSituation;