/** 
* 自动生成文件，请勿修改 
* @name: 报销:报账单流水模块
* @description: 报销-报账单流水模块-增删改查
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const recordFlow = { 
	/** 1 summary:报账单流水-流水导出excel
	description:参数：recordId(报账单id,导出一份报账单下的所有流水详情列表)
	method:get
	params:需要配合Column函数使用
	*/ 
	exportExcelByRecord : new APIObject.getObject("/api/recordFlow/exportExcelByRecord.do"), 
	/** 2 summary:报账单流水-单条删除
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/recordFlow/delete.do"), 
	/** 3 summary:报账单流水-批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/recordFlow/batchDelete.do"), 
	/** 4 summary:报账单流水-批量保存/修改
	description:参数：List<RecordFlowVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/recordFlow/batchSaveOrUpd.do"), 
	/** 5 summary:报账单流水-所有数据查询
	description:参数：recordId(报账单id,可有可无);itemId(项目id,可有可无)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/recordFlow/queryAll.do"), 
	/** 6 summary:报账单流水-分页查询
	description:参数：pageBean分页对象(传page、limit即可)、recordId(报账单id,可有可无);itemId(项目id,可有可无)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/recordFlow/queryPage.do"), 
	/** 7 summary:报账单流水-保存/修改
	description:参数：单条数据对象RecordFlowVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/recordFlow/saveOrUpd.do"), 
	/** 8 summary:报账单流水-单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/recordFlow/queryOne.do"), 
};
export default recordFlow;