/** 
* 自动生成文件，请勿修改 
* @name: 新增:部门其他收入/支出管理(包括部门间流转)
* @description: 部门其他收入/支出管理
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:5条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const depIncomePay = { 
	/** 1 summary:新增（只能新增支出记录）
	description:描述：在部门负责人转账给其他部门时，将新增一条其他部门的收入
	method:post
	params:需要配合Column函数使用
	*/ 
	save : new APIObject.postObject("/api/depIncomePay/save.do"), 
	/** 2 summary:删除（不能删除转账记录）
	description:描述：转账记录一旦录入将不允许更改删除，若错误操作，需联系收款部门删除相应的收款记录（会连同转账记录一起删除）
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/depIncomePay/delete.do"), 
	/** 3 summary:根据id查询
	description:参数：id(主键id)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/depIncomePay/queryOne.do"), 
	/** 4 summary:查询-分页
	description:参数：DepIncomePayQueryVO queryVO查询记录封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/depIncomePay/queryPage.do"), 
	/** 5 summary:查询-不分页（全部）
	description:参数：DepIncomePayQueryVO queryVO查询记录封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/depIncomePay/queryAll.do"), 
};
export default depIncomePay;