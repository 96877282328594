<!--
* @program: office_automation
* @author: ly
* @component:MonthMoneySum
* @description: 按月统计输入的条目金额
* @create: 2022-04-18 16:42
-->
<template>
    <a-popover trigger="hover" placement="bottom" >
        <template #content>
            <div>只统计【初始余额日期】后的记录</div>
            <a-list size="small" style="margin: 0" bordered :data-source="monthSum">
                <template #renderItem="{item}">
                    <a-list-item>{{item.yearMonth}}【{{item.value.toFixed(2)}}元】</a-list-item>
                </template>
            </a-list>
        </template>
        <a-button  type="link" >
            按月份统计
        </a-button>
    </a-popover>
</template>

<script>
    import dayjs from "dayjs";
    export default {
        name: "MonthMoneySum",
        props:["records","dateSeg","sumSeg","startDate","summary"],
        emits:["update:summary"],
        data() {
            return {
                monthSum:[]
            }
        },
        watch:{
            records(){
                this.init()
            }
        },
        methods: {
            init() {
                this.monthSum = [];
                for(let i in this.records){
                    let yearMonth;
                    let date = this.records[i][this.dateSeg];
                    if(date){
                        if(dayjs(date).isBefore(dayjs(this.startDate))){
                            continue
                        }
                        yearMonth = dayjs(date).format("YYYY-MM");
                    }
                    else{
                        yearMonth = "未填写时间";
                    }
                    let arr = this.monthSum.filter(item =>item.yearMonth === yearMonth);
                    if(arr.length >0){
                        arr[0].value += this.records[i][this.sumSeg];
                    }else{
                        this.monthSum.push({
                            yearMonth:yearMonth,
                            value:this.records[i][this.sumSeg]
                        })
                    }
                }
                this.monthSum = this.monthSum.sort((a,b)=>{
                    return new Date(b.yearMonth) - new Date(a.yearMonth)
                });
                let sum = 0;
                for(let j in this.monthSum){
                    sum += this.monthSum[j].value;
                }
                this.$emit("update:summary",sum)
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>
