<!--
* @program: HXQTDZSRemake
* @author: ly
* @component:SearchBar
* @description:
* @create: 2021-11-19 16:02
searchOptions:[
{value:"name",label:"姓名"}
]
value:{key:value}
-->
<template>
    <div style="display: flex;">
        <a-select v-model:value="type" @select="handleSelect"
                  placeholder="类别"
                  style="width: 120px"
                  :options="searchOptions" />

        <a-input v-if="visible"  @change="emitTrigger"
                 v-model:value="keyValue" style="width:200px" placeholder="请输入关键字" />
        <a-select v-else v-model:value="keyValue"
                  :fieldNames="fieldNames"
                  placeholder="类别"
                  style="width:200px"
                  :options="enumOptions"
                  @select="emitTrigger"
        />
        <slot name="action" :value="searchData" />
    </div>
</template>

<script>

    export default {
        name: "SearchBar",
        emits: ['update:value',"select"],
        props:{
            searchOptions:{required:true},//选项
            value:{required:true}//当前选中的键值对
        },
        watch:{
            value(){
                this.init()
            }
        },
        data(){
            return{
                type:null,
                keyValue:null,
                currentOption:null,
                visible:true,
                enumOptions:[],
                fieldNames:{ label: "title", value: "dataIndex"}
            }
        },
        computed:{
            searchData(){
                let data = {};
                data[this.type] = this.keyValue;
                return data
            }
        },
        methods:{
            init(){
                if(this.value){
                    this.type = Object.keys(this.value)[0];
                    this.keyValue = this.value[this.type];
                }
                else{
                    this.keyValue = null;
                }
            },
            handleSelect(value,option){
                this.keyValue = null;
                this.visible = true;
                this.currentOption = option;
                this.enumOptions = [];
                if(this.currentOption.enums){
                    this.visible = false;
                    for(let i in this.currentOption.enums){
                        this.enumOptions.push({title:this.currentOption.enums[i].label,dataIndex:this.currentOption.enums[i].value});
                    }
                    this.keyValue = this.currentOption.enums[0].value;
                }
                this.emitTrigger();
            },
            emitTrigger(){
                this.$emit("update:value",this.searchData);
                this.$emit("select",this.type);
                this.$emit("change",this.keyValue);
            }
        },
        created() {
            this.init();
            this.visible = true;
            if(this.searchOptions.length>0){
                this.type = this.searchOptions[0].value;
                this.currentOption = this.searchOptions[0];
                if(this.currentOption.enums){
                    this.visible = false;
                    for(let item of this.currentOption.enums){
                        this.enumOptions.push({title:item.label,dataIndex:item.value});
                    }
                    this.keyValue = this.currentOption.enums[0];
                }
                this.emitTrigger();
            }
        }
    }
</script>

<style scoped>

</style>
