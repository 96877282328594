<!--
* @program: TYHOA2 
* @author: ly
* @component:MenuList 
* @description: 人，财，物，事菜单的默认页面
* @create: 2023-09-11 10:13
-->
<template>
    <div style="width: 100%;">
        <div style="margin:20px;display: flex">
            <a-input @change="handleChange" style="font-size: 24px;width: 300px" placeholder="菜单名称"  v-model:value="name" />
        </div>
        <div style="width: 100%; display:flex;flex-wrap: wrap;justify-content: center;align-items: center;align-content: center;">
            <div  v-for="(item,idx) in menuList" :key="idx">
                <MenuListItem :item="item" @click="menuClick(item)" />
            </div>
        </div>
    </div>

</template>

<script>
    import MenuListItem from "./MenuListItem";
    export default {
        name: "MenuList",
        inject:["addHisMenu"],
        components:{
            MenuListItem
        },
        data() {
            return {
                menuList:[],
                name:null
            }
        },
        methods: {
            init(){
                let menuList = this.$route.matched.find(item=>item.name === this.$route.name);
                if(menuList){
                    this.menuList = menuList.children().filter(item=>item.path);//踢出path为""的情况
                    this.menuList.forEach(item=>{
                        item.enable = true;
                    });
                }
            },
            handleChange(){
                if(this.name){
                    this.menuList.forEach(item=>{
                        item.enable = !!item.cnName.includes(this.name);
                    });
                }
                else{
                    this.menuList.forEach(item=>{
                        item.enable = true;
                    });
                }
            },
            menuClick(item){
                let{name,cnName} = item;
                this.addHisMenu({name,cnName});
                this.$router.push({name:item.name});
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>