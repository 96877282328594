/**
 * @program: 前台
 * @author: ly
 * @description: ColorMap
 * @create: 2023-03-03 11:48
 **/

const ColorMap={
        "green":"#61b36a",
        "pink":"#bd4ea8",
        "purple":"#e62fc0",
        "blue":"#62849f",
        "red":"#c64634",
        "yellow":"#b4973d",
        "cyan":"#62bab3",
        "CadetBlue":"#8ff0f0"
};
const ColorKeys = Object.keys(ColorMap);
const ColorValues = Object.values(ColorMap);
export default ColorMap;
export {ColorKeys,ColorValues}