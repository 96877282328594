<!--
* @program: TYHOA2 
* @author: ly
* @component:WeekReport 
* @description: 
* @create: 2024-08-12 20:20
-->
<template>
    <div class="form-title">
        <span>项目周报</span>
        <a-button @click="init" style="margin-left: 20px" type="dashed">
            <template #icon><SyncOutlined/></template>
        </a-button>
        <a-button v-if="!viewModel" @click="addData" type="dashed">
            <template #icon>
                <PlusOutlined />
            </template>
        </a-button>
        <a-input v-model:value="filter.weekDate" style="width:150px" placeholder="XXXX年X周" />
    </div>
    <div style="text-align: right;color: #a7a7a7">完成进度：指本项目的总体进度，由于存在预估误差，计算绩效时候进度以月报进度为准，周报进度仅为参考</div>
    <div v-if="weekSource.length >0" style="height:calc(100% - 40px);overflow:auto">
        <WeekReportItem @delete="deleteData" v-for="report in weekSource" :key="report.id" :report="report" :view-model="viewModel"/>
    </div>
    <a-result v-else status="404" title="还未上传周报"/>
    <a-modal v-model:open="visible"
             width="800px"
             title="周报">
        <a-row>
            <a-col class="form-label" style="padding-right: 15px;" :span="4">
                <div>周报时间</div>
            </a-col>
            <a-col class="form-value" :span="4">
                <a-week-picker style="width: 100%;" @change="selectHandle" v-model:value="selectedDate" :disabledDate="disabledDate" placeholder="请选择周查询"/>
            </a-col>
            <a-col class="form-label" style="padding-right: 15px;" :span="4">
                <div >完成进度(%)</div>
            </a-col>
            <a-col class="form-value" :span="4">
                <a-input-number style="width: 100%;" :max="100" :min="0" v-model:value="formData.completeRatio"  />
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="4">
                <div>本周工作情况</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-model:value="formData.thisWeekWork"  placeholder="本周工作情况" :rows="4" />
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="4">
                <div>下周工作计划</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea  v-model:value="formData.nextWeekWork"  placeholder="下周工作计划" :rows="4" />
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="4">
                <div>目前存在问题</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-model:value="formData.existProblem"  placeholder="目前存在问题" :rows="4" />
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="4">
                <div>目前应对措施</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea  v-model:value="formData.solveMethod"  placeholder="目前应对措施" :rows="4" />
            </a-col>
        </a-row>
        <template #footer>
            <a-button type="primary" html-type="submit"  @click="saveData">保存</a-button>
        </template>
    </a-modal>
</template>

<script>
    import {PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import dayjs from "dayjs";
    import weekWork from "../../../../assets/api/weekWork";
    import WeekReportItem from "./WeekReportItem";

    export default {
        name: "WeekReport",
        props:["viewModel","task"],
        components:{
            PlusOutlined,SyncOutlined,WeekReportItem

        },
        computed:{
            weekSource(){
                return ArrayFilter(this.weekReport,this.filter)
            }
        },
        data(){
            return{
                loading:false,
                weekReport: [],
                formData:{},
                filter:{},
                visible:false,
                selectedDate: null,
                dateFormat: 'YYYY-WW',
                vModel:true,
            }
        },
        methods:{
            init(){
                if(this.task && this.task.id){
                    this.weekReport = [];
                    this.loading = true;
                    let data = {
                        taskId:this.task.id
                    };
                    weekWork.queryAll.post(data,res=>{
                        if(res.data.flag){
                            this.weekReport = res.data.data.sort((a,b)=>{
                                return b.createTime - a.createTime;
                            });
                        }
                        this.loading = false;
                    })
                }
            },
            addData(){
                this.vModel = false;
                this.formData = {};
                let year = dayjs().format('YYYY');
                let week = dayjs().week();
                this.formData.weekDate = year + '年第' + week + "周";
                this.formData.existProblem = "暂无";
                this.formData.solveMethod = "暂无";
                this.visible = true;
            },
            saveData(){
                this.formData.taskId = this.task.id;
                weekWork.saveOrUpd.post(this.formData,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(id){
                this.weekReport = this.weekReport.filter(item=>item.id !== id)
            },
            disabledDate(current){ //
                let thisWeek = dayjs().week();
                let currentWeek = dayjs(current).week();
                return currentWeek !== thisWeek && currentWeek !== thisWeek -1
            },
            selectHandle(){
                if(this.selectedDate){
                    let year = this.selectedDate.format('YYYY');
                    let week = this.selectedDate.week()
                    this.formData.weekDate = year + '年第' + week + "周";
                }
            }
        },
        created() {
            this.init();
        },
    }
</script>

<style scoped>

</style>