/**
 * @program: oa2.0
 * @author: ly
 * @description: FinanceComp
 * @create: 2023-08-25 09:33
 **/
import yearBalance from "@/page/Finance/financialManager/yearBalanceMange/yearBalance";
import BankViewer from "@/page/Finance/financialManager/BankManager/BankViewer";
import BankManager from "@/page/Finance/financialManager/BankManager/BankManager";
import OtherOutcome from "@/page/Finance/financialManager/OtherOutcome/OtherOutcome";
import OtherIncome from "@/page/Finance/financialManager/OtherIncome/OtherIncome";
import OtherOutcomeAudit from "@/page/Finance/financialManager/OtherOutcomeAudit/OtherOutcomeAudit";
import financeSuject from "@/page/Finance/financialManager/financeSujectMange/financeSuject";
/*import financeReport from "@/page/Finance/financialCount/financeReport";*/
import invoiceManagerIncome from "@/page/Finance/financialManager/invoiceManager/invoiceManagerIncome";
import invoiceManagerPayoff from "@/page/Finance/financialManager/invoiceManager/invoiceManagerPayoff";


import BillSetting from "@/page/Finance/BillManager/BIllSetting/BillSetting";

import billStatisticsByCategory from "@/page/Finance/BillManager/billStatistics/billStatisticsByCategory";
import billStatisticsByProject from "@/page/Finance/BillManager/billStatistics/billStatisticsByProject";
import billStatisticsByDepartment from "@/page/Finance/BillManager/billStatistics/billStatisticsByDepartment";

import MenuList from "@/page/Other/OAMenuDefaultPage/MenuList";
import BillEntry from "@/page/Finance/BillManager/BillEntry";
import DepartmentBillEntry from "@/page/Finance/BillManager/DepartmentBillEntry";
import BillManager from "@/page/Finance/BillManager/BillManager";
import DepartmentBillManager from "@/page/Finance/BillManager/DepartmentBillManager";
let FinanceComp = [
    {path: '', component: MenuList, name:"FinanceComp",cnName:"默认页面",children:()=>{return FinanceComp}},
    /** 财务 **/
    {path: 'BankViewer', component: BankViewer, name:"BankViewer",cnName:"资金账户一览"},
    {path: 'BankManager', component: BankManager, name:"BankManager",cnName:"资金账户管理"},
    {path: 'OtherOutcome', component: OtherOutcome, name:"OtherOutcome",cnName:"资金账户支出"},
    {path: 'OtherOutcomeAudit', component: OtherOutcomeAudit, name:"OtherOutcomeAudit",cnName:"资金账户支出审核"},
    {path: 'invoiceManagerIncome', component: invoiceManagerIncome, name:"invoiceManagerIncome",cnName:"收入发票"},
    {path: 'invoiceManagerPayoff', component: invoiceManagerPayoff, name:"invoiceManagerPayoff",cnName:"支出发票"},
    {path: 'OtherIncome', component: OtherIncome, name:"OtherIncome",cnName:"其他收入"},
    {path: 'yearBalance', component: yearBalance, name:"yearBalance",cnName:"年初余额"},
    {path: 'financeSuject', component: financeSuject, name:"financeSuject",cnName:"财务科目管理"},
   /* {path: 'financeReport', component: financeReport, name:"financeReport",cnName:"财务报表"},*/

    /** 账单 **/
    {path: 'BillEntry', component: BillEntry, name:"BillEntry",cnName:"入账管理"},
    {path: 'DepartmentBillEntry', component: DepartmentBillEntry, name:"DepartmentBillEntry",cnName:"部门入账管理"},
    {path: 'BillManager', component: BillManager, name:"BillManager",cnName:"账单管理"},
    {path: 'DepartmentBillManager', component: DepartmentBillManager, name:"DepartmentBillManager",cnName:"部门账单管理"},

    {path: 'BillSetting', component: BillSetting, name:"BillSetting",cnName:"报账相关设定"},
    {path: 'billStatisticsByCategory', component: billStatisticsByCategory, name:"billStatisticsByCategory",cnName:"按报账类别统计"},
    {path: 'billStatisticsByProject', component: billStatisticsByProject, name:"billStatisticsByProject",cnName:"项目消耗统计"},
    {path: 'billStatisticsByDepartment', component: billStatisticsByDepartment, name:"billStatisticsByDepartment",cnName:"部门报账统计"},

]

export default FinanceComp
