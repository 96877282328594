/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_task_process  生产工序管理  - 部门任务一览用
 * 分配工序使用
 * @create: 2021-04-16 16:48
 **/

import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type"
export default class item_task_process extends Table{
    constructor() {
        super();
        this.name = "item_task_process";
        this.CNName = "生产工序管理";
        this.columnArray = [
            new Column("工序标题","title",ColumnType.String,true).setTableView(200),
            new Column("工序内容","content",ColumnType.String,true),
            new Column("工作量权重","workRatio",ColumnType.Number,true).setNumberRange(0,200).setTableView(100),
            new Column("任务开始时间","startDate",ColumnType.Date,true),
            new Column("完成期限","endDate",ColumnType.Date,true),
            new Column("实际完成时间","completeDate",ColumnType.Date,false),
            new Column("完成状态","state",ColumnType.Enum,false).setTableView(100)
                .setDictEnum("state"),
            new Column("完成情况说明","describe",ColumnType.String,false),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(200)];
    }
}