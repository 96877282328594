<!--
* @program: office_automation
* @author: ly
* @component:OtherOutcomeAudit
* @description: 资金账户支出审核
* @create: 2022-02-18 11:02
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <LTabs :pane-list="paneList" @update="handleUpdate"/>
<!--            <a-tabs v-model:activeKey="activeKey" >
                <a-tab-pane  key="first" tab="项目支付审核" >
                    <OtherOutcomeAuditNow />
                </a-tab-pane>
                <a-tab-pane  key="second" tab="审核记录一览" >
                    <OtherOutcomeAuditHistory />
                </a-tab-pane>
            </a-tabs>-->
        </div>
    </div>
</template>

<script>
    import OtherOutcomeAuditHistory from "@/page/Finance/financialManager/OtherOutcomeAudit/OtherOutcomeAuditHistory";
    import OtherOutcomeAuditNow from "@/page/Finance/financialManager/OtherOutcomeAudit/OtherOutcomeAuditNow";
    import LTabs from "@/components/tabs/LTabs";
    export default {
        name: "OtherOutcomeAudit",
        components:{
            OtherOutcomeAuditHistory, OtherOutcomeAuditNow,LTabs
        },
        data(){
            return{
                activeKey:"first",
                paneList:[],
            }
        },
        created() {
            this.paneList = [
                {tag:"项目支付审核",component:OtherOutcomeAuditNow,param:null},
                {tag:"审核记录一览",component:OtherOutcomeAuditHistory,param:null},
            ]
        },
        methods:{
            handleUpdate(data){
                const keys = Object.keys(data);
                for (const key of keys) {
                    this.record[key] = data[key];
                }
            },
        },
    }
</script>

<style scoped>

</style>
