/** 
* 自动生成文件，请勿修改 
* @name: 资产:设备归还模块
* @description: 设备归还模块-增删改查
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const deviceBack = { 
	/** 1 summary:查询-所有数据
	description:参数：deviceBackQueryVO,参数过多而封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/deviceBack/queryAll.do"), 
	/** 2 summary:新增/修改-单条（设备归还使用）
	description:注释：点击设备归还时，只需传原归还申请id,其他均不用传
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/deviceBack/saveOrUpd.do"), 
	/** 3 summary:归还申请-查询需要我审批且已到达我这里的归还申请-分页
	description:参数：userId
	method:post
	params:需要配合Column函数使用
	*/ 
	pageMyApproval : new APIObject.postObject("/api/deviceBack/pageMyApproval.do"), 
	/** 4 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/deviceBack/batchDelete.do"), 
	/** 5 summary:归还申请-查询需要我审批且已到达我这里的申请-不分页
	description:参数：userId
	method:post
	params:需要配合Column函数使用
	*/ 
	queryMyApproval : new APIObject.postObject("/api/deviceBack/queryMyApproval.do"), 
	/** 6 summary:查询-分页
	description:参数：deviceBackQueryVO,参数过多而封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/deviceBack/queryPage.do"), 
	/** 7 summary:查询-单条
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/deviceBack/queryOne.do"), 
	/** 8 summary:删除-单条（提交的申请 未被审核时才能删除）
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/deviceBack/delete.do"), 
	/** 9 summary:设备管理者强制归还别人使用中的设备
	description:注释：设备管理者强制归还别人使用中的设备
	method:post
	params:需要配合Column函数使用
	*/ 
	forceBack : new APIObject.postObject("/api/deviceBack/forceBack.do"), 
};
export default deviceBack;