<!--
* @program: office_automation
* @author: ly
* @component:outflow
* @description: 项目外协支付
* @create: 2022-01-25 11:06
-->
<template>
    <div class="center-flex">
        <span class="statistic-label"> 合计：{{summary}} 元</span>
        <MonthMoneySum :start-date="record.balanceDate" :records="argument.dataSource" date-seg="payTime" sum-seg="payAmount" />
    </div>
    <!--项目付款申请 -->
    <div style="height:600px">
    <LTable  :data-source="argument.dataSource" :columns="argument.tableColumns" :view-model="true">
        <template #action="{index,record}">
            <div class="center-flex">
                <a-button @click="showDetail(index,record)"
                          class="action-button" type="link">
                    <Search class="pointer"/>
                </a-button>
            </div>
        </template>
    </LTable>
    </div>
    <a-modal v-model:open="visible2"
             :width="1200"
             destroy-on-close
             title="详情"
             :maskClosable="vModel"
             :footer="null">
        <LForm :form-columns="formArg.formColumns" :form-data="formArg.formData" :view-model="vModel" />
        <a-divider >审核状态</a-divider>
<!--        <projectOutSourcePayAuditStatus :record="formArg.formData" />-->
    </a-modal>
</template>

<script>
import Icons from "@/page/common/Icons";
import dayjs from "dayjs";
import LTable from "@/components/table/LTable";
import LForm from "@/components/form/LForm";
//import projectOutSourcePayAuditStatus from "@/projectManager/projectRelateInfo/projectOutSourcePayAuditStatus";
import item_outsourc_pay_apply from "@/page/TableStruct/item_outsourc_pay_apply";
import payApply from "@/assets/api/payApply";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import MonthMoneySum from "@/page/Finance/financialManager/BankManager/MonthMoneySum";


export default {
    name: "outflow",
    props:["record","value"],
    mixins:[Icons],
    components:{
      LTable,
      LForm,MonthMoneySum
    },
    watch:{
        record(){
            this.init()
        }
    },
    computed:{
        summary(){
            let sum=0;
            for(let i in this.argument.dataSource){
                if(this.argument.dataSource[i].payState === "1"){
                    let date = this.argument.dataSource[i].payTime;
                    if(date.isBefore(dayjs(this.record.balanceDate))){
                        continue
                    }
                    sum += this.argument.dataSource[i].payAmount*100;
                }
            }
            return sum/100;
        }
    },
    data(){
        return{
            table1:new item_outsourc_pay_apply(),
            argument:{
                dataSource:null,//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                currentIndex:null, //当前选中的条目
            },
            formArg:{
                formColumns:[],
                formData:null
            },
            formData:null,
            visible:false,
            visible2:false,
            vModel:true,
            visible3:false,
            date:null,//支付日期
            cardId:null,//支付银行卡号
            cardOptions:[],
        }
    },
    methods:{
        init(){
            this.visible = false;
            this.argument.loading = true;
            let data = {
                bankId: this.record.id,
                payState:"1"
            };
            payApply.queryAll.post(data,res=>{
                this.argument.loading = false;
                if(res.data.flag){
                    this.argument.dataSource = res.data.data;
                    this.$emit("update:value",{value:this.summary,loading:false});
                }
                else{
                    this.argument.dataSource = [];
                    this.$emit("update:value",{value:0,loading:false});
                }
            })
        },
        showDetail(index,record){
            this.argument.currentIndex = index;
            this.formArg.formData = record;
            this.formArg.formColumns = this.table1.getFormColumns();
            this.visible2 = true;
            this.vModel = true;
        }
    },
    created() {
        this.argument.tableColumns = [
            /*new Column("序号","_index",ColumnType.Index).setTableView(60),*/
            new Column("项目名称","itemName",ColumnType.String).setTableView(150),
            new Column("支付时间","payTime",ColumnType.Date).setTableView(80),
            new Column("付款合同名称","contractName",ColumnType.String,true).setTableView(120),
            new Column("收款单位/个人","outSourceName",ColumnType.String,true).setTableView(120),
            new Column("申请支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(80),
            new Column("付款公司", "payCompanyId", ColumnType.Enum, true).setTableView(180)
                .setEnum({value:"2b5f21c6-750e-4f98-b0c1-c7068b700a27",label: "贵州图云慧空间信息技术有限公司",color:"gold"},
                    {value:"70f61478-c716-477c-b0e7-9f2e333e6538",label: "北京中色测绘院有限公司",color:"green"},
                    {value:"0d89dd47-1f19-4e97-a565-eae226d6d743",label:"北京中色地科测绘有限公司贵州分公司",color:"purple"},
                    {value:"1527858e-4494-4647-ab87-ebfcc2ec8935",label: "河北中色测绘有限公司",color:"cyan"},
                    {value:"aa60eee4-8212-4ccd-a21d-31fb7dff6f3f",label: "北京中色地科测绘有限公司",color:"pink"},
                    {value:"9fac494d-d74e-49d2-ab39-7da87e0783a9",label:"中色蓝图科技股份有限公司贵州分公司",color:"purple"},
                    {value:"5ce1d392-cf46-43a0-8097-fc1d37f57784",label:"贵州中色蓝图科技有限公司",color:"purple"},
                    {value: "15b60020-4691-4d3c-aa38-cd5e54b3b784",label:"中色蓝图科技股份有限公司",color:"gold"},
                    {value: "d0bc5391-7367-4b5b-97fb-12d7fc67cc6d",label:"贵州中色蓝图科技有限公司兴义分公司",color:"green"},
                    {value: "0303b748-93b2-4ae4-b737-558bf2f8b282",label:"贵州中色蓝图科技有限公司花溪分公司",color:"purple"},),
            new Column("付款部门", "applyDepartName", ColumnType.String, true).setTableView(100),
            //new Column("支付备注","remarks",ColumnType.String,true).setTableView(100),
            new Column("支付描述","payReason",ColumnType.String,true).setTableView(80),
            new Column("操作","actions","actions").setTableView(80)
        ];
        this.init();
    }
}
</script>

<style scoped>

</style>
