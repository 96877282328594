<!--
* @program: TYHOA2 
* @author: ly
* @component:TaskWeekReport 
* @description: 
* @create: 2024-07-30 19:58
-->
<template>
    <div class="column-flex">
        <div class="row-flex">
            <div class="main-content50" >
                <WeekReport :view-model="true" :task="task" />
            </div>
            <div class="main-content50">
                <MonthReport :view-model="true" :task="task" />
            </div>
        </div>
    </div>
</template>

<script>
    import WeekReport from "../../PersonalCenter/WorkCenter/WorkTaskReport/WeekReport";
    import MonthReport from "../../PersonalCenter/WorkCenter/WorkTaskReport/MonthReport";
    export default {
        name: "TaskWeekReport",
        props:["task"],
        components:{
            WeekReport,MonthReport
        },
    }
</script>

<style scoped>

</style>