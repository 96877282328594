/** 
* 自动生成文件，请勿修改 
* @name: 用章:公章用途管理模块
* @description: 公章用途管理模块-增删改查
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const sealPurpose = { 
	/** 1 summary:查询-所有数据
	description:参数：
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/sealPurpose/queryAll.do"), 
	/** 2 summary:查询-分页
	description:参数：pageBean分页对象(传page、limit即可)、
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/sealPurpose/queryPage.do"), 
	/** 3 summary:保存/修改-单条
	description:参数：单条数据对象SealPurposeVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/sealPurpose/saveOrUpd.do"), 
	/** 4 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/sealPurpose/batchDelete.do"), 
	/** 5 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/sealPurpose/delete.do"), 
	/** 6 summary:查询-单条
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/sealPurpose/queryOne.do"), 
};
export default sealPurpose;