<!--
* @program: TYHOA2 
* @author: ly
* @component:PoolItem 
* @description: 
* @create: 2024-09-12 15:39
-->
<template>
    <div style="border-bottom: #c8c8c8 1px dashed">
        <div>{{index+1}}. {{item.year}}年
            <span style="font-weight: bold;color: #022d5c">￥{{(item.monthAmount + item.yearAmount).toFixed(2)}}</span>
            【月度￥{{item.monthAmount}} 年度￥{{item.yearAmount}} 】
            <slot />
        </div>
        <div v-if="item.applyReason"><span style="font-weight: bold;margin-right:5px">申请原因</span>{{item.applyReason}}</div>
        <div v-if="item.remarks"><span style="font-weight: bold;margin-right:5px">说明</span>{{item.remarks}}</div>
        <div v-if="showAudit">
            <span style="font-weight: bold;margin-right:5px">审核状态</span>
             <a-tag color="red" v-if="item.approvState ==='2'">申请被拒绝</a-tag>
             <a-tag color="green" v-if="item.approvState ==='1'">已通过</a-tag>
             <a-tag color="purple" v-if="item.approvState==='0' || !item.approvState">待审核</a-tag>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PoolItem",
        props:["item","index","showAudit"],
    }
</script>

<style scoped>

</style>