<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentMember 
* @description: 部门人员一览
* @create: 2024-08-29 20:25
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh"   type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-select v-model:value="params.departmentId" style="width: 250px"
                      placeholder="通过部门筛选"
                      @change="departmentSelect">
                <a-select-option v-for="item in departments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <a-input v-model:value="filter.name" style="width:150px" placeholder="请输入姓名" />
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="main-content25">
                <LTable :show-index="false" :loading="loading" :columns="tableColumns" :data-source="source">
                    <template #action="{record}">
                        <div class = "action-column">
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="重置密码为：123456?"
                                    @confirm="resetPassword(record)">
                                <a-tooltip title="重置密码">
                                    <WarningOutlined style="color: #ff40f0" class="pointer"/>
                                </a-tooltip>
                            </a-popconfirm>
                            <a-tooltip  title="人员信息">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <UserOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip  title="工作安排">
                                <a-button  class="action-button" type="link" @click="workArrange(record)">
                                    <AlignLeftOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip  title="工作日志记录">
                                <a-button  class="action-button" type="link" @click="workDiary(record)">
                                    <CalendarOutlined  class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip  title="绩效分配记录">
                                <a-button  class="action-button" type="link" @click="moneyRecord(record)">
                                    <MoneyCollectOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip  title="考核记录">
                                <a-button  class="action-button" type="link" @click="assessmentRecord(record)">
                                    <ProfileOutlined  class="pointer"/>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content75">
                <component v-if="currentComp" :viewModel="false" :is="currentComp" :param="currentRecord" :department-id="params.departmentId"/>
                <Welcome v-else/>
            </div>
        </div>
    </div>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import {
        SearchOutlined,
        SyncOutlined,
        WarningOutlined,
        AccountBookOutlined,CalendarOutlined,ProfileOutlined,
        UserOutlined,AlignLeftOutlined,MoneyCollectOutlined
    } from "@ant-design/icons-vue";
    import ExtraInfo from "../../../HumanResources/UserInfoManager/ExtraInfo";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import userDepartRelation from "../../../../assets/api/userDepartRelation";
    import userInfo from "../../../../assets/api/userInfo";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import Welcome from "../../../Other/Welcome";
    import WorkCalendar from "./WorkCalendar";
    import CalendarDiaryView from "./CalendarDiaryView";
    import UserMonthMoney from "./UserMonthMoney";
    import UserAssessmentItem from "./UserAssessmentItem";

    export default {
        name: "DepartmentMember",
        components:{
            LTable,Welcome,
            SearchOutlined,SyncOutlined,UserOutlined,
            WarningOutlined,AlignLeftOutlined,
            AccountBookOutlined,CalendarOutlined,ProfileOutlined,MoneyCollectOutlined
        },
        data() {
            return {
                filter:{},
                params:{
                    disableState:"1", //默认只查询在职人员
                    loginState:undefined, //
                    departmentId:undefined
                },
                dataSource:null,//提供table的DataSource
                tableColumns:[
                    new Column("姓名","name",ColumnType.String,true).setTableView(80),
                    new Column("入职状态","loginState",ColumnType.Enum,false)
                        .setDictEnum("loginState").setTableView(80),
                    new Column("操作","actions","actions",false).setTableView(120)
                ], //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                departments:[],
                currentRecord:null,
                currentComp:undefined,
                visible:false,
            }
        },
        computed:{
            queryParams(){
                let obj = {...this.params};
                const keys = Object.keys(obj);
                for (const key of keys) {
                    if(!obj[key]){
                        delete obj[key]
                    }
                }
                return obj;
            },
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        methods: {
            init() {
                this.loading = true;
                userDepartRelation.queryAll.urlPost(this.queryParams,res=>{
                    this.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        for(let item of res.data.data){
                            item.id = item.userId;
                            item.name = item.userName;
                        }
                        this.dataSource = res.data.data.filter(item=>item.id !=="2");
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.params = {
                    disableState:"1", //默认只查询在职人员
                    loginState:undefined, //
                    departmentId:this.departments[0].id
                };
                this.init()
            },
            departmentSelect(){
                this.loading = true;
                userDepartRelation.queryAll.urlPost(this.queryParams,res=>{
                    this.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        for(let item of res.data.data){
                            item.id = item.userId;
                            item.name = item.userName;
                        }
                        this.dataSource = res.data.data.filter(item=>item.id !=="2");
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
            resetPassword(record){
                let data = {
                    id:record.id
                };
                userInfo.passWordInitial.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showDetail(record){
                this.currentRecord = record;
                this.currentComp = ExtraInfo;
            },
            workArrange(record){
                this.currentRecord = record;
                this.currentComp = WorkCalendar;
            },
            workDiary(record){
                this.currentRecord = record;
                this.currentComp = CalendarDiaryView;
            },
            moneyRecord(record){
                this.currentRecord = record;
                this.currentComp = UserMonthMoney;
            },
            assessmentRecord(record){
                this.currentRecord = record;
                this.currentComp = UserAssessmentItem;
            },
        },
        async created() {
            this.departments = JSON.parse(await this.$getItem("__myDepartments"));
            this.params.departmentId = this.departments[0].id;
            this.init();
        }
    }
</script>

<style scoped>

</style>