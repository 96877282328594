/**
 * @program: oa2.0
 * @author: ly
 * @description: WorkAdministrationComp
 * @create: 2023-08-25 09:34
 **/

import ProjectRegister from "@/page/WorkAdministration/ProjectRegister/ProjectRegister";
import DepartmentMember from "@/page/WorkAdministration/Department/DepartmentWork/DepartmentMember";
import DepartmentPool from "@/page/WorkAdministration/Department/DepartmentPool";
import DepartmentTaskViewer from "@/page/WorkAdministration/Department/DepartmentTaskViewer";
import DepartmentWeekReport from "@/page/WorkAdministration/Department/DepartmentWork/DepartmentWeekReport";
import DepartmentRole from "@/page/WorkAdministration/Department/DepartmentWork/DepartmentRole";
import DepartmentMemberKPI from "@/page/WorkAdministration/Department/DepartmentWork/DepartmentMemberKPI";

let WorkAdministrationComp = [
    {path: '', component: ()=>import("@/page/Other/OAMenuDefaultPage/MenuList") , name:"WorkAdministrationComp",children:()=>{return WorkAdministrationComp}},
    {path: 'ProjectManager', component: ()=>import("@/page/WorkAdministration/ProjectManager/ProjectManager"), name:"ProjectManager",cnName:"项目管理"},
    {path: 'ProjectTaskArrange', component: ()=>import("@/page/WorkAdministration/ProjectTask/ProjectTaskArrange"), name:"ProjectTaskArrange",cnName:"项目任务管理"},
    {path: 'DepartmentTaskViewer', component: ()=>import("@/page/WorkAdministration/Department/DepartmentTaskViewer"), name:"DepartmentTaskViewer",cnName:"部门项目任务管理"},
    {path: 'PoolManager', component: ()=>import("@/page/WorkAdministration/Pool/PoolManager"), name:"PoolManager",cnName:"绩效池管理"},
    {path: 'DepartmentPool', component: ()=>import("@/page/WorkAdministration/Department/DepartmentPool"), name:"DepartmentPool",cnName:"部门绩效池管理"},

    {path: 'OAInfoManager', component: ()=>import("@/page/System/OAInfoManager/OAInfoManager"), name:"OAInfoManager",cnName:"公告管理"},
    {path: 'DepartmentWork',
        component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/DepartmentWork"),
        name:"DepartmentWork",
        cnName:"部门工作汇总",
        children:[
            {path: 'Work1', component: DepartmentTaskViewer,
                name:"DepartmentWorkWork1",cnName:"部门任务一览",
                meta:{content:"指定负责人，提交完成，延期申请",checkAuth:"parentAuth"}
            },
            {path: 'Work2', component: DepartmentWeekReport,
                name:"DepartmentWorkWork2",cnName:"周报审核",
                meta:{content:"审核部门周报及提交总经办",checkAuth:"parentAuth"}
            },
            {path: 'Work3', component: DepartmentPool,
                name:"DepartmentWorkWork3",cnName:"部门绩效池",
                meta:{content:"部门绩效总览，支付其他部门",checkAuth:"parentAuth"},
            },
            {path: 'Work4', component: DepartmentMemberKPI,
                name:"DepartmentWorkWork4",cnName:"部门成员KPI",
                meta:{content:"月度绩效统计",checkAuth:"parentAuth"}
            },
            {path: 'Work5', component: ProjectRegister,
                name:"DepartmentWorkWork5",cnName:"项目创建",
                meta:{content:"新项目创建，请注意不要重复创建",checkAuth:"parentAuth"},
            },
            {path: 'Work6', component: DepartmentMember,
                name:"DepartmentWork6",cnName:"部门成员一览",
                meta:{content:"工作安排，绩效，工作日志",checkAuth:"parentAuth"},
            },
            {path: 'Work7', component: DepartmentRole,
                name:"DepartmentWork7",cnName:"部门角色管理",
                meta:{content:"正在开发",checkAuth:"parentAuth"},
            },
        ]
    },
    {path: 'ProjectWeekReport', component: ()=>import("@/page/WorkAdministration/ProjectWeekReport/ProjectWeekReport"), name:"ProjectWeekReport",cnName:"项目周报审核"},
    {path: 'ProjectProcessViewer', component: ()=>import("@/page/WorkAdministration/ProjectProcessViewer/ProjectProcessViewer"), name:"ProjectProcessViewer",cnName:"项目进度一览"},
    {path: 'ProjectRegister', component: ()=>import("@/page/WorkAdministration/ProjectRegister/ProjectRegister"), name:"ProjectRegister",cnName:"项目创建"},
    {path: 'ProjectAudit', component: ()=>import("@/page/WorkAdministration/ProjectRegister/ProjectAudit"), name:"ProjectAudit",cnName:"新建项目审核"},
    {path: 'DelayManager', component: ()=>import("@/page/WorkAdministration/DelayManager/DelayManager"), name:"DelayManager",cnName:"项目延期审核"},
]

export default WorkAdministrationComp