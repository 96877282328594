<!--
* @program: TYH_office_automation 
* @author: ly
* @component:projectChart 
* @description: 
* @create: 2021-06-18 11:17
-->
<template>
    <v-chart :autoresize="true" class="chart" :option="option" />
</template>

<script>
    import { use } from "echarts/core";
    import { CanvasRenderer } from "echarts/renderers";
    import { PieChart,BarChart,TreeChart } from "echarts/charts";
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent,GridComponent
    } from "echarts/components";
    import VChart, { THEME_KEY } from "vue-echarts";
    import { ref, defineComponent } from "vue";

    use([
        CanvasRenderer,
        PieChart,
        BarChart,
        TitleComponent,
        TooltipComponent,
        LegendComponent,GridComponent,TreeChart
    ]);

    export default defineComponent({
        name: "projectChart",
        components: {
            VChart
        },
        provide: {
            [THEME_KEY]: "light"
        },
        props:["setting"],
        setup (props) {
            //type,legend,xAxis,yAxis,series}
            const legend = ref(props.setting.legend);
            const yAxis = ref(props.setting.xAxis);
            const xAxis = ref(props.setting.yAxis);
            const series = ref(props.setting.series);
            const option = ref({
                toolbox: {
                    show: true,
                    top:0,
                    left:0,
                    feature: {
                        restore: {},
                        saveAsImage: {}
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'line',        // 默认为直线，可选为：'line' | 'shadow'
                        label:{
                            formatter: function ({seriesData,value}) {
                                let sum = 0;
                                for(let i in seriesData){
                                    sum += seriesData[i].data
                                }
                                return value+"  （合计："+sum+"元）";
                            },
                        }
                    }
                },
                legend: legend,
                grid: {
                    left: '5%',
                    right: '5%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis:xAxis,
                yAxis: yAxis,
                series: series
            });
            return { option };
        }
    });
</script>

<style scoped>
    .chart {
        height: 100%;
    }
</style>
