/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工月度考核表得分-导出excel
* @description: 员工月度考核表得分-导出excel（三期新增）
* @create: 2024/8/1 17:09:16
* @swagger: 2.0
* 合计:1条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const kpi = { 
	/** 1 summary:根据userId与yearMonth导出对应员工月度考核得分表
	description:参数：userId,yearMonth
	method:get
	params:需要配合Column函数使用
	*/ 
	exportByUserMonth : new APIObject.getObject("/api/kpi/exportByUserMonth.do"), 
};
export default kpi;