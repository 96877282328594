<!--
* @program: office_automation 
* @author: ly
* @component:delayApply 
* @description: 
* @create: 2022-01-08 10:16
-->
<template>
    <div>
        <LForm :view-model="false"  :form-columns="formColumns" :form-data="{}">
            <template #action="{record}">
                <a-button type="primary" @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </LForm>
        <LTable title="申请历史" :columns="tableColumns" :data-source="dataSource">
            <template #action="{index,record}">
                <div class="action-column">
                    <a-popconfirm
                            v-if="record.approveState ==='0'"
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            title="确认撤销吗?"
                            @confirm="deleteData(index,record)">
                        <a-tooltip title="撤销申请">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-tooltip>
                    </a-popconfirm>
                </div>
            </template>
        </LTable>
    </div>
</template>

<script>
    import LForm from "../../../components/form/LForm";
    import LTable from "../../../components/table/LTable";
    import progressApply from "../../../assets/api/progressApply";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import {DeleteOutlined} from "@ant-design/icons-vue"
    export default {
        name: "delayApply",
        props:["record","type","deadLine"],//类型：1:生产任务；2：生产工序；3：质检工序；4：员工工序
        watch:{
            record(){
                this.init()
            }
        },
        components:{
            LForm,LTable,DeleteOutlined
        },
        data(){
            return{
                formColumns:[
                    //new Column("原定完成时间","oldDate",ColumnType.Date,true),
                    new Column("延期到","newDate",ColumnType.Date,true),
                    new Column("申请缘由","reason",ColumnType.String,true),
                ], //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
                dataSource:null,//提供table的DataSource
                tableColumns:[
                    new Column("审核状态","approveState",ColumnType.String).setTableView(80)
                        .setDictEnum("item_progress_apply.approveState"),
                    new Column("申请人","applyUserName",ColumnType.String).setTableView(80),
                    new Column("原计划时间","oldDate",ColumnType.Date).setTableView(80),
                    new Column("延期到","newDate",ColumnType.Date,true).setTableView(80),
                    new Column("延迟天数","delayDay",ColumnType.Number).setTableView(80),
                    new Column("申请缘由","reason",ColumnType.String,true).setTableView(150),
                    new Column("审核批注","approveRemark",ColumnType.String).setTableView(100),
                    new Column("操作","actions","actions").setTableView(40)
                ], //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
            }
        },
        methods:{
            init(){
                this.formData = {
                    newDate:null,
                    reason:null,
                };
                this.loading = true;
                let data = {
                    originalId:this.record.id
                };
                progressApply.queryAll.post(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = [];
                    }
                })
            },
            saveData(record){
                record.originalId = this.record.id;
                record.applyType = this.type;//类型：1:生产任务；2：生产工序；3：质检工序；4：员工工序
                record.oldDate = this.record.endDate;
                progressApply.save.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                progressApply.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.init();
            if(this.deadLine){
                let arr = this.formColumns.filter(item=>item.dataIndex ==="newDate");
                let time = parseInt(localStorage.getItem("time"));
                arr[0].setEnableTime([time,this.deadLine])
            }
        }
    }
</script>

<style scoped>

</style>