<!--
* @program: TYHOA2 
* @author: ly
* @component:typeColorNumber 
* @description: 
* @create: 2024-05-08 12:46
-->
<template>
    <div v-if="viewModel" >
        <a-tooltip trigger="click" :title="presentValue">
            <div  v-if="presentValue>0" class="no-warp-string positive" >{{presentValue}}</div>
            <div  v-else class="no-warp-string negative" >{{presentValue}}</div>
        </a-tooltip>
    </div>
    <div v-else>
        <div v-if="editable" style="display: flex">
            <a-input-number
                    :placeholder="placeholder"
                    @blur="blurTrigger"
                    :min="min"
                    :max="max"
                    :formatter="formatter"
                    :parser = "parser"
                    style="width:100%" v-model:value="record[column.dataIndex]"
                    @change="columnTrigger"
            />
            <a-tooltip  title="数值计算器">
                <a-button type="link" @click="calcShow">
                    <template #icon><MobileOutlined /></template>
                </a-button>
            </a-tooltip>
        </div>
        <a-tooltip trigger="click" :title="presentValue">
            <div  v-if="presentValue>0" class="no-warp-string positive" >{{presentValue}}</div>
            <div  v-else class="no-warp-string negative" >{{presentValue}}</div>
        </a-tooltip>
        <a-modal v-model:open="cVisible"
                 destroyOnClose
                 :footer="null"
                 title="数值计算器">
            <div style="display: flex">
                <a-textarea
                        v-model:value="execString"
                        auto-size
                        @change ="calc"
                        placeholder="例如：100*5.8"
                />
                <div style="margin:5px;width: 200px">={{calcValue}}</div>
            </div>
            <a-button size="small" type="dash" style="margin: 20px" @click="inputValue">
                填入计算结果
            </a-button>
        </a-modal>
    </div>
</template>

<script>
    import typeNumber from "./typeNumber";
    export default {
        name: "typeColorNumber",
        mixins:[typeNumber]
    }
</script>

<style scoped>
    .positive{
        color:#c64634
    }
    .negative{
        color:#61b36a
    }
</style>