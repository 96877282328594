<!--
* @program: tyh-oa
* @author: ly
* @component:billStatisticsToolBar
* @description:
* @create: 2021-06-11 14:03
-->
<template>
    <div  class="search-bar">
        <div style="margin: 8px;font-weight: 900;font-size: 16px"> 统计方式：</div>
        <a-select v-model:value="type" style="width: 200px"  @select="selectChange">
            <a-select-option v-for="{seg,label} in options" :key="seg" >
                {{label }}
            </a-select-option>
        </a-select>
        <component :is="currentComp" ref="i_selector"/>
        <a-button  style="margin-left: 20px" type="dashed" shape="circle" @click="doSearch">
            <template #icon><SearchOutlined /></template>
        </a-button>
        <a-button  type="dashed" shape="circle" @click="refresh" >
            <template #icon><SyncOutlined /></template>
        </a-button>
        <a-radio-group  v-model:value="deleteZero" @change="change">
            <a-radio-button :value="true">删除空数据项</a-radio-button>
            <a-radio-button :value="false">保留空数据项</a-radio-button>
        </a-radio-group>
        <a-button   type="link" @click="tipsShow" >
            <template #icon><InfoCircleOutlined /></template>
        </a-button>
    </div>
</template>

<script>
   /* import statisticsBar from "../../../components/toolBar/statisticsBar";*/
    export default {
        name: "billStatisticsToolBar",
       /* mixins:[statisticsBar],*/
        data(){
            return{
                options:[
                    {seg:"month",label:"按月统计",comp:"monthSelector"},
                    {seg:"year",label:"按年统计",comp:"yearSelector"},
                    {seg:"period",label:"按时间段统计",comp:"periodSelector"}
                ], //[{seg:"title",label:"账单标题",type:"input"}]
                currentComp:"monthSelector",
                type:"month",

            }
        },
        methods:{
            tipsShow(){
                this.$message.info("金额统计只统计已入账的账单，未入账的账单不进行统计")
            }
        }
    }
</script>

<style scoped>

</style>
