import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: promotion_audit
 * @create: 2022-03-08 13:58
 **/

export default class promotion_audit extends Table{
    constructor() {
        super();
        this.name = "promotion_audit";
        this.CNName = "职位晋升申请";
        this.columnArray = [
            new Column("部门","departName",ColumnType.String).setTableView(100),
            new Column("入职日期","inductionDate",ColumnType.Date),
            new Column("申请人","userName",ColumnType.String).setTableView(80),
            new Column("现岗位","currentLevel",ColumnType.PositionLevel).setTableView(100),
            new Column("晋升岗位","applyLevel",ColumnType.PositionLevel).setTableView(100),
            new Column("越级晋升","crossLevel",ColumnType.Boolean),
            new Column("申请时间","applyTime",ColumnType.Date).setTableView(80),
            new Column("晋升自述","workDescribe",ColumnType.String),
            new Column("月绩效考核情况","achieve",ColumnType.String),
            new Column("部门意见","departSugg",ColumnType.String,true).setGroup(1),
            new Column("部门负责人","departSuggUserName",ColumnType.String).setGroup(1),
            new Column("人力资源部意见","hrSugg",ColumnType.String,true).setGroup(2),
            new Column("人力资源部负责人","hrSuggUserName",ColumnType.String).setGroup(2),
            new Column("总经办意见","gmoSugg",ColumnType.String,true).setGroup(3),
            new Column("总经办成员","gmoSuggUserName",ColumnType.String).setGroup(3),
            new Column("特殊人才","specialFlag",ColumnType.Boolean,true).setGroup(3)
                .setBoolTags(["否","是"]),
            new Column("晋升评分","score",ColumnType.String),
            new Column("是否通过审核","success",ColumnType.Enum,true).setGroup(3)
                .setEnum(["0","1","2"],["未审核","未通过审核","审核通过"],["pink","red","green"]),
            new Column("生效日期","effectDate",ColumnType.Date,true).setGroup(3),
            new Column("重大工作失误","majorError",ColumnType.Boolean,true).setGroup(1),
            new Column("违反规章制度","violationRules",ColumnType.Boolean,true).setGroup(2),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(60)];
    }
}