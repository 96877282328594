<!--
* @description：图标
* @author:rzl
* @date:2022/7/27 15:43
-->
<template>
    <div>
      <SearchOutlined /><RedoOutlined /><PlusOutlined /><DeleteOutlined /><EditTwoTone /><FileSearchOutlined /><RollbackOutlined />

      <FileZipOutlined /><PushpinOutlined /><UploadOutlined /><ReadOutlined /><AuditOutlined />
      <CheckCircleOutlined /><ClusterOutlined /><HomeTwoTone /><TeamOutlined /><FolderOpenOutlined />
      <EnvironmentOutlined /><DatabaseTwoTone /><TagsOutlined /><DownloadOutlined /><PieChartOutlined />
      <CheckOutlined /><BorderBottomOutlined /><BlockOutlined /><DownOutlined /><UnorderedListOutlined />

      <SyncOutlined />
        <EditOutlined /><LinkOutlined /><EditFilled /><SwapOutlined /><OrderedListOutlined />
        <SnippetsOutlined /> <ArrowUpOutlined /><SortAscendingOutlined /><RetweetOutlined />
      <!-- 双色 -->
      <FolderOpenTwoTone /><FileTwoTone /><SettingTwoTone /><EyeTwoTone/><DownloadOutlined/><AuditOutlined /><PlusCircleTwoTone /><MinusCircleTwoTone />
      <CalculatorTwoTone /><HddTwoTone /><DiffTwoTone /><BarChartOutlined /><InfoCircleOutlined />
    </div>
</template>

<script>
    import {
      SearchOutlined,RedoOutlined,PlusOutlined,DeleteOutlined,EditTwoTone,FileSearchOutlined,
      CheckOutlined,RollbackOutlined,UnorderedListOutlined,

    BorderBottomOutlined,AuditOutlined,SyncOutlined,
        EditOutlined,LinkOutlined,SettingOutlined,EditFilled,SwapOutlined,OrderedListOutlined,
        SnippetsOutlined,ArrowUpOutlined,SortAscendingOutlined,RetweetOutlined,
      // 双色
      FolderOpenTwoTone,FileTwoTone,SettingTwoTone,EyeTwoTone,DownloadOutlined,PlusCircleTwoTone,MinusCircleTwoTone,
      CalculatorTwoTone,HddTwoTone,DiffTwoTone,BarChartOutlined,InfoCircleOutlined

    }  from "@ant-design/icons-vue";

    export default {
        name: "Icons",
        components:{
          Search:SearchOutlined,// 查询
          Reset:RedoOutlined,// 重置
          Add:PlusOutlined,// 新增
          Delete:DeleteOutlined,// 删除
          Edit:EditTwoTone,// 编辑
          Show:FileSearchOutlined,// 查看
          CheckOutlined:CheckOutlined,// 提交
          revoke:RollbackOutlined, // 撤销
          Audit:AuditOutlined,// 审核


          // 双色
          folder:FolderOpenTwoTone,// 文件夹
          layer_s:FileTwoTone,// 图层
          SettingTwoTone:SettingTwoTone,// 设置
          zdhx:BorderBottomOutlined,// 宗地红线
          EyeTwoTone:EyeTwoTone,// 查看
          Download:DownloadOutlined,// 下载

          PlusCircleTwoTone:PlusCircleTwoTone,// 加
          MinusCircleTwoTone:MinusCircleTwoTone,// 减
          CalculatorTwoTone:CalculatorTwoTone,// 算法图标
          HddTwoTone:HddTwoTone,// 财务科目关联费用类别
          DiffTwoTone:DiffTwoTone,// 录入
          BarChartOutlined:BarChartOutlined,// 统计饼图

          InfoCircleOutlined:InfoCircleOutlined,
          UnorderedListOutlined:UnorderedListOutlined,


          SyncOutlined:SyncOutlined,

            EditOutlined:EditOutlined,
            LinkOutlined:LinkOutlined,
            SettingOutlined:SettingOutlined,
            EditFilled:EditFilled,
            SwapOutlined:SwapOutlined,
            OrderedListOutlined:OrderedListOutlined,
            SnippetsOutlined:SnippetsOutlined,
            ArrowUpOutlined:ArrowUpOutlined,
            SortAscendingOutlined:SortAscendingOutlined,
            RetweetOutlined:RetweetOutlined,
        }
    }
</script>

<style scoped>

</style>
