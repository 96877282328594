<!--
* @program: TYHOA2 
* @author: ly
* @component:CalendarCellHead
* @description: 
* @create: 2024-08-14 17:53
-->
<template>
    <div style="padding: 5px;background:#ededed;">
        <a-tag v-if="name" :color="isOffDay?'green':'red'">{{name}}{{isOffDay===false?"补假（正常上班）":""}}</a-tag>
        <a-tag v-if="isWeekend && !name" color="blue">周末</a-tag>
        <a-tag v-if="isToday" color="cyan">今天</a-tag>
        <span>{{day.month()+1}}月{{day.date()}}日</span>
    </div>
</template>

<script>
    import dayjs from "dayjs"
    export default {
        name: "CalendarCellHead",
        props:["day"],
        watch:{
            day(){
                this.init()
            }
        },
        data() {
            return {
                name:"",
                isOffDay:false,
                isWeekend:false,
                isToday:false,
                holidays:{
                    "2020":()=>import(`@/assets/holiday-cn/2020.json`),
                    "2021":()=>import(`@/assets/holiday-cn/2021.json`),
                    "2022":()=>import(`@/assets/holiday-cn/2022.json`),
                    "2023":()=>import(`@/assets/holiday-cn/2023.json`),
                    "2024":()=>import(`@/assets/holiday-cn/2024.json`),
                    "2025":()=>import(`@/assets/holiday-cn/2025.json`),
                },
            }
        },
        methods: {
            async init() {
                this.isToday = this.day.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")
                this.name = "";
                this.isOffDay = "";
                let year = this.day.format("YYYY");
                this.isWeekend = this.day.day() === 6 || this.day.day() === 0;
                if(!this.holidays[year]){
                    return
                }
                let res = await this.holidays[year]();
                if(res.days && res.days.length>0){
                    let day = res.days.find(item=> item.date === this.day.format("YYYY-MM-DD"));
                    if(day){
                        this.name = day.name;
                        this.isOffDay = day.isOffDay;
                    }
                    else{
                        this.name = "";
                        this.isOffDay = "";
                    }
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>