<!--
* @program: 前台 
* @author: ly
* @component:typeSubSegment 
* @description: 
* @create: 2023-02-23 10:44
-->
<template>
    <div @click="onClick">
        {{text}}
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeSubSegment",
        mixins:[typeBase],
        computed:{
            dataIndex(){
                return this.column.dataIndex.split(".")
            },
            text(){
                if(this.dataIndex.length ===1){
                    return this.record[this.dataIndex[0]]
                }
                else{
                    return this.record[this.dataIndex[0]][this.dataIndex[1]];
                }
            }
        },
    }
</script>

<style scoped>

</style>