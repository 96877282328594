<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectIncome 
* @description: 
* @create: 2024-07-25 11:17
-->
<template>
    <div style="width: 100%;height: 100% ;display: flex">
        <div class="main-content50">
            <ProjectInvoice :record="project" :bank-cards="bankCards" :view-model="true"/>
        </div>
        <div class="main-content50">
            <ProjectIncomeRecord :record="project" :bank-cards="bankCards" :view-model="true"/>
        </div>
    </div>

</template>

<script>
    import ProjectIncome from "./ProjectIncome";
    export default {
        name: "ProjectIncome",
        mixins:[ProjectIncome],
        props:["record","task"],
        computed:{
            project(){
                if(this.record){
                    return {id:this.record.id};
                }
                if(this.task){
                    return {id:this.task.itemId};
                }
                else{
                    return {}
                }
            },
        }

    }
</script>

<style scoped>

</style>