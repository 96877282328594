
/**
 * @program: tdzs
 * @author: ly
 *  @description: 用于定义字段类型 用于form 或table column的时候区别显示
 * @create: 2020-08-25 16:33
 **/

class ColumnType{
    constructor() {
        this.actions = "actions";
        this.String ="String";
        this.ColorText ="ColorText";
        this.WrapString ="WrapString";
        this.TextArea ="TextArea"; //用于多行展示的String类型
        this.Number ="Number";
        this.ColorNumber ="ColorNumber"; //正负数颜色不一样
        this.Date = "Date";
        this.Time = "Time";
        this.Month = "Month"; //用于显示月份
        this.Enum ="Enum";  //枚举类型
        this.File = "File"; //字段为选择文件上传时使用
        this.Boolean = "Boolean"; //用于显示true false 都显示的情况
        this.BooleanCheck = "BooleanCheck"; //用于显示 true 的情况
        this.Index = "Index"; //用于显示table的第一个column，表示序号
        this.IdReplace = "IdReplace"; //用于表示数据库中存放的是ID，但是显示的时候却需要显示名字,显示名字由后台传出的字段中，并且在编辑的时候可以通过查询关键字来进行查询,查询方式使用postUrl
        this.IdReplace2 = "IdReplace2"; //用于表示数据库中存放的是ID，但是显示的时候却需要显示名字，显示名字由后台传出的字段中，并且在编辑的时候可以通过查询关键字来进行查询，查询方式使用post
        this.IdReplaceXmgd = "IdReplaceXmgd";
        this.ObjectReplace = "ObjectReplace"; //用于表示数据库中存放的是ID，但是显示的时候却需要显示名字，显示名字在后台传出的Object中，并且在编辑的时候可以通过查询关键字来进行查询，查询方式使用postUrl
        this.PreViewFile = "PreViewFile" ; //可以预览的文件类型，用于上传报销凭证和展示
        this.Avatar = "Avatar"; //用于用户头像，上传和预览
        this.Selector = "Selector"; //给字段特定的selector
        this.RemoteEnum = "RemoteEnum";
        this.Year = "Year";
        this.Password = "Password"; //密码框
        this.OperateEnum = "OperateEnum"; //权限字段
        this.Geometry = "Geometry"; //权限字段
        this.SubSegment = "SubSegment"; //展示字段为Object,展示子项内的某个字段
        this.DepartsRole = "DepartsRole"; //专门用于处理部门能选择的角色
        this.UserRoles = "UserRoles"; //专门用于展示用户所拥有的角色
        this.CategoryAssets = "CategoryAssets";
        this.Category = "Category";//用于展示资金类别
        this.Array = "Array";//用于数组

        this.PositionLevel = "PositionLevel"; //专门用于展示用户所拥有的角色
    }
    get types(){
        let ct = new ColumnType();
        return Object.keys(ct);
    }
    get lowerTypes(){
        let types = [];
        for(let i in this.types){
            types.push(this.types[i].toLowerCase())
        }
        return types
    }
    getType(type){
        let index = this.lowerTypes.indexOf(type.toLowerCase());
        if( index !== -1){
            return this.types[index];
        }
        else if(type.toLowerCase() === "integer"){
            return "Number";
        }
        else{
            return "String";
        }
    }
}
let columnType = new ColumnType();
export default columnType;