<!--
* @program: TYHOA2 
* @author: ly
* @component:typeArray 
* @description: 
* @create: 2024-08-13 09:33
-->
<template>
    <a-popover v-if="array && array.length>0" trigger="hover" placement="top">
        <template #content>
            <NoWrapString v-for="(item,index) in array" :key="index" @click="onClick" :text="stringfy(item)"/>
        </template>
        <span class="no-warp-string" style="color:#10539c">{{stringfy(array[array.length-1])}}</span>
    </a-popover>
    <div v-else></div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeArray",
        mixins:[typeBase],
        data(){
            return{
                array:[]
            }
        },
        watch:{
            presentValue(){
                if(this.presentValue && this.column.sortFunction){
                    this.array = this.presentValue.sort(this.column.sortFunction)
                }
                else{
                    this.array = this.presentValue?this.presentValue:[]
                }
            }
        },
        methods:{
            stringfy(item){
                if(this.column.ArrayStringfy){
                    return this.column.ArrayStringfy(item)
                }
                else {
                    return item
                }
            }
        },
        created() {
            if(this.presentValue && this.column.sortFunction){
                this.array = this.presentValue.sort(this.column.sortFunction)
            }
            else{
                this.array = this.presentValue?this.presentValue:[]
            }
        }
    }
</script>

<style scoped>

</style>