<!--
* @program: tyh-oa 
* @author: ly
* @component:DataPresent
* @description: 
* @create: 2021-03-26 11:57
-->
<template>
    <component
            :column="column" :index="index"
            :record="record"
            :viewModel="viewModel"
            :is="componentSource"
            @blur="handleBlur"
            @click="handleClick"
            @trigger="handleTrigger"
            @update="handleUpdate"
            @expanded="handleExpanded"
            style="min-height: 24px"/>

</template>

<script>
    import {Form} from 'ant-design-vue';
    import typeBooleanCheck from "./typeBooleanCheck";
    import typeDate from "./typeDate";
    import typeEnum from "./typeEnum";
    import typeString from "./typeString";
    import typeNumber from "./typeNumber";
    import typeBoolean from "./typeBoolean";
    import typeMonth from "./typeMonth";
    import typeIndex from "./typeIndex";
    import typeIdReplace from "./typeIdReplace";
    import typeIdReplace2 from "./typeIdReplace2";
    import typeSelector from "./typeSelector";
    import columnType from "../../assets/tables/column_type";
    import typeObjectReplace from "./typeObjectReplace";
    import typeRemoteEnum from "./typeRemoteEnum";
    import typeIdReplaceXmgd from "./typeIdReplaceXmgd";
    import typeYear from "./typeYear";
    import typePassword from "./typePassword";
    import typePreViewFile from "./typePreViewFile";
    import typeGeometry from "./typeGeometry";
    import typeSubSegment from "./typeSubSegment";
    import typeDepartsRole from "./typeDepartsRole";
    import typeUserRoles from "./typeUserRoles";
    import typePositionLevel from "./typePositionLevel";
    import typeAvatar from "./typeAvatar";
    import typeWrapString from "./typeWrapString";
    import typeTime from "./typeTime";
    import typeCategoryAssets from './typeCategoryAssets'
    import typeColorNumber from "./typeColorNumber";
    import typeCategory from "./typeCategory";
    import typeArray from "./typeArray";
    import typeColorText from "./typeColorText";

    export default {
        name: "DataPresent",
        emits: ["update", "trigger", "click","expanded"],
        props: {
            column: {required: true},
            record: {required: false},
            viewModel: {
                required: false,
                default: true
            },
            index: {required: false}
        },
        computed:{
            componentSource(){
                switch (this.column.type) {
                    case columnType.String:{return typeString}
                    case columnType.Number:{return typeNumber}
                    case columnType.Date:{return typeDate}
                    case columnType.BooleanCheck:{return typeBooleanCheck}
                    case columnType.Enum:{return typeEnum}
                    case columnType.Boolean:{return typeBoolean}
                    case columnType.Month:{return typeMonth}
                    case columnType.Index:{return typeIndex}
                    case columnType.IdReplace:{return typeIdReplace}
                    case columnType.IdReplace2:{return typeIdReplace2}
                    case columnType.PreViewFile:{return typePreViewFile}
                    case columnType.Selector:{return typeSelector}
                    case columnType.ObjectReplace:{return typeObjectReplace}
                    case columnType.RemoteEnum:{return typeRemoteEnum}
                    case columnType.IdReplaceXmgd:{return typeIdReplaceXmgd}
                    case columnType.Year:{return typeYear}
                    case columnType.Password:{return typePassword}
                    case columnType.Geometry:{return typeGeometry}
                    case columnType.SubSegment:{return typeSubSegment}
                    case columnType.DepartsRole:{return typeDepartsRole}
                    case columnType.UserRoles:{return typeUserRoles}
                    case columnType.PositionLevel:{return typePositionLevel}
                    case columnType.Avatar:{return typeAvatar}
                    case columnType.WrapString:{return typeWrapString}
                    case columnType.Time:{return typeTime}
                    case columnType.CategoryAssets:{return typeCategoryAssets}
                    case columnType.ColorNumber:{return typeColorNumber}
                    case columnType.Category:{return typeCategory}
                    case columnType.Array:{return typeArray}
                    case columnType.ColorText:{return typeColorText}
                    default :{return "";}
                }
            }
        },
        data() {
            return {
                formItemContext: Form.useInjectFormItemContext(),
            }
        },
        methods: {
            handleUpdate() {
                this.$emit("update");
                this.formItemContext.onFieldChange();
            },
            handleBlur() {
                this.$emit("update");
                this.formItemContext.onFieldBlur();
            },
            handleTrigger(action) {
                this.$emit("update");
                this.formItemContext.onFieldBlur();
                this.$emit("trigger", action);
            },
            handleClick() {
                this.$emit("click")
            },
            handleExpanded(key){
                this.$emit("expanded",key);
            }
        }
    }
</script>

<style scoped>

</style>