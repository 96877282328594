<!--
* @program: office_automation
* @author: ly
* @component:OtherOutcomeAuditNow
* @description:
* @create: 2022-03-24 11:51
-->
<template>
    <div  class="search-bar">
        <a-button @click="refresh"   type="dashed">
            <template #icon><SyncOutlined/></template>
        </a-button>
        <a-radio-group v-model:value="type" @change="selectChange">
            <a-radio value="4">支出记录</a-radio>
            <a-radio value="0">内部账户间转账记录</a-radio>
        </a-radio-group>
        <span>银行：</span>
        <a-select v-model:value="bank" style="width: 200px"  :options="banks" />
        <span>合计：{{sum}}</span>
        <a-button @click="enableSelect" style="margin: 10px" :type="selectable?'primary':null"  >
            批量审核
        </a-button>
        <a-button v-if="selectable" style="margin: 10px"  @click="audit2">
            <SwapOutlined class="pointer"/>
            审核
        </a-button>
    </div>
    <LTable v-if="!selectable"
            :loading="loading"
            :columns="tableColumns"
            :data-source="dataSource">
        <template #action="{record}">
            <div class="center-flex">
                <a-tooltip title="查看审核进度">
                    <a-button class="action-button" type="link" @click="stepDetail(record)">
                        <Search class="pointer"/>
                    </a-button>
                </a-tooltip>
                <a-tooltip title="支付审核">
                    <a-button class="action-button" type="link" @click="audit(record)">
                        <SwapOutlined class="pointer"/>
                    </a-button>
                </a-tooltip>
            </div>
        </template>
    </LTable>
    <LTable v-else
            @selectChange="handleSelectChange"
            :loading="loading"
            :columns="tableColumns"
            :data-source="dataSource"
            :page-size="pageParam.limit">
        <template #action="{record}">
            <div class="center-flex">
                <a-tooltip title="查看审核进度">
                    <a-button class="action-button" type="link" @click="stepDetail(record)">
                        <Search class="pointer"/>
                    </a-button>
                </a-tooltip>
            </div>
        </template>
    </LTable>
    <a-modal v-model:open="stepVisible"
             :width="800"
             destroyOnClose
             :footer="null"
             title="审核进度">
        <OtherOutcomeAuditStatus :record="formData" />
    </a-modal>
    <a-modal v-model:open="auditVisible"
             destroyOnClose
             :maskClosable="false"
             :footer="null"
             title="审核">
        <a-radio-group v-model:value="value" @change="radioChange">
            <a-radio :value="'3'">不通过</a-radio>
            <a-radio :value="'2'">通过</a-radio>
        </a-radio-group>
        <a-divider>审核意见</a-divider>
        <div style="margin: 5px">
            审核意见模板：<a-button size="small" type="link" @click="autoInput" >{{defaultComment}}</a-button>
        </div>
        <a-textarea v-model:value="comment" size="large" placeholder="请输入审核意见" :rows="4" />
        <a-popconfirm
                ok-text="是"
                cancel-text="否"
                title="提交审核吗?"
                @confirm="auditSubmit">
            <a-button size="small" type="primary" style="margin: 20px">
                提交
            </a-button>
        </a-popconfirm>
    </a-modal>
</template>

<script>
    import LTable from "@/components/table/LTable";
    import Icons from "@/page/common/Icons";
    import OtherOutcomeAuditStatus from "@/page/Finance/financialManager/OtherOutcomeAudit/OtherOutcomeAuditStatus";
    import otherPay from "@/assets/api/otherPay";
    import otherPayApproval from "@/assets/api/otherPayApproval";
    import {arrayDeleteItem} from "@/assets/utils/general";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";

    export default {
        name: "OtherOutcomeAuditNow",
        mixins:[Icons],
        components:{
            LTable,OtherOutcomeAuditStatus,
        },
        computed:{
            defaultComment(){
                return this.value === "2"?"符合要求，通过审核。":"不符合要求，不通过审核。"
            },
            dataSource(){
                if(this.type === "4"){
                    if(this.bank === "全部"){
                        return this.tableSource;
                    }
                    else{
                        return this.tableSource.filter(item=>item.payBankName === this.bank);
                    }
                }
                else{
                    if(this.bank === "全部"){
                        return this.tableSource2;
                    }
                    else{
                        return this.tableSource2.filter(item=>item.payBankName === this.bank);
                    }
                }
            },
            sum(){
                let a =0;
                for(let i in this.dataSource){
                    a += this.dataSource[i].payAmount
                }
                return a.toFixed(2);
            },
        },
        data(){
            return{
                pageParam:{
                    page: 1,
                    limit: 15,
                },

                tableColumns:null,
                tableSource:[],//存放账户支出的记录
                tableSource2:[],//存放账户转账的记录
                loading:false,
                formColumns:null,
                formData:{},
                viewModel:true,



                activeKey:"first",
                stepVisible:false,
                auditVisible:false,
                value:"2", //是否通过 审批状态(2:通过,3:不通过)
                comment:"", //审核意见
                banks:[],
                bank:"全部",
                type:"4",
                selectable:false,//批量操作
                currentIndex:null,
                selectedRows:[]
            }
        },
        methods:{
            init(){
                this.tableSource = [];
                this.tableSource2 = [];
                this.loading = true;
                let data={
                    state:"1"
                };
                this.banks=[
                    {label:"全部",value:"全部"},
                ];
                this.selectChange();
                otherPay.queryMyApproval.urlPost(data,res=>{
                    this.loading =false;
                    if(res.data.flag){
                        let source = res.data.data.sort((a,b)=>{
                            return b.payDate - a.payDate;
                        });
                        for(let i in source){
                            let arr = this.banks.filter(item=>item.label === source[i].payBankName);
                            if(arr.length ===0){
                                this.banks.push({label:source[i].payBankName,value:source[i].payBankName})
                            }
                            if(source[i].type === "4"){
                                this.tableSource.push(source[i]);
                            }
                            else{
                                this.tableSource2.push(source[i]);
                            }

                        }
                    }
                    else{
                        this.tableSource = [];
                        this.tableSource2 = [];
                    }
                });
            },
            refresh(){
                this.init();
            },
            stepDetail(record){
                this.formData = record;
                this.stepVisible = true;
            },
            audit(record){
                this.comment = "";
                this.value = "2";
                this.formData = record;
                this.auditVisible = true;
            },
            audit2(){
                this.comment = "";
                this.value = "2";
                if(this.selectedRows.length === 0){
                    this.$message.error("请选择条目");
                }
                else {
                    this.auditVisible = true;
                }
            },
            autoInput(){
                this.comment += this.defaultComment;
            },
            radioChange(){
                this.comment = "";
            },
            auditSubmit(){
                if(this.selectedRows.length>0){
                    for(let i in this.selectedRows){
                        let data = {
                            payId:this.selectedRows[i].id,
                            state:this.value,
                            remarks:this.comment
                        };
                        otherPayApproval.approvalApply.urlPost(data,res=>{
                            if(res.data.flag){
                                this.$message.success(res.data.msg);
                                this.auditVisible = false;
                                if(this.type === "4"){
                                    arrayDeleteItem(this.tableSource,this.selectedRows[i])
                                }
                                else{
                                    arrayDeleteItem(this.tableSource2,this.selectedRows[i])
                                }
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        })
                    }
                }
                else{
                    let data = {
                        payId:this.formData.id,
                        state:this.value,
                        remarks:this.comment
                    };
                    otherPayApproval.approvalApply.urlPost(data,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.auditVisible = false;
                            if(this.type === "4"){
                                arrayDeleteItem(this.tableSource,this.formData)
                            }
                            else{
                                arrayDeleteItem(this.tableSource2,this.formData)
                            }

                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }

            },
            handleSelectChange(selectedRowKeys,selectedRows){
                this.selectedRows = selectedRows;
            },
            enableSelect(){
                this.selectIds = [];
                this.selectable = !this.selectable;
            },
            selectChange(){
                this.selectable = false;
                if(this.type === "4"){
                    this.tableColumns = [
                       /* new Column("序号","_index",ColumnType.Index).setTableView(60),*/
                        new Column("支付类型","type",ColumnType.Enum).setTableView(150)
                            .setEnum(
                                {value:"1",label: "现金",color:"green"},
                                /*["1","2","3","4"],["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"],["cyan","blue","green","red"]*/),
                        new Column("费用类别","categoryName",ColumnType.String),
                        new Column("支付金额","payAmount",ColumnType.Number).setDefaultValue(0).setTableView(100),
                        new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
                        new Column("支付银行","payBankName",ColumnType.String,true).setTableView(100),
                        new Column("支付账户","payBankNum",ColumnType.String,true).setTableView(150),
                        new Column("说明","remarks",ColumnType.String,true).setTableView(200),
                        new Column("操作","actions","actions").setTableView(80)
                    ];
                }
                else{
                    this.tableColumns = [
                       /* new Column("序号","_index",ColumnType.Index).setTableView(60),*/
                        new Column("支付类型","type",ColumnType.Enum).setTableView(150)
                            .setEnum(
                                {value:"1",label: "现金",color:"green"},
                               /* ["1","2","3","4"],["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"],["cyan","blue","green","red"]*/),
                        new Column("费用类别","categoryName",ColumnType.String),
                        new Column("支付金额","payAmount",ColumnType.Number).setDefaultValue(0).setTableView(100),
                        new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
                        new Column("支付银行","payBankName",ColumnType.String,true).setTableView(100),
                        new Column("支付账户","payBankNum",ColumnType.String,true).setTableView(150),
                        new Column("接收银行","receiveBankName",ColumnType.String,true).setTableView(100),
                        new Column("接收账户","receiveBankNum",ColumnType.String,true).setTableView(150),
                        new Column("说明","remarks",ColumnType.String,true).setTableView(200),
                        new Column("操作","actions","actions").setTableView(80)
                    ];
                }
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>
