<!--
* @program: tyh-oa
* @author: ly
* @component:billStatisticsToolBar
* @description:
* @create: 2021-06-11 14:03
-->
<template>
    <div  class="search-bar">
        <div style="margin: 8px;font-weight: 900;font-size: 16px"> 选择部门：</div>
        <a-select v-model:value="departmentId" style="width: 200px" >
            <a-select-option v-for="{id,fullName} in departments" :key="id" >
                {{fullName}}
            </a-select-option>
        </a-select>
        <div class="statistic-label"> 开始时间：</div> <a-month-picker @change="check" placeholder="请选择月份" style="width: 120px" v-model:value="date1" :format="dateFormat" />
        <div class="statistic-label"> 结束时间：</div> <a-month-picker @change="check" placeholder="请选择月份" style="width: 120px" v-model:value="date2" :format="dateFormat" />

<!--      <a-range-picker
          :placeholder="['开始日期', '结束日期']"
          format="YYYY-MM"
          :value="value"
          :mode="mode2"
          @panelChange="handlePanelChange2"
          @change="handleChange"
      />-->
        <a-button  style="margin-left: 20px" type="dashed" shape="circle" @click="doSearch">
            <template #icon><Search /></template>
        </a-button>
    </div>
</template>

<script>
    import Icons from "@/page/common/Icons";
    /*import statisticsBar from "../../../../components/toolBar/statisticsBar";*/
    import api_sys_department from "@/assets/api/department";
    import dayjs from "dayjs";
    export default {
        name: "billStatisticsToolBar_date",
        mixins:[Icons],
        data(){
            return{
              departments:[{id:"all",fullName:"全部"}],
              currentComp:"monthSelector",
              departmentId:"all",
              mode2:['month', 'month'],
              value:[],

                date1:null,
                date2:null,
                dateFormat: 'YYYY-MM',
                values:["2020-01","2030-12"] //允许的时间范围
            }
        },
        created() {
            let time = parseInt(localStorage.getItem("time"));

            const yy = dayjs(time).format('YYYY');
            const ym = dayjs(time).format('YYYY-MM');
            console.log(yy,ym)
             this.init();
             this.doSearch();
        },
        methods:{
            init(){
                api_sys_department.queryAll.post({},res=>{
                    if(res.data.flag){
                        this.departments=this.departments.concat(res.data.data);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            doSearch(){
                let date1 = dayjs(this.date1).format(this.dateFormat);
                let date2 = dayjs(this.date2).format(this.dateFormat);
              if(date1 == null || date2 == null){
                this.$message.error("请选择查询时间段！")
                return;
              }
              let arg = {
                departId:this.departmentId,
                startMonth:date1,
                endMonth:date2,
              };
              if(this.departmentId === "all"){
                delete arg.departId;
              }
              let parms = {
                  type:"search",
                  arg:arg
              }
                this.$emit("event",parms);
            },

            handlePanelChange2 (val, mode){
              this.value = val;
              this.mode2 = [
                mode[0] === 'date' ? 'month' : mode[0],
                mode[1] === 'date' ? 'month' : mode[1],
              ];
            },
          handleChange(val){
            this.value = val;
          },
          get_value(){
             let arg = [];
           /* if(this.value != null && this.value.length > 0){
              arg.push(dayjs(this.value[0],'YYYY-MM'))
              arg.push(dayjs(this.value[1],'YYYY-MM'))
            }*/
              if(this.date1 != null){
                  let date1 = dayjs(this.date1).format(this.dateFormat);
                  arg.push(date1);
              }
              if(this.date2 != null){
                  let date2 = dayjs(this.date2).format(this.dateFormat);
                  arg.push(date2);
              }
              return arg;
          },

            check(){//禁止date1 时间在date2之后
                if(dayjs(this.date1).isBefore(this.values[0])){
                    this.date1 = dayjs(this.values[0]);
                }
                if(dayjs(this.date2).isAfter(this.values[1])){
                    this.date2 = dayjs(this.values[1])
                }
                if(dayjs(this.date1).isAfter(this.date2)){
                    this.date1 = this.date2
                }
            }
        },

    }
</script>

<style scoped>

</style>
