<!--
* @program: office_automation
* @author: ly
* @component:OtherOutflow
* @description: 其他支出（除项目外协支出之外）
* @create: 2022-02-16 16:55
-->
<template>
    <div class="center-flex">
        <span class="statistic-label"> 合计：{{summary}} 元</span>
        <MonthMoneySum :start-date="record.balanceDate" :records="argument.dataSource" date-seg="payDate" sum-seg="payAmount" />
    </div>
    <div style="height:600px">
    <LTable :data-source="argument.dataSource" :columns="argument.tableColumns" :view-model="true" :page-size="pageSize">
        <template #action="{record}">
            <div class="action-column">
                <a-button class="action-button" type="link" @click="showDetail(record)">
                    <Search class="pointer"/>
                </a-button>
            </div>
        </template>
    </LTable>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <LForm :form-data="formArg.formData" :form-columns="formArg.formColumns" :view-model="viewModel" >

        </LForm>
    </a-modal>
</template>

<script>
import dayjs from "dayjs";
import Icons from "@/page/common/Icons";
import LTable from "@/components/table/LTable";
import LForm from "@/components/form/LForm";
import item_other_pay from "@/page/TableStruct/item_other_pay";
import otherPay from "@/assets/api/otherPay";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import MonthMoneySum from "@/page/Finance/financialManager/BankManager/MonthMoneySum";

export default {
    name: "OtherOutflow",
    props:["record","value"],
    mixins:[Icons],
    components: {
      LTable,LForm,MonthMoneySum
    },
    computed:{
        summary(){
            let sum=0;
            for(let i in this.argument.dataSource){
                let date = this.argument.dataSource[i].payDate;
                if(dayjs(date).isBefore(dayjs(this.record.balanceDate))){
                    continue
                }
                sum += this.argument.dataSource[i].payAmount*100;
            }
            return sum/100;
        }
    },
    watch:{
        record(){
            this.init()
        }
    },
    data(){
        return{
            table: new item_other_pay(),
            viewModel:true,
            pageSize:15,
            argument:{
                dataSource:null,//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
            },
            formArg:{
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
            },
            visible:false,
        }
    },
    methods:{
        init(){
            this.visible = false;
            this.argument.loading = true;
            this.argument.dataSource = [];
            let data = {
                "approvalState": "1",
                "payBankId": this.record.id,
            };
            otherPay.queryAll.post(data,res=>{
                this.argument.loading = false;
                if(res.data.flag){
                    this.argument.dataSource = res.data.data;
                    this.$emit("update:value",{value:this.summary,loading:false});
                }
                else{
                    this.$emit("update:value",{value:0,loading:false});
                }
            })
        },
        refresh(){
            this.init()
        },
        showDetail(record){
            this.formArg.formData = record;
            this.viewModel = true;
            this.visible = true;
        },
    },
    created() {
        this.argument.tableColumns = [
           /* new Column("序号","_index",ColumnType.Index,false).setTableView(60),*/
            new Column("支付类型","type",ColumnType.Enum).setTableView(150)
                .setEnum(
                    {value:"1",label: "银行账户间转账",color:"cyan"},
                    {value:"2",label: "由银行卡取出到现金账户",color:"blue"},
                    {value:"3",label: "由现金账户存入银行卡",color:"green"},
                    {value:"4",label: "支出",color:"red"},
                ),
            new Column("费用类别","categoryId",ColumnType.String,true),
            /*new Column("费用类别","categoryId",ColumnType.Category,true),*/
            new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
            new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
            new Column("接收账户","receiveBankId",ColumnType.Selector,true).setTableView(150)
                .setSelectorObject("receiveBankNum",[]),
         /* new Column("账户名称","receiveBankName",ColumnType.String2,false).setTableView(100),*/
          new Column("账户名称","receiveBankName",ColumnType.String,false).setTableView(100),
            new Column("操作","actions","actions",false).setTableView(80)
        ];
        this.formArg.formColumns = this.table.getFormColumns();
        this.init();
    }
}
</script>

<style scoped>

</style>
