/**
 * @program: office_automation
 * @author: ly
 * @description: companies
 * @create: 2021-12-15 18:40
 * 数据来源为数据库
 *
 以前的指代
 ["0","1","2","3","4"],
 ["贵州图云慧空间信息技术有限公司",
 "北京中色地科测绘有限公司",
 "北京中色地科测绘有限公司贵州分公司",
 "北京中色测绘院有限公司",
 "河北中色测绘有限公司"]

**/


let companyIds = [
    "2b5f21c6-750e-4f98-b0c1-c7068b700a27",
    "70f61478-c716-477c-b0e7-9f2e333e6538",
    "0d89dd47-1f19-4e97-a565-eae226d6d743",
    "1527858e-4494-4647-ab87-ebfcc2ec8935",
    "aa60eee4-8212-4ccd-a21d-31fb7dff6f3f",
    "9fac494d-d74e-49d2-ab39-7da87e0783a9",
    "5ce1d392-cf46-43a0-8097-fc1d37f57784",
    "15b60020-4691-4d3c-aa38-cd5e54b3b784",
    "d0bc5391-7367-4b5b-97fb-12d7fc67cc6d",
    "0303b748-93b2-4ae4-b737-558bf2f8b282"
];

let companyNames = [
    "贵州图云慧空间信息技术有限公司",
    "北京中色测绘院有限公司",
    "北京中色地科测绘有限公司贵州分公司",
    "河北中色测绘有限公司",
    "北京中色地科测绘有限公司",
    "中色蓝图科技股份有限公司贵州分公司",
    "贵州中色蓝图科技有限公司",
    "中色蓝图科技股份有限公司",
    "贵州中色蓝图科技有限公司兴义分公司",
    "贵州中色蓝图科技有限公司花溪分公司"
];

let companyColors = ["gold","green","purple","cyan",
    "pink","purple","gold","green"];

let companyList = [
    {value:"2b5f21c6-750e-4f98-b0c1-c7068b700a27",label:"贵州图云慧空间信息技术有限公司",color:"gold"},
    {value:"70f61478-c716-477c-b0e7-9f2e333e6538",label:"北京中色测绘院有限公司",color:"green"},
    {value:"0d89dd47-1f19-4e97-a565-eae226d6d743",label:"北京中色地科测绘有限公司贵州分公司",color:"purple"},
    {value:"1527858e-4494-4647-ab87-ebfcc2ec8935",label:"河北中色测绘有限公司",color:"cyan"},
    {value:"aa60eee4-8212-4ccd-a21d-31fb7dff6f3f",label:"北京中色地科测绘有限公司",color:"pink"},
    {value:"9fac494d-d74e-49d2-ab39-7da87e0783a9",label:"中色蓝图科技股份有限公司贵州分公司",color:"purple"},
    {value:"5ce1d392-cf46-43a0-8097-fc1d37f57784",label:"贵州中色蓝图科技有限公司",color:"purple"},
    {value:"15b60020-4691-4d3c-aa38-cd5e54b3b784",label:"中色蓝图科技股份有限公司",color:"gold"},
    {value:"d0bc5391-7367-4b5b-97fb-12d7fc67cc6d",label:"贵州中色蓝图科技有限公司兴义分公司",color:"green"},
    {value:"0303b748-93b2-4ae4-b737-558bf2f8b282",label:"贵州中色蓝图科技有限公司花溪分公司",color:"purple"},
]
export {companyIds,companyNames,companyColors,companyList}
