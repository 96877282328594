import Table from "@/assets/tables/table";
import ColumnType from "@/assets/tables/column_type";
import Column from "@/assets/tables/column";

/**
 * @program: TYH_office_automation
 * @author: ly
 * @description: device
 * @create: 2021-06-21 10:56
 **/

export default class device  extends Table{
    constructor() {
        super();
        this.name = "device ";
        this.CNName = "设备表";
        this.columnArray = [
            new Column("类别","categoryId",ColumnType.CategoryAssets,true).setTableView(200),
            new Column("名称","name",ColumnType.String,true).setTableView(150),
            new Column("编码类别","codeName",ColumnType.Enum,true)
                .setDictEnum("codeName").setTableView(60),
            new Column("编码","code",ColumnType.String,true).setTableView(100),
            new Column("设备参数","remarks",ColumnType.String,true).setTableView(100),
            new Column("主/附属设备","mainId",ColumnType.Boolean).setBoolTags(["主设备","附属设备"]),
            new Column("健康状态","state",ColumnType.String,false).setDefaultValue("1").setTableView(80)
                .setDictEnum("device.state"),
            new Column("用途","permanentFlag",ColumnType.String,false).setDefaultValue("0").setTableView(80)
                .setDictEnum("permanentFlag"),
            new Column("录入时间","createTime",ColumnType.Date),
            new Column("购入日期","buyDate",ColumnType.Date,true),
            new Column("价值（元）","price",ColumnType.Number,true),
            new Column("使用人","currentUseUserName",ColumnType.String).setTableView(60),
            new Column("设备说明","param",ColumnType.String).setTableView(150),
           //new Column("设备状态","state",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(150)];
    }
}