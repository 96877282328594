<!--
* @program: tnjt_project 
* @author: ly
* @component:VForm 
* @description: 基于LForm,只显示FormData内的FormData[key]不为null || undefined || ""的字段
* @create: 2024-04-28 10:02
-->
<template>
    <div style="margin: 5px">
        <div class="form-title" v-if="title">{{title}}</div>
        <a-form :model="lfd" layout="horizontal" size="small">
            <a-row :key="index" v-for="(group,index) in groupCols">
                <a-col :key="idx" :span="column.formColSpan" v-for="(column,idx) in group">
                    <a-form-item
                            :labelCol="column.labelCol"
                            :name="column.dataIndex"
                            :rules="viewModel?[]:column.rules"
                            :wrapperCol="column.wrapperCol"
                            style="display: flex;align-items: center;">
                        <template #label>
                            <span class="form-label">{{column.title}}</span>
                        </template>
                        <DataPresent class="form-value" :column="column" :record="lfd" :view-model="viewModel"
                                     @trigger="handleTrigger" @update="handleUpdate" />
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
        <slot :columns="cols" :record="lfd" name="action" v-if="!viewModel"/>
    </div>
</template>

<script>
    import LForm from "./LForm";
    export default {
        name: "VForm",
        mixins:[LForm],
        computed: {
            cols() {
                if (this.formColumns === null) {
                    return [];
                }
                if (!this.viewModel) {
                    return this.formColumns.filter(col => {
                        return col.editable === true && col.visible ;
                    });
                } else {
                    return this.formColumns.filter(col => col.visible && CheckValue(col,this.formData));
                }
            },
        }
    }
    /**
     * @return {boolean}
     */
    function CheckValue(col,formData) {
        let dataIndex = col.dataIndex;
        return formData[dataIndex] !== null && formData[dataIndex] !== undefined && formData[dataIndex] !== "";
    }
</script>

<style scoped>

</style>