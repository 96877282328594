<!--
* @program: TYHOA2 
* @author: ly
* @component:MonthlyLeaderArrangePlan 
* @description: 
* @create: 2024-08-12 10:08
-->
<template>
    <div>
        <a-row style="margin-bottom: 10px;">
            <a-col :span="2">
                <div class="form-label" style="margin-right: 10px">年月</div>
            </a-col>
            <a-col :span="4">
                <div class="form-value">
                    <a-date-picker @change="monthSelect" v-model:value="lym" picker="month" />
                </div>
            </a-col>
            <a-col v-if="lym" :span="4">
                <div class="form-label" style="margin-right: 10px">占比合计(%)</div>
            </a-col>
            <a-col v-if="lym" :span="2">
                <div class="form-value">
                    {{completeRatio}}
                </div>
            </a-col>
            <a-col v-if="lym" :span="8">
                <div class="form-value" style="color:red">
                    <span v-if="completeRatio<100">不足100%</span><span v-if="completeRatio>100">超过100%</span>
                </div>
            </a-col>
        </a-row>
        <a-row style="padding: 10px; border-bottom: #022d5c dashed 1px" v-for="user in plan" :key="user.userId">
            <a-col :span="4">
                <a-button @click="remove(user)" type="link" danger>
                    <template #icon>
                        <MinusCircleOutlined />
                    </template>
                    移除
                </a-button>
            </a-col>
            <a-col :span="4">
                <div class="form-label" style="margin-right: 10px">{{user.userName}}</div>
            </a-col>
            <a-col :span="4">
                <div class="form-label" style="margin-right: 10px">工作量占比(%)</div>
            </a-col>
            <a-col :span="5">
                <div class="form-value">
                    <a-input-number :min="0" :max="100" :precision="2" style="width:100%" v-model:value="user.completeRatio"/>
                </div>
            </a-col>
        </a-row>
        <a-button v-if="lym" style="margin: 10px" type="primary" @click="saveData">提交</a-button>
    </div>
</template>

<script>
    import dayjs from "dayjs"
    import {MinusCircleOutlined} from "@ant-design/icons-vue"
    export default {
        name: "MonthlyLeaderArrangePlan",
        props:["users","departmentId","yearMonth"],
        emits:["finish","change"],
        components:{
            MinusCircleOutlined
        },
        computed:{
            completeRatio(){
                let sum = 0;
                for(let item of this.plan){
                    sum += item.completeRatio
                }
                return sum *100 /100
            }
        },
        watch:{
            users(){
                this.init()
            }
        },
        data() {
            return {
                columns:[],
                plan:[],
                completeMoney:0,
                lym:null
            }
        },
        methods: {
            init() {
                this.plan = [];
                if(this.users && this.users.length>0){
                    for(let user of this.users){
                        let p = {...user};
                        p.deptId = this.departmentId;
                        this.plan.push(p)
                    }
                }
            },
            saveData(){
                if(this.completeRatio !== 100){
                    this.$message.error("合计占比不为100%");
                    return
                }
                if(!this.lym){
                    this.$message.error("请选择年月")
                    return;
                }
                for(let user of this.plan){
                    user.yearMonth = this.lym.format("YYYY-MM")
                }
                this.plan = this.plan.filter(item =>item.completeRatio > 0);
                this.$emit("finish",this.plan);
            },
            monthSelect(){
                if(this.lym){
                    this.$emit("change",this.lym.format("YYYY-MM"));
                }
                else{
                    this.$emit("change","");
                }
            },
            remove(user){
                this.plan = this.plan.filter(item =>item.userId !== user.userId)
            }
        },
        created() {
            this.init()
            if(this.yearMonth){
                this.lym = dayjs(this.yearMonth)
                this.$emit("change",this.lym.format("YYYY-MM"));
            }
        }
    }
</script>

<style scoped>

</style>